import * as angular from 'angular';
import OfferSequenceService from '../../../common/offers/OfferSequenceService';
import { logger } from 'helpers';
import CoupledWindowActiveConfiguration from 'configurations/CoupledWindowActiveConfiguration';

type ConfiguratorMode =
    | 'COMPLETELY_NEW'
    | 'NEW_NEXT'
    | 'NEW_FIRST_OFFER'
    | 'NEW_GROUP'
    | 'EDIT'
    | 'NEW_FROM_GROUP'
    | 'SIMILAR_FROM_GROUP'
    | 'COUPLED_WINDOW';

export default function ConfiguratorCtrl(
    $scope,
    $location,
    $window,
    $http,
    $filter,
    $rootScope,
    Core,
    DrawService,
    PositionsFactory,
    StepFactory,
    CurConfService,
    ConfiguratorsDataService,
    ConfigurationsService,
    DrawDataService,
    ParametersModalService,
    GtmService,
    LayoutFactory,
    SashesLayoutService,
    IssuesService,
    PositionAttachmentsFactory,
    IccConfig,
    InfoFactory,
    DatabaseManager,
    OffersGroupFactory,
    OffersFactory,
    OffersService,
    hotkeys,
    PriceService,
    ColorsFactory,
    ShapeService,
    NewPositionService,
    DependenciesService,
    SealColorsService,
    GlazingBeadColorsService,
    EventBusService,
    ValidationService,
    TimeLimitService,
    StateService,
    DimensionsFactory,
    EnvConfig,
    ConfiguratorsAvailabilityService,
    SystemsComparisonService,
    SiliconeColorsService,
    CoupledWindowService
) {
    'ngInject';

    //#region zmienne
    const vm = this;
    const adminMistake = sessionStorage.getItem('adminMistake');

    vm.step = StepFactory.getStep();
    vm.stepCode = StepFactory.getCode();
    vm.goToNextStep = StepFactory.goToNextStep.bind(StepFactory);
    vm.nextStepIndex = getNextStepIndex;
    vm.conf = CurConfService.conf;
    vm.num = Core.num;
    vm.currentConfig = ConfigurationsService.conf.Current;
    vm.defaultConfig = ConfigurationsService.conf.Default;
    vm.editConfig = ConfigurationsService.conf.Edit;
    vm.systemsComparisonEdit = SystemsComparisonService.edit;
    vm.noPrice = ConfigurationsService.price;
    vm.version = ConfigurationsService.version;
    vm.isLastStep = isLastStep;
    vm.currentDrawData = DrawDataService;
    vm.location = $location;
    vm.pos_count = {};
    vm.b2c = !$rootScope.user || !$rootScope.user.access || $rootScope.user.access === 'klient';
    vm.adminThumb = ~~sessionStorage.getItem('adminThumb');
    vm.adminLayout = ~~sessionStorage.getItem('adminLayout');
    vm.adminMistake = adminMistake ? (adminMistake === 'true' ? true : adminMistake) : false;
    vm.newSimilarPosition = newSimilarPosition;
    vm.makeSimilarPosition = makeSimilarPosition;
    vm.save = save;
    vm.saveRedirect = saveRedirect;
    vm.saveThumbnail = saveThumbnail;
    vm.saveInfo = {};
    vm.savePopUp = false;
    vm.newPositionFromGroup = newPositionFromGroup;
    vm.lastPositionGroupCode = null;
    vm.addProduct = addProduct;
    vm.gtmSimilarPosition = gtmSimilarPosition;
    vm.availableConfigs = ConfiguratorsAvailabilityService.availableConfigs;
    vm.createCoupledWindow = createCoupledWindow;
    vm.saveSystemsComparisonConfig = saveSystemsComparisonConfig;
    vm.zoomIn = false;
    vm.maxWidth = window.innerWidth;
    vm.maxHeight = window.innerHeight - 100;

    vm.systemsComparison = SystemsComparisonService.systemsComparison
        .bind(SystemsComparisonService);

    vm.drawService = DrawService;

    let lockSave = false;
    let initialized = false;
    //#endregion

    //#region subskrypcje
    const broadcastSubscriptions = [];
    broadcastSubscriptions.push(
        $rootScope.$on('loadedProductMistake', () => {
            vm.currentConfig = ConfigurationsService.conf.Current;
            vm.step = StepFactory.getStep();
            vm.stepCode = StepFactory.getCode();
        })
    );
    broadcastSubscriptions.push(
        $rootScope.$on('warranty', (ev, val) => {
            if (IccConfig.Configurators.warrantyRestrictions && !val) {
                InfoFactory.showInfo(
                    $filter('translate')(
                        'OFFER|BRAK GWARANCJI: jedna lub więcej kwater w konstrukcji przekracza wymiary - na konstrukcję nie można udzielić gwarancji.'
                    ),
                    null
                );
            }
        })
    );

    const subscriptions = [];
    subscriptions.push(
        EventBusService.subscribeWithoutConfiguration('changedConfigurator', () => {
            IssuesService.clearValidateFunctions();
            vm.conf = CurConfService.conf;

            vm.currentConfig = ConfigurationsService.conf.Current;
            vm.editConfig = ConfigurationsService.conf.Edit;
            vm.version = ConfigurationsService.version || 1;

            init();
            initialized = true;
        })
    );

    subscriptions.push(
        EventBusService.subscribeWithoutConfiguration('wideSash', data => {
            if (IccConfig.Configurators.suggestWideSash && data.value) {
                InfoFactory.showInfo(
                    $filter('translate')('WINDOW|Sugerowane skrzydło szerokie'),
                    null
                );
            }
        })
    );
    subscriptions.push(
        EventBusService.subscribeWithoutConfiguration(
            ['availableConfigs'],
            data => (vm.availableConfigs = data.value)
        )
    );

    const resizeListener = () => {
        vm.maxWidth = window.innerWidth;
        vm.maxHeight = window.innerHeight - 100;
    };

    window.addEventListener('resize', resizeListener);

    $scope.$on('$destroy', () => {
        subscriptions.map(el => el.unsubscribe());
        broadcastSubscriptions.map(el => el());
        window.removeEventListener('resize', resizeListener);
        SystemsComparisonService.configs = [];
        SystemsComparisonService.edit.value = false;
    });

    $scope.$on('changedStep', () => {
        vm.step = StepFactory.getStep();
        vm.stepCode = StepFactory.getCode();
    });

    $scope.$on('$locationChangeSuccess', (event, newUrl, oldUrl) => {
        IssuesService.clearValidateFunctions();
        if (angular.isDefined(ConfigurationsService.conf.Edit)) {
            if (
                oldUrl.indexOf('/app/coupled_window') === -1
                || (newUrl.indexOf('/app/window') === -1
                    && newUrl.indexOf('/app/door') === -1
                    && newUrl.indexOf('/app/hs') === -1
                    && newUrl.indexOf('/app/folding_door') === -1
                    && newUrl.indexOf('/app/roller_shutter') === -1)
            ) {
                ConfigurationsService.conf.Edit = undefined;
            }
            ConfigurationsService.conf.Current = ConfigurationsService.createActiveConfiguration();
            ConfigurationsService.conf.Default = ConfigurationsService.createActiveConfiguration();
        }
    });

    //#endregion

    hotkeys
        .bindTo($scope)
        .add({
            combo: 'mod+shift+4',
            description: 'Szczegóły wyceny',
            callback: () => {
                PriceService.showPriceDetailsModal(ConfigurationsService.conf.Current);
            },
        })
        .add({
            combo: 'mod+shift+l',
            description: 'Szczegóły wyceny',
            callback: () => {
                PriceService.showPriceDetailsModal(ConfigurationsService.conf.Current);
            },
        })
        .add({
            combo: '%',
            description: 'Parametry Uw/Sw',
            callback: () => {
                ParametersModalService.showModal(ConfigurationsService.conf.Current);
            },
        });

    const s = StateService.state;
    if (angular.isObject(s.offer)) {
        $rootScope.currency = s.offer.currency;
        vm.pos_count = OfferSequenceService.getPositionsCountByTypeFromSequence(s.offer.sequence);
    } else {
        // pobierz domyślną cenę gdy nie ma oferty
        ConfiguratorsDataService.dataAsync()
            .then(data => {
                $rootScope.currency =
                    data.currencies[data.markets[$rootScope.user.market || 'P'].currency];
            })
            .catch(errHandler2 => {
                logger.error(errHandler2);
            });
    }
    if (!initialized) {
        init();
    }

    async function init() {
        const configuratorMode: ConfiguratorMode = 'NEW_FIRST_OFFER';
        if (
            angular.isDefined($rootScope.completelyNewPosition)
            && $rootScope.completelyNewPosition === true
            && ConfigurationsService.conf.Current.type !== 'coupled_window'
        ) {
            await loadConfigurator('COMPLETELY_NEW');
        } else {
            if (
                angular.isDefined(ConfigurationsService.conf.Edit)
                && angular.isDefined(ConfigurationsService.conf.Edit.type)
            ) {
                await loadConfigurator('EDIT');
                if (IccConfig.Configurators.dependencies) {
                    EventBusService.post({ key: 'processDependencies', value: null });
                }
            } else if (ConfigurationsService.conf.Current.type === 'coupled_window') {
                await loadConfigurator('COUPLED_WINDOW');
            } else if (
                (angular.isDefined(ConfigurationsService.conf.Current.System)
                    && angular.isDefined(ConfigurationsService.conf.Current.System.id))
                || (angular.isDefined(ConfigurationsService.conf.Current.RollerShutter)
                    && angular.isDefined(ConfigurationsService.conf.Current.RollerShutter.system)
                    && angular.isDefined(
                        ConfigurationsService.conf.Current.RollerShutter.system.id
                    )
                    && angular.isUndefined(ConfigurationsService.conf.addPositionToGroup))
            ) {
                if (StateService.state && angular.isObject(StateService.state.offer)) {
                    vm.pos_count = OfferSequenceService.getPositionsCountByTypeFromSequence(
                        StateService.state.offer.sequence
                    );
                }
                await newSimilarPosition(vm.pos_count && !vm.pos_count[vm.conf]);
            } else {
                await loadConfigurator('NEW_FIRST_OFFER');
            }
            if (IccConfig.GTM.allowTags) {
                EventBusService.post({
                    key: 'googleTags',
                    value: $scope.config.conf + '.' + $scope.config.stepCode,
                });
            }
        }
    }

    /**
     * Tworzenie nowej konstrukjcji podobnej do ostatnio dodanej
     */
    async function newSimilarPosition(newOffer = false) {
        $rootScope.completelyNewPosition = false;
        if (
            !newOffer
            && ((vm.currentConfig.System && vm.currentConfig.System.id)
                || $rootScope.coupledPosition)
        ) {
            await loadConfigurator('NEW_NEXT');
        } else if (
            angular.isDefined(ConfigurationsService.conf.addPositionToGroup)
            && ConfigurationsService.conf.addPositionToGroup
        ) {
            await loadConfigurator('NEW_GROUP');
            ConfigurationsService.conf.addPositionToGroup = false;
        } else {
            await loadConfigurator('NEW_FIRST_OFFER');
        }
    }

    /**
     * Tworzenie nowej konstrukjcji podobnej do ostatnio dodanej
     */
    function newPositionFromGroup(firstStep = false) {
        lockSave = false;
        $rootScope.completelyNewPosition = false;
        if (firstStep) {
            loadConfigurator('SIMILAR_FROM_GROUP');
        } else {
            loadConfigurator('NEW_FROM_GROUP');
        }
    }

    async function loadConfigurator(mode: ConfiguratorMode) {
        let configurationMode: 'NEW' | 'NEW_SIMILAR' | 'EDIT' | 'GROUP' | 'COUPLED' = 'NEW_SIMILAR';
        let stepsMode: 'FIRST' | 'DEFAULT';
        let stepsFireEvent: boolean = true;
        switch (mode) {
            case 'COMPLETELY_NEW':
                configurationMode = 'NEW';
                stepsMode = 'FIRST';
                stepsFireEvent = false;
                break;
            case 'NEW_NEXT':
                configurationMode = 'NEW_SIMILAR';
                stepsMode = 'DEFAULT';
                stepsFireEvent = false;
                break;
            case 'NEW_FIRST_OFFER':
                configurationMode = 'NEW';
                stepsMode = 'FIRST';
                stepsFireEvent = false;
                break;
            case 'NEW_GROUP':
                configurationMode = 'NEW_SIMILAR';
                stepsMode = 'DEFAULT';
                stepsFireEvent = false;
                break;
            case 'NEW_FROM_GROUP':
                configurationMode = 'GROUP';
                stepsMode = 'DEFAULT';
                stepsFireEvent = false;
                break;
            case 'SIMILAR_FROM_GROUP':
                configurationMode = 'GROUP';
                stepsMode = 'FIRST';
                stepsFireEvent = false;
                break;
            case 'EDIT':
                configurationMode = 'EDIT';
                stepsMode = 'DEFAULT';
                stepsFireEvent = false;
                break;
            case 'COUPLED_WINDOW':
                configurationMode = 'COUPLED';
                stepsMode = 'DEFAULT';
                stepsFireEvent = false;
                break;
        }
        setSteps(stepsMode, stepsFireEvent);
        loadSteps();
        const configuratorsData = await ConfiguratorsDataService.load();
        await loadConfigurations(configurationMode);
        EventBusService.post({
            key: 'initializedConfigurator',
            value: {
                mode,
                type: CurConfService.conf,
                data: configuratorsData,
            },
        });
    }

    async function loadConfigurations(mode: 'NEW' | 'NEW_SIMILAR' | 'EDIT' | 'GROUP' | 'COUPLED') {
        if (mode === 'NEW_SIMILAR') {
            ConfigurationsService.conf.Current = angular.copy(ConfigurationsService.conf.Default);
        } else {
            let sourceConfiguration;

            if (mode === 'EDIT') {
                sourceConfiguration = ConfigurationsService.conf.Edit;
                if (
                    vm.conf === 'coupled_window'
                    && CoupledWindowService.editing
                    && (!ConfigurationsService.conf.Edit
                        || ConfigurationsService.conf.Edit.windows.length === 0)
                ) {
                    ConfigurationsService.conf.Edit = CoupledWindowService.currentCoupledWindow;
                }
            } else if (mode === 'COUPLED') {
                sourceConfiguration = CoupledWindowService.currentCoupledWindow;
                if (CoupledWindowService.editing) {
                    ConfigurationsService.conf.Edit = sourceConfiguration;
                }
            }

            ConfigurationsService.conf.Current = ConfigurationsService.createActiveConfiguration(
                sourceConfiguration
            );
            ConfigurationsService.conf.Default = ConfigurationsService.createActiveConfiguration(
                sourceConfiguration
            );
        }
        if (mode === 'GROUP') {
            const offer = await OffersService.get(StateService.state.offer_id);
            if (angular.isDefined(offer.positions_groups_data[vm.lastPositionGroupCode])) {
                OffersGroupFactory.addPositionToGroupConf(
                    ConfigurationsService.conf.conf,
                    offer.positions_groups_data[vm.lastPositionGroupCode]
                );
            }
        }
        vm.currentConfig = ConfigurationsService.conf.Current;
        vm.editConfig = ConfigurationsService.conf.Edit;

        if (
            (mode === 'COUPLED' || mode === 'EDIT')
            && ConfigurationsService.conf.Current
            && ConfigurationsService.conf.Current.type === 'coupled_window'
        ) {
            CoupledWindowService.setCoupledWindow(
                ConfigurationsService.conf.Current,
                mode === 'EDIT'
            );
        }

        if (mode === 'EDIT') {
            loadEditData();
        }
    }

    function loadEditData() {
        DrawService.init();
        const configurators = ['window', 'hs', 'door', 'folding_door'];
        if (configurators.indexOf(vm.conf) > -1) {
            EventBusService.post({
                key: 'icc-redraw',
                value: null,
            });
            SashesLayoutService.rebuildRefs(ConfigurationsService.conf.Current);
            LayoutFactory.prepareSashesLayout(
                ConfiguratorsDataService.getLayout(ConfigurationsService.conf.Edit.Layout.id)
            );
            ValidationService.valid(ConfigurationsService.conf.Current, 'sashes');
            DimensionsFactory.setDimensions(ConfigurationsService.conf.Current.Shape);
            ShapeService.setShapes(ConfigurationsService.conf.Current);
        }
    }

    function setSteps(mode: 'FIRST' | 'DEFAULT', fireEvent = true) {
        StepFactory.setSteps({ conf: vm.conf });
        switch (mode) {
            case 'FIRST':
                StepFactory.selectStep(1, !fireEvent);
                break;
            case 'DEFAULT':
                StepFactory.setDefaultStep(!fireEvent);
                break;
        }
    }

    function loadSteps() {
        vm.step = StepFactory.getStep();
        vm.stepCode = StepFactory.getCode();
    }

    function isLastStep() {
        const currentStep = StepFactory.getStep();
        const lastStep = StepFactory.getLastStep().step;
        return currentStep === lastStep;
    }

    /**
     * Funkcja wysyła wygenerowany obrazek
     */
    function saveThumbnail() {
        if (vm.adminThumb) {
            if ($('.step3 .icc-draw > svg').length > 0) {
                $rootScope.showInfo(
                    $filter('translate')('CONFIGURATOR|Trwa generowanie rysunku'),
                    null,
                    null,
                    20000
                );
                $rootScope.loader = true;
                const svg = $('.step3 .icc-draw > svg')[0].outerHTML;
                $http
                    .post(
                        `${EnvConfig.remoteHost
                            || window.location
                                .origin}/admin/window/sashes_layouts_variants/generate_thumb/${
                            vm.adminThumb
                        }`,
                        {
                            svg,
                            width: 200,
                            height: 200,
                            shape: vm.currentConfig.Shape,
                        }
                    )
                    .then(data => {
                        window.location.href =
                            '/admin/window/sashes_layouts_variants/index/'
                            + (vm.adminLayout || (window as any).sashesLayoutId || data.data);
                    });
            }
        }
    }

    /**
     * Pobiera indeks (numer kroku) kolejnego kroku.
     * @return {int} Numer kolejnego kroku.
     */
    function getNextStepIndex() {
        let step = -1;

        for (let i = 0; i < StepFactory.steps.length; ++i) {
            if (StepFactory.steps[i].step === vm.step) {
                if (angular.isDefined(StepFactory.steps[i + 1])) {
                    step = StepFactory.steps[i + 1].step;
                    break;
                }
            }
        }

        return step;
    }

    let newPosition;

    /**
     * Funkcja zapisuje tworzon lub edytowan konstrukcję jako pozycja w ofercie
     * @param  {boolean} redirect  Przekierowanie
     * @param  {boolean} savePopup Popup
     */
    function save(redirect = false, savePopUp) {
        if (IccConfig.Configurators.tutorialAvailable) {
            EventBusService.post({
                key: 'tutorialSteps',
                value: 'empty',
            });
        }
        EventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });

        localStorage.setItem('been', 'true');

        const timeout = setTimeout(
            () =>
                logger.error(
                    new Error(
                        `Zapisywanie pozycji trwało dłużej niż ${
                            IccConfig.Raven.savePositionTimeout
                        }s.`
                    )
                ),
            IccConfig.Raven.savePositionTimeout * 1000
        );
        const isStandard =
            !isNaN(vm.currentConfig.Price)
            && vm.currentConfig.Price !== null
            && Core.isEmpty(vm.currentConfig.NoPriceElems)
                ? true
                : false;

        LayoutFactory.validateLayout(true, false);
        IssuesService.validateAll(vm.currentConfig);

        $rootScope.mistakeProductLoaded = false;
        vm.saveInfo.error = 1;
        $rootScope.mainStep = 3;

        const offerId = StateService.state.offer_id;
        if (offerId && !IssuesService.isBlockedAddToOffer(vm.currentConfig)) {
            if (lockSave) {
                clearTimeout(timeout);
                return;
            }
            lockSave = true;
            OffersService.get(s.offer_id).then(doc => {
                $rootScope.mistakeProductExcepts = [];
                delete ConfigurationsService.conf.addPositionToGroup;
                localStorage.removeItem('colors');

                if (~~doc.order && !isStandard) {
                    $rootScope.showInfo(
                        $filter('translate')(
                            'OFFER|Pozycje bez wyceny można dodać tylko do oferty, nie podczas edycji zamówienia.'
                        )
                    );
                } else if (
                    angular.isUndefined(ConfigurationsService.conf.Edit)
                    || ConfigurationsService.conf.NewPosition
                ) {
                    const details = ConfigurationsService.createSimpleConfiguration(
                        vm.currentConfig
                    );

                    PositionsFactory.add({
                        configuration: vm.currentConfig,
                        details,
                        offer: doc,
                        image: Core.svgToPngData($('.smallWindowPreviewX')[0]),
                        price: vm.currentConfig.Price,
                        price_no_margin: vm.currentConfig.PriceNoMargin,
                        custom_title: vm.currentConfig.Title,
                        description: vm.currentConfig.Description,
                        standard: isStandard,
                    })
                        .then(pos => {
                            newPosition = pos;
                            vm.lastPositionGroupCode = pos.groupCode;
                            return PositionAttachmentsFactory.addMulti(
                                vm.currentConfig.Attachments || [],
                                pos
                            );
                        })
                        .then(() => {
                            if (
                                ConfigurationsService.conf.NewPosition
                                || angular.isDefined(ConfigurationsService.conf.Edit)
                            ) {
                                delete ConfigurationsService.conf.Edit;
                                delete ConfigurationsService.conf.NewPosition;
                                ConfigurationsService.conf.Default = ConfigurationsService.createActiveConfiguration();
                            }
                            if (
                                !$rootScope.coupledPosition
                                || vm.currentConfig.type === 'coupled_window'
                            ) {
                                if (savePopUp) {
                                    if (IccConfig.GTM.allowTags) {
                                        EventBusService.post({
                                            key: 'googleTags',
                                            value: 'savePopUp',
                                        });
                                    }
                                    vm.savePopUp = true;
                                }
                                saveCoupledWindows(vm.currentConfig, newPosition, doc).then(() => {
                                    if (
                                        redirect
                                        || !IccConfig.Configurators.showAfterAddingWindow
                                    ) {
                                        saveRedirect();
                                    } else {
                                        vm.saveInfo.message = $filter('translate')(
                                            'OFFER|Konstrukcja została dodana do oferty'
                                        );
                                        vm.saveInfo.error = 0;
                                    }
                                });
                            } else {
                                CoupledWindowService.addNewElement(
                                    newPosition.tmp_id,
                                    details,
                                    newPosition.groupCode
                                );
                                $location.url('/app/coupled_window');
                            }
                            $scope.$apply();
                            clearTimeout(timeout);
                        });
                } else {
                    delete ConfigurationsService.conf.addPositionToGroup;
                    const details = ConfigurationsService.createSimpleConfiguration(
                        vm.currentConfig
                    );
                    PositionsFactory.updateEdit(ConfigurationsService.conf.Edit._id, {
                        configuration: vm.currentConfig,
                        details,
                        offer: doc,
                        image: Core.svgToPngData($('.smallWindowPreviewX')[0]),
                        price: vm.currentConfig.Price,
                        price_no_margin: vm.currentConfig.PriceNoMargin,
                        custom_title: vm.currentConfig.Title,
                        description: vm.currentConfig.Description,
                        standard: isStandard,
                        valuated_price: null,
                    })
                        .then(pos => {
                            newPosition = pos;
                            return PositionAttachmentsFactory.addMulti(
                                vm.currentConfig.Attachments || [],
                                pos
                            );
                        })
                        .then(() => {
                            return PositionAttachmentsFactory.removeMulti(
                                vm.currentConfig.DeletedAttachments || []
                            );
                        })
                        .then(
                            () => {
                                delete ConfigurationsService.conf.Edit;
                                ConfigurationsService.conf.Default = ConfigurationsService.createActiveConfiguration();
                                if (
                                    !$rootScope.coupledPosition
                                    || vm.currentConfig.type === 'coupled_window'
                                ) {
                                    saveCoupledWindows(vm.currentConfig, newPosition, doc).then(
                                        () => {
                                            newSimilarPosition().then(() => {
                                                if (
                                                    angular.isDefined(redirect)
                                                    || !IccConfig.Configurators
                                                        .showAfterAddingWindow
                                                ) {
                                                    saveRedirect();
                                                } else {
                                                    vm.saveInfo.message = $filter('translate')(
                                                        'OFFER|Edycja konstrukcji została zapisana'
                                                    );
                                                    vm.saveInfo.error = 0;
                                                }
                                                clearTimeout(timeout);
                                            });
                                        }
                                    );
                                } else {
                                    CoupledWindowService.updateElement(
                                        newPosition.tmp_id,
                                        details,
                                        newPosition.groupCode
                                    );
                                    clearTimeout(timeout);
                                    $location.url('/app/coupled_window');
                                }
                            },
                            e => {
                                logger.error(e);
                            }
                        );
                }
            });
        } else {
            if (!StateService.state.offer_id) {
                vm.saveInfo.message = $filter('translate')('OFFER|Nie wybrano ofery');
            } else if (IssuesService.isBlockedAddToOffer(vm.currentConfig)) {
                vm.saveInfo.message = $filter('translate')(
                    'CONFIGURATOR|Niepoprawna konfiguracja.'
                );
            }
            vm.savePopUp = savePopUp;
            vm.saveInfo.error = 1;
            clearTimeout(timeout);
        }
    }

    /**
     * Przekierowanie do oferty po dodaniu pozycji
     */
    function saveRedirect() {
        if (IccConfig.GTM.allowTags) {
            EventBusService.post({
                key: 'googleTags',
                value: 'saveRedirect',
            });
        }
        $location.url('/app/offers_view/' + StateService.state.offer_id);
    }

    /**
     * Dodawanie nowej pozycji
     */
    function addProduct() {
        lockSave = false;
        NewPositionService.openModal(
            this.offer,
            () => {
                $location.url('/app/offers_view/');
                vm.savePopUp = false;
            },
            () => {
                newSimilarPosition();
                vm.savePopUp = false;
            }
        );
    }

    /**
     * Wysłanie do gtm info o tworzeniu podobnej pozycji
     */
    function gtmSimilarPosition() {
        lockSave = false;
        $rootScope.completelyNewPosition = false;
        if (IccConfig.GTM.allowTags) {
            EventBusService.post({
                key: 'googleTags',
                value: 'similarPosition',
            });
        }
        if (IccConfig.Configurators.tutorialAvailable) {
            EventBusService.post({
                key: 'tutorialSteps',
                value: 'getStepImg',
            });
        }
    }

    function createCoupledWindow() {
        const offerId = StateService.state.offer_id;
        if (offerId) {
            OffersService.get(s.offer_id).then(offer => {
                CoupledWindowService.addCoupledWindow(newPosition, offer);
            });
        }
    }

    async function saveCoupledWindows(
        conf: CoupledWindowActiveConfiguration,
        coupledPosition: any,
        offer
    ) {
        if (conf.type === 'coupled_window') {
            await PositionsFactory.updateMany(
                conf.windows.map(window => window.positionId),
                {
                    coupled_position_id: coupledPosition.tmp_id,
                    quantity: coupledPosition.quantity,
                },
                offer
            );
            await PositionsFactory.updateMany(
                conf.rollerShutters.map(rollerShutter => rollerShutter.positionId),
                {
                    coupled_position_id: coupledPosition.tmp_id,
                    quantity: coupledPosition.quantity,
                },
                offer
            );
            await PositionsFactory.updateMany(
                conf.detachedPositions,
                { coupled_position_id: null },
                offer
            );
            await PositionsFactory.removeMany(conf.removedPositions, offer);
            CoupledWindowService.editing = false;
        }
    }

    function saveSystemsComparisonConfig(saveConfig = true) {
        SystemsComparisonService.saveConfig(saveConfig);
    }

    function makeSimilarPosition() {
        const conf = ConfigurationsService.conf.Current;
        
        if (conf.type === 'coupled_window' && StateService.state && StateService.state.offer_id) {
            OffersService.get(s.offer_id).then(offer => {
                PositionsFactory.copyPositionsFromScope(offer, offer.tmp_id, [
                    ...conf.windows.map(window => window.positionId),
                    ...conf.rollerShutters.map(rollerShutter => rollerShutter.positionId),
                ]).then((relations: Record<string, string>) => {
                    conf.windows = conf.windows.map(window => {
                        window.positionId = relations[window.positionId];
                        return window;
                    });
                    conf.rollerShutters = conf.rollerShutters.map(
                        rollerShutter => {
                            rollerShutter.positionId = relations[rollerShutter.positionId];
                            return rollerShutter;
                        }
                    );
                    conf.couplings = conf.couplings.map(
                        coupling => {
                            coupling.framesId.forEach(rel => {
                                rel.positionId = relations[rel.positionId];
                            });
                            coupling.otherFramesId.forEach(rel => {
                                rel.positionId = relations[rel.positionId];
                            });
                            return coupling;
                        }
                    );
                    conf.sideProfiles = conf.sideProfiles.map(
                        sideprofile => {
                            sideprofile.framesId.forEach(rel => {
                                rel.positionId = relations[rel.positionId];
                            });
                            return sideprofile;
                        }
                    );
                    conf.windowSills = conf.windowSills.map(
                        sill => {
                            sill.framesId.forEach(rel => {
                                rel.positionId = relations[rel.positionId];
                            });
                            return sill;
                        }
                    );
                    ConfiguratorsDataService.load().then(configuratorsData => {
                        EventBusService.post({
                            key: 'initializedConfigurator',
                            value: {
                                mode: 'NEW',
                                type: CurConfService.conf,
                                data: configuratorsData,
                            },
                        });
                        DrawService.init();
                        EventBusService.post({
                            key: 'icc-redraw',
                            value: null,
                        });
                        $scope.$apply();
                        vm.savePopUp = false;
                        vm.gtmSimilarPosition();
                    });
                });
            });
        } else {
            vm.savePopUp = false;
            vm.gtmSimilarPosition();
        }
    }
}
