import angular from 'angular';
import PositionsDetailsService from '../../../../common/PositionsDetailsService';

export default function SummaryCtrl(
    $scope,
    $rootScope,
    $filter,
    Core,
    IccConfig,
    ElementsFactory,
    ConfigurationsService,
    DrawService,
    SashesLayoutService,
    ProductMistakesFactory,
    StepFactory,
    AlternativeLayoutsFactory,
    PriceService,
    ParametersModalService,
    IssuesService,
    EventBusService,
    ConfiguratorsAvailabilityService,
    GarageDimensionsFactory,
    StateService
) {
    'ngInject';

    var vm = this;

    vm.mistakeProductDetails = false;
    vm.configurationElementsVisible = false;
    vm.priceElementsVisible = false;
    vm.alternativesVisible = false;
    vm.noPrice = ConfigurationsService.price;
    vm.configurationElements = {};
    vm.addToProductMistakes = ProductMistakesFactory.addToProductMistakes;
    vm.showMistakeProductDetails = showMistakeProductDetails;
    vm.hideMistakeProductDetails = hideMistakeProductDetails;
    vm.showConfigurationElements = showConfigurationElements;
    vm.hideConfigurationElements = hideConfigurationElements;
    vm.showPriceElements = showPriceElements;
    vm.hidePriceElements = hidePriceElements;
    vm.showAlternatives = showAlternatives;
    vm.hideAlternatives = hideAlternatives;
    vm.hideInfo = hideInfo;
    vm.acceptMistakeProduct = acceptMistakeProduct;
    vm.calculateMaterials = calculateMaterials;
    vm.recalcAlternatives = recalcAlternatives;
    vm.toggled = toggled;
    vm.alternatives = [];
    vm.isOpen = false;
    vm.humanPriceElems = {};
    vm.isArray = angular.isArray;
    vm.isObject = angular.isObject;
    vm.isAddButton = isAddButton;
    vm.showParametersModal = showParametersModal;
    vm.isBlockedAddToOffer = isBlockedAddToOffer;
    vm.availableConfigs = ConfiguratorsAvailabilityService.availableConfigs;
    vm.garageGuidesBySymbol = {};
    vm.details = {};

    vm.drawOptions = angular.merge({}, DrawService.options, {
        dimensions: {
            multiplier: 2,
        },
        indexes: {
            multiplier: 3,
        },
    });
    vm.drawOptionsOuter = angular.merge({}, DrawService.options, {
        dimensions: {
            multiplier: 2,
        },
        indexes: {
            multiplier: 3,
        },
        base: {
            side: 'outer'
        }
    });

    let broadcastChangedConfigurator = () => {};
    let broadcastLoadedProductMistake = () => {};

    init();

    const subscriptions = [];
    subscriptions.push(
        EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            if (ConfigurationsService.conf.Current.IsMistakeProduct === true) {
                StepFactory.disableAllSteps();
                StepFactory.enable('dimensions');
                StepFactory.enable('fitting');
                StepFactory.enable('accessories');

                if (
                    ConfiguratorsAvailabilityService.availableConfigs.roller_shutter
                    && angular.isDefined(ConfigurationsService.conf.mainSteps)
                    && ConfigurationsService.conf.mainSteps.filter(e => e.code == 'rollershutter')
                        .length
                ) {
                    StepFactory.selectStep('dimensions', true);
                } else {
                    StepFactory.selectStep('accessories', true);
                }
                $rootScope.$broadcast('loadedProductMistake');
            } else {
                if (
                    ConfigurationsService.conf.Current.hasRoller !== true
                    && !IccConfig.Configurators.alwaysShowRollerStep
                ) {
                    StepFactory.disable('rollershutter');
                }
            }
            vm.garageGuidesBySymbol = GarageDimensionsFactory.getGuidesBySymbol();
        })
    );
    subscriptions.push(
        EventBusService.subscribeWithoutConfiguration(
            ['availableConfigs'],
            data => (vm.availableConfigs = data.value)
        )
    );

    $scope.$on('$destroy', () => {
        subscriptions.map(el => el.unsubscribe());
        broadcastChangedConfigurator();
        broadcastLoadedProductMistake();
        calculateMaterials();
    });

    function init() {}

    /**
     * Pokazywanie szczegółów produktu z pomyłek
     */
    function showConfigurationElements() {
        vm.configurationElementsVisible = true;
    }

    /**
     * Ukrywanie szczegółów produktu z pomyłek
     */
    function hideConfigurationElements() {
        vm.configurationElementsVisible = false;
    }

    /**
     * Pokazywanie szczegółów produktu z pomyłek
     */
    function showPriceElements() {
        if (IccConfig.Configurators.showPriceDetails === true) {
            PriceService.showPriceDetailsModal(ConfigurationsService.conf.Current);
        } else if (IccConfig.Configurators.showPriceDetails === 'old') {
            vm.priceElementsVisible = true;
        }
    }

    /**
     * Ukrywanie szczegółów produktu z pomyłek
     */
    function hidePriceElements() {
        vm.priceElementsVisible = false;
    }

    /**
     * Pokazywanie pododbnych konstrukcji
     */
    function showAlternatives() {
        if (
            ['window', 'hs', 'door', 'folding_door'].indexOf(
                ConfigurationsService.conf.Current.type
            ) === -1
        ) {
            return -1;
        }
        var alter = AlternativeLayoutsFactory.alternative(ConfigurationsService.conf.Current);
        Core.clear(vm.alternatives);
        for (var i = 0; i < alter.length; i++) {
            vm.alternatives.push(alter[i]);
        }
        vm.alternativesVisible = true;
    }

    /**
     * Przeliczanie pododbnych konstrukcji
     */
    function recalcAlternatives() {
        if (
            ['window', 'hs', 'door', 'folding_door'].indexOf(
                ConfigurationsService.conf.Current.type
            ) === -1
        ) {
            return -1;
        }
        var alter = AlternativeLayoutsFactory.alternative(ConfigurationsService.conf.Current);
        Core.clear(vm.alternatives);
        for (var i = 0; i < alter.length; i++) {
            vm.alternatives.push(alter[i]);
        }
        EventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
    }

    /**
     * Ukrywanie pododbnych konstrukcji
     */
    function hideAlternatives() {
        vm.alternativesVisible = false;
    }

    /**
     * Pokazywanie szczegółów produktu z pomyłek
     */
    function showMistakeProductDetails() {
        vm.mistakeProductDetails = true;
        hideInfo();
    }

    /**
     * Ukrywanie szczegółów produktu z pomyłek
     */
    function hideMistakeProductDetails() {
        vm.mistakeProductDetails = false;
        hideInfo();
    }

    /**
     * Pokazywanie modala z parametrami Uw/Sw
     */
    function showParametersModal() {
        ParametersModalService.showModal();
    }

    function isAddButton() {
        if (!angular.isObject($scope.config)) {
            return false;
        }

        if ($scope.config.conf == 'awning') {
            return $scope.config.currentConfig.FabricStyle.id > 0;
        } else {
            return (
                (!$scope.config.close || $scope.config.currentConfig._id)
                && $scope.user.access != 'Super Admin'
                && $scope.user.access != 'Administratorzy'
                && !$scope.config.editConfig
                && (localStorage.getItem('been')
                    || StepFactory.isVisited(StepFactory.getLastStep().step)
                    || !IccConfig.Configurators.firstVisitAddButton)
                && ((angular.isArray($scope.config.currentConfig.Sashes)
                    && $scope.config.currentConfig.Sashes.length > 0
                    && !$scope.config.currentConfig.ChangedDimensions)
                    || ($scope.config.conf == 'roller_shutter'
                        && $scope.config.currentConfig.RollerShutter.shutters.length > 0)
                    || $scope.config.conf == 'garage'
                    || $scope.config.conf == 'mosquito'
                    || $scope.config.conf == 'coupled_window'
                    || $scope.config.conf == 'winter_garden'
                    || ($scope.config.conf == 'accessory'
                        && angular.isArray($scope.config.currentConfig.Accessories)
                        && $scope.config.currentConfig.Accessories.length)
                    || ($scope.config.conf == 'awning'
                        && $scope.config.currentConfig.FabricStyle.id)
                    || (angular.isArray($scope.config.currentConfig.Accessories)
                        && $scope.config.currentConfig.Accessories.length)
                    || ($scope.config.conf == 'complementary_goods'
                        && $scope.config.currentConfig.ComplementaryGoods
                        && ($scope.config.currentConfig.ComplementaryGoods.glass.length
                            || $scope.config.currentConfig.ComplementaryGoods.windowsill.length
                            || $scope.config.currentConfig.ComplementaryGoods.cassonetto.length
                            || $scope.config.currentConfig.ComplementaryGoods.accessory.length
                            || $scope.config.currentConfig.ComplementaryGoods.profile.length)))
            );
        }
    }

    /**
     * Ukrywanie info o znalezieniu produktu z pomyłek
     */
    function hideInfo() {
        if (!angular.isArray($rootScope.mistakeProductId)) {
            $rootScope.mistakeProductExcepts = [];
        }
        $rootScope.mistakeProductExcepts.push($rootScope.mistakeProductId);
        $rootScope.mistakeProductsMessage = false;
    }

    function acceptMistakeProduct() {
        vm.mistakeProductDetails = false;

        $rootScope.mistakeProductsMessage = false;
        $rootScope.mistakeProductLoaded = true;

        ConfigurationsService.conf.Current = angular.copy($rootScope.mistakeProductConfig);
        ConfigurationsService.conf.Default = angular.copy($rootScope.mistakeProductConfig);

        if (angular.isArray($rootScope.mistakeProductConfig.Sashes)) {
            SashesLayoutService.rebuildRefs(ConfigurationsService.conf.Current);
        }

        $rootScope.showInfo(
            $filter('translate')(
                'MISTAKE|Produkt z magazynu wczytano do konfiguratora. Przed dokonaniem zamówienia sprawdź dostępność u producenta.'
            ),
            null
        ); // jscs:ingore

        broadcastChangedConfigurator = EventBusService.post({
            key: 'initializedConfigurator',
            value: null,
        });
        broadcastLoadedProductMistake = $rootScope.$broadcast('loadedProductMistake'); // jshint ignore:line
    }

    function calculateMaterials() {
        vm.configurationElements = ElementsFactory.getElements();

        vm.showConfigurationElements();
    }

    function toggled(open) {
        preparePriceElems();
        if (open) {
            EventBusService.post({
                key: 'icc-redraw',
                value: null,
            });
            const positions = new PositionsDetailsService(
                IccConfig,
                [{
                    configuration: ConfigurationsService.conf.Current,
                    details: ConfigurationsService.createSimpleConfiguration(
                        ConfigurationsService.conf.Current
                    ),
                    summary: {
                        dealer: {
                            components: {}
                        },
                        client: {
                            components: {}
                        }
                    },
                    group_discounts: {}
                }],
                StateService.getCurrentOffer(),
                $rootScope.user.access,
                true,
                $rootScope.user.access,
                $filter('translate')
            );
            positions[0].detailsForView.allWidth = true;
            vm.details = Object.assign(
                vm.details,
                positions[0].detailsForView
            );
        }
    }

    function preparePriceElems() {
        var PriceElemsTranslations = {
            accessories: $filter('translate')('ACCESSORY|Akcesoria do konstrukcji'),
            amount: $filter('translate')('DIMENSION|Rozmiar'),
            area: $filter('translate')('DIMENSION|Pow.'),
            assembly: $filter('translate')('CONFIGURATOR|Montaż'),
            alushell: $filter('translate')('WINDOW|Nakładka alum.'),
            bars: $filter('translate')('WINDOW|Szprosy'),
            bottom: $filter('translate')('INTERFACE|Dół'),
            box: $filter('translate')('ROLLERSHUTTER|Skrzynka'),
            boxPrice: $filter('translate')('ROLLERSHUTTER|Cena skrzynki'),
            cassonetto: $filter('translate')('GOODS|Kasonetka'),
            cause: $filter('translate')('CONFIGURATOR|Powód'),
            changedHandlesHeights: $filter('translate')('WINDOW|Zmiany wysokości klamek'),
            handlePosition: $filter('translate')('WINDOW|Zmiany pozycji klamek'),
            changedSize: $filter('translate')('CONFIGURATOR|Zmiana rozmiaru'),
            charge: $filter('translate')('CONFIGURATOR|Dopłata'),
            code: $filter('translate')('WINDOW|Kod'),
            color: $filter('translate')('COLOR|Kolor'),
            colorField: $filter('translate')('COLOR|Pole koloru'),
            colorOut: $filter('translate')('COLOR|Kolor zewn.'),
            colors: $filter('translate')('COLOR|Kolory'),
            complementaryGoods: $filter('translate')('GOODS|Dobra komplementarne'),
            constructSupp: $filter('translate')('WINDOW|Rabat za wiele skrzydeł'),
            count: $filter('translate')('INTERFACE|Ilość'),
            discountGroup: $filter('translate')('OFFER|Grupa rabatów'),
            discountGroups: $filter('translate')('OFFER|Grupy rabatów'),
            dividers: $filter('translate')('WINDOW|Słupki/Poprzeczki'),
            dividersCross: $filter('translate')('WINDOW|Łączenia szczeblin'),
            doorModel: $filter('translate')('DOOR|Model drzwi'),
            outerDoor: $filter('translate')('DOOR|Dopłata za drzwi otwierane na zewnątrz'),
            drive: $filter('translate')('ROLLERSHUTTER|Sterowanie'),
            driveElems: $filter('translate')('ACCESSORY|Akcesoria do sterowania'),
            driveManual: $filter('translate')('ROLLERSHUTTER|Sterowanie ręczne'),
            duplex: $filter('translate')('WINDOW|Dupleks'),
            factor: $filter('translate')('WINDOW|Współczynnik'),
            fields: $filter('translate')('WINDOW|Pola'),
            fitting: $filter('translate')('WINDOW|Okucie bezp.'),
            fittings: $filter('translate')('WINDOW|Okucia bezpieczne'),
            frame: $filter('translate')('WINDOW|Rama'),
            glass: $filter('translate')('WINDOW|Szyba'),
            glassTypes: $filter('translate')('WINDOW|Szyby'),
            good: $filter('translate')('GOODS|Dobro'),
            handles: $filter('translate')('WINDOW|Klamki'),
            height: $filter('translate')('DIMENSION|Wysokość'),
            id: $filter('translate')('INTERFACE|ID'),
            intMullions: $filter('translate')(
                'WINDOW|Słupki/Poprzeczki/Szczebliny/Szprosy konstrukcyjne'
            ),
            isashId: $filter('translate')('WINDOW|Nr pola'),
            isashIndex: $filter('translate')('WINDOW|Nr pola'),
            left: $filter('translate')('INTERFACE|Lewy'),
            length: $filter('translate')('DIMENSION|Długość'),
            lowThreshold: $filter('translate')('WINDOW|Niski próg'),
            multiSash: $filter('translate')('WINDOW|Konstr. wieloskrzydłowa'),
            name: $filter('translate')('INTERFACE|Nazwa'),
            overlay: $filter('translate')('WINDOW|Nakładka'),
            overload: $filter('translate')('ROLLERSHUTTER|Przeciążenie'),
            oversize: $filter('translate')('WINDOW|Ponadgabaryt'),
            price: $filter('translate')('OFFER|Cena'),
            priceId: $filter('translate')('CONFIGURATOR|Id cennika'),
            priceType: $filter('translate')('CONFIGURATOR|Typ ceny'),
            profile: $filter('translate')('CONFIGURATOR|Profil'),
            profile_id: $filter('translate')('CONFIGURATOR|Id profilu'),
            right: $filter('translate')('INTERFACE|Prawy'),
            rollers: $filter('translate')('ROLLERSHUTTER|Rolety'),
            sashAccessories: $filter('translate')('ACCESSORY|Akcesoria do kwater'),
            sashes: $filter('translate')('WINDOW|Kwatery'),
            sashFrames: $filter('translate')('WINDOW|Skrzydła'),
            sashId: $filter('translate')('WINDOW|Nr kwatery'),
            sashIndex: $filter('translate')('WINDOW|Nr kwatery'),
            side: $filter('translate')('INTERFACE|Bok'),
            size: $filter('translate')('DIMENSION|Rozmiar'),
            system: $filter('translate')('CONFIGURATOR|System'),
            systemFactors: $filter('translate')('CONFIGURATOR|Dopłaty procentowe'),
            top: $filter('translate')('INTERFACE|Góra'),
            type: $filter('translate')('INTERFACE|Rodzaj'),
            typeName: $filter('translate')('INTERFACE|Rodzaj'),
            warmEdge: $filter('translate')('WINDOW|Ciepła ramka'),
            width: $filter('translate')('DIMENSION|Szerokość'),
            windowsill: $filter('translate')('ACCESSORY|Parapet'),
            wood: $filter('translate')('CONFIGURATOR|Drewno'),
            bottomSashesCount: $filter('translate')('CONFIGURATOR|Liczba kwater u dołu'),
        };

        var priceElemsString = JSON.stringify(ConfigurationsService.conf.Current.PriceElems);
        var re;
        for (var k in PriceElemsTranslations) {
            re = new RegExp('"' + k + '":', 'g');
            priceElemsString = priceElemsString.replace(re, '"' + PriceElemsTranslations[k] + '":');
        }

        vm.humanPriceElems = JSON.parse(priceElemsString);
    }

    function isBlockedAddToOffer() {
        return IssuesService.isBlockedAddToOffer(ConfigurationsService.conf.Current);
    }
}
