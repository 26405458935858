import { Injectable } from '@angular/core';
import { core } from 'helpers';
import Common from 'Common';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import { EventBusService } from 'event-bus.service';
import { Alignment } from 'configurations/parts/window';
import BrowserProfilesService from 'configurator/steps/window/profiles/profiles.service';

@Injectable()
export class AlignmentsService {
    constructor(
        private eventBusService: EventBusService,
        private profilesService: BrowserProfilesService
    ) {}

    putAlignmentInField(profile, field, conf: WindowActiveConfiguration, side) {
        const sides = {
            left: {
                direction: 'vertical',
                position: ['rx'],
                shift: 'ry',
                length: 'rHeight',
                adjSashes: 'right',
                perp: ['top', 'bottom'],
            },
            right: {
                direction: 'vertical',
                position: ['rx', 'rWidth'],
                shift: 'ry',
                length: 'rHeight',
                adjSashes: 'left',
                perp: ['top', 'bottom'],
            },
            top: {
                direction: 'horizontal',
                position: ['ry'],
                shift: 'rx',
                length: 'rWidth',
                adjSashes: 'bottom',
                perp: ['left', 'right'],
            },
            bottom: {
                direction: 'horizontal',
                position: ['ry', 'rHeight'],
                shift: 'rx',
                length: 'rWidth',
                adjSashes: 'top',
                perp: ['left', 'right'],
            },
        };
        let alignments;
        if (Common.isDefined(field.parentId) && field.parentId !== null) {
            const sash = core.fIdO(conf.Sashes, field.parentId);
            alignments = sash.intAlignments;
        } else {
            alignments = conf.Alignments;
        }
        const direction = sides[side].direction;
        const position = sides[side].position.reduce((prev, key) => prev + field[key], 0);
        const shift = field[sides[side].shift];
        const length = field[sides[side].length];
        const newAlignmentId = this.getIdForNew(conf);
        const newAlignment: Alignment = {
            id: newAlignmentId,
            profileId: profile.id,
            frameId: field.frameId,
            position,
            shift,
            length,
            direction,
            side,
            adjacentSashes: {
                top: [],
                bottom: [],
                left: [],
                right: [],
            },
            perpendicularMullions: {
                top: [],
                bottom: [],
                left: [],
                right: [],
            },
            parallelMullions: {
                top: [],
                bottom: [],
                left: [],
                right: [],
            },
            perpendicularAlignments: {
                top: [],
                bottom: [],
                left: [],
                right: [],
            },
            parallelAlignments: {
                top: [],
                bottom: [],
                left: [],
                right: [],
            },
        };
        newAlignment.adjacentSashes[sides[side].adjSashes] = [field.id];
        newAlignment.perpendicularMullions[sides[side].perp[0]] = this.wrapIdByArray(
            field.nearMullions[sides[side].perp[0]]
        );
        newAlignment.perpendicularMullions[sides[side].perp[1]] = this.wrapIdByArray(
            field.nearMullions[sides[side].perp[1]]
        );
        newAlignment.parallelMullions[side] = this.wrapIdByArray(field.nearMullions[side]);
        newAlignment.perpendicularAlignments[sides[side].perp[0]] = this.wrapIdByArray(
            field.nearAlignments[sides[side].perp[0]]
        );
        newAlignment.perpendicularAlignments[sides[side].perp[1]] = this.wrapIdByArray(
            field.nearAlignments[sides[side].perp[1]]
        );
        newAlignment.parallelAlignments[side] = this.wrapIdByArray(field.nearAlignments[side]);

        if (field.nearAlignments[side] > -1) {
            const nearAlignment = core.fIdO<any>(alignments, field.nearAlignments[side]);
            nearAlignment.adjacentSashes[sides[side].adjSashes] = nearAlignment.adjacentSashes[
                sides[side].adjSashes
            ].filter(el => el !== field.id);
            nearAlignment.parallelAlignments[sides[side].adjSashes].push(newAlignmentId);
        }

        field.nearMullions[side] = -1;
        field.nearAlignments[side] = newAlignmentId;

        alignments.push(newAlignment);
        conf.Layout.changed = true;
        this.eventBusService.post({
            key: 'putAlignmentInField',
            value: {
                profile,
                field,
                side,
            },
            conf
        });
    }

    removeAlignmentInField(field, conf: WindowActiveConfiguration, side) {
        const sides = {
            left: {
                direction: 'vertical',
                position: ['rx'],
                shift: 'ry',
                length: 'rHeight',
                adjSashes: 'right',
                perp: ['top', 'bottom'],
            },
            right: {
                direction: 'vertical',
                position: ['rx', 'rWidth'],
                shift: 'ry',
                length: 'rHeight',
                adjSashes: 'left',
                perp: ['top', 'bottom'],
            },
            top: {
                direction: 'horizontal',
                position: ['ry'],
                shift: 'rx',
                length: 'rWidth',
                adjSashes: 'bottom',
                perp: ['left', 'right'],
            },
            bottom: {
                direction: 'horizontal',
                position: ['ry', 'rHeight'],
                shift: 'rx',
                length: 'rWidth',
                adjSashes: 'top',
                perp: ['left', 'right'],
            },
        };
        let alignments;
        let mullions;
        if (Common.isDefined(field.parentId) && field.parentId !== null) {
            const sash = core.fIdO(conf.Sashes, field.parentId);
            alignments = sash.intAlignments;
            mullions = sash.intMullions;
        } else {
            alignments = conf.Alignments;
            mullions = conf.Mullions;
        }
        const reverseSide = sides[side].adjSashes;
        const alignmentToRemove = alignments.filter(
            alignment => field.nearAlignments[side] === alignment.id
        )[0];
        if (alignmentToRemove.parallelAlignments[side].length > 0) {
            const nearAlignment = core.fIdO<Alignment>(
                alignments,
                alignmentToRemove.parallelAlignments[side][0]
            );
            field.nearAlignments[side] = nearAlignment.id;
            nearAlignment.adjacentSashes[reverseSide].push(field.id);
            nearAlignment.parallelAlignments[reverseSide] = nearAlignment.parallelAlignments[
                reverseSide
            ]
                .filter(el => el !== alignmentToRemove.id)
                .concat(alignmentToRemove.parallelAlignments[reverseSide]);
        } else {
            field.nearAlignments[side] = -1;
        }

        if (alignmentToRemove.parallelMullions[side].length > 0) {
            const nearMullion = core.fIdO<Alignment>(
                mullions,
                alignmentToRemove.parallelMullions[side][0]
            );
            field.nearMullions[side] = nearMullion.id;
            // nearMullion[`multiAlign${core.capitalize(reverseSide)}`].push(field);
        } else {
            field.nearMullions[side] = -1;
        }

        if (alignmentToRemove.parallelAlignments[reverseSide].length > 0) {
            alignmentToRemove.parallelAlignments[reverseSide]
                .map(nearAlignmentId => core.fIdO<Alignment>(alignments, nearAlignmentId))
                .forEach(nearAlignment => {
                    nearAlignment.parallelAlignments[side] = nearAlignment.parallelAlignments[side]
                        .filter(el => el !== alignmentToRemove.id)
                        .concat(alignmentToRemove.parallelAlignments[side]);
                });
        }

        const oldAlignmentIndex = alignments.findIndex(
            alignment => alignment.id === alignmentToRemove.id
        );

        alignments.splice(oldAlignmentIndex, 1);
        conf.Layout.changed = true;
        this.eventBusService.post({
            key: 'removedAlignmentInField',
            value: {
                field,
                side,
            },
        });
    }

    expandAlignments(conf, side, perpendicularSide, perpendicularSideRev, field, toSash) {
        let alignments;
        if (Common.isDefined(field.parentId) && field.parentId !== null) {
            const sash = core.fIdO<any>(conf.Sashes, field.parentId);
            alignments = sash.intAlignments;
        } else {
            alignments = conf.Alignments;
        }
        if (field.nearAlignments[side] !== -1) {
            let alignment = core.fIdO<Alignment>(alignments, field.nearAlignments[side]);
            do {
                if (side === 'left' || side === 'right') {
                    alignment.length = parseInt(alignment.length + toSash.rHeight);
                } else {
                    alignment.length = parseInt(alignment.length + toSash.rWidth);
                }
                alignment.perpendicularMullions[
                    perpendicularSide
                ] = alignment.perpendicularMullions[perpendicularSide].filter(
                    mullionId => mullionId !== toSash.nearMullions[perpendicularSideRev]
                );
                alignment = core.fIdO<Alignment>(alignments, alignment.parallelAlignments[side][0]);
            } while (
                alignment
                && alignment.parallelAlignments[side].length > -1
                && alignment.perpendicularMullions[perpendicularSide].length > 0
            );
        }
    }

    rearrangeAlignments(conf, side, field, nearMullionId?) {
        let alignments;
        if (Common.isDefined(field.parentId) && field.parentId !== null) {
            const sash = core.fIdO<any>(conf.Sashes, field.parentId);
            alignments = sash.intAlignments;
        } else {
            alignments = conf.Alignments;
        }
        if (field.nearAlignments[side] !== -1) {
            let alignment = core.fIdO<Alignment>(alignments, field.nearAlignments[side]);
            do {
                switch (side) {
                    case 'left':
                        alignment.position = field.rx;
                        alignment.shift = field.ry;
                        alignment.length = field.rHeight;
                        break;
                    case 'right':
                        alignment.position = field.rx + field.rWidth;
                        alignment.shift = field.ry;
                        alignment.length = field.rHeight;
                        break;
                    case 'top':
                        alignment.position = field.ry;
                        alignment.shift = field.rx;
                        alignment.length = field.rWidth;
                        break;
                    case 'bottom':
                        alignment.position = field.ry + field.rHeight;
                        alignment.shift = field.rx;
                        alignment.length = field.rWidth;
                        break;
                }
                if (nearMullionId) {
                    alignment.parallelMullions[side] = alignment.parallelMullions[side].filter(
                        mullionId => mullionId !== nearMullionId
                    );
                }
                alignment = core.fIdO<Alignment>(alignments, alignment.parallelAlignments[side][0]);
            } while (alignment && alignment.parallelAlignments[side].length > -1);
        }
    }

    resizeAlignments(conf, side, perpendicularSide, field, length: number, lengthChange = false) {
        let alignments;
        if (Common.isDefined(field.parentId) && field.parentId !== null) {
            const sash = core.fIdO<any>(conf.Sashes, field.parentId);
            alignments = sash.intAlignments;
        } else {
            alignments = conf.Alignments;
        }
        if (field.nearAlignments[side] !== -1) {
            let alignment = core.fIdO<Alignment>(alignments, field.nearAlignments[side]);
            do {
                if (field.nearMullions[perpendicularSide] === -1 || lengthChange) {
                    alignment.length = Math.round(alignment.length + length);
                }
                if (
                    (perpendicularSide === 'left' || perpendicularSide === 'top')
                    && alignment.shift !== 0
                ) {
                    alignment.shift = lengthChange
                        ? Math.round(alignment.shift - length)
                        : Math.round(alignment.shift + length);
                }
                alignment = core.fIdO<Alignment>(alignments, alignment.parallelAlignments[side][0]);
            } while (
                alignment
                && alignment.parallelAlignments[side].length > -1
                && alignment.perpendicularMullions[perpendicularSide].length > 0
            );
        }
    }

    isAvailableAlignments(field, conf: WindowActiveConfiguration) {
        const options = {
            and: [Common.isNumber(field.parentId) ? 'alignment_sash' : 'alignment_frame'],
            not: [],
        };
        return this.profilesService.getFilteredProfiles(conf, null, options).length > 0;
    }

    getThresholdAlignments(conf: WindowActiveConfiguration) {
        const options = {
            and: ['alignment_threshold'],
            not: [],
        };
        return this.profilesService.getFilteredProfiles(conf, null, options);
    }

    private getIdForNew(conf: WindowActiveConfiguration) {
        const intAlignmentsId = conf.Sashes.reduce((prev, sash) => {
            const maxId = sash.intAlignments.reduce(
                (intPrev, intAlignment) => (intAlignment.id > intPrev ? intAlignment.id : intPrev),
                0
            );
            return maxId > prev ? maxId : prev;
        }, -1);
        const alignmentsId = conf.Alignments.reduce((prev, el) => {
            return el.id > prev ? el.id : prev;
        }, -1);
        return Math.max(alignmentsId, intAlignmentsId) + 1;
    }

    private wrapIdByArray(id) {
        return id > -1 ? [id] : [];
    }
}
