import 'angular-ui-bootstrap';

/**
 * Kontroler modala kolorów dodatku.
 *
 * @export
 * @class ModalPriceDetailsCtrl
 */
export default class ModalPdfOptionsCtrl {

    options;

    /**
     * Creates an instance of ModalPriceDetailsCtrl.
     *
     * @param {ng.ui.bootstrap.IModalServiceInstance} $uibModalInstance $uibModalInstance
     * @param {any} conf conf
     * @param {any} Core Core
     * @param {PriceDictionary} PriceDictionary PriceDictionary
     *
     * @memberOf ModalPriceDetailsCtrl
     */
    constructor(
        private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        private IccConfig,
        private ProjectName,
        private LangService,
        public userGroup,
        public extendedPdf,
        public offer,
        private user
        ) {
        'ngInject';

        this.options = {
            word: false,
            docType: 'offer',
            priceType: 'all',
            extended: false,
            completion: false,
            hideLegalNotices: false,
            lang: this.LangService.getLang(),
            currency: offer.dealer_currency || user.market_currency,
            currencyExchangeRate: offer.dealer_currency_exchange_rate || 1,
            showUw: true,
            showSw: true,
            showWeight: true,
            showCircuit: true,
            showArea: true,
        };

        if (!offer.dealer_currency_exchange_rate) {
            this.restoreCurrency();
        }
    }

    /**
     * Zamyka modal.
     *
     *
     * @memberOf ModalPriceDetailsCtrl
     */
    print() {
        const pdfOptions = {
            orderPreview: this.options.docType === 'orderPreview',
            prices: !['no-prices', 'no-client'].includes(this.options.priceType),
            discounts: this.options.priceType === 'all',
            extended: this.options.extended,
            completion: this.options.completion,
            word: this.options.word,
            hideLegalNotices: this.options.hideLegalNotices,
            lang: this.options.lang,
            extendedDrawDimensions: this.options.docType === 'extendedDraw',
            client: this.options.priceType !== 'no-client',
            currency: null,
            currencyExchangeRate: null,
            showUw: this.options.showUw,
            showSw: this.options.showSw,
            showWeight: this.options.showWeight,
            showCircuit: this.options.showCircuit,
            showArea: this.options.showArea,
        };
        if (this.availableCurrencyChange) {
            pdfOptions.currency = this.options.currency;
            pdfOptions.currencyExchangeRate = this.options.currencyExchangeRate;
        }
        this.$uibModalInstance.close(pdfOptions);
    }


    /**
     * Zamyka modal.
     *
     *
     * @memberOf ModalPriceDetailsCtrl
     */
    close() {
        this.$uibModalInstance.close();
    }

    changedDocType() {
        if (this.options.docType === 'orderPreview') {
            this.options.priceType = 'no-discounts';
        } else {
            this.options.priceType = 'all';
        }
    }

    restoreCurrency() {
        if (this.availableCurrencyChange) {
            this.options.currencyExchangeRate = this.user.dealer.currencies[this.options.currency];
        }
    }

    get availableOrderPreview() {
        return (
            this.user.access === 'dealer'
            || (this.user.access === 'producent' && !IccConfig.PDF.producerOfferLikeOrder)
            || this.user.access === 'dealerh' && this.user.dealer_seller.see_prices
        )
        && this.IccConfig.Offer.orderPreview
        && !this.order;
    }

    get availableExtendedDraw() {
        return this.IccConfig.PDF.extendedDrawDimensions;
    }

    get availableCurrencyChange() {
        return this.IccConfig.PDF.enableCurrencyChange
            && !this.options.word
            && !this.order
            && this.options.docType !== 'orderPreview'
            && ['dealer', 'dealerh'].includes(this.user.access);
    }

    get showRestoreButton() {
        return this.availableCurrencyChange && this.options.currencyExchangeRate !== this.user.dealer.currencies[this.options.currency];
    }

    get order() {
        return Boolean(Number(this.offer.order));
    }
}
