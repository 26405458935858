import { Injectable, Inject } from '@angular/core';
import { Profile, ProfileSet } from 'configurations/parts/window';
import {
    LoadedConfiguratorsDataValue,
    ConfiguratorsDataService,
} from 'configurators/configurators-data.service';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import ProfilesService from 'profiles.service';
import ProfileSetsService from 'profile-sets.service';
import { EventBusService } from 'event-bus.service';
import { core, logger } from 'helpers';
import { ValidationService } from 'configurators/validation.service';

@Injectable()
export default class BrowserProfileSetsService extends ProfileSetsService {
    constructor(
        @Inject('$uibModal') private $uibModal: ng.ui.bootstrap.IModalService,
        private eventBusService: EventBusService,
        protected profilesService: ProfilesService,
        private validationService: ValidationService
    ) {
        super(profilesService);
        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>(
            'loadedConfiguratorsData',
            data => {
                this.loadProfileSets(data.value);
            }
        );

        this.eventBusService.subscribe('setSystem', data => {
            try {
                this.validateAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
            } catch (err) {
                logger.error(err);
            }
        });
    }

    openProfileSetsModal(conf: WindowActiveConfiguration, defaultConf: WindowActiveConfiguration) {
        const modalOptions: ng.ui.bootstrap.IModalSettings = {
            component: 'profileSetsModal',
            resolve: {
                profileSets: () => core.copy(this.getProfileSetsForSystem(conf.System.id)),
                profileShapes: () => core.copy(this.profilesService.getProfileShapesNames()),
                selectedProfileSet: () => conf.ProfileSet,
                defaultProfileSet: () => this.getDefaultSet(conf, defaultConf),
                conf: () => conf,
            },
        };
        this.$uibModal.open(modalOptions).result.then((profileSet: ProfileSet) => {
            this.setSet(conf, defaultConf, profileSet);
        });
    }

    validateAndFixIssues(conf: WindowActiveConfiguration, defaultConf: WindowActiveConfiguration) {
        if (
            !this.loadedData
            || (conf.ProfileSet
                && conf.ProfileSet.systems.map(Number).includes(Number(conf.System.id))
                && conf.Frames.length
                && conf.Frames.every(frame => frame.frame.length > 0))
        ) {
            this.validationService.valid(conf, 'profileSet');
            return;
        }
        const defaultProfileSet = this.getDefaultSet(conf, defaultConf);
        if (defaultProfileSet) {
            this.setSet(conf, defaultConf, defaultProfileSet, true);
        }
    }

    setSet(
        conf: WindowActiveConfiguration,
        defaultConf: WindowActiveConfiguration,
        profileSet: ProfileSet,
        isDefault: boolean = false
    ) {
        conf.ProfileSet = core.copy(profileSet);
        conf.ProfileSet.isDefault = isDefault;
        defaultConf.ProfileSet = core.copy(profileSet);
        defaultConf.ProfileSet.isDefault = isDefault;
        this.validationService.valid(conf, 'profileSet');
        this.eventBusService.post({
            key: 'setProfileSet',
            value: {
                profileSet,
            },
            conf,
            defaultConf
        });
    }
}
