import angular from 'angular';
import { IssueLevel } from 'issues.service';

/**
 * Fabryka kolorow bram garazowych
 * @param {object} $rootScope               RootScope
 * @param {object} $uibModal                   Dostarcza obsługę okien modalnych
 * @param {object} $filter                  Filtry angularowe
 * @param {object} $location                Obsługa adresów URL
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} ConfiguratorsDataService Fabryka z danymi do konfiguratorów
 * @param {object} CurConfService           Informacje o bieżącym konfiguratorze
 * @param {object} IssuesService            Obługa błędów
 * @param {object} PriceService             Liczenie cen
 * @param {object} Core                     Biblioteka podręcznych funkcji
 */
export default function ColorsFactory($rootScope, $uibModal, $filter, $location, ConfigurationsService, ConfiguratorsDataService, // jshint ignore:line
                       CurConfService, IssuesService, PriceService, Core, EventBusService, IccConfig) {
    'ngInject';

    var configurators   = ['mosquito'];
    var white           = {};
    var netColors       = {};
    var panelColors     = [];
    var defColors       = {};
    var factory         = {
        netColors         : [],
        panelColors       : [],
        frameColors       : [],
        frameColorsGroups : [],
        colors            : {},
        loadedData        : false,
        loadColorsBySystem: loadColorsBySystem,
        loadDefaultData   : loadDefaultData,
        setColor          : setColor,
        openModalColor    : openModalColor,
        validate          : validate,
        setColorSide      : setColorSide
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });

    return factory;

    /**
     * Funkcja uruhamiana po zaladowaniu konfiguratora
     */
    function init() {
        IssuesService.addValidateFunction(factory.validate);

        netColors = [
            {
                color: '000000',
                name : $filter('translate')('COLOR|Czarny'),
                code : 'black'
            }
        ];

        if (IccConfig.Configurators.greyColorInMosquito) {
            netColors.unshift({
                color: 'A9A9A9',
                name : $filter('translate')('COLOR|Szary'),
                code : 'gray'

            });
        }
        panelColors = [
            {
                color: 'Ffffff',
                name : $filter('translate')('COLOR|Biały'),
                code : 'white'
            },
            {
                color: '654321',
                name : $filter('translate')('COLOR|Brązowy'),
                code : 'brown'
            }
        ];
        defColors = {
            frame: {},
            panel: panelColors[0],
            net  : netColors[0]
        };
        factory.netColors   = netColors;
        factory.panelColors = panelColors;
        factory.color       = defColors;

        if (configurators.indexOf(CurConfService.conf) !== -1) {
            if (angular.isUndefined(ConfigurationsService.conf)) {
                return;
            }
            if (angular.isUndefined(ConfigurationsService.conf.Current.Colors.frame)
                && angular.isUndefined(ConfigurationsService.conf.Current.Colors.panel)
                && angular.isUndefined(ConfigurationsService.conf.Current.Colors.net)
            ) {
                loadDefaultData();
            }

            loadColorsBySystem(ConfigurationsService.conf.Current.System.id, ConfigurationsService.conf.Current.Type.id);
        }

        factory.loadedData = true;
    }

    /**
     * Kolory dla danego systemu
     * @param {number} systemId Id wybranego systemu
     */
    function loadColorsBySystem(systemId, typeId) {
        if (angular.isUndefined(ConfigurationsService.conf) || angular.isUndefined(systemId)) {
            return;
        }
        factory.frameColors = Core.copy(ConfiguratorsDataService.data.windowColors[systemId]) || [];

        factory.frameColorsGroups = getColorsGroups(factory.frameColors, systemId, typeId) || [];

        factory.frameColors = factory.frameColors.filter(color => angular.isArray(color.groups) && color.groups.some(el => factory.frameColorsGroups.indexOf(el) > -1)) || [];

        setDefaultColors();
    }

    function getColorsGroups(allColors, systemId, typeId) {
        // pobierz grupy kolorów z dostępnych kolorów
        var groups = [];
        for (let x = 0; x < allColors.length; ++x) {
            if (angular.isUndefined(allColors[x].groups) || allColors[x].groups === null) {
                continue;
            }
            for (var y = 0; y < allColors[x].groups.length; ++y) {
                if (groups.indexOf(allColors[x].groups[y]) < 0) {
                    groups.push(allColors[x].groups[y]);
                }
            }
        }

        ConfiguratorsDataService.data.windowColorRals.forEach(color => {
            if (angular.isArray(color.groups)) {
                groups = groups.concat(color.groups.filter(group => groups.indexOf(group) === -1));
            }
        });

        // pobierz grupy kolorów dla danej linii (sprawdź czy grupa kolorów dla linii nie jest pusta)
        var colorGroups = ConfiguratorsDataService.data.windowColorGroups.filter((el) => {
            var val = angular.isArray(el.systems) && angular.isArray(groups)
                    && groups.indexOf(el.id) > -1
                    && el.systems.indexOf(systemId) > -1
                    && el.target.indexOf('show') > -1
                    && ((angular.isArray(el.mosquitoTypes) && el.mosquitoTypes.length && el.mosquitoTypes.indexOf(typeId) > -1)
                        || (!el.mosquitoTypes && !typeId));
            return val;
        });
        for (let x = 0; x < colorGroups.length; ++x) {
            colorGroups[x] = colorGroups[x].id;
        }
        return colorGroups;
    }

    /**
     * ustawienie domyślnego koloru
     */
    function setDefaultColors() {
        if (angular.isArray(factory.frameColors) && angular.isDefined(ConfigurationsService.conf.Current.System.id)) {
            var allColors = factory.frameColors;
            var whites = allColors.filter(function(el) {
                return el.color.toLowerCase() == 'ffffff';
            });
            var colorF;

            // ustawianie domyślnego koloru
            // biały - pierwszy kolor - pierwszy RAL
            if (angular.isObject(ConfigurationsService.conf.Current.Colors.frame)
                && angular.isDefined(ConfigurationsService.conf.Current.Colors.frame.id)
                && ((!ConfigurationsService.conf.Current.Colors.frame.RAL && allColors.some(color => color.id == ConfigurationsService.conf.Current.Colors.frame.id))
                    || (ConfigurationsService.conf.Current.Colors.frame.RAL && ConfiguratorsDataService.data.windowColorRals.some(color => color.id == ConfigurationsService.conf.Current.Colors.frame.id)))
            ){
                colorF = ConfigurationsService.conf.Current.Colors.frame;
            } else if (angular.isDefined(whites) && angular.isDefined(whites[0])) {
                colorF = whites[0];
            } else if (angular.isDefined(factory.frameColors[0])) {
                colorF = factory.frameColors[0];
            }

            setColorSide(defColors, 'frame', colorF);
        }

        if (configurators.indexOf(CurConfService.conf) !== -1
            && angular.isDefined(ConfigurationsService.conf.Current.Colors)
            && angular.isDefined(ConfigurationsService.conf.Current.Colors.frame)
            && angular.isUndefined(Core.fId(factory.frameColors, ConfigurationsService.conf.Current.Colors.frame.id))
        ) {
        loadDefaultData();
        }
    }

    /**
     * Ustawianie domyslnego zestawu kolorow
     */
    function loadDefaultData() {
        ConfigurationsService.conf.Current.Colors = Core.copy(defColors);
        ConfigurationsService.conf.Default.Colors = Core.copy(defColors);

        PriceService.count();
    }

    /**
     * Pzzypisanie skladowych koloru w wybranym elemencie
     * @param {object} object Kolor elementu
     * @param {object} color  Nowy kolor
     */
    function setColorSide(object, element, color) {
        if (angular.isDefined(color)) {
            object[element] = Core.copy(color);

            PriceService.count();
        }
    }

    /**
     * Wczytanie kolorow z konfiguracji
     * @param {object} color   Wybrany kolor
     * @param {string} group   Typ koloru
     * @param {string} element Element kolorowany
     * @param {object} object  Miejsce wystpienia elementu
     */
    function setColor(color, element, object) {
        let objectd;

        if (!angular.isDefined(object)) {
            object  = ConfigurationsService.conf.Current.Colors;
            objectd = ConfigurationsService.conf.Default.Colors;
            setColorSide(objectd, element, color);
        }

        setColorSide(object, element, color);
    }

    /**
     * Otwiera okno modalne z wyborem kolorow
     * @param  {string} type Typ koloru
     */
    function openModalColor(type, selColor, sash) {

        var modalInstance = $uibModal.open({
            templateUrl: 'modalMosquitoColor.html',
            controller : 'ModalMosquitoColorCtrl as gColor',
            resolve    : {
                colors: function() {
                    if (type == 'net') {
                        return factory.netColors;
                    } else if (type == 'panel') {
                        return factory.panelColors;
                    } else {
                        return factory.frameColors;
                    }
                },
                selColor: function() {
                    return selColor;
                },
                type: function() {
                    return type;
                },
                colorGroups: () => ConfiguratorsDataService.data.windowColorGroups.filter(el =>
                    factory.frameColorsGroups.indexOf(el.id) > -1
                    && el.target.indexOf('show') > -1
                ),
                colorsRAL: function() {
                    return ConfiguratorsDataService.data.windowColorRals;
                }
            }
        });

        modalInstance.result.then(function(selectedColor) {
            if (selectedColor) {
                setColor(selectedColor.color, selectedColor.type, sash);
            }
        });
    }

    /**
     * Sprawdza, czy został wybrany jakiś kolor
     */
    function validate() {
        if (configurators.indexOf(CurConfService.conf) >= 0) {
            if (ConfigurationsService.conf.Current.Colors.id === null) {
                IssuesService.simpleRegister(
                    'no-mosquito-colors',
                    'Kolor siatki nie został określony.',
                    $filter('translate')('MOSQUITO|Kolor siatki nie został określony.'),
                    ConfigurationsService.conf.Current,
                    {
                        logLevel: IssueLevel.NONE
                    }
                );
                return false;
            }
        }
        IssuesService.unregister('no-mosquito-colors', ConfigurationsService.conf.Current);
        return true;
    }

}
