import SystemsByTagFilter from './SystemsByTagFilter';

export function systemsByTagFilterFactory(i: any) {
  return i.get('systemsByTagFilter');
}
export const systemsByTagFilterProvider = {
  provide: SystemsByTagFilter,
  useFactory: systemsByTagFilterFactory,
  deps: ['$injector']
};

export function systemsFactoryFactory(i: any) {
  return i.get('SystemsFactory');
}
export const systemsFactoryProvider = {
  provide: 'SystemsFactory',
  useFactory: systemsFactoryFactory,
  deps: ['$injector']
};
