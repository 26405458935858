import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import SystemsComparisonService from './systems-comparison.service';

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [],
    exports: [],
    entryComponents: [],
    providers: [
        SystemsComparisonService,
    ],
})
export class SystemsComparisonModule {}
