import angular from 'angular';
import { IssueLevel } from 'issues.service';

export default function SystemsFactory(
    $rootScope,
    $uibModal,
    $filter,
    Core,
    ConfigurationsService,
    GlazingFactory,
    HandlesFactory,
    CurConfService,
    ParametersService,
    ConfiguratorsDataService,
    StepFactory,
    ColorsFactory,
    AccessoriesFactory,
    PriceService,
    MuntinsFactory,
    IssuesService,
    IccConfig,
    SashesFactory,
    ConstructionLimitationFactory,
    LocksFactory,
    WarrantyFactory,
    DependenciesService,
    ProfileSetsService,
    ProfilesService,
    EventBusService,
    ValidationService,
    SchemasFactory,
    LayoutFactory,
    MosquitoSystemsFactory,
    DiscountsAndMultipliersService,
    FramesService,
    CoupledWindowService
) {
    'ngInject';

    var configurators = ['window', 'hs', 'door', 'folding_door'];
    var factory = {
        systems: [],
        selectedSystems: [],
        systemTypes: [],
        tags: [],
        minHeightBalcony: 1600,
        maxHeightBalcony: 1800,
        loadedData: false,
        sashFramesOverlap: [],
        dividerOverlap: [],
        switchFromGreater: false,
        selectSystem,
        selectDestination,
        selectSystemType,
        setDefaultValues,
        getSystems,
        getSystemType,
    };

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });
    $rootScope.$on('changedStep', (event, newVal, oldVal) => {
        if (newVal.i < oldVal.i) {
            factory.switchFromGreater = true;
        } else {
            factory.switchFromGreater = false;
        }

        if (
            newVal.code != 'system'
            && StepFactory.getStepByCode('system') > -1
            && newVal.i > StepFactory.getStepByCode('system')
        ) {
            if (angular.isUndefined(ConfigurationsService.conf.Current.System.id)) {
                IssuesService.simpleRegister(
                    'no-system',
                    'Brak wybranego systemu.',
                    $filter('translate')('WINDOW|Brak wybranego systemu.'),
                    ConfigurationsService.conf.Current,
                    {
                        logLevel: IssueLevel.NONE,
                    }
                );
                StepFactory.selectStep(oldVal.i);
            } else {
                IssuesService.unregister('no-system', ConfigurationsService.conf.Current);
            }
        }
    });

    return factory;

    function init() {
        if (configurators.indexOf(CurConfService.conf) === -1) {
            return;
        }
        factory.systems = getSystems();
        factory.systemTypes = ConfiguratorsDataService.data.windowLineTypes.filter(
            sT =>
                factory.systems.filter(s => (angular.isDefined(sT) ? s.type == sT.static : true))
                    .length > 0
        );
        factory.tags = (ConfiguratorsDataService.data.systemsTags || []).filter(
            tag => tag.type === 'window_line'
        );
        factory.setDefaultValues();

        factory.loadedData = true;
    }

    function getSystems(confType = CurConfService.conf) {
        const unavailableSystems = DiscountsAndMultipliersService.getUnavailableSystems();
        switch (confType) {
            case 'window':
                return ConfiguratorsDataService.data.windowLines.filter(
                    el =>
                        unavailableSystems.indexOf(el.id) < 0
                        && (!$rootScope.coupledPosition
                            || !CoupledWindowService.couplingOptions.matchedWindowSystems
                            || CoupledWindowService.couplingOptions.matchedWindowSystems.indexOf(
                                Number(el.id)
                            ) > -1)
                );
            case 'hs':
                return ConfiguratorsDataService.data.hsLines.filter(
                    el =>
                        unavailableSystems.indexOf(el.id) < 0
                        && (!$rootScope.coupledPosition
                            || !CoupledWindowService.couplingOptions.matchedWindowSystems
                            || CoupledWindowService.couplingOptions.matchedWindowSystems.indexOf(
                                Number(el.id)
                            ) > -1)
                );
            case 'door':
                return ConfiguratorsDataService.data.doorLines.filter(
                    el =>
                        unavailableSystems.indexOf(el.id) < 0
                        && (!$rootScope.coupledPosition
                            || !CoupledWindowService.couplingOptions.matchedWindowSystems
                            || CoupledWindowService.couplingOptions.matchedWindowSystems.indexOf(
                                Number(el.id)
                            ) > -1)
                );
            case 'folding_door':
                return ConfiguratorsDataService.data.foldingDoorLines.filter(
                    el =>
                        unavailableSystems.indexOf(el.id) < 0
                        && (!$rootScope.coupledPosition
                            || !CoupledWindowService.couplingOptions.matchedWindowSystems
                            || CoupledWindowService.couplingOptions.matchedWindowSystems.indexOf(
                                Number(el.id)
                            ) > -1)
                );
        }
    }

    function selectDestination(destination) {
        ConfigurationsService.conf.Current.destination = destination;
        ConfigurationsService.conf.Default.destination = destination;
        StepFactory.goToNextStep(true);
    }

    function selectSystem(
        conf,
        defaultConf,
        system,
        type,
        nextStep,
        price,
        editedPosition = !!ConfigurationsService.conf.Edit
    ) {
        factory.selectSystemType(type, conf, defaultConf);
        if (CurConfService.conf == 'window') {
            if (!system.different_sash_color) {
                conf.ColorsSashExt = false;
                ColorsFactory.setColorsSashExt(false, conf, defaultConf);
            }
            if (IccConfig.Configurators.steelSelect) {
                if (system.steel_disabled) {
                    conf.Steel = null;
                    defaultConf.Steel = null;
                } else if (conf.Steel === null) {
                    const steel = system.steel_closed ? 'Closed' : 'Opened';
                    conf.Steel = steel;
                    defaultConf.Steel = steel;
                }
            }
        }

        const newSystem =
            ConfigurationsService.conf.Current.System
            && ConfigurationsService.conf.Current.System.id
            && Number(ConfigurationsService.conf.Current.System.id) === Number(system.id)
                ? false
                : true;

        conf.System = system;
        defaultConf.System = system;

        ValidationService.someIndeterminate(conf, [
            'sashes',
            'shape',
            'colors',
            'loadedMuntinsColors',
            'loadedProfiles',
            'sealColor',
        ]);
        ValidationService.valid(conf, 'system');
        EventBusService.post({
            key: 'setSystem',
            value: {
                systemId: system.id,
                newSystem
            },
            conf,
            defaultConf
        });

        if (conf.type === 'door' && IccConfig.Configurators.door.version === 1) {
            conf.Name =
                (conf.ModelGroup && conf.ModelGroup.name ? conf.ModelGroup.name + ' > ' : '')
                + system.name;
            defaultConf.Name =
                (conf.ModelGroup && conf.ModelGroup.name ? conf.ModelGroup.name + ' > ' : '')
                + system.name;
        } else {
            conf.Name = (type && type.name ? type.name + ' > ' : '') + system.name;
            defaultConf.Name = (type && type.name ? type.name + ' > ' : '') + system.name;
        }

        if (system.steel_closed && conf.Steel != 'Closed') {
            conf.Steel = 'Closed';
        }

        HandlesFactory.findFittingsBySystem(false, conf, defaultConf);
        HandlesFactory.findHandlesBySystem(false, conf.Fitting, conf);
        HandlesFactory.findHingesBySystem(false, conf);
        HandlesFactory.setDefaultHingeForSystem(conf, defaultConf);
        LocksFactory.findLocksBySystem(conf, defaultConf);
        AccessoriesFactory.findAccessories(conf);
        ColorsFactory.setDefaults(conf, defaultConf);

        if (
            factory.switchFromGreater
            && (angular.isUndefined(ConfigurationsService.conf.addPositionToGroup)
                || !ConfigurationsService.conf.addPositionToGroup)
        ) {
            defaultConf.Glazing = null;
        }

        if (!editedPosition) {
            HandlesFactory.setHandleType(conf.HandleType, () => true, conf, defaultConf);

            defaultConf.Monoblock = {};
            conf.Monoblock = {};
        }

        MuntinsFactory.changeType(conf);

        // sprawdź dostępność słupków i skrzydeł
        ConstructionLimitationFactory.findReinforcement(conf);

        if (nextStep) {
            if (CurConfService.conf != 'door' || IccConfig.Configurators.door.version > 1) {
                StepFactory.goToNextStep(true);
            }
        }
        if (price) {
            PriceService.count();
        }

        if (IccConfig.Configurators.dependencies) {
            EventBusService.post({ key: 'processDependencies', value: null, conf });
        }

        if (IccConfig.Configurators.active.includes('mosquito')) {
            MosquitoSystemsFactory.valid(conf);
        }

        HandlesFactory.refreshTypes(conf);
        SchemasFactory.init(conf);
    }

    function getSystemType() {
        if (
            angular.isDefined(ConfigurationsService.conf.Current.SystemType)
            && angular.isDefined(ConfigurationsService.conf.Current.SystemType.static)
        ) {
            return ConfigurationsService.conf.Current.SystemType;
        }

        return factory.systemTypes[0];
    }

    function selectSystemType(type, conf, defaultConf) {
        if (type.id != conf.SystemType.id) {
            conf.SystemType = type;
            defaultConf.SystemType = type;
            ColorsFactory.setDefaults();
        }
    }

    function setDefaultValues() {
        const adminThumb = ~~sessionStorage.getItem('adminThumb');
        if (
            IccConfig.Configurators.autoSelectSystem
            || (ConfigurationsService.conf.Default.System
                && ConfigurationsService.conf.Default.System.id)
            || CurConfService.conf === 'door'
            || adminThumb
        ) {
            const system = Core.fIdO(factory.systems, ConfigurationsService.conf.Default.System.id);
            if (system && system.id) {
                factory.selectSystem(
                    ConfigurationsService.conf.Current,
                    ConfigurationsService.conf.Default,
                    system,
                    ConfigurationsService.conf.Default.SystemType
                );
            } else {
                factory.selectSystem(
                    ConfigurationsService.conf.Current,
                    ConfigurationsService.conf.Default,
                    factory.systems[0],
                    factory.systemTypes[0]
                );
            }
        }
        if (
            adminThumb
            && ['window', 'hs', 'door', 'folding_door'].includes(ConfigurationsService.conf.conf)
        ) {
            for (var j = 0; j < LayoutFactory.defaultLayouts.length; j++) {
                if (~~LayoutFactory.defaultLayouts[j].SashesLayoutsVariant.id === adminThumb) {
                    LayoutFactory.selectLayout(LayoutFactory.defaultLayouts[j], true);
                    window.sashesLayoutId = LayoutFactory.defaultLayouts[j].SashesLayout.id;
                }
            }
        }
    }
}
