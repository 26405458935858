const SystemsComparisonModalTemplate = require<string>('ngtemplate-loader!./systems-comparison-modal.component.html');
import SystemsComparisonModalController from './systems-comparison-modal.controller';

export const SystemsComparisonModalComponent: ng.IComponentOptions = {
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  templateUrl: SystemsComparisonModalTemplate,
  controller: SystemsComparisonModalController
};
