export default class SystemsComparisonModalController {
    resolve: {
        conf;
        currency;
        configs;
        onOffer;
        drawOptions;
    };
    close: ({ $value }) => any;
    dismiss: () => any;

    constructor(
        private $rootScope,
        private ScrollbarService,
        private SystemsComparisonService,
        private InfoFactory,
        private $translate,
        public IccConfig,
    ) {}

    $onChanges() {
        this.ScrollbarService.update();
    }

    isPrice(price) {
        return !isNaN(price);
    }

    closeModal() {
        this.close({ $value: { config: false } });
    }

    changeSystem(config) {
        this.InfoFactory.confirmModal(
            this.$translate.instant('OFFER|Potwierdzenie zmiany systemu'),
            this.$translate.instant('OFFER|Czy na pewno chcesz zmienić system?'),
            [
                {
                    name: this.$translate.instant('INTERFACE|Tak'),
                    callback: () => {
                        this.close({ $value: { config } })
                    }
                },
                {
                    name:this.$translate.instant('INTERFACE|Nie'),
                    callback: () => {},
                    accent: true,
                },
            ]
        );
    }

    async details(config) {
        const data = await this.SystemsComparisonService.openSystemsComparisonDetailsModal(
            config,
            this.resolve.conf,
            this.resolve.onOffer
        );
        if (data.config) {
            this.close({ $value: data});
        }
    }

    createSubOffer() {
        this.SystemsComparisonService.createSubOffer();
        this.close({ $value: false});
    }
}
