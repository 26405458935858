export default function SvgImageFilter(base64DecoderFilter, $sce) {
    'ngInject';

    return function(data, IccConfig, conf) {
        data = base64DecoderFilter(data);

        data = data.replace(/url\(\'(https?|icc-res)\:\/\/.*?\#/g, `url('#`);
        data = data.replace(/url\((https?|icc-res)\:\/\/.*?\#/g, `url(#`);

        data = data.replace(/cdvfile:\/\/localhost\/persistent\/files/g, ``);

        data = data.replace(/class="windowSvg[a-zA-Z0-9-\s]*"/g, '$0 height="' + IccConfig.Drawing.pdf.height + '" width="' + IccConfig.Drawing.pdf.width + '"');
        data = data.replace(/about:blank/g, '');

        data = data.replace(/<\/?icc-draw[^>]*>/g, '');

        if (IccConfig.Drawing.pdf.blackWhite) {
            data = data.replace(/url\(\'?#iccDraw\-muntins\-[a-zA-Z0-9-\s]*\'?\)/g, `#000`);
            data = data.replace(/url\(\'?#iccDraw\-[a-z]*\-[a-z0-9\-]{36}\'?\)/g, `#fff`);
            data = data.replace(/stroke\=\"\#bbb\"/g, `stroke="#000"`);
        }

        if (IccConfig.Drawing.pdf.muntins == 'black') {
            data = data.replace(/url\(\'?#iccDraw\-muntins\-[a-zA-Z0-9-\s]*\'?\)/g, `#000`);
        }

        return $sce.trustAsHtml(data);
    };
}
