import Common from '../Common';
import {PriceFunc, PriceElemsData, PriceSegment} from './Prices';
import {CustomPricesRecords} from './CustomPrice';
import SiliconeColorsDefaultsService from '../colors/silicone-colors-defaults.service';
import {APP_CONFIG, AppConfig} from '../config';
import {Injectable, Inject} from '@angular/core';

@Injectable()
export default class PriceSiliconeService {
    constructor(
        @Inject(APP_CONFIG) private config: AppConfig,
        private siliconeColorsDefaultsService: SiliconeColorsDefaultsService
    ) {}

    /**
     * Wylicza dopłatę procentową za zmianę koloru uszczelki.
     *
     * @param {number} price         Wejsciowa cena
     * @param {object} PriceElems    Składowe wyceny
     * @param {array}  NoPriceCauses Powody braku wyceny
     * @param {array}  constrElems   Lista elementów konstrukcyjnych, do przeliczenia przez dopłatę.
     * @param {object} sealColor     Okucie bezpieczne
     * @return {number} Cena z doliczoną dopłatą.
     */
    @PriceFunc({
        shortName: 'siliconeColor',
        data: {
            siliconeColor: 'conf.SiliconeColor',
            conf         : 'conf',
            customPrice  : 'price.WindowSiliconeColor',
            colors       : 'data.windowSiliconeColors'
        }
    })
    suppSiliconeColor({}: PriceElemsData,
        {siliconeColor, conf, customPrice, colors}: {siliconeColor, conf, customPrice: CustomPricesRecords, colors}
    ): PriceSegment[] {
        let factors = 1;
        if (!this.config.IccConfig.Configurators.siliconeColor || conf.SystemType.static !== 'wood') {
            return [];
        }
        const defaultColor = this.siliconeColorsDefaultsService.getDefaultColor(colors, conf);
        if (Common.isObject(siliconeColor) && siliconeColor.id && defaultColor && Number(defaultColor.id) !== Number(siliconeColor.id)) {
            let siliconeColorPrice;
            if (Common.isObject(customPrice) && customPrice[siliconeColor.id]) {
                siliconeColorPrice = customPrice[siliconeColor.id].getPrice('price');
            } else {
                siliconeColorPrice = parseFloat(siliconeColor.price);
            }

            factors *= (100 + siliconeColorPrice) / 100;
        }

        return <PriceSegment[]>[{
            type: 'siliconeColor',
            baseValue: factors,
            value: factors,
            valueType: 'percent',
            data: {
                id  : siliconeColor.id,
                name: siliconeColor.name,
            }
        }];
    }
}
