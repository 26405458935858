import Common from './Common';
import { core } from 'helpers';
import {
    Profile,
    ProfileType,
    FrameProfile,
    ProfileShape,
    Frame,
} from './configurations/parts/window';
import { LoadedConfiguratorsDataValue } from 'configurators/configurators-data.service';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import { ProfilesPriceService } from 'profiles/profiles-price.service';
import { EventBusService } from 'event-bus.service';
import { MullionsService } from 'profiles/mullions.service';
import { ValidationService } from 'configurators/validation.service';
import { Injectable } from '@angular/core';
import WindowConfiguration from 'configurations/WindowConfiguration';
import CoupledWindowActiveConfiguration from 'configurations/CoupledWindowActiveConfiguration';
import { IccColor } from 'data-types';
import { ActiveSash } from 'layout/active-sash';

/**
 * Fabryka użytkowników
 * @param {object} $filter             filter
 * @param {object} $q                  q
 * @param {object} SynchronizeService  SynchronizeService
 * @param {String} USER_ID             id użytkownika
 */
@Injectable()
export default abstract class ProfilesService {
    loadedData = false;
    profilesPrices = [];
    colors: IccColor[] = [];
    colorRals: IccColor[] = [];
    protected profiles: Profile[] = [];
    protected profileShapes = [];
    protected profileSets: Profile[] = [];
    protected reinforcements = [];

    constructor(
        protected profilesPriceService: ProfilesPriceService,
        protected eventBusService: EventBusService,
        protected mullionsService: MullionsService,
        protected validationService: ValidationService
    ) {
        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>(
            'loadedConfiguratorsData',
            data => {
                this.loadProfiles(
                    data.value,
                    data.activeConfiguration as WindowActiveConfiguration
                );
                this.loadColors(data.value);
            }
        );
    }

    getProfile(id: number) {
        return this.profiles.filter(profile => profile.id === id)[0];
    }

    getProfilesWeight(
        type:
            | 'alignment'
            | 'extension'
            | 'frame'
            | 'glazingBead'
            | 'mullion'
            | 'coupling'
            | 'sash'
            | 'sashFrame',
        data,
        conf: WindowActiveConfiguration | CoupledWindowActiveConfiguration,
        params: {
            frameId?: number;
            sashId?: number;
            mullionId?: number;
            extensionId?: number;
            alignmentId?: number;
            couplingId?: number;
        } = {}
    ) {
        let weight = 0;
        const dimensionsData = conf.drawData[type].find(el =>
            params
                ? Object.keys(params).reduce(
                      (prev, curr) => prev && el[curr] === params[curr],
                      true
                  )
                : true
        );

        if (dimensionsData) {
            if (Common.isDefined(dimensionsData.sides)) {
                weight = dimensionsData.sides.reduce((prev, side, index) => {
                    let profile: Profile;
                    let reinforcementId;
                    if (
                        Common.isArray(data)
                        && Common.isDefined(data[index])
                        && Common.isDefined(data[index].profileId)
                    ) {
                        profile = this.getProfile(data[index].profileId);
                        if (data[index].Id && data[index].Id.id) {
                            reinforcementId = data[index].reinforcement.id;
                        }
                    } else if (Common.isObject(data) && Common.isDefined(data.profileId)) {
                        profile = this.getProfile(data.profileId);
                        if (data.reinforcement && data.reinforcement.id) {
                            reinforcementId = data.reinforcement.id;
                        }
                    } else if (
                        Common.isObject(data)
                        && Common.isObject(data[side.outerEdge.side])
                        && Common.isDefined(data[side.outerEdge.side].profileId)
                    ) {
                        profile = this.getProfile(data[side.outerEdge.side].profileId);
                        if (
                            !side.outerEdge.circle
                            && data[side.outerEdge.side].reinforcement
                            && data[side.outerEdge.side].reinforcement.id
                        ) {
                            reinforcementId = data[side.outerEdge.side].reinforcement.id;
                        }
                    }

                    if (profile) {
                        if (
                            WindowActiveConfiguration.is(conf)
                            && conf.System.type === 'wood'
                            && conf.Wood.mass
                            && profile.sectionArea
                        ) {
                            prev +=
                                (((profile.sectionArea || 0) * (side.outerEdge.length || 0))
                                    / 1000000000)
                                * (parseFloat(conf.Wood.mass) || 0);
                        } else {
                            prev += ((profile.mass || 0) * (side.outerEdge.length || 0)) / 1000;
                        }
                        if (reinforcementId) {
                            const reinforcement = this.reinforcements.find(
                                el => el.id === reinforcementId
                            );
                            if (reinforcement) {
                                prev +=
                                    ((reinforcement.weight || 0) * (side.outerEdge.length || 0))
                                    / 1000;
                            }
                        }
                    }
                    return prev;
                }, 0);
            } else {
                let reinforcementId;
                const profile = this.getProfile(data.profileId);
                if (data.reinforcement && data.reinforcement.id) {
                    reinforcementId = data.reinforcement.id;
                }
                if (profile) {
                    if (
                        WindowActiveConfiguration.is(conf)
                        && conf.System.type === 'wood'
                        && conf.Wood.mass
                        && profile.sectionArea
                    ) {
                        weight =
                            (((profile.sectionArea || 0) * (dimensionsData.length || 0))
                                / 1000000000)
                            * (parseFloat(conf.Wood.mass) || 0);
                    } else {
                        weight = ((profile.mass || 0) * (dimensionsData.length || 0)) / 1000;
                    }
                    if (reinforcementId) {
                        const reinforcement = this.reinforcements.find(
                            el => el.id === reinforcementId
                        );
                        if (reinforcement) {
                            weight +=
                                ((reinforcement.weight || 0) * (dimensionsData.length || 0)) / 1000;
                        }
                    }
                }
            }
        }

        return weight;
    }

    getFilteredProfiles(
        conf: WindowActiveConfiguration | WindowConfiguration | null,
        type: ProfileType | ProfileType[],
        options:
            | {
                  and?: string[];
                  not?: string[];
              }
            | {
                  and?: string[];
                  not?: string[];
              }[] = {
            and: [],
            not: [],
        },
        calcPrice = true
    ) {
        const systemId = conf
            ? WindowConfiguration.is(conf)
                ? conf.system.id
                : Number(conf.System.id)
            : null;

        const tmpProfileIds = {};
        return this.profiles
            .filter(el => {
                const result =
                    !tmpProfileIds[el.id]
                    && (!systemId || el.systems.some(sys => Number(sys) === systemId))
                    && ((Common.isArray(type)
                        && Common.isArray(options)
                        && type.some(
                            (t, index) =>
                                (el.type === t || !type)
                                && (!Common.isDefined(options[index].and)
                                    || !options[index].and.length
                                    || options[index].and.every(
                                        option => el.options.indexOf(option) > -1
                                    ))
                                && (!Common.isDefined(options[index].not)
                                    || !options[index].not.length
                                    || options[index].not.every(
                                        option => el.options.indexOf(option) === -1
                                    ))
                        ))
                        || (!Common.isArray(type)
                            && !Common.isArray(options)
                            && (el.type === type || !type)
                            && (!Common.isDefined(options.and)
                                || !options.and.length
                                || options.and.every(option => el.options.indexOf(option) > -1))
                            && (!Common.isDefined(options.not)
                                || !options.not.length
                                || options.not.every(
                                    option => el.options.indexOf(option) === -1
                                ))));

                tmpProfileIds[el.id] = true;
                return result;
            })
            .map(el => {
                if (calcPrice) {
                    let profileOptions: {
                        and?: string[];
                        not?: string[];
                    } = {
                        and: [],
                        not: [],
                    };
                    if (Common.isArray(options) && Common.isArray(type)) {
                        profileOptions = options[type.indexOf(el.type)];
                    } else if (!Common.isArray(options) && !Common.isArray(type)) {
                        profileOptions = options;
                    }
                    const priceType = this.getProfilePriceType(el.type, profileOptions);
                    el.price = systemId
                        ? this.profilesPriceService.getProfilePrice(
                            el.id,
                            priceType,
                            WindowConfiguration.is(conf) ? conf.system : conf.System,
                            WindowConfiguration.is(conf) ? conf.colors : conf.Colors,
                            this.profilesPrices
                        )
                        : null;
                }
                delete el.selectedColor;
                delete el.selectedWood;

                return el;
            });
    }

    getProfilePriceType(
        type: ProfileType,
        options: {
            and?: string[];
            not?: string[];
        } = {
            and: [],
            not: [],
        }
    ) {
        let priceType: string = type;

        if (options.and && options.and.some(el => el.indexOf('alignment') > -1)) {
            priceType = 'alignment';
        } else if (!priceType) {
            priceType = 'other';
        } else if (
            options.and
            && options.and.some(
                el => el.indexOf('vertical_sash') > -1 || el.indexOf('horizontal_sash') > -1
            )
        ) {
            priceType = 'structured_muntin_sash';
        }

        return priceType;
    }

    getMullionPriceType(mullion, mullions, confType, profiles = this.profiles) {
        let fields1 = mullion.multiAlignLeft;
        let fields2 = mullion.multiAlignRight;
        if (mullion.direction === 'horizontal') {
            fields1 = mullion.multiAlignTop;
            fields2 = mullion.multiAlignBottom;
        }
        const falseMullionSashes = this.getFalseMullionSashes(profiles).map(profile => profile.id);
        const mullionType = this.mullionsService.getMullionTypeBetweenFields(
            fields1,
            fields2,
            mullion.direction,
            falseMullionSashes,
            confType,
            mullions
        );

        if (!mullion.profileId) {
            return false;
        }

        return this.mullionsService.mullionTypes[mullionType].priceType;
    }

    getMullionSetType(mullion, mullions, confType, profiles = this.profiles) {
        let fields1 = mullion.multiAlignLeft;
        let fields2 = mullion.multiAlignRight;
        if (mullion.direction === 'horizontal') {
            fields1 = mullion.multiAlignTop;
            fields2 = mullion.multiAlignBottom;
        }
        const falseMullionSashes = this.getFalseMullionSashes(profiles).map(profile => profile.id);
        const mullionType = this.mullionsService.getMullionTypeBetweenFields(
            fields1,
            fields2,
            mullion.direction,
            falseMullionSashes,
            confType,
            mullions
        );

        if (!mullion.profileId) {
            return false;
        }

        return this.mullionsService.mullionTypes[mullionType].set;
    }

    setDefaultsFromSet(conf: WindowActiveConfiguration, defaultConf: WindowActiveConfiguration) {
        this.setDefaultFrames(conf);
        this.setDefaultSashes(conf, defaultConf);
        this.setDefaultMullions(conf);
    }

    setDefaultFrames(conf: WindowActiveConfiguration) {
        conf.Frames.forEach(frame => this.setDefaultFrame(frame, conf));
    }

    setDefaultFrame(frame: Frame, conf: WindowActiveConfiguration) {
        // rama i próg
        const pauseId = this.eventBusService.pause(['setFrameProfile']);
        try {
            const sides = this.getFrameSides(frame, conf);
            sides.forEach((side, index) => {
                this.setDefaultFrameProfile(conf, frame, side.side, side.sideSimple, index);
            });
        } finally {
            this.eventBusService.resume(['setFrameProfile'], pauseId);
        }
    }

    setDefaultFrameProfile(
        conf: WindowActiveConfiguration,
        frame: Frame,
        side: string,
        sideSimple: string,
        position: number
    ) {
        // rama i próg
        const type = this.getFrameTypeForSide(sideSimple, frame);
        const profile = this.getProfile(conf.ProfileSet[type]);

        this.setFrameProfile(conf, profile, frame, position, {
            isDefault: true,
            finWidth:
                profile && (sideSimple !== 'top' || (sideSimple === 'top' && !conf.hasRoller))
                    ? profile.finWidth || 0
                    : 0,
            side,
        });
    }

    setDefaultSashes(conf: WindowActiveConfiguration, defaultConf: WindowActiveConfiguration) {
        // skrzydło
        const pauseId = this.eventBusService.pause(['setSashProfile']);
        try {
            conf.Sashes.forEach(sash => {
                this.setDefaultSash(sash, conf, defaultConf);
            });
        } finally {
            this.eventBusService.resume(['setSashProfile'], pauseId);
        }
    }

    setDefaultMullion(conf: WindowActiveConfiguration, mullion) {
        let fields1 = mullion.multiAlignLeft;
        let fields2 = mullion.multiAlignRight;
        if (mullion.direction === 'horizontal') {
            fields1 = mullion.multiAlignTop;
            fields2 = mullion.multiAlignBottom;
        }
        const defaultProfileId = this.getMullionProfileIdBetweenFields(
            conf,
            fields1,
            fields2,
            mullion.direction
        );
        const profile = this.getProfile(defaultProfileId);
        this.setMullionProfile(conf, mullion, profile, { isDefault: true });
    }

    setDefaultMullions(conf: WindowActiveConfiguration) {
        // słupki
        conf.Mullions.forEach(mullion => {
            this.setDefaultMullion(conf, mullion);
        });
        conf.Sashes.forEach(sash => {
            if (sash.intMullions) {
                sash.intMullions.forEach(mullion => {
                    this.setDefaultMullion(conf, mullion);
                });
            }
        });
    }

    setDefaultSash(sash, conf: WindowActiveConfiguration, defaultConf: WindowActiveConfiguration) {
        const profile = this.getProfile(
            conf.ProfileSet[sash.type.out_open ? 'sashOutward' : 'sash']
        );
        this.setSash(conf, defaultConf, sash, profile, { isDefault: true });
    }

    setMullionProfile(
        conf: WindowActiveConfiguration,
        mullion,
        profile: Profile,
        { isDefault = false }: { isDefault?: boolean }
    ) {
        if (profile) {
            mullion.profileId = profile.id;
            mullion.type = profile.type;
            mullion.options = profile.options;
        } else {
            mullion.profileId = null;
            mullion.type = 'no_mullion';
            mullion.options = [];
        }
        mullion.isDefault = isDefault;

        this.setUsedProfiles(conf, profile);

        this.eventBusService.post({
            key: 'setMullionProfile',
            value: {
                profile,
                mullion,
            },
            conf
        });
    }

    getMullionProfileIdBetweenFields(
        conf: WindowActiveConfiguration,
        fields1: ActiveSash[],
        fields2: ActiveSash[],
        direction: 'horizontal' | 'vertical'
    ) {
        const falseMullionSashes = this.getFalseMullionSashes().map(profile => profile.id);
        const mullionType = this.mullionsService.getMullionTypeBetweenFields(
            fields1,
            fields2,
            direction,
            falseMullionSashes,
            conf.type,
            conf.Mullions
        );
        const neededWiderMullion = this.mullionsService.neededWiderMullion(
            fields1,
            fields2,
            direction
        );
        const matchingProfiles = this.getFilteredProfiles(
            conf,
            this.mullionsService.mullionTypes[mullionType].type,
            {
                and: this.mullionsService.mullionTypes[mullionType][direction],
                not: [],
            }
        ).filter(profile => !neededWiderMullion || (neededWiderMullion && profile.width >= 60));
        const matchingProfileIdFromSet =
            conf.ProfileSet[this.mullionsService.mullionTypes[mullionType].set];
        if (matchingProfiles.length > 0 && mullionType !== 'no_mullion') {
            if (
                matchingProfiles.map(profile => profile.id).indexOf(matchingProfileIdFromSet) > -1
            ) {
                return matchingProfileIdFromSet;
            }
            return matchingProfiles[0].id;
        }
        return null;
    }

    setSashProfile(
        conf: WindowActiveConfiguration,
        defaultConf: WindowActiveConfiguration,
        sash,
        profile: Profile,
        position: 'bottom' | 'top' | 'left' | 'right',
        { isDefault = false }: { isDefault?: boolean }
    ) {
        const sashProfile: FrameProfile = {
            id: 0,
            isDefault,
            profileId: profile && profile.id ? profile.id : null,
            jointAngle: 'E',
            weldFinishType: 'groove',
            reinforcement: null,
        };
        sash.frame[position] = sashProfile;

        this.setUsedProfiles(conf, profile);

        this.eventBusService.post({
            key: 'setSashProfile',
            value: {
                sash,
                position,
                profile,
            },
            conf,
            defaultConf
        });
    }

    setSash(
        conf: WindowActiveConfiguration,
        defaultConf: WindowActiveConfiguration,
        sash,
        profile: Profile,
        { isDefault = false }: { isDefault?: boolean }
    ) {
        const sides: ('bottom' | 'top' | 'left' | 'right')[] = ['top', 'bottom', 'left', 'right'];
        sides.forEach((side, index) => {
            const matchingProfile =
                profile && this.validSashProfile({ profileId: profile.id }, sash, conf, side)
                    ? profile
                    : this.profiles.find(el =>
                          this.validSashProfile({ profileId: el.id }, sash, conf, side)
                      );

            if (matchingProfile) {
                this.setSashProfile(conf, defaultConf, sash, matchingProfile, side, { isDefault });
            } else {
                this.setSashProfile(conf, defaultConf, sash, profile, side, { isDefault });
            }
        });
    }

    validSashProfile(sashProfile, sash, conf, side) {
        if (!sashProfile || !sashProfile.profileId) {
            return false;
        }
        const profile = this.getProfile(sashProfile.profileId) as any;
        if (!profile) {
            return false;
        }
        if (profile.systems.indexOf(Number(conf.System.id)) === -1) {
            return false;
        }
        let valid = profile.type === 'sash';
        if (
            sash.type.passive
            && ((sash.type.handle_position === 'R' && side === 'right')
                || (sash.type.handle_position === 'L' && side === 'left'))
        ) {
            valid = valid || profile.type === 'false_mullion_sash';
        }

        if (sash.nearMullions.bottom > -1) {
            const bottomSashes = conf.Mullions.find(el => el.id === sash.nearMullions.bottom)
                .multiAlignBottom;
            const sidesMap = {
                top: 'top_in_top_sash',
                bottom: 'bottom_in_top_sash',
                left: 'side_in_top_sash',
                right: 'side_in_top_sash',
            };

            if (
                (sash.type.type === 'SD' || bottomSashes.some(el => el.type.type === 'SU'))
                && !profile.options.includes(sidesMap[side])
            ) {
                valid = false;
            }
        } else if (sash.nearMullions.top > -1) {
            const topSashes = conf.Mullions.find(el => el.id === sash.nearMullions.top)
                .multiAlignTop;
            const sidesMap = {
                top: 'top_in_bottom_sash',
                bottom: 'bottom_in_bottom_sash',
                left: 'side_in_bottom_sash',
                right: 'side_in_bottom_sash',
            };

            if (
                (sash.type.type === 'SU' || topSashes.some(el => el.type.type === 'SD'))
                && !profile.options.includes(sidesMap[side])
            ) {
                valid = false;
            }
        }

        return valid && profile.options.includes('outward_opening') === sash.type.out_open;
    }

    setFrameProfile(
        conf: WindowActiveConfiguration,
        profile: Profile,
        frame: Frame,
        position: number,
        {
            isDefault = false,
            finWidth = 0,
            side,
        }: { isDefault?: boolean; finWidth?: number; side?: string }
    ) {
        const sides = this.getFrameSides(frame, conf);
        if (position < 0 || position > sides.length - 1) {
            throw new Error('Nie można ustawić profilu ramy w tym miejscu!');
        }

        const frameProfile: FrameProfile = {
            id: position,
            isDefault,
            profileId: profile && profile.id ? profile.id : null,
            finWidth,
            jointAngle: 'E',
            weldFinishType: 'groove',
            reinforcement: null,
            side,
        };
        frame.frame[position] = frameProfile;

        this.setUsedProfiles(conf, profile);

        this.eventBusService.post({
            key: 'setFrameProfile',
            value: {
                frame,
                position,
                profile,
            },
            conf
        });
    }

    getUsedProfilesIds(conf: WindowActiveConfiguration) {
        const profilesIds = [];
        const profilesSegments = {};

        const frameColor = {
            outer: null,
            inner: null,
            core: null,
        };
        const sashColor = {
            outer: null,
            inner: null,
            core: null,
        };

        Object.keys(frameColor).forEach(side => {
            if (conf.Colors.frame[side] && conf.Colors.frame[side].id) {
                frameColor[side] = {
                    id: conf.Colors.frame[side].id,
                    RAL: conf.Colors.frame[side].RAL,
                    groups: conf.Colors.frame[side].groups,
                    name: conf.Colors.frame[side].name,
                    type: conf.Colors.frame[side].type,
                };
            }
            if (conf.Colors.sash[side] && conf.Colors.sash[side].id) {
                sashColor[side] = {
                    id: conf.Colors.sash[side].id,
                    RAL: conf.Colors.sash[side].RAL,
                    groups: conf.Colors.frame[side].groups,
                    name: conf.Colors.frame[side].name,
                    type: conf.Colors.frame[side].type,
                };
            }
        });

        conf.Frames.forEach(frame => {
            frame.frame.forEach((el, index) => {
                if (profilesIds.indexOf(el.profileId) === -1) {
                    profilesIds.push(el.profileId);

                    profilesSegments[el.profileId] = [];
                }
                if (conf.drawData && conf.drawData.frame) {
                    const frameDrawData = conf.drawData.frame.find(f => f.frameId === frame.id);
                    if (frameDrawData && frameDrawData.sides[index]) {
                        profilesSegments[el.profileId].push({
                            length: frameDrawData.sides[index].outerEdge.length,
                            color: frameColor,
                        });
                    }
                }
            });
        });

        conf.Mullions.forEach(el => {
            if (profilesIds.indexOf(el.profileId) === -1) {
                profilesIds.push(el.profileId);

                profilesSegments[el.profileId] = [];
            }
            const mullionDimensions =
                conf.drawData && conf.drawData.mullion
                    ? conf.drawData.mullion.find(m => m.mullionId === el.id)
                    : null;
            if (mullionDimensions) {
                profilesSegments[el.profileId].push({
                    length: mullionDimensions.length,
                    color: frameColor,
                });
            }
        });
        if (conf.Alignments) {
            conf.Alignments.forEach(el => {
                if (profilesIds.indexOf(el.profileId) === -1) {
                    profilesIds.push(el.profileId);

                    profilesSegments[el.profileId] = [];
                }

                const alignmentDimensions =
                    conf.drawData && conf.drawData.alignment
                        ? conf.drawData.alignment.find(m => m.alignmentId === el.id)
                        : null;
                if (alignmentDimensions) {
                    profilesSegments[el.profileId].push({
                        length: alignmentDimensions.length,
                        color: frameColor,
                    });
                }
            });
        }
        conf.SideProfiles.forEach(el => {
            if (profilesIds.indexOf(el.profileId) === -1) {
                profilesIds.push(el.profileId);

                profilesSegments[el.profileId] = [];
            }

            const extensionColor = {
                outer: null,
                inner: null,
                core: null,
            };

            Object.keys(extensionColor).forEach(s => {
                if (el.color && el.color[s] && el.color[s].id) {
                    extensionColor[s] = {
                        id: el.color[s].id,
                        RAL: el.color[s].RAL,
                        groups: el.color[s].groups,
                        name: el.color[s].name,
                        type: el.color[s].type,
                    };
                }
            });

            const extensionDimensions =
                conf.drawData && conf.drawData.extension
                    ? conf.drawData.extension.find(m => m.alignmentId === el.id)
                    : null;
            if (extensionDimensions) {
                profilesSegments[el.profileId].push({
                    length: extensionDimensions.length,
                    color: extensionColor,
                });
            }
        });
        conf.Sashes.forEach(sash => {
            if (sash.glazingBead && sash.intSashes.length === 0) {
                if (profilesIds.indexOf(sash.glazingBead.profileId) === -1) {
                    profilesIds.push(sash.glazingBead.profileId);

                    profilesSegments[sash.glazingBead.profileId] = [];
                }
                const glazingBeadDimensions =
                    conf.drawData && conf.drawData.glazingBead
                        ? conf.drawData.glazingBead.find(m => m.sashId === sash.id)
                        : null;
                if (glazingBeadDimensions) {
                    glazingBeadDimensions.sides.forEach(bead => {
                        profilesSegments[sash.glazingBead.profileId].push({
                            length: bead.outerEdge.length,
                            color: frameColor,
                        });
                    });
                }
            }
            sash.intMullions.forEach(el => {
                if (profilesIds.indexOf(el.profileId) === -1) {
                    profilesIds.push(el.profileId);

                    profilesSegments[el.profileId] = [];
                }

                const mullionDimensions =
                    conf.drawData && conf.drawData.mullion
                        ? conf.drawData.mullion.find(m => m.mullionId === el.id)
                        : null;
                if (mullionDimensions) {
                    profilesSegments[el.profileId].push({
                        length: mullionDimensions.length,
                        color: sashColor,
                    });
                }
            });
            sash.intSashes.forEach(el => {
                if (el.glazingBead) {
                    if (profilesIds.indexOf(el.glazingBead.profileId) === -1) {
                        profilesIds.push(el.glazingBead.profileId);

                        profilesSegments[el.glazingBead.profileId] = [];
                    }

                    const glazingBeadDimensions =
                        conf.drawData && conf.drawData.glazingBead
                            ? conf.drawData.glazingBead.find(m => m.sashId === el.id)
                            : null;
                    if (glazingBeadDimensions) {
                        glazingBeadDimensions.sides.forEach(bead => {
                            profilesSegments[el.glazingBead.profileId].push({
                                length: bead.outerEdge.length,
                                color: sashColor,
                            });
                        });
                    }
                }
            });
            sash.intAlignments.forEach(el => {
                if (profilesIds.indexOf(el.profileId) === -1) {
                    profilesIds.push(el.profileId);

                    profilesSegments[el.profileId] = [];
                }

                const alignmentDimensions =
                    conf.drawData && conf.drawData.alignment
                        ? conf.drawData.alignment.find(m => m.alignmentId === el.id)
                        : null;
                if (alignmentDimensions) {
                    profilesSegments[el.profileId].push({
                        length: alignmentDimensions.length,
                        color: sashColor,
                    });
                }
            });
            const sashFrameDimensions =
                conf.drawData && conf.drawData.sashFrame
                    ? conf.drawData.sashFrame.find(m => m.sashId === sash.id)
                    : null;
            ['left', 'right', 'top', 'bottom'].forEach((el, index) => {
                if (
                    sash.frame[el]
                    && sash.frame[el].profileId
                    && profilesIds.indexOf(sash.frame[el].profileId) === -1
                ) {
                    profilesIds.push(sash.frame[el].profileId);

                    profilesSegments[sash.frame[el].profileId] = [];
                }
                if (
                    sash.frame[el]
                    && sash.frame[el].profileId
                    && sashFrameDimensions
                    && sashFrameDimensions.sides
                    && sashFrameDimensions.sides[index]
                ) {
                    profilesSegments[sash.frame[el].profileId].push({
                        length: sashFrameDimensions.sides[index].outerEdge.length,
                        color: sashColor,
                    });
                }
            });
        });

        conf.couplings.forEach(el => {
            if (profilesIds.indexOf(el.profileId) === -1) {
                profilesIds.push(el.profileId);

                profilesSegments[el.profileId] = [];
            }

            const couplingColor = {
                outer: null,
                inner: null,
                core: null,
            };

            Object.keys(couplingColor).forEach(s => {
                if (el.color && el.color[s] && el.color[s].id) {
                    couplingColor[s] = {
                        id: el.color[s].id,
                        RAL: el.color[s].RAL,
                        groups: el.color[s].groups,
                        name: el.color[s].name,
                        type: el.color[s].type,
                    };
                }
            });

            const couplingDimensions =
                conf.drawData && conf.drawData.coupling
                    ? conf.drawData.coupling.find(m => m.couplingId === el.id)
                    : null;
            if (couplingDimensions) {
                profilesSegments[el.profileId].push({
                    length: couplingDimensions.length,
                    color: couplingColor,
                });
            }
        });

        return { profilesIds, profilesSegments };
    }

    getUsedProfileShapesIds(conf: WindowActiveConfiguration) {
        const profileShapesIds = [];
        let profile;

        conf.Sashes.forEach(sash => {
            if (sash.glazingBead && sash.glazingBead.profileId) {
                profile = this.getProfile(sash.glazingBead.profileId);
                if (profileShapesIds.indexOf(profile.profileShapeId) === -1) {
                    profileShapesIds.push(profile.profileShapeId);
                }
            }
            sash.intSashes.forEach(el => {
                if (el.glazingBead && el.glazingBead.profileId) {
                    profile = this.getProfile(el.glazingBead.profileId);
                    if (profileShapesIds.indexOf(profile.profileShapeId) === -1) {
                        profileShapesIds.push(profile.profileShapeId);
                    }
                }
            });
        });

        return profileShapesIds;
    }

    setUsedProfiles(conf: WindowActiveConfiguration, profile?: Profile) {
        if (profile && Common.isUndefined(core.fId(conf.UsedProfiles, profile.id))) {
            conf.UsedProfiles.push(profile);
        }
        const { profilesIds, profilesSegments } = this.getUsedProfilesIds(conf);
        conf.UsedProfiles = conf.UsedProfiles.length
            ? conf.UsedProfiles.filter(el => profilesIds.indexOf(el.id) > -1)
            : (conf.UsedProfiles = this.profiles.filter(el => profilesIds.indexOf(el.id) > -1));

        conf.UsedProfilesSegments = profilesSegments;
    }

    setUsedProfileShapes(conf: WindowActiveConfiguration, profileShape?: ProfileShape) {
        if (profileShape && Common.isUndefined(core.fId(conf.UsedProfileShapes, profileShape.id))) {
            conf.UsedProfileShapes.push(profileShape);
        }
        const profileShapesIds = this.getUsedProfileShapesIds(conf);
        if (conf.UsedProfiles.length) {
            conf.UsedProfileShapes = conf.UsedProfileShapes.filter(
                el => profileShapesIds.indexOf(el.id) > -1
            );
        } else {
            conf.UsedProfileShapes = this.profileShapes.filter(
                el => profileShapesIds.indexOf(el.id) > -1
            );
        }
    }

    getWindowSides(conf: WindowActiveConfiguration) {
        let sides: {
            side: string;
            sideSimple: string;
        }[] = [];
        const sidesMap = [
            { side: 'bottom', sideSimple: 'bottom', test: this.hasBottomSide },
            { side: 'right', sideSimple: 'side', test: this.hasRightSide },
            { side: 'right-top', sideSimple: 'side', test: this.hasRightTopSide },
            { side: 'top', sideSimple: 'top', test: this.hasTopSide },
            { side: 'left-top', sideSimple: 'side', test: this.hasLeftTopSide },
            { side: 'left', sideSimple: 'side', test: this.hasLeftSide },
        ];
        if (conf.Shape) {
            sides = sidesMap.filter(side => side.test(conf.Shape));
        }

        return sides;
    }

    getFrameSidesOnEdge(conf: WindowActiveConfiguration) {
        const sides: {
            side: string;
            sideSimple: string;
            frameEdges: {
                frameId: number;
                frameEdgeIndex: number;
            }[];
        }[] = [];
        const windowSides = this.getWindowSides(conf);

        windowSides.forEach((side, sideIndex) => {
            if (conf.Frames && conf.Frames.length && conf.drawData && conf.drawData.frame.length) {
                for (const frame of conf.Frames) {
                    const frameData =
                        conf.drawData && conf.drawData.frame.find(f => f.frameId === frame.id);
                    let sidePolyIndex = sideIndex;
                    if (
                        conf.Shape.shape === 'arc'
                        && conf.Shape.type === 'F'
                        && side.side === 'left'
                    ) {
                        sidePolyIndex = sidePolyIndex + 1;
                    }
                    if (frameData && frameData.parallel.poly.indexOf(sidePolyIndex) > -1) {
                        const matchSide = sides.find(s => s.side === side.side);
                        if (!matchSide) {
                            sides.push({
                                side: side.side,
                                sideSimple: side.sideSimple,
                                frameEdges: [
                                    {
                                        frameId: frame.id,
                                        frameEdgeIndex: frameData.parallel.poly.indexOf(
                                            sidePolyIndex
                                        ),
                                    },
                                ],
                            });
                        } else {
                            matchSide.frameEdges.push({
                                frameId: frame.id,
                                frameEdgeIndex: frameData.parallel.poly.indexOf(sidePolyIndex),
                            });
                        }
                    }
                }
            } else {
                sides.push({
                    side: side.side,
                    sideSimple: side.sideSimple,
                    frameEdges: [
                        {
                            frameId: 0,
                            frameEdgeIndex: sideIndex,
                        },
                    ],
                });
            }
        });

        return sides;
    }

    getFrameSides(frame: Frame, conf: WindowActiveConfiguration) {
        let sides: {
            side: string;
            sideSimple: string;
        }[] = [];

        const frameData = conf.drawData && conf.drawData.frame.find(f => f.frameId === frame.id);

        if (frameData) {
            sides = frameData.sides.map(side => ({
                side: side.outerEdge.side,
                sideSimple: this.sideToSimpleSide(side.outerEdge.side),
            }));
        } else {
            sides = this.getWindowSides(conf);
        }

        return sides;
    }

    getProfileShape(id: number) {
        return this.profileShapes.filter(profileShape => profileShape.id === id)[0];
    }

    getProfileShapes() {
        return this.profileShapes;
    }

    getProfileShapesNames() {
        return this.profileShapes.reduce((prev, cur) => {
            prev[cur.id] = cur.name;
            return prev;
        }, {});
    }

    getArcFramesEdges(conf: WindowActiveConfiguration) {
        const frameSides = this.getFrameSidesOnEdge(conf);
        if (conf.Shape.shape === 'circle') {
            const topEdges = frameSides.filter(s => s.side === 'top' || s.side === 'bottom');
            const temp = topEdges.reduce<FrameProfile[]>(
                (edges, side) =>
                    edges.concat(
                        side.frameEdges.reduce<FrameProfile[]>((prev, cur) => {
                            const frame = conf.Frames.find(f => f.id === cur.frameId);
                            return frame ? prev.concat(frame.frame[cur.frameEdgeIndex]) : prev;
                        }, [])
                    ),
                []
            );
            return temp;
        } else if (conf.Shape.shape === 'arc') {
            const topEdges = frameSides.find(s => s.side === 'top') || frameSides[0];
            return (
                (topEdges
                    && topEdges.frameEdges.reduce<FrameProfile[]>((prev, cur) => {
                        const frame = conf.Frames.find(f => f.id === cur.frameId);
                        return frame
                            ? prev.concat(frame.frame[cur.frameEdgeIndex] || frame.frame[0])
                            : prev;
                    }, []))
                || []
            );
        }
        return null;
    }

    abstract openProfileColorsModal(profile, selectedProfile);

    abstract async openProfilesShapesModal(selectedShape, shapes);

    protected loadProfiles(data, conf: WindowActiveConfiguration) {
        if (conf && ['window', 'hs', 'door', 'folding_door'].indexOf(conf.type) > -1) {
            this.validationService.indeterminate(conf, 'loadedProfiles');
        }
        if (data.profiles && data.profiles.length > 0) {
            this.profiles = data.profiles || [];
            this.profileShapes = data.profileShapes || [];
            this.profilesPrices = data.profilesPrices || [];
            this.reinforcements = data.reinforcements || [];
            this.loadedData = true;
            if (conf && ['window', 'hs', 'door', 'folding_door'].indexOf(conf.type) > -1) {
                this.validationService.valid(conf, 'loadedProfiles');
                this.eventBusService.post({
                    key: 'loadedProfiles',
                    value: {
                        profiles: this.profiles,
                        profileShapes: this.profileShapes,
                    },
                });
            }
        } else if (conf) {
            this.validationService.invalid(conf, 'loadedProfiles');
        }
    }

    protected getFalseMullionSashes(profiles = this.profiles) {
        const falseMullionSashes = profiles.filter(
            profile => profile.type === 'false_mullion_sash'
        );
        return falseMullionSashes;
    }

    protected isThreshold(side: string, frame: Frame) {
        return side === 'bottom' && frame.lowThreshold;
    }

    protected getFrameTypeForSide(side: string, frame: Frame) {
        let type = 'frame' + side[0].toUpperCase() + side.substring(1);

        if (this.isThreshold(side, frame)) {
            type = 'threshold';
        }

        return type;
    }

    protected hasBottomSide(shape) {
        return shape.shape !== 'circle';
    }

    protected hasTopSide(shape) {
        return (
            ['rect', 'circle', 'arc'].indexOf(shape.shape) > -1
            || (shape.shape === 'poligon' && shape.s2 > 0)
        );
    }

    protected hasRightSide(shape) {
        return (
            ['rect', 'triangle'].indexOf(shape.shape) > -1
            || (shape.shape === 'poligon'
                && (['SLT', 'SLS', 'SLC'].includes(shape.type) || shape.h2 > 0))
            || (shape.shape === 'arc' && (shape.h1 > 0 || shape.type === 'L'))
        );
    }

    protected hasRightTopSide(shape) {
        return (
            shape.shape === 'poligon'
            && (['SRT', 'SRS', 'SRC'].includes(shape.type) || shape.h1 - shape.h2 > 0)
            && shape.s3 > 0
        );
    }

    protected hasLeftTopSide(shape) {
        return shape.shape === 'poligon' && shape.h3 > 0;
    }

    protected hasLeftSide(shape) {
        return (
            ['rect', 'triangle'].indexOf(shape.shape) > -1
            || (shape.shape === 'poligon' && shape.h1 - shape.h3 > 0 && shape.s1 > 0)
            || (shape.shape === 'arc' && (shape.h1 > 0 || shape.type === 'R'))
        );
    }

    protected isFalseMullion(mullion, conf: WindowActiveConfiguration) {
        if (mullion.multiAlignLeft.length === 1 && mullion.multiAlignRight.length === 1) {
            const falseSashTypes = ['DS', 'DSC', 'DRP'];

            const leftSash = mullion.multiAlignLeft[0];
            const rightSash = mullion.multiAlignRight[0];

            if (
                (leftSash.type
                    && falseSashTypes.indexOf(leftSash.type.type) > -1
                    && leftSash.type.handle_position === 'R')
                || (rightSash.type
                    && falseSashTypes.indexOf(rightSash.type.type) > -1
                    && rightSash.type.handle_position === 'L')
            ) {
                return true;
            }
        }

        return false;
    }
    protected isFrameOrThreshold(type: string, position: string) {
        return type === 'frame' || (type === 'threshold' && position === 'bottom');
    }

    protected sideToSimpleSide(side: 'top' | 'bottom' | 'left' | 'right') {
        if (side === 'left' || side === 'right') {
            return 'side';
        } else {
            return side;
        }
    }

    private loadColors(data) {
        this.colorRals = data.windowColorRals || [];
        this.colors = data.windowColorsAll || [];
    }
}
