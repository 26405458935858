import {NgModule}           from '@angular/core';
import {CommonModule}       from '@angular/common';
import {FormsModule}        from '@angular/forms';

import {TagsComponent}   from './tags.component';
import {systemsByTagFilterProvider, systemsFactoryProvider} from './ajs-upgraded-providers';
import { SystemsByTagPipe } from './systems-by-tag.pipe';

@NgModule({
  imports:      [ CommonModule, FormsModule ],
  declarations: [ TagsComponent, SystemsByTagPipe ],
  exports:      [ TagsComponent, SystemsByTagPipe ],
  entryComponents: [TagsComponent],
  providers:    [
    systemsByTagFilterProvider,
    systemsFactoryProvider
  ]
})
export class SystemsModule { }
