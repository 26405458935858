import { Injectable, Inject } from '@angular/core';
import { IccDrawMathService, IccDrawSyncService } from '@icc/draw';
import { APP_CONFIG, AppConfig } from 'config';

import ConfigurationsService from 'configurations/configurations.service';
import { EventBusService } from 'event-bus.service';
import UserService from 'user.service';
import { DrawData } from './draw-data';
import { logger } from 'helpers';

@Injectable()
export class DrawService {
    details: any = {};
    options: any = {
        base: {
            indexes: false,
            kickerLowThreshold: this.config.IccConfig.Drawing.kickerLowThreshold,
            ventilator: this.config.IccConfig.Drawing.ventilator,
            side: 'inner',
        },
        colors: {
            glassStartColor: this.config.IccConfig.Drawing.glassStartColor,
            glassStopColor: this.config.IccConfig.Drawing.glassStopColor,
            glassGradient: this.config.IccConfig.Drawing.glassGradient,
        },
        dimensions: {
            multiplier: 1.5,
            ratio: 1,
            sumHeight: this.config.IccConfig.Drawing.sumHeight,
            type:
                this.config.IccConfig.Drawing.dimensionType === 'allWithExpanders'
                ? 'outer'
                : 'inner',
        },
        indexes: false,
        onInterfaceClick: () => {},
    };

    data: any;
    node: any;

    constructor(
        private configurationsService: ConfigurationsService,
        private eventBusService: EventBusService,
        private userService: UserService,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {
        const user = this.userService.get();
        this.options.dimensions.ratio =
            user.dimension_unit === 'inch'
                ? this.config.IccConfig.Configurators.inchToMilimeter
                : 1;
        this.eventBusService.subscribe(
            [
                'updatedCoupledWindow',
                'changedSashes',
                'changedFrames',
                'changedFillings',
                'setExtensionProfile',
                'setFrameProfile',
                'setSashProfile',
                'setMullionProfile',
                'putAlignmentInField',
                'removedAlignmentInField',
                'removedAlignment',
                'putExtensionOnSide',
                'removedExtensionFromSide',
                'setLowThreshold',
                'unsetLowThreshold',
                'insertMuntins',
                'removeMuntins',
                'updateMuntins',
                'setMuntinColor',
                'icc-redraw',
            ],
            data => {
                this.init();
            }
        );
    }

    get(type: string, params?: any): any {
        if (type && this.data && this.data[type] && this.data[type].length) {
            return (
                this.data[type].find(el =>
                    params
                        ? Object.keys(params).reduce(
                              (prev, curr) => prev && el[curr] === params[curr],
                              true
                          )
                        : true
                ) || null
            );
        } else {
            return null;
        }
    }

    getData(details: any): any[] {
        this.details = details;

        this.resetNodeData();
        this.parseNodeData();

        return { ...this.data };
    }

    init() {
        if (this.configurationsService.conf && this.configurationsService.conf.Current) {
            this.details = this.configurationsService.createSimpleConfiguration(
                this.configurationsService.conf.Current
            );
            this.details.timestamp = Date.now();

            this.resetNodeData();
            this.parseNodeData();
            this.configurationsService.conf.Current.drawData = { ...this.data };
        }
    }

    resetNodeData() {
        const options = {
            base: { ...this.options.base, side: null },
            dimensions: false,
        };

        try {
            this.node = IccDrawSyncService.syncDetails(this.details, options);
        } catch (err) {
            logger.error(err);
        }

        this.data = {
            base: [],
            alignment: [],
            coupling: [],
            extension: [],
            filling: [],
            frame: [],
            glazingBead: [],
            mullion: [],
            muntin: [],
            reno: [],
            renson: [],
            sash: [],
            sashFrame: [],
            shape: [],
        };
    }

    parseNodeData(node: any = this.node) {
        if (node) {
            const functionName = node.constructor.name ? `parse${node.constructor.name}Data` : null;

            if (functionName && typeof this[functionName] === 'function') {
                this[functionName](node);
            }

            if (node.children && node.children.length > 0) {
                node.children.map(child => this.parseNodeData(child));
            }
        }
    }

    parseAlignmentNodeData(node: any) {
        this.data.alignment.push(
            new DrawData(node.poly, {
                alignmentId: node.alignment ? node.alignment.id : null,
                direction: node.alignment ? node.alignment.direction : null,
                sashId: node.sash ? node.sash.id : null,
            })
        );
    }

    parseExtensionsNodeData(node: any) {
        node.extensions.map(extension => {
            this.data.extension.push(
                new DrawData(extension.poly, {
                    direction:
                        ['top', 'bottom'].indexOf(extension.side) !== -1
                            ? 'horizontal'
                            : 'vertical',
                    extensionId: extension.extension ? extension.extension.id : null,
                    positionId: node.positionId,
                })
            );
        });
    }

    parseCoupledExtensionNodeData(node: any) {
        this.data.extension.push(
            new DrawData(
                node.poly,
                {
                    extensionId: node.extension ? node.extension.id : null,
                },
                true
            )
        );
    }

    parseFrameNodeData(node: any) {
        const parallelPoly = IccDrawMathService.getParallelPoly(
            node.parent.poly,
            node.parent.parent.poly
        );

        const parallel = new Array(
            Math.max(...Object.values(node.profilesPolygonsMap).map(Number)) + 1
        );

        Object.keys(node.profilesPolygonsMap).forEach(k => {
            if (
                parallel[node.profilesPolygonsMap[k]] == null
                || parallelPoly[k] < parallel[node.profilesPolygonsMap[k]]
            ) {
                parallel[node.profilesPolygonsMap[k]] = parallelPoly[k];
            }
        });

        this.data.frame.push({
            inner: new DrawData(node.poly),
            outer: new DrawData(node.parent.poly),
            sides: node.polygons.map(poly => new DrawData(poly, {}, true)),
            frameId: node.frame ? node.frame.id : null,
            positionId: node.positionId,
            parallel: {
                poly: parallel || [],
            },
        });
    }

    parseCouplingNodeData(node: any) {
        this.data.coupling.push(
            new DrawData(node.poly, {
                direction: node.coupling ? node.coupling.direction : null,
                couplingId: node.coupling ? node.coupling.id : null,
                positionId: node.positionId,
            })
        );
    }

    parseCoupledCouplingNodeData(node: any) {
        this.data.coupling.push(
            new DrawData(node.poly, {
                direction: node.coupling ? node.coupling.direction : null,
                couplingId: node.coupling ? node.coupling.id : null,
            })
        );
    }

    parseGlazingNodeData(node: any) {
        this.data.filling.push(
            new DrawData(node.poly, {
                sashId: node.sash ? node.sash.id : null,
            })
        );
    }

    parseGlazingBeadNodeData(node: any) {
        this.data.glazingBead.push({
            inner: new DrawData(node.poly),
            outer: new DrawData(node.parent.poly),
            sides: node.polygons.map(poly => new DrawData(poly, {}, true)),
            sashId: node.sash ? node.sash.id : null,
        });
    }

    parseMullionNodeData(node: any) {
        this.data.mullion.push(
            new DrawData(node.poly, {
                direction: node.mullion ? node.mullion.direction : null,
                mullionId: node.mullion ? node.mullion.id : null,
                sashId: node.sash ? node.sash.id : null,
                minWidthPrev: node.positionMin,
                minWidthNext: node.positionMax
            })
        );
    }

    parseMuntinNodeData(node: any) {
        this.data.muntin.push(
            new DrawData(node.poly, {
                muntinId: node.muntin ? node.muntin.id : null,
                sashId: node.sash ? node.sash.id : null,
            })
        );
    }

    parseRenoNodeData(node: any) {
        this.data.reno.push(new DrawData(node.poly));
    }

    parseRensonNodeData(node: any) {
        this.data.renson.push(
            new DrawData(node.poly, {
                rensonId: node.renson ? node.renson.id : null,
                sashId: node.sash ? node.sash.id : null,
            })
        );
    }

    parseSashFrameNodeData(node: any) {
        this.data.sashFrame.push({
            inner: new DrawData(node.poly),
            outer: new DrawData(node.parentPoly),
            sides: node.polygons.map(poly => new DrawData(poly, {}, true)),
            sashId: node.sash ? node.sash.id : null,
        });
    }

    parseSashNodeData(node: any) {
        const shapeData =
            this.data.shape.find(s => s.positionId === node.positionId) || this.data.shape[0];
        const poly = IccDrawMathService.intersectPolyWithRect(shapeData.poly, node.rect);
        const parallelPoly = IccDrawMathService.getParallelPoly(node.poly, node.parent.poly);
        const parallelProfiles = parallelPoly.map(i =>
            typeof i === 'number' && Number.isInteger(i)
                ? (node.parent.profiles[i] || node.parent.profiles[0]).profileId
                : null
        );

        this.data.sash.push({
            inner: new DrawData(node.poly),
            outer: new DrawData(poly),
            sashId: node.sash ? node.sash.id : null,
            parallel: {
                poly: parallelPoly || [],
                profiles: parallelProfiles || [],
            },
        });
    }

    parseShapeNodeData(node: any) {
        const frameShapeNodes = node.children.filter(n => n.constructor.name === 'FrameShapeNode');
        const parallelPolygons = frameShapeNodes.map(n =>
            IccDrawMathService.getParallelPoly(n.poly, node.poly)
        );
        const angles = node.poly.reduce((prev, point, index) => {
            let oneSideProfile;
            let otherSideProfile;

            frameShapeNodes.forEach((n, fsIndex) => {
                for (
                    let currInner = 0, nextInner = 1;
                    currInner < n.poly.length;
                    ++currInner, ++nextInner, nextInner %= n.poly.length
                ) {
                    const line1 = [n.poly[currInner], n.poly[nextInner]];
                    const parallelEdge = parallelPolygons[fsIndex][currInner];
                    if (
                        line1[1].x === point.x
                        && line1[1].y === point.y
                        && typeof parallelEdge === 'number'
                        && Number.isInteger(parallelEdge)
                    ) {
                        oneSideProfile = {
                            frameId: n.frame ? n.frame.id : 0,
                            frameEdgeIndex: currInner,
                        };
                    }
                    if (
                        line1[0].x === point.x
                        && line1[0].y === point.y
                        && typeof parallelEdge === 'number'
                        && Number.isInteger(parallelEdge)
                    ) {
                        otherSideProfile = {
                            frameId: n.frame ? n.frame.id : 0,
                            frameEdgeIndex: currInner,
                        };
                    }
                }
            });
            prev[index] = [oneSideProfile, otherSideProfile];
            return prev;
        }, {});
        this.data.shape.push(
            Object.assign(new DrawData(node.poly), {
                angles,
                positionId: node.positionId,
            })
        );
    }

    parseBaseNodeData(node: any) {
        this.data.base.push(
            new DrawData(node.poly, {
                positionId: node.positionId,
            })
        );
    }
}
