import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from 'config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import TokenService from '../authorization/token.service';

@Injectable()
export default class ResourceService {
    constructor(
        private httpClient: HttpClient,
        private tokenService: TokenService,
        @Inject(APP_CONFIG) private config
    ) {}

    async online() {
        return (
            await this.httpClient
                .get(
                    (this.config.EnvConfig.remoteHost || window.location.origin)
                        + '/api/online?token='
                        + this.tokenService.getToken()
                )
                .toPromise()
        ) as any;
    }

    async getUserData() {
        return (
            await this.httpClient
                .get(
                    (this.config.EnvConfig.remoteHost || window.location.origin)
                        + '/sync/sync/get_user_data.json?t='
                        + new Date().getTime()
                        + '&token='
                        + this.tokenService.getToken()
                        + '&iccDbVersion='
                        + this.config.EnvConfig.iccDbVersion
                        + '&iccAppVersion='
                        + this.config.IccConfig.Version
                        + (this.config.isPhonegap ? '&phonegap' : '')
                )
                .toPromise()
        ) as any;
    }

    async getPrices(data) {
        return await this.httpClient
            .post(
                `${this.config.EnvConfig.remoteHost
                    || window.location.origin}/sync/sync/get_prices.json`,
                this.tokenService.addToken(data),
                {
                    headers: new HttpHeaders({
                        token: this.tokenService.getToken(),
                    }),
                }
            )
            .toPromise() as any;
    }

    async getConfiguratorsData(data) {
        return await this.httpClient
            .post(
                `${this.config.EnvConfig.remoteHost
                    || window.location.origin}/sync/sync/get_configurators_data.json`,
                this.tokenService.addToken(data),
                {
                    headers: new HttpHeaders({
                        token: this.tokenService.getToken(),
                    }),
                }
            )
            .toPromise() as any;
    }

    async getAdditionals(data) {
        return await this.httpClient
            .post(
                `${this.config.EnvConfig.remoteHost
                    || window.location.origin}/sync/sync/get_additionals.json`,
                this.tokenService.addToken(data),
                {
                    headers: new HttpHeaders({
                        token: this.tokenService.getToken(),
                    }),
                }
            )
            .toPromise() as any;
    }

    async getDealerData() {
        return (
            await this.httpClient
                .get(
                    (this.config.EnvConfig.remoteHost || window.location.origin)
                        + '/dealer/dealers/dealer.json'
                )
                .toPromise()
        ) as any;
    }

    async getAssignData() {
        return (
            await this.httpClient
                .get(
                    (this.config.EnvConfig.remoteHost || window.location.origin)
                        + '/dealer/dealers/get_assign_data.json'
                )
                .toPromise()
        ) as any;
    }

    async getAllSellerData() {
        return (
            await this.httpClient
                .get(
                    (this.config.EnvConfig.remoteHost || window.location.origin)
                        + '/dealer/dealers/get_all_seller_data.json'
                )
                .toPromise()
        ) as any;
    }

    async getSynchronizeElements() {
        return (
            await this.httpClient
                .get(
                    (this.config.EnvConfig.remoteHost || window.location.origin)
                        + '/window/synchronize_elements/index.json'
                )
                .toPromise()
        ) as any;
    }

    async getLayouts(data) {
        return await this.httpClient
            .post(
                `${this.config.EnvConfig.remoteHost
                    || window.location.origin}/sync/sync/get_sashes_layouts.json`,
                this.tokenService.addToken(data),
                {
                    headers: new HttpHeaders({
                        token: this.tokenService.getToken(),
                    }),
                }
            )
            .toPromise() as any;
    }

    async getCustomPrices(data) {
        return await this.httpClient
            .post(
                `${this.config.EnvConfig.remoteHost
                    || window.location.origin}/sync/sync/get_custom_prices.json`,
                this.tokenService.addToken(data),
                {
                    headers: new HttpHeaders({
                        token: this.tokenService.getToken(),
                    }),
                }
            )
            .toPromise() as any;
    }

    async getDiscountsAndMultipliers(data) {
        return await this.httpClient
            .post(
                `${this.config.EnvConfig.remoteHost
                    || window.location.origin}/sync/sync/get_discounts_and_multipliers.json`,
                this.tokenService.addToken(data),
                {
                    headers: new HttpHeaders({
                        token: this.tokenService.getToken(),
                    }),
                }
            )
            .toPromise() as any;
    }

    async getMontages(data) {
        return await this.httpClient
            .post(
                `${this.config.EnvConfig.remoteHost
                    || window.location.origin}/sync/sync/get_montages.json`,
                this.tokenService.addToken(data),
                {
                    headers: new HttpHeaders({
                        token: this.tokenService.getToken(),
                    }),
                }
            )
            .toPromise() as any;
    }

    async createOffer(data) {
        return await this.httpClient
            .post(
                `${this.config.EnvConfig.remoteHost
                    || window.location.origin}/dealer/sellers/create_offer.json`,
                this.tokenService.addToken(data),
                {
                    headers: new HttpHeaders({
                        token: this.tokenService.getToken(),
                    }),
                }
            )
            .toPromise() as any;
    }

    async syncClientsData(data) {
        return await this.httpClient
            .post(
                `${this.config.EnvConfig.remoteHost
                    || window.location.origin}/api/sync/clients.json`,
                this.tokenService.addToken(data),
                {
                    headers: new HttpHeaders({
                        token: this.tokenService.getToken(),
                    }),
                }
            )
            .toPromise() as any;
    }

    async syncOffersData(data) {
        return await this.httpClient
            .post(
                `${this.config.EnvConfig.remoteHost
                    || window.location.origin}/api/sync/offers.json`,
                this.tokenService.addToken(data),
                {
                    headers: new HttpHeaders({
                        token: this.tokenService.getToken(),
                    }),
                }
            )
            .toPromise() as any;
    }

    async syncOfferAttachments(data) {
        return await this.httpClient
            .post(
                `${this.config.EnvConfig.remoteHost
                    || window.location.origin}/api/sync/offer_attachments.json`,
                this.tokenService.addToken(data),
                {
                    headers: new HttpHeaders({
                        token: this.tokenService.getToken(),
                    }),
                }
            )
            .toPromise() as any;
    }

    async getSettings(data) {
        return await this.httpClient
            .post(
                `${this.config.EnvConfig.remoteHost || window.location.origin}/api/sync/settings`,
                this.tokenService.addToken(data),
                {
                    headers: new HttpHeaders({
                        token: this.tokenService.getToken(),
                    }),
                }
            )
            .toPromise() as any;
    }
    async getTranslations(data) {
        return await this.httpClient
            .post(
                `${this.config.EnvConfig.remoteHost
                    || window.location.origin}/api/sync/translations`,
                this.tokenService.addToken(data),
                {
                    headers: new HttpHeaders({
                        token: this.tokenService.getToken(),
                    }),
                }
            )
            .toPromise() as any;
    }
    async getUsers(data) {
        return await this.httpClient
            .post(
                `${this.config.EnvConfig.remoteHost || window.location.origin}/api/sync/users`,
                this.tokenService.addToken(data),
                {
                    headers: new HttpHeaders({
                        token: this.tokenService.getToken(),
                    }),
                }
            )
            .toPromise() as any;
    }
    async getApplicationNumber() {
        return (
            await this.httpClient
                .get(
                    (this.config.EnvConfig.remoteHost || window.location.origin)
                        + '/api/sync/application_number?token='
                        + this.tokenService.getToken()
                )
                .toPromise()
        ) as any;
    }

    async license(data) {
        return (
            await this.httpClient
                .post(
                    `${this.config.EnvConfig.remoteHost
                        || window.location.origin}/api/license?token=`
                        + this.tokenService.getToken(),
                    this.tokenService.addToken(data)
                )
                .toPromise()
        ) as any;
    }
}
