import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ReinforcementsModalService } from './reinforcements-modal.service';

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [],
    exports: [],
    entryComponents: [],
    providers: [
        ReinforcementsModalService,
    ],
})
export class ReinforcementsModule {}
