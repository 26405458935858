export function timeLimitServiceFactory(i: any) {
  return i.get('TimeLimitService');
}
export const currentConfiguratorServiceProvider = {
  provide: 'TimeLimitService',
  useFactory: timeLimitServiceFactory,
  deps: ['$injector']
};

export function offersFactoryFactory(i: any) {
  return i.get('OffersFactory');
}
export const offersFactoryProvider = {
  provide: 'OffersFactory',
  useFactory: offersFactoryFactory,
  deps: ['$injector'],
};