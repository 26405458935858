import angular from 'angular';

/**
 * Fabryka napędow
 * @param {object} $rootScope               Angular Scope
 * @param {object} $uibModal                Dostarcza obsługę okien modalnych
 * @param {object} $filter                  FilterFilter
 * @param {object} Core                     Core
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} StepFactory              Fabryka kroków
 * @param {object} ConfiguratorsDataService Fabryka danych konfiguracji
 * @param {object} PriceService             Liczenie cen
 * @param {object} CurConfService           Bieżaca konfiguracja
 * @param {object} RollerDimensionsFactory  RollerDimensionsFactory
 * @return {Factory} Fabryka.
 */
export default function DrivesFactory($rootScope, $uibModal, $filter, IccConfig, Core, ConfigurationsService, StepFactory,
    ConfiguratorsDataService, PriceService, CurConfService, RollerDimensionsFactory, EventBusService, TimeLimitService, ParametersService) {
    'ngInject';
    let driveType = 'manual';

    var factory = {
        drives      : [],
        driveManuals: [],
        accessories : [],
        hangers     : [],

        loadRetractor,
        openModalDrives,
        openModalDriveElements,
        openModalDrivesManuals,
        changeDriveType,
        checkOverride,
        changeDriveManual,
        changeOverrideHanger,
        changeHanger,
        loadedData: false,
        setDrive,
        get driveType() {
            return driveType;
        },
        showDriveElectrical,
        getDriveElectricalMinWidth,
        loadDrives,
        loadDriveManuals,
        loadHangers,
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });
    $rootScope.$on('changedShutter', loadDrives);

    return factory;

    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        if (CurConfService.conf != 'window' && CurConfService.conf != 'roller_shutter' && CurConfService.conf != 'hs') {
            return;
        }
        loadDrives();
        loadDriveManuals();
        loadHangers();
        if (angular.isArray(ConfiguratorsDataService.data.rollerAccessories)) {
            factory.accessories = ConfiguratorsDataService.data.rollerAccessories;
        }
        if (angular.isUndefined(ConfigurationsService.conf.Current.RollerShutter.driveType)
            && ConfigurationsService.conf.Current.RollerShutter.shutters.length === 0) {
            loadDefaults();
        }
        if (ConfigurationsService.conf.Current.RollerShutter.drive.type != 'manual') {
            driveType = 'electrical';
        }
        if (!ConfigurationsService.conf.Current.RollerShutter.hanger && IccConfig.Configurators.roller_shutter.hangerLock) {
            ConfigurationsService.conf.Current.RollerShutter.hanger = factory.hangers.find(e => e && !e.blockade);
            changeOverrideHanger();
        }
        factory.loadedData = true;
    }

    /**
     * Ładuje wieszaki
     */
    function loadHangers() {
        if (angular.isArray(ConfiguratorsDataService.data.rollerShutterHangers)) {
            factory.hangers = ConfiguratorsDataService.data.rollerShutterHangers;
        }
    }

    /**
     * Ładuje sterowania
     */
    function loadDrives() {
        const roller = ConfigurationsService.conf.Current.RollerShutter;
        if (angular.isArray(ConfiguratorsDataService.data.rollerShutterDrives)) {
            factory.drives = ConfiguratorsDataService.data.rollerShutterDrives.filter(d =>
                d.type == 'manual'
                || !d.prices
                || !d.prices.length
                || d.prices.some(p => {
                    return (!p.from || roller.shutters.every(el => parseFloat(p.from) <= el.realWidth))
                        && (!p.height || roller.shutters.every(el => parseFloat(p.height) >= el.realHeight))
                        && (!p.to || roller.shutters.every(el =>
                            parseFloat(p.to) >= ParametersService.getRollerWeight(roller, el)))
                        && (!p.reel || p.reel == roller.roundReel.id);
                })
            );
            const manualDrives = factory.drives.filter((el) => el.type == 'manual');
            if ((
                    !roller.drive
                    || factory.drives.map(d => d.id).indexOf(roller.drive.id) == -1
                )
                && manualDrives.length > 0
            ) {
                roller.drive = manualDrives[0];
                PriceService.count();
                TimeLimitService.count();
            }
        }
    }

    /**
     * Ładuje rodzaje sterowania ręcznego
     */
    function loadDriveManuals(conf = ConfigurationsService.conf.Current) {
        if (angular.isArray(ConfiguratorsDataService.data.rollerShutterDrivesManuals)) {
            if (conf.RollerShutter.system) {
                factory.driveManuals = ConfiguratorsDataService.data.rollerShutterDrivesManuals.reverse().filter(d =>
                    angular.isArray(d.systems)
                        && d.systems.indexOf(conf.RollerShutter.system.id) > -1
                );
            }
            if (conf.RollerShutter.drive
                && conf.RollerShutter.drive.type == 'manual'
                && (!conf.RollerShutter.driveManual
                    || factory.driveManuals.map(d => Number(d.id)).indexOf(Number(conf.RollerShutter.driveManual.id)) == -1
                )
            ) {
                const defaultManual = factory.driveManuals.filter(el =>
                    el.id == conf.RollerShutter.system.default_drive_manual_id);
                if (defaultManual.length > 0) {
                    conf.RollerShutter.driveManual = Core.copy(defaultManual[0]);
                } else {
                    conf.RollerShutter.driveManual = Core.copy(factory.driveManuals[0]);
                }
                PriceService.count();
                TimeLimitService.count(conf);
            }
        }
    }

    /**
     * Funkcja ładujaca domyślne dane
     */
    function loadDefaults() {
        ConfigurationsService.conf.Current.RollerShutter.driveType = 'none';
        ConfigurationsService.conf.Current.RollerShutter.driveManual = null;
        ConfigurationsService.conf.Current.RollerShutter.driveElements = [];
        ConfigurationsService.conf.Current.RollerShutter.driveSide = 'R';
        ConfigurationsService.conf.Current.RollerShutter.drive = {type: 'manual'};
        driveType = 'manual';
        if (angular.isArray(factory.drives)) {
            var manualDrives = factory.drives.filter(el => el.type == 'manual');
            if (angular.isDefined(manualDrives) && angular.isDefined(manualDrives[0])) {
                ConfigurationsService.conf.Current.RollerShutter.drive = manualDrives[0];
            }
        }
        factory.loadRetractor();
    }

    /**
     * Funkcja ustawiajaca akcesoria
     * @param {object} selectedAccessory Wybór akcesoriów
     */
    function setAccessories(selectedAccessory) {
        if (angular.isUndefined(selectedAccessory)) {
            return;
        }
        ConfigurationsService.conf.Current.RollerShutter.driveElements = [];
        for (var i = 0; i < selectedAccessory.length; i++) {
            var result = factory.accessories.filter(e => e.id == selectedAccessory[i]);
            if (result.length == 1) {
                ConfigurationsService.conf.Current.RollerShutter.driveElements.push(result[0]);
            }
        }
        PriceService.count();
        TimeLimitService.count();
    }

    /**
     * Funkcja ładowania zwijacza
     */
    function loadRetractor(conf = ConfigurationsService.conf.Current) {
        if (
            conf.RollerShutter.drive.type == 'manual'
            && (!angular.isObject(conf.RollerShutter.driveManual)
                || factory.driveManuals
                    .map(d => Number(d.id))
                    .indexOf(Number(conf.RollerShutter.driveManual.id)) == -1)
        ) {
            if (
                angular.isDefined(conf.RollerShutter.system)
                && angular.isDefined(factory.driveManuals)
            ) {
                var defaultManual = factory.driveManuals.filter(
                    el => el.id == conf.RollerShutter.system.default_drive_manual_id
                );
                conf.RollerShutter.driveManual = Core.copy(defaultManual[0]);
            }
        }
        PriceService.count();
        TimeLimitService.count(conf);
    }

    /**
     * Ustawia wieszaki ręcznie
     */
    function changeHanger(hangerId) {
        const hanger = factory.hangers.find(o => o.id === hangerId);

        ConfigurationsService.conf.Current.RollerShutter.hanger = Core.copy(hanger);
    }

    /**
     * Ustawia wieszaki automatycznie
     */
    function changeOverrideHanger() {
        if (ConfigurationsService.conf.Current.RollerShutter.drive
            && ConfigurationsService.conf.Current.RollerShutter.hanger
            && IccConfig.Configurators.roller_shutter.hangerLock
            && IccConfig.Configurators.roller_shutter.hangerLockLinkedOverloadLock
        ) {
            if (!ConfigurationsService.conf.Current.RollerShutter.hanger.blockade
                && ConfigurationsService.conf.Current.RollerShutter.drive.type !== 'manual'
                && ConfigurationsService.conf.Current.RollerShutter.driveType === 'override'
            ) {
                ConfigurationsService.conf.Current.RollerShutter.hanger = factory.hangers.find(e => e && e.blockade);
            }

            if (ConfigurationsService.conf.Current.RollerShutter.hanger.blockade
                && (ConfigurationsService.conf.Current.RollerShutter.drive.type === 'manual'
                || ConfigurationsService.conf.Current.RollerShutter.driveType !== 'override')
            ) {
                ConfigurationsService.conf.Current.RollerShutter.hanger = factory.hangers.find(e => e && !e.blockade);
            }

            RollerDimensionsFactory.loadBoxHeights();
            RollerDimensionsFactory.setBoundaryDimensionsFromPrices();
            $rootScope.$emit('icc-redraw');
        }
    }

    /**
     * Ustawia sterowanie
     *
     * @param {any} drive Sterowanie
     */
    function setDrive(drive) {
        ConfigurationsService.conf.Current.RollerShutter.drive = Core.copy(drive);
        ConfigurationsService.conf.Current.RollerShutter.driveElements
            = (factory.accessories || []).filter(el => el.roller_shutter_drives_id.includes(drive.id) && el.default_drive);
        if (drive.type != 'manual') {
            ConfigurationsService.conf.Current.RollerShutter.driveManual = null;
            driveType = 'electrical';
            if (!checkOverride(ConfigurationsService.conf.Current.RollerShutter.driveType)) {
                ConfigurationsService.conf.Current.RollerShutter.driveType = checkOverride('none') ? 'none' : 'override';
            }
        }
        changeOverrideHanger();
        factory.loadRetractor();
    }

    /**
     * Ustawia sterowanie po zmianie rodzaju sterowania.
     *
     * @param {string} type Rodzaj sterowania
     */
    function changeDriveType(type) {
        if ((ConfigurationsService.conf.Current.RollerShutter.drive.type == 'manual') != (type == 'manual')) {
            if (angular.isArray(factory.drives)) {
                const drives = factory.drives.filter((el) => (el.type == 'manual') == (type == 'manual'));
                if (angular.isDefined(drives) && angular.isDefined(drives[0])) {
                    ConfigurationsService.conf.Current.RollerShutter.drive = drives[0];
                    changeOverrideHanger();
                    PriceService.count();
                    TimeLimitService.count();
                }
            }
        }
    }

    /**
     * Funkcja otwierania okna modal z napędami
     *
     * @param {bool} onlyElectrical Tylko elektryczne
     */
    function openModalDrives(onlyElectrical) {
        let filter = () => true;
        if (onlyElectrical) {
            filter = (el) => el.type != 'manual';
        }
        var modalInstance = $uibModal.open({
            templateUrl: 'modalDrives.html',
            controller : 'ModalDrivesController',
            resolve    : {
                drives() {
                    return (factory.drives || []).filter(filter);
                },
                selDrive() {
                    return ConfigurationsService.conf.Current.RollerShutter.drive;
                },
                b2c    : () => (!$rootScope.user || !$rootScope.user.access || $rootScope.user.access == 'klient')
            } ,
        });
        if (IccConfig.Configurators.tutorialAvailable) {
            EventBusService.post({
                key: 'tutorialSteps',
                value: 'drivers'
             });
        }

        modalInstance.result.then(selectedDrive => {
            if (angular.isDefined(selectedDrive)) {
                setDrive(selectedDrive);
            }
            RollerDimensionsFactory.setBoundaryDimensionsFromPrices();
            RollerDimensionsFactory.loadBoxHeights();
            PriceService.count();
            TimeLimitService.count();
        });

        modalInstance.closed.then(() => {
            if (IccConfig.Configurators.tutorialAvailable) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg'
                });
            }
        });
    }

    /**
     * Okno modalne z wyborem dodatku do skrzydła
     *
     * @param {any} drive Sterowanie
     */
    function openModalDriveElements(drive) {
        var modalInstance = $uibModal.open({
            templateUrl: 'modalRollerElements.html',
            controller : 'ModalDriveElementController as $ctrl',
            resolve    : {
                sashAccessories() {
                    return (factory.accessories || []).filter((el) => el.roller_shutter_drives_id.includes(drive.id));
                },
                selected() {
                    return ConfigurationsService.conf.Current.RollerShutter.driveElements.map((el) => el.id);
                },
                single: () => !IccConfig.Configurators.roller_shutter.manyElectricalDriveAccessory && drive.type == 'electrical',
                b2c   : () => (!$rootScope.user || !$rootScope.user.access || $rootScope.user.access == 'klient')
            },
        });

        modalInstance.result.then((selectedAccessory) => {
            setAccessories(selectedAccessory);
        });
    }

    /**
     * Funkcja otwierania okna modal z wyborem dodatkw do sterowania recznego
     */
    function openModalDrivesManuals() {
        var modalInstance = $uibModal.open({
            templateUrl: 'modalDrivesManual.html',
            controller : 'ModalDrivesManualController',
            resolve    : {
                drives() {
                    return (factory.driveManuals || []);
                },
                selDrive() {
                    return ConfigurationsService.conf.Current.RollerShutter.driveManual;
                },
                b2c    : () => (!$rootScope.user || !$rootScope.user.access || $rootScope.user.access == 'klient')
            }
        });
        if (IccConfig.Configurators.tutorialAvailable) {
            EventBusService.post({
                key: 'tutorialSteps',
                value: 'driversManuals'
            });
        }

        modalInstance.result.then((selectedDrive) => {
            if (angular.isDefined(selectedDrive)) {
                ConfigurationsService.conf.Current.RollerShutter.driveManual = Core.copy(selectedDrive);
            }
            PriceService.count();
            TimeLimitService.count();
        });

        modalInstance.closed.then(() => {
            if (IccConfig.Configurators.tutorialAvailable) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg'
                });
            }
        });
    }

    /**
     * Funkcja zmieniająca sterowanie ręczne na podstawie radio.
     *
     * @param {any} manualId id sterowania ręcznego
     */
    function changeDriveManual(manualId) {
        let manual = (factory.driveManuals || []).filter(m => m.id == manualId)[0];
        if (manualId == 'r') {
            manual = (factory.driveManuals || []).filter(m => m.type == 'white_retractor')[0];
        } else if (manualId == 'b') {
            manual = (factory.driveManuals || []).filter(m => m.type == 'brown_retractor')[0];
        }
        if (manual) {
            ConfigurationsService.conf.Current.RollerShutter.driveManual = Core.copy(manual);
            PriceService.count();
            TimeLimitService.count();
        }
    }

    /**
     * Czy dostępny wybór sterowania elektrycznego
     *
     * @returns {bool} Czy dostępny wybór sterowania elektrycznego
     */
    function showDriveElectrical() {
        return (factory.drives || []).filter(d => d.type != 'manual').length > 0;
    }

    /**
     * Zwraca minimalną szerokość dla sterowania elektrycznego
     *
     * @returns {number} Minimalna szerokość dla sterowania elektrycznego
     */
    function getDriveElectricalMinWidth() {
        return (ConfiguratorsDataService.data.rollerShutterDrives || []).filter(d => d.type != 'manual').reduce((prev, cur) => {
            const minFrom = angular.isArray(cur.prices)
                ? cur.prices.reduce((p, c) => !p || parseFloat(c.from) < p ? parseFloat(c.from) : p, Infinity)
                : 0;
            return !prev || minFrom < prev ? minFrom : prev;
        }, Infinity);
    }

    function checkOverride(override) {
        const roller = ConfigurationsService.conf.Current.RollerShutter;
        return roller.drive.prices.some(p =>
            (!p.from || roller.shutters.every(el => parseFloat(p.from) <= el.realWidth))
            && (!p.height || roller.shutters.every(el => parseFloat(p.height) >= el.realHeight))
            && (override === 'override' && ~~p.override || override === 'none' && !~~p.override)
            && (!p.to || roller.shutters.every(el =>
                parseFloat(p.to) >= ParametersService.getRollerWeight(roller, el)))
            && (!p.reel || p.reel == roller.roundReel.id)
        );
    }

}
