import angular from 'angular';
import {logger} from 'helpers';
import {IssueLevel} from 'issues.service';

/**
 * Fabryka szprosow
 * @param {object} $rootScope               Angular Root Scope
 * @param {object} $uibModal                   Dostarcza obsługę okien modalnych
 * @param {object} $filter                  Filtry
 * @param {object} $location                Lokalizacja
 * @param {object} DrawDataService          Fabryka danych rysowania
 * @param {object} ConfiguratorsDataService Fabryka danych konfiguracji
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} CurConfService           Bieżaca konfiguracja
 * @param {object} PriceService             Liczenie ceny
 * @param {object} Core                     Core
 * @param {object} PriceBaseColorsFactory   Fabryka ceny podstawowych kolorow
 */
export default function MuntinsFactory($rootScope, $uibModal, $filter, $location, DrawDataService, ConfiguratorsDataService,
                        ConfigurationsService, CurConfService, PriceService, Core, IccConfig, EventBusService, MuntinsService, ParametersService,
                        ValidationService, IssuesService) {
    'ngInject';

    var configurators   = ['window', 'hs', 'door', 'folding_door'];
    var minRWidth       = 100;
    var allMuntinsTypes = [];

    var factory = {
        loadedData     : false,
        muntinsTypes   : [],
        colorRals      : [],
        barColors      : [],
        activeMoveTools: false,
        barPosition    : {
            left      : 0,
            right     : 0,
            mmLeft    : 0,
            mmRight   : 0,
            showInputs: false
        },
        changeType,
        changeWidth,
        findMuntinsTypesBySystem,
        hasMuntins,
        isDuplexAvailable,
        removeIfNotAvailable,
        hasPVCPanelsOnAllSashes,
        checkMuntinsStatus
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration(['initializedConfigurator'], () => init());

    EventBusService.subscribe('setSystem', data => {
        checkMuntinsStatus(data.activeConfiguration);
    });

    EventBusService.subscribe('setGlazingInSash', () => {
        try {
            if (!isDuplexAvailable(
                ConfigurationsService.conf.Current.Sashes,
                ConfigurationsService.conf.Current.MuntinsData.type.duplex_availability
            )) {
                ConfigurationsService.conf.Current.MuntinsData.duplex = false;
            }
            removeIfNotAvailable(ConfigurationsService.conf.Current);
        } catch (err) {
            logger.error(err);
        }
    });

    EventBusService.subscribe(
        'loadedConfiguratorsData',
        data => {
            loadMuntinsTypes(data.value);
        }
    );

    return factory;

    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        if (configurators.indexOf(CurConfService.conf) === -1) {
            return;
        }

        findMuntinsTypesBySystem();
        changeType(ConfigurationsService.conf.Current);
        factory.loadedData = true;
    }

    function loadMuntinsTypes(data) {
        allMuntinsTypes = data.windowBarTypes || [];
    }

    /**
     * Funkcja sprawdzajaca czy aktualnie znalezione szprosy pasuja do systemu,
     * a nastepnie wyswietla stosowne komunikaty w przypadku zmiany statusu
     */
    function checkMuntinsStatus(conf = ConfigurationsService.conf.Current) {
        findMuntinsTypesBySystem(conf);
        ValidationService.valid(conf, 'muntins');
        IssuesService.unregister('invalid-muntins', conf);
        if (!factory.muntinsTypes.length) {
            ValidationService.invalid(conf, 'muntins');
            IssuesService.simpleRegister('invalid-muntins', 'Brak typów szprosów', $filter('translate')('Brak typów szprosów'), conf, {
                level: IssueLevel.ERROR,
                blockStepsAfter: null
            });
        } else if (!conf.MuntinsData.type || !factory.muntinsTypes.find(el => el.id == conf.MuntinsData.type.id)) {
            changeType(conf);
            if (conf.HasMuntins) {
                IssuesService.simpleRegister('invalid-muntins', 'Typ szprosów został zmieniony', $filter('translate')('Typ szprosów został zmieniony'), conf, {
                    level: IssueLevel.INFO,
                    logLevel: IssueLevel.NONE,
                    blockStepsAfter: null,
                    blockAddToOffer: false,
                    noPrice: false,
                });
            }
        }
    }

    /**
     * Funkcja szukania typów szprosów
     */
    function findMuntinsTypesBySystem(conf = ConfigurationsService.conf.Current) {
        if (angular.isUndefined(conf)) {
            return;
        }
        Core.clear(factory.muntinsTypes);
        for (var i = 0; i < allMuntinsTypes.length; i++) {
            var systemTypes = allMuntinsTypes[i].system_types;
            var windowLines = allMuntinsTypes[i].windowLines;

            if (angular.isArray(systemTypes) && angular.isArray(windowLines)) {
                if (angular.isObject(conf.System)) {
                    if (systemTypes.indexOf(conf.System.type) > -1) {
                        if (windowLines.length) {
                            if (!(windowLines.indexOf(conf.System.id) > -1)) {
                                continue;
                            }
                        }

                        factory.muntinsTypes.push(allMuntinsTypes[i]);
                    }
                } else {
                    if (systemTypes.indexOf('pvc') > -1) {
                        factory.muntinsTypes.push(allMuntinsTypes[i]);
                    }
                }
            }

        }
    }

    /**
     * Funkcja szprosów
     * @param  {object}  conf Konfiguracja
     */
    function hasMuntins(conf) {
        conf.HasMuntins = false;
        for (var i = 0; i < conf.Sashes.length; i++) {
            for (var j = conf.Sashes[i].intSashes.length - 1; j >= 0; j--) {
                var intSash = conf.Sashes[i].intSashes[j];
                if (intSash.muntins.length > 0) {
                    conf.HasMuntins = true;
                    break;
                }
            }
            if (conf.Sashes[i].intSashes.length === 0 && conf.Sashes[i].muntins.length > 0) {
                conf.HasMuntins = true;
                break;
            }
        }
        conf.MuntinsData.duplex = setDuplex(conf);
        return conf.HasMuntins;
    }

    /**
     * Funkcja zmiana typu
     * @param  {object} conf konfiguracja
     */
    function changeType(conf) {
        if (angular.isUndefined(conf) || conf.type == 'roller_shutter') {
            return;
        }
        if (angular.isDefined(conf.MuntinsData.typeCode) && !isNaN(parseInt(conf.MuntinsData.typeCode))) {
            conf.MuntinsData.type = factory.muntinsTypes.filter(function(el) {
                return el.id == conf.MuntinsData.typeCode;
            })[0];
        }

        if (!angular.isObject(conf.MuntinsData.type)
            || angular.isUndefined(conf.MuntinsData.type.id)
            || angular.isUndefined(conf.MuntinsData.typeCode)
            || isNaN(parseInt(conf.MuntinsData.typeCode))
            ) {
            if (angular.isArray(factory.muntinsTypes) && factory.muntinsTypes.length > 0) {
                conf.MuntinsData.type = factory.muntinsTypes[0];
                conf.MuntinsData.typeCode = parseInt(conf.MuntinsData.type.id);
            }
        }

        if (angular.isObject(conf.MuntinsData.type) && conf.MuntinsData.type.id) {
            var barWidths = Core.objToArray(conf.MuntinsData.type.sizes) || [];
            var avBarWidths = barWidths.filter(function(el) {
                return parseInt(conf.MuntinsData.rWidth) === parseInt(el.size);
            });
            if (avBarWidths.length === 0) {
                if (barWidths[0]) {
                    conf.MuntinsData.rWidth = ~~barWidths[0].size;
                    conf.MuntinsData.sizeId = ~~barWidths[0].id;
                } else {
                    conf.MuntinsData.rWidth = 0;
                    conf.MuntinsData.sizeId = 0;
                }
            } else {
                conf.MuntinsData.rWidth = ~~avBarWidths[0].size;
                conf.MuntinsData.sizeId = ~~avBarWidths[0].id;
            }

            conf.MuntinsData.duplex = setDuplex(conf);

            EventBusService.post({key: 'setMuntinType', value: {}, conf});

            factory.changeWidth(conf);
        }

        removeIfNotAvailable(conf);
        EventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
            conf
        });

        ParametersService.count(conf);
        PriceService.count();
    }

    /**
     * Funkcja zmiany szerokości
     * @param  {object} conf Konfiguracja
     */
    function changeWidth(conf) {
        if (angular.isDefined(conf.MuntinsData) && angular.isObject(conf.MuntinsData.type)) {
            var size = Core.fIdO(conf.MuntinsData.type.sizes, conf.MuntinsData.sizeId);

            if (angular.isObject(size)) {
                conf.MuntinsData.rWidth = ~~size.size;
                conf.MuntinsData.sizeId = ~~size.id;
            }
            conf.MuntinsData.width = parseInt(conf.MuntinsData.rWidth);

            PriceService.count();
        }
        EventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
            conf
        });
    }

    /**
     * Sprawdza czy dla wybranych pakietów szybowych jest dostępny duplex wynikający z ograniczeń
     * @param {object} [sashes]     Lista skrzydeł
     * @param {number} [frameWidth] Minimalna szerokość ramki szybowej
     * @returns {boolean}
     */
    function isDuplexAvailable(sashes = [], frameWidth = 0) {
        return sashes.map(s => s.intSashes < 1
                        ? s.muntins.length ? [~~s.glazing.frame_width] : []
                        : s.intSashes.map(i => i.muntins.length ? ~~i.glazing.frame_width : null))
                     .reduce((prev, e) => prev.concat(e), [])
                     .every(e => !e || e >= ~~frameWidth);
    }

    /**
     * Ustawia duplex dla szprosów o ile jest dostępny
     * @param {any} conf
     * @returns
     */
    function setDuplex(conf) {
        if (angular.isObject(conf.MuntinsData.type)) {
            return isDuplexAvailable(conf.Sashes, conf.MuntinsData.type.duplex_availability)
                    && (~~conf.MuntinsData.type.duplex === 1 || ~~conf.MuntinsData.type.duplex === 0 && conf.MuntinsData.duplex);
        }
        return false;
    }

    /**
     * Usuwa szprosy jeżeli nie są dostępne w danym układzie.
     * Kwatery zawierające panele wypełnieniowe nie mogą mieć szprosów wewnętrznych.
     * Szprosy naklejane są możliwe, ale tylko bez dupleksu.
     * W przypadku drzwi szprosy nie są dostępne.
     * @param  {object} conf Konfiguracja.
     * @return {void}
     */
    function removeIfNotAvailable(conf = ConfigurationsService.conf.Current) {
        if (!conf.Sashes || !conf.MuntinsData || !conf.MuntinsData.type) {
            return;
        }

        conf.Sashes
            .reduce((sashes, sash) => sashes.concat([sash], sash.intSashes), [])
            .map(sash => {
                sash.canHaveDuplex = !(conf.MuntinsData.type.type === 'sticked' && sash.glazing.type === 'pvc_panels');
                sash.muntins = MuntinsService.isValidSash(conf, sash.id) ? sash.muntins : [];
            });
    }

    /**
     * Sprawdza czy skrzydła mają panele wypełnieniowe.
     * @param  {Object}  conf Konfiguracja.
     * @return {Boolean}      Czy wszystkie skrzydła mają panele wypełnieniowe?
     */
    function hasPVCPanelsOnAllSashes(conf = ConfigurationsService.conf.Current) {
        if (!conf.Sashes) {
            return true;
        }

        return conf.Sashes
            .reduce((sashes, sash) => sashes.concat([sash], sash.intSashes), [])
            .every(sash => ['pvc_panels', 'deco_panels'].includes(sash.glazing.type));
    }

}
