import { NgModule } from '@angular/core';
import ColorMappingService from './ColorsMappingService';
import {colorsFactoryProvider} from './ajs-upgraded-providers';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        ColorMappingService,
        colorsFactoryProvider,
    ],
})
export class ColorsModule {}
