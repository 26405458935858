import { ActiveConfiguration } from './ActiveConfiguration';
import WindowConfiguration from './WindowConfiguration';
import { PriceSegment, DiscountGroups, PricePart } from '../price/Prices';
import { TimeLimitSegment } from '../../application/src/time_limit/time_limit_functions';
import {
    Sash,
    Muntin,
    Mullion,
    ProfileSet,
    Profile,
    ProfileShape,
    Measurement,
    Shape,
    Frame,
    Coupling,
    SideProfile,
    Alignment,
} from './parts/window';
import MosquitoActiveConfiguration from './MosquitoActiveConfiguration';
import { ActiveRollerShutter } from './parts/roller_shutter';
import { ActiveAccessory } from './parts/common';
import { ConfiguratorsDataService } from '../configurators/configurators-data.service';
import Common from '../Common';
import { core } from '../helpers';
import { Issue } from 'issues/Issue';
import {
    IccColors,
    IccWood,
    IccColor,
    IccSimpleColor,
    IccWarmEdge,
    IccAccessory,
} from 'data-types';
import { ActiveSash } from 'layout/active-sash';
import { ActiveMullion } from 'layout/active-mullion';

export default class WindowActiveConfiguration implements ActiveConfiguration {
    static is(configuration): configuration is WindowActiveConfiguration {
        return (
            configuration instanceof WindowActiveConfiguration
            || (!configuration.$version
                && ['window', 'door', 'hs', 'folding_door'].indexOf(configuration.type) > -1)
        );
    }

    //#region pola interfejsu
    type: 'window' | 'door' | 'hs' | 'folding_door' = 'window';
    Name: string = '';
    Price: number = 0;
    PriceNoMargin: number = 0;
    PriceAfterDiscounts: number = 0;
    Quantity: number = 1;
    Issues: Issue[] = [];
    PriceSegments: PriceSegment[] = [];
    PriceSegmentsNoMargin: PriceSegment[] = [];
    PriceParts: PricePart[] = [];
    PricePartsNoMargin: PricePart[] = [];
    DiscountGroups: DiscountGroups = {};
    DiscountGroupsNoMargin: DiscountGroups = {};
    timeLimit: number = 0;
    timeLimitsStack: TimeLimitSegment[] = [];
    Title = '';
    Description = '';
    Attachments = [];
    DeletedAttachments = [];
    valid: {
        system: null | boolean;
        shape: null | boolean;
        dimensions: null | boolean;
        sashes: null | boolean;
        sealColor: null | boolean;
        siliconeColor: null | boolean;
        colors: null | boolean;
        wood: null | boolean;
        profileSet: null | boolean;
        loadedMuntinsColors: null | boolean;
        loadedProfiles: null | boolean;
        mullionsProfiles: null | boolean;
        sashesProfiles: null | boolean;
        frameProfiles: null | boolean;
        loadedGlazingBeads: null | boolean;
        loadedFillings: null | boolean;
        glazingBeads: null | boolean;
        fillings: null | boolean;
    } = {
        system: null,
        shape: null,
        dimensions: null,
        sashes: null,
        sealColor: null,
        siliconeColor: null,
        colors: null,
        wood: null,
        profileSet: null,
        loadedMuntinsColors: null,
        loadedProfiles: null,
        mullionsProfiles: null,
        sashesProfiles: null,
        frameProfiles: null,
        loadedGlazingBeads: null,
        loadedFillings: null,
        glazingBeads: null,
        fillings: null,
    };
    PriceValid = false;

    Warranty = true;
    IsMistakeProduct = false;
    Measurements: Measurement[] = [];
    //#endregion

    //#region kształt, rozmiar, współczynniki
    Height = 1500;
    Width = 1500;
    Shape: Partial<Shape> = {};
    Weight = 0;
    //#endregion

    //#region kolory, drewno, wykończenie
    ColorType = 'White';
    ColorsSashExt = false;
    Colors: IccColors = {
        frame: {
            outer: {},
            inner: {},
            core: {},
            alushell: {},
        },
        sash: {
            outer: {},
            inner: {},
            core: {},
            alushell: {},
        },
    };
    Wood: Partial<IccWood> = {};
    AlushellType: 'painted' | 'brushed' = 'brushed';
    GlazingBeadColor: Partial<IccColor> = {};
    SealColor: Partial<IccSimpleColor> = {};
    SiliconeColor: Partial<IccSimpleColor> = {};
    //#endregion

    //#region wypełnienia
    GlazingBeadType: boolean | number = false;
    WarmEdge: Partial<IccWarmEdge> = {};
    HasWarmEdge = false;
    CustomFillings = [];
    GlazingWarranty: boolean = true;
    //#endregion

    //#region akcesoria
    Accessories: IccAccessory[] = [];
    SideAccessories: {
        sizes: {
            top: number;
            bottom: number;
            left: number;
            right: number;
        };
        top: IccAccessory[];
        bottom: IccAccessory[];
        left: IccAccessory[];
        right: IccAccessory[];
    } = {
        sizes: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
        top: [],
        bottom: [],
        left: [],
        right: [],
    };
    //#endregion

    //#region okucia
    HasHandle = false;
    Handle = {};
    HandleOuter = {};
    HandleColor = {};
    HandleOuterColor = {};
    HandleHeight = 0;
    HandleType = 'InnerLever';
    Hinge: any = null;
    HingeColor: any = {};
    Lock: any = {};
    Fitting: any = {};
    //#endregion

    //#region profile, wzmocnienia, system
    System: any = {};
    SystemType: any = {};
    Steel: any = null;
    HasAlushell = false;
    ProfileSet: ProfileSet = {
        id: null,
        name: '',
        frameTop: null,
        frameBottom: null,
        frameSide: null,
        sash: null,
        fixedMullion: null,
        falseMullion: null,
        threshold: null,
        sashOutward: null,
        centralHandleFalseMullion: null,
        doubleHandleFalseMullion: null,
        zMullion: null,
        glazingBeadShape: null,
        systems: [],
    };
    UsedProfiles: Profile[] = [];
    UsedProfileShapes: ProfileShape[] = [];
    UsedProfilesSegments: {
        [profileId: number]: number[];
    } = {};
    weldFinishType: string = 'V';
    drainagePosition: string = 'front';
    //#endregion

    //#region konstrukcja
    Frames: Frame[] = [];
    Sashes: ActiveSash[] = [];
    Mullions: ActiveMullion[] = [];
    Alignments: Alignment[] = [];
    couplings: Coupling[] = [];
    SideProfiles: SideProfile[] = [];
    hasRoller = false;
    RollerShutter: ActiveRollerShutter = new ActiveRollerShutter();
    mosquito: MosquitoActiveConfiguration = null;

    Sill: any = {};
    HasSill = false;

    SashesType: 'Fix' | 'FixSash' | 'Func' | 'Mix' = 'Fix';
    OwnedSashesTypes = {
        window: true,
        doorActive: false,
        doorPassive: false,
    };
    EdgeSashes: {
        top?: number[];
        bottom?: number[];
        left?: number[];
        right?: number[];
    } = {
        top: [],
        left: [],
        right: [],
        bottom: [],
    };

    Balcony = false;
    Kicker = false;
    Layout: any = {};
    MuntinsData: {
        rWidth: number;
        width: number;
        color: any;
        colorOut: any;
        colorDraw: string;
        type: any;
        typeCode: any;
        duplex: boolean;
        sizeId: number;
    } = {
        rWidth: 0,
        width: 0,
        color: {},
        colorOut: {},
        colorDraw: '#FFFFFF',
        type: {},
        typeCode: 'S',
        duplex: false,
        sizeId: 0,
    };
    HasMuntins = false;
    //#endregion

    //#region one
    OneGlazing = false;
    OneFilling = {
        window: false,
        doorActive: false,
        doorPassive: false,
    };
    OneGlazingBead = false;
    OneGlazingBeadSash: {
        fix: number | boolean;
        sashes: number | boolean;
    } = {
        fix: false,
        sashes: false
    };
    OneReinforcement = true;
    OneBondedGlazing = false;
    OneBondedGlazingPerSash = {
        window: false,
        doorActive: false,
        doorPassive: false,
    };
    OneHandle = false;
    OneHandlesHeight = false;
    //#endregion

    ChangedDimensions = false;
    CustomPrice = 0;

    DrawId: string = '';
    drawData: any = null;

    PriceElems: any = {};

    systemComparison?: boolean;

    constructor(configuration?, private configuratorsDataService?: ConfiguratorsDataService) {
        if (configuration) {
            if (WindowActiveConfiguration.is(configuration)) {
                for (const propName in configuration) {
                    if (this.hasOwnProperty(propName)) {
                        this[propName] = configuration[propName];
                    }
                }
            } else {
                if (WindowConfiguration.is(configuration) && configuratorsDataService) {
                    this.Name = configuration.name;
                    this.Price = configuration.price;
                    this.PriceNoMargin = configuration.priceNoMargin;
                    this.PriceAfterDiscounts = configuration.priceAfterDiscounts;
                    this.Quantity = configuration.quantity;
                    this.PriceSegments = configuration.priceSegments;
                    this.PriceSegmentsNoMargin = configuration.priceSegmentsNoMargin;
                    this.PriceParts = configuration.priceParts;
                    this.PricePartsNoMargin = configuration.pricePartsNoMargin;
                    this.DiscountGroups = configuration.discountGroups;
                    this.DiscountGroupsNoMargin = configuration.discountGroupsNoMargin;
                    this.Title = configuration.title;
                    this.Description = configuration.description;
                    this.timeLimit = configuration.timeLimit;
                    this.timeLimitsStack = configuration.timeLimitsStack;
                    this.valid = configuration.valid;
                    this.valid.loadedMuntinsColors = null;
                    this.valid.loadedFillings = null;
                    this.valid.loadedGlazingBeads = null;
                    this.valid.loadedProfiles = null;

                    this.System = configuratorsDataService.get(
                        'windowLinesAll',
                        configuration.system.id
                    );
                    this.SystemType = configuratorsDataService.get('windowLineTypes', {
                        key: 'static',
                        val: configuration.system.type,
                    });
                    this.ColorType = configuration.colorType;
                    this.ColorsSashExt = configuration.colorsSashExt;

                    ['frame', 'sash'].forEach(part => {
                        this.Colors[part] = {};
                        ['outer', 'inner', 'core', 'alushell'].forEach(side => {
                            this.Colors[part][side] = {};
                            if (
                                configuration.colors[part][side]
                                && configuration.colors[part][side].id
                            ) {
                                if (configuration.colors[part][side].RAL) {
                                    this.Colors[part][side] = configuratorsDataService.get(
                                        'windowColorRals',
                                        configuration.colors[part][side].id
                                    );
                                } else {
                                    this.Colors[part][side] = configuratorsDataService.get(
                                        'windowColorsAll',
                                        configuration.colors[part][side].id
                                    );
                                }
                                this.Colors[part][side].isDefault =
                                    configuration.colors[part][side].isDefault;
                            }
                        });
                    });

                    this.Steel = configuration.steel;
                    if (configuration.wood) {
                        this.Wood = configuratorsDataService.get(
                            'woodTypes',
                            configuration.wood.id
                        );
                    }
                    this.AlushellType = configuration.alushellType || 'brushed';
                    this.HasAlushell = configuration.hasAlushell;
                    this.Height = configuration.height;
                    this.Width = configuration.width;
                    this.Shape = configuration.shape;
                    this.CustomFillings = configuration.customFillings;
                    this.Sashes = configuration.sashes.map(this.mapSash.bind(this));
                    this.SashesType = configuration.sashesType;
                    this.OwnedSashesTypes = configuration.ownedSashesTypes;
                    this.EdgeSashes = {
                        top: this.Sashes.filter(
                            sash => sash.nearMullions.top === -1 && sash.nearAlignments.top === -1
                        ).map(sash => sash.id),
                        bottom: this.Sashes.filter(
                            sash =>
                                sash.nearMullions.bottom === -1 && sash.nearAlignments.bottom === -1
                        ).map(sash => sash.id),
                        left: this.Sashes.filter(
                            sash => sash.nearMullions.left === -1 && sash.nearAlignments.left === -1
                        ).map(sash => sash.id),
                        right: this.Sashes.filter(
                            sash =>
                                sash.nearMullions.right === -1 && sash.nearAlignments.right === -1
                        ).map(sash => sash.id),
                    };

                    this.SideProfiles = configuration.sideProfiles;
                    this.Alignments = configuration.alignments || [];
                    this.couplings = configuration.couplings || [];
                    this.Mullions = configuration.mullions.map(
                        this.mapMullion(this.Sashes, null).bind(this)
                    );
                    this.Balcony = configuration.balcony;
                    this.UsedProfiles = configuration.usedProfiles;
                    this.UsedProfileShapes = configuration.usedProfileShapes;
                    this.UsedProfilesSegments = configuration.usedProfilesSegments;
                    this.ProfileSet = configuration.profileSet;

                    if (Common.isObject(configuration.layout) && configuration.layout.id) {
                        this.Layout = {
                            id: configuration.layout.id,
                            name: configuration.layout.name,
                            changed:
                                configuration.layout.divs && configuration.layout.sashes
                                    ? configuration.layout.changed
                                    : true,
                            sashes: configuration.layout.sashes,
                            divs: configuration.layout.divs,
                        };
                    } else {
                        this.Layout = {
                            id: configuration.layout,
                            changed: true,
                        };
                    }

                    this.Sill = {};
                    this.HasSill = false;

                    this.OneGlazing = configuration.oneGlazing;
                    this.OneFilling = configuration.oneFilling;
                    this.OneGlazingBead = configuration.oneGlazingBead;
                    this.OneGlazingBeadSash = configuration.oneGlazingBeadSash;
                    this.GlazingBeadColor =
                        configuratorsDataService.get<IccColor>(
                            'glazingBeadsColors',
                            configuration.glazingBeadColor ? configuration.glazingBeadColor.id : -1
                        ) || configuratorsDataService.get<IccColor>(
                            'windowColorsAll',
                            configuration.glazingBeadColor ? configuration.glazingBeadColor.id : -1
                        ) || null;
                    if (this.GlazingBeadColor) {
                        this.GlazingBeadColor.isDefault = configuration.glazingBeadColor
                            ? configuration.glazingBeadColor.isDefault
                            : false;
                    }

                    if (configuration.sealColor) {
                        this.SealColor =
                            configuratorsDataService.get(
                                'windowSealColors',
                                configuration.sealColor.id
                            ) || {};
                        this.SealColor.isDefault = configuration.sealColor.isDefault;
                    }
                    if (configuration.siliconeColor) {
                        this.SiliconeColor =
                            configuratorsDataService.get(
                                'windowSiliconeColors',
                                configuration.siliconeColor.id
                            ) || {};
                        this.SiliconeColor.isDefault = configuration.siliconeColor.isDefault;
                    }

                    this.OneHandle = configuration.oneHandle;
                    this.OneHandlesHeight = configuration.oneHandlesHeight;
                    this.HasHandle = configuration.hasHandle;
                    this.HandleType = configuration.handleType;
                    if (configuration.hinge) {
                        this.Hinge = configuratorsDataService.get('hinges', configuration.hinge.id);
                        this.HingeColor = configuratorsDataService.get(
                            'windowHandlesColors',
                            configuration.hinge.color.id
                        );
                    }
                    if (configuration.lock) {
                        this.Lock = configuratorsDataService.get('locks', configuration.lock.id);
                    }
                    if (configuration.glazingSpacer && configuration.glazingSpacer.id) {
                        this.WarmEdge = configuratorsDataService.get(
                            'windowWarmEdges',
                            configuration.glazingSpacer.id
                        );
                        this.HasWarmEdge = true;
                    }

                    if (configuration.muntins) {
                        this.MuntinsData.rWidth = configuration.muntins.size;
                        this.MuntinsData.colorDraw = configuration.muntins.colorDraw;
                        this.MuntinsData.color = configuratorsDataService.get(
                            configuration.muntins.colorInner.RAL
                                ? 'windowColorRals'
                                : 'windowColorsAll',
                            configuration.muntins.colorInner.id
                        );
                        this.MuntinsData.colorOut = configuratorsDataService.get(
                            configuration.muntins.colorOuter.RAL
                                ? 'windowColorRals'
                                : 'windowColorsAll',
                            configuration.muntins.colorOuter.id
                        );
                        if (!this.MuntinsData.color) {
                            this.MuntinsData.color = configuratorsDataService.get(
                                'windowBarColors',
                                configuration.muntins.colorInner.id
                            );
                        }
                        if (!this.MuntinsData.colorOut) {
                            this.MuntinsData.colorOut = configuratorsDataService.get(
                                'windowBarColors',
                                configuration.muntins.colorOuter.id
                            );
                        }
                        if (!this.MuntinsData.color.color || !this.MuntinsData.color.color.length) {
                            this.MuntinsData.color.color = configuration.muntins.colorInner.color;
                        }
                        if (
                            !this.MuntinsData.color.color_img
                            || !this.MuntinsData.color.color_img.length
                        ) {
                            this.MuntinsData.color.color_img =
                                configuration.muntins.colorInner.color_img;
                        }
                        if (
                            !this.MuntinsData.colorOut.color
                            || !this.MuntinsData.colorOut.color.length
                        ) {
                            this.MuntinsData.colorOut.color =
                                configuration.muntins.colorOuter.color;
                        }
                        if (
                            !this.MuntinsData.colorOut.color_img
                            || !this.MuntinsData.colorOut.color_img.length
                        ) {
                            this.MuntinsData.colorOut.color_img =
                                configuration.muntins.colorOuter.color_img;
                        }
                        this.MuntinsData.type = configuratorsDataService.get(
                            'windowBarTypes',
                            configuration.muntins.type.id
                        );
                        this.MuntinsData.typeCode = configuration.muntins.type.id;
                        this.MuntinsData.duplex = configuration.muntins.duplex;
                        this.MuntinsData.sizeId = configuration.muntins.sizeId;
                    }
                    this.HasMuntins = Boolean(configuration.muntins);

                    this.Fitting = configuratorsDataService.get(
                        'windowFittings',
                        configuration.fitting.id
                    );

                    this.Frames = configuration.frames;

                    this.Accessories = configuration.accessories
                        .filter(accessory =>
                            configuratorsDataService.get('windowAccessories', accessory.id)
                        )
                        .map(
                            accessory =>
                                new ActiveAccessory(accessory, null, this.configuratorsDataService)
                        );
                    this.SideAccessories.top = configuration.sideAccessories.top
                        .filter(accessory =>
                            configuratorsDataService.get('windowAccessories', accessory.id)
                        )
                        .map(
                            accessory =>
                                new ActiveAccessory(accessory, 'top', this.configuratorsDataService)
                        );
                    this.SideAccessories.bottom = configuration.sideAccessories.bottom
                        .filter(accessory =>
                            configuratorsDataService.get('windowAccessories', accessory.id)
                        )
                        .map(
                            accessory =>
                                new ActiveAccessory(
                                    accessory,
                                    'bottom',
                                    this.configuratorsDataService
                                )
                        );
                    this.SideAccessories.left = configuration.sideAccessories.left
                        .filter(accessory =>
                            configuratorsDataService.get('windowAccessories', accessory.id)
                        )
                        .map(
                            accessory =>
                                new ActiveAccessory(
                                    accessory,
                                    'left',
                                    this.configuratorsDataService
                                )
                        );
                    this.SideAccessories.right = configuration.sideAccessories.right
                        .filter(accessory =>
                            configuratorsDataService.get('windowAccessories', accessory.id)
                        )
                        .map(
                            accessory =>
                                new ActiveAccessory(
                                    accessory,
                                    'right',
                                    this.configuratorsDataService
                                )
                        );
                    this.SideAccessories.sizes.top = this.SideAccessories.top.reduce(
                        (prev, accessory) =>
                            (prev += accessory.count * Number(accessory.accessory.height)),
                        0
                    );
                    this.SideAccessories.sizes.bottom = this.SideAccessories.bottom.reduce(
                        (prev, accessory) =>
                            (prev += accessory.count * Number(accessory.accessory.height)),
                        0
                    );
                    this.SideAccessories.sizes.left = this.SideAccessories.left.reduce(
                        (prev, accessory) =>
                            (prev += accessory.count * Number(accessory.accessory.height)),
                        0
                    );
                    this.SideAccessories.sizes.right = this.SideAccessories.right.reduce(
                        (prev, accessory) =>
                            (prev += accessory.count * Number(accessory.accessory.height)),
                        0
                    );

                    ['top', 'bottom', 'left', 'right'].forEach(side => {
                        this.SideAccessories[side].reduce((prev, cur) => {
                            if (prev) {
                                cur.pos = prev.pos + prev.count * prev.accessory.height;
                            }
                            return cur;
                        }, null);
                    });

                    this.Warranty = configuration.hasWarranty;
                    this.IsMistakeProduct = configuration.isMistakeProduct;
                    this.hasRoller = Boolean(configuration.rollerShutter);
                    this.Weight = configuration.parameters.weight;
                    this.weldFinishType = configuration.weldFinishType;
                    this.drainagePosition = configuration.drainagePosition;
                    this.GlazingWarranty = configuration.hasGlazingWarranty;
                    this.Measurements = configuration.measurements;
                    this.Measurements.forEach(measurement => (measurement.attachments = []));
                    this.OneBondedGlazing = configuration.oneBondedGlazing;

                    if (configuration.rollerShutter) {
                        this.RollerShutter = new ActiveRollerShutter(
                            configuration.rollerShutter,
                            configuratorsDataService
                        );
                    }

                    this.mosquito = configuration.mosquito
                        ? new MosquitoActiveConfiguration(
                              configuration.mosquito,
                              configuratorsDataService
                          )
                        : null;
                }
            }
        }
        this.configuratorsDataService = null;
    }

    private mapSash(sash: Sash): ActiveSash {
        const newSash = new ActiveSash(sash.id, {
            index: sash.index,
            parentId: Common.isDefined(sash.parentId) ? sash.parentId : null,
            frameId: Common.isDefined(sash.frameId) ? sash.frameId : null,
            rx: sash.x,
            ry: sash.y,
            rWidth: sash.width,
            rHeight: sash.height,
            frame: sash.frame
                ? sash.frame
                : {
                      top: {},
                      bottom: {},
                      left: {},
                      right: {},
                  },
            type: sash.type
                ? Common.extend({}, this.configuratorsDataService.get('sashTypes', sash.type.id))
                : {},
            divs: {
                top: sash.nearMullions.top,
                bottom: sash.nearMullions.bottom,
                left: sash.nearMullions.left,
                right: sash.nearMullions.right,
            },
            nearAlignments: {
                left: sash.nearAlignments.left,
                right: sash.nearAlignments.right,
                top: sash.nearAlignments.top,
                bottom: sash.nearAlignments.bottom,
            },
            bondedGlazing: Boolean(sash.bondedGlazing),
        });

        newSash.drawing = [];
        newSash.muntins = sash.muntins;
        newSash.intSashes = sash.intSashes ? sash.intSashes.map(this.mapSash.bind(this)) : [];
        newSash.intAlignments = sash.intAlignments ? sash.intAlignments : [];
        newSash.intMullions = sash.intMullions
            ? sash.intMullions.map(this.mapMullion(newSash.intSashes, sash.id).bind(this))
            : [];
        newSash.intEdgeSashes = {
            top: sash.intAdjacentSashes ? sash.intAdjacentSashes.top : [],
            bottom: sash.intAdjacentSashes ? sash.intAdjacentSashes.bottom : [],
            left: sash.intAdjacentSashes ? sash.intAdjacentSashes.left : [],
            right: sash.intAdjacentSashes ? sash.intAdjacentSashes.right : [],
        };
        newSash.nearMullions = {
            top: sash.nearMullions.top,
            bottom: sash.nearMullions.bottom,
            left: sash.nearMullions.left,
            right: sash.nearMullions.right,
        };
        newSash.nearAlignments = {
            left: sash.nearAlignments.left,
            right: sash.nearAlignments.right,
            top: sash.nearAlignments.top,
            bottom: sash.nearAlignments.bottom,
        };
        newSash.glazing = sash.filling.custom
            ? core.fIdO(this.CustomFillings, sash.filling.id)
            : core.copy(
                  this.configuratorsDataService.data.fillings.find(e => e.id === sash.filling.id)
              )
              || core.copy(
                  this.configuratorsDataService.data.fillings.find(
                      e => e.id.split('.')[0] === sash.filling.id
                  )
              )
              || {};
        newSash.glazingBead = sash.glazingBead;
        newSash.panelGlazing = sash.panelGlazing
            ? this.configuratorsDataService.get('fillings', sash.panelGlazing.id) || {}
            : {};
        newSash.bondedGlazing = Boolean(sash.bondedGlazing);
        newSash.panelType = sash.panelType || 'Inset';
        newSash.oneGlazing = sash.oneGlazing;
        newSash.oneFilling = {
            window: false,
            doorActive: false,
            doorPassive: false,
        };
        newSash.glazingSizes = sash.fillingSizes
            ? {
                  width: sash.fillingSizes.width,
                  height: sash.fillingSizes.height,
                  area: sash.fillingSizes.area,
                  x: sash.fillingSizes.x,
                  y: sash.fillingSizes.y,
              }
            : null;
        newSash.mosquito = sash.mosquito
            ? new MosquitoActiveConfiguration(sash.mosquito, this.configuratorsDataService)
            : null;
        newSash.frame = sash.frame
            ? sash.frame
            : {
                  top: {},
                  bottom: {},
                  left: {},
                  right: {},
              };
        newSash.handle = sash.handleInner
            ? this.configuratorsDataService.get('windowHandlers', sash.handleInner.id)
            : {};
        newSash.handleOuter = sash.handleOuter
            ? this.configuratorsDataService.get('windowHandlers', sash.handleOuter.id)
            : {};
        newSash.handleColor = sash.handleInner
            ? this.configuratorsDataService.get('windowHandlesColors', sash.handleInner.color.id)
            : {};
        newSash.handleOuterColor = sash.handleOuter
            ? this.configuratorsDataService.get('windowHandlesColors', sash.handleOuter.color.id)
            : {};
        newSash.rHandleY = sash.handleHeight;
        newSash.defaultHandleHeight = sash.defaultHandleHeight;
        newSash.handleHeightType = sash.handleHeightType;
        newSash.hardware = sash.hardware
            .filter(accessory =>
                this.configuratorsDataService.get('windowAccessories', accessory.id)
            )
            .map(
                accessory => new ActiveAccessory(accessory, sash.id, this.configuratorsDataService)
            );
        newSash.shape = {
            shape: sash.shape
        };
        newSash.oneGlazingBead = sash.oneGlazingBead;
        newSash.canHaveDuplex = true;
        newSash.weight = sash.weight;
        if (newSash.type && sash.type) {
            newSash.type.handle_position = sash.type.handlePosition;
        }
        if (Common.isObject(newSash.frame)) {
            newSash.frame = sash.frame;
        }
        newSash.glazing.selectedColor = !sash.filling.color
            ? null
            : {
                  frame: {
                      inner: sash.filling.color.inner,
                      outer: sash.filling.color.outer,
                      core: sash.filling.color.core,
                  },
                  sash: {
                      inner: sash.filling.color.inner,
                      outer: sash.filling.color.outer,
                      core: sash.filling.color.core,
                  },
              };
        newSash.glazing.selectedColorSecond = !sash.filling.overlay_color
            ? null
            : {
                  frame: {
                      inner: sash.filling.overlay_color.inner,
                      outer: sash.filling.overlay_color.outer,
                      core: sash.filling.overlay_color.core,
                  },
                  sash: {
                      inner: sash.filling.overlay_color.inner,
                      outer: sash.filling.overlay_color.outer,
                      core: sash.filling.overlay_color.core,
                  },
              };
        return newSash;
    }

    private mapMullion(sashes: ActiveSash[], sashId: number = null) {
        return (mullion: Mullion): ActiveMullion => ({
            id: mullion.id,
            isDefault: mullion.isDefault,
            frameId: Common.isDefined(mullion.frameId) ? mullion.frameId : null,
            profileId: mullion.profileId,
            rx:
                mullion.direction === 'vertical'
                    ? mullion.position
                    : mullion.adjacentSashes.top.reduce((prev, sId) => {
                          const adjacentSash = sashes.filter(s => s.id === sId);
                          return adjacentSash.length > 0 && prev > adjacentSash[0].rx
                              ? adjacentSash[0].rx
                              : prev;
                      }, Number.MAX_VALUE),
            ry:
                mullion.direction === 'horizontal'
                    ? mullion.position
                    : mullion.adjacentSashes.left.reduce((prev, sId) => {
                          const adjacentSash = sashes.filter(s => s.id === sId);
                          return adjacentSash.length > 0 && prev > adjacentSash[0].ry
                              ? adjacentSash[0].ry
                              : prev;
                      }, Number.MAX_VALUE),
            rWidth: mullion.direction === 'horizontal' ? mullion.length : 1,
            rHeight: mullion.direction === 'vertical' ? mullion.length : 1,
            direction: mullion.direction,
            type: mullion.type,
            parentSash: Common.isDefined(sashId) ? sashId : null,
            active: 1,
            multiAlignLeft: Common.isArray(mullion.adjacentSashes.left)
                ? mullion.adjacentSashes.left.map(adjSash => sashes.find(s => s.id === adjSash))
                : [],
            multiAlignRight: Common.isArray(mullion.adjacentSashes.right)
                ? mullion.adjacentSashes.right.map(adjSash => sashes.find(s => s.id === adjSash))
                : [],
            multiAlignTop: Common.isArray(mullion.adjacentSashes.top)
                ? mullion.adjacentSashes.top.map(adjSash => sashes.find(s => s.id === adjSash))
                : [],
            multiAlignBottom: Common.isArray(mullion.adjacentSashes.bottom)
                ? mullion.adjacentSashes.bottom.map(adjSash => sashes.find(s => s.id === adjSash))
                : [],
            multiAlignLeftDiv: Common.isArray(mullion.perpendicularMullions.left)
                ? mullion.perpendicularMullions.left.map(adjDiv => ({ id: adjDiv }))
                : [],
            multiAlignRightDiv: Common.isArray(mullion.perpendicularMullions.right)
                ? mullion.perpendicularMullions.right.map(adjDiv => ({ id: adjDiv }))
                : [],
            multiAlignTopDiv: Common.isArray(mullion.perpendicularMullions.top)
                ? mullion.perpendicularMullions.top.map(adjDiv => ({ id: adjDiv }))
                : [],
            multiAlignBottomDiv: Common.isArray(mullion.perpendicularMullions.bottom)
                ? mullion.perpendicularMullions.bottom.map(adjDiv => ({ id: adjDiv }))
                : [],
            perpendicularAlignments: {
                top:
                    mullion.perpendicularAlignments && mullion.perpendicularAlignments.top
                        ? mullion.perpendicularAlignments.top
                        : [],
                bottom:
                    mullion.perpendicularAlignments && mullion.perpendicularAlignments.bottom
                        ? mullion.perpendicularAlignments.bottom
                        : [],
                left:
                    mullion.perpendicularAlignments && mullion.perpendicularAlignments.left
                        ? mullion.perpendicularAlignments.left
                        : [],
                right:
                    mullion.perpendicularAlignments && mullion.perpendicularAlignments.right
                        ? mullion.perpendicularAlignments.right
                        : [],
            },
            parallelAlignments: {
                top:
                    mullion.parallelAlignments && mullion.parallelAlignments.top
                        ? mullion.parallelAlignments.top
                        : [],
                bottom:
                    mullion.parallelAlignments && mullion.parallelAlignments.bottom
                        ? mullion.parallelAlignments.bottom
                        : [],
                left:
                    mullion.parallelAlignments && mullion.parallelAlignments.left
                        ? mullion.parallelAlignments.left
                        : [],
                right:
                    mullion.parallelAlignments && mullion.parallelAlignments.right
                        ? mullion.parallelAlignments.right
                        : [],
            },
            reinforcement: mullion.reinforcement,
            figure: {},
            cutAngle: 0,
            valid: true,
        });
    }
}
