export default class OptionsModalController {
    options;
    close: ({ $value }) => any;
    dismiss: () => any;

    constructor(
        private ScrollbarService,
        private $translate,
        public IccConfig,
    ) {
        this.options = {
            system: this.$translate.instant('OFFER|Zmień system'),
            color: this.$translate.instant('OFFER|Zmień kolor')
        }
    }

    $onChanges() {
        this.ScrollbarService.update();
    }

    closeModal() {
        this.close({ $value: false });
    }

    selectOption(option) {
        this.close({ $value: option });
    }

}
