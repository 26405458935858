import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import { ReinforcementsModalComponent } from './reinforcements-modal/reinforcements-modal.component';
import { ReinforcementsModalService } from './reinforcements-modal.service';

export default angular
    .module('icc.configurator.steps.window.reinforcements', [])
    .factory('ReinforcementsModalService', downgradeInjectable(ReinforcementsModalService))
    .component('reinforcementsModal', ReinforcementsModalComponent).name;
