import $ from 'jquery';
import Common from 'Common';
import {core, logger} from 'helpers';
const colorsModalTemplate = require('ngtemplate-loader!./colors/modal.html');

/**
 * Fabryka dóbr komplementarnych
 * @param {Object} $rootScope               rootScope
 * @param {Object} $uibModal                   modal
 * @param {Object} $filter                  Filtr Angularowy
 * @param {Object} Core                     Core
 * @param {Object} ConfigurationsService    ConfigurationsService
 * @param {Object} CurConfService           CurConfService
 * @param {Object} ConfiguratorsDataService ConfiguratorsDataService
 * @param {Object} accessoriesFilter        Filtr akceroriów
 * @param {Object} PriceService             PriceService
 * @param {Object} ParametersService        ParametersService
 * @param {Object} StepFactory              StepFactory
 */
export default function ComplementaryGoodsFactory($rootScope, $uibModal, $filter, IccConfig, Core, ConfigurationsService, CurConfService, // jshint ignore:line
    ConfiguratorsDataService, accessoriesFilter, PriceService, ParametersService, StepFactory, ColorsFactory, ProfilesService,
    EventBusService, ProfilesPriceService, ConfiguratorsAvailabilityService, ScrollbarService
) {
    'ngInject';

    var currentGood = { type: 'accessory' };

    var glassData = [];
    var cassonettosData = [];
    var sillsData = [];
    var accessoriesData = [];
    var colorsAll = [];
    var profilesData = {};

    var factory = {
        loadedData: false,
        loaded: {
            glass: false,
            windowsill: false,
            cassonetto: false,
            profile: false,
            accessory: false,
        },
        currentGood,
        add,
        remove,
        openModalComplementaryGoodsImage,
        setGoodType,
        glassData,
        colorsAll,
        cassonettosData,
        sillsData,
        accessoriesData,
        isEnabledType,
        openModalColors,
        setCassonettoColors,
        profilesData,
        calcProfilePrice
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });

    return factory;

    /**
     * Funkcja inicjujaca
     */
    function init() {
        if (CurConfService.conf !== 'complementary_goods') {
            return;
        }
        ConfigurationsService.conf.Current.Name = IccConfig.Configurators.complementary_goods.namedAccessory
            ? $filter('translate')('ACCESSORY|Akcesoria') : $filter('translate')('GOODS|Dobra komplementarne');

        var type = sessionStorage.getItem('adminMistake');
        if (type) {
            setGoodType(type);
            for (const i in ConfigurationsService.conf.Current.types) {
                if (i != type) {
                    delete ConfigurationsService.conf.Current.types[i];
                }
            }
        }
        factory.loadedData = true;
        PriceService.count();
    }

    function loadProfiles() {
        profilesData.profilesTypes = {
            coupling: $filter('translate')('WINDOW|Łącznik'),
            extension: $filter('translate')('WINDOW|Poszerzenie'),
            other: $filter('translate')('WINDOW|Inny profil'),
        };
        profilesData.profiles = Core.copy(ConfiguratorsDataService.data.profiles)
            .filter(el => Object.keys(profilesData.profilesTypes).indexOf(el.type) > -1)
            .map(el => {
                el.currentLength = 1000;
                setDefaultProfileColors(el);
                return el;
            });
    }

    function loadAccessories() {
        factory.colorsAll = ConfiguratorsDataService.data.windowHandlesColors;
        accessoriesData.colors = ConfiguratorsDataService.data.windowHandlesColors;
        accessoriesData.accessories = Core.copy(ConfiguratorsDataService.data.windowAccessories).filter((el) => {
            return el.access_conf;
        }).map(el => {
            el.colorOptions = 'white';
            if (el.price_source == 'confColors') {
                setDefaultColors(el);
            }
            if (el.price_type == 2) {
                el.currentLength = 1000;
            }
            if (el.price_type == 1) {
                el.currentWidth = 1000;
                el.currentHeight = 1000;
            }
            if (el.colors_ids && el.colors_ids.length > 0 && el.price_source == 'colors') {
                el.selectedColor = el.colors_ids[0];
            }
            return el;
        });
        accessoriesData.categories = ConfiguratorsDataService.data.windowAccessoriesCategories.filter((el) => {
            return accessoriesFilter(accessoriesData.accessories, el.id).length > 0;
        });
        accessoriesData.subcategories = ConfiguratorsDataService.data.windowAccessoriesSubCategories.filter((el) => {
            return accessoriesFilter(accessoriesData.accessories, el.id).length > 0;
        });
        var indexedColors = {};
        for (let i = 0; i < accessoriesData.colors.length; i++) {
            indexedColors[accessoriesData.colors[i].id] = accessoriesData.colors[i];
        }
        accessoriesData.indexedColors = indexedColors;
    }

    function loadSills() {
        sillsData.sills = ConfiguratorsDataService.data.windowSillsGoods;
    }

    function loadCassonettos() {
        cassonettosData.windowColorsAll = ConfiguratorsDataService.data.windowColorsAll;
        cassonettosData.productPricelists = ConfiguratorsDataService.data.productPricelists;
        cassonettosData.cassonettos = ConfiguratorsDataService.data.cassonettos;
        cassonettosData.cassonettos = cassonettosData.cassonettos.map(cassonetto => {
            cassonetto.colors = setCassonettoColors(cassonetto);
            return cassonetto;
        });
        cassonettosData.cassonettoGroups = ConfiguratorsDataService.data.cassonettoGroups;
    }

    function loadGlazings() {
        glassData.fillingsCategories = ConfiguratorsDataService.data.fillingsCategory;
        glassData.glassTypes = ConfiguratorsDataService.data.fillings.filter(el => el.type == 'glazing');
        glassData.warmEdges = ConfiguratorsDataService.data.windowWarmEdges;
        glassData.glassTypesSubcategories = ConfiguratorsDataService.data.glassTypesSubcategory;
        glassData.fillings = core.copy(ConfiguratorsDataService.data.fillings);
        glassData.glazingRestricts = ConfiguratorsDataService.data.glass4mmRestricts;
        glassData.beadColors = ConfiguratorsDataService.data.glazingBeadsColors || [];
        glassData.glassColors = ConfiguratorsDataService.data.glassColors;
    }

    /**
     * Ustawia domyślne kolory dodatku
     *
     * @param {any} accessory Dodatek
     */
    function setDefaultColors(accessory) {
        const config = {
            Colors: accessory.selectedColor || {
                frame: {
                    core: {},
                    outer: {},
                    inner: {},
                    alushell: {}
                },
                sash: {
                    core: {},
                    outer: {},
                    inner: {},
                    alushell: {}
                }
            },
            HasAlushell: false,
            AlushellType: '',
            System: { type: accessory.material },
            ColorType: 'White',
            Wood: accessory.selectedWood || {},
            ColorsSashExt: false
        };

        const configDefault = Core.copy(config);
        ColorsFactory.loadData();
        ColorsFactory.loadColorsBySystem((color) =>
            accessory.conf_colors_ids.map(Number).indexOf(~~color.id) > -1
            || color.groups && accessory.conf_color_groups_ids.map(Number).some(c => color.groups.map(Number).indexOf(~~c) > -1),
            config
        );

        ColorsFactory.setDefaultColorTypeForSystem(config, configDefault);
        ColorsFactory.setDefaultWood(config, configDefault);
        ColorsFactory.loadColorsByWood(config);
        ColorsFactory.setDefaultColors(false, config, configDefault);
        ColorsFactory.setDefaultColorTypeForColors(config, configDefault);
        accessory.selectedColor = Core.copy(config.Colors);
        accessory.selectedWood = Core.copy(config.Wood);
    }

    /**
     * Ustawia domyślne kolory profilu
     *
     * @param {any} accessory Dodatek
     */
    function setDefaultProfileColors(profile) {
        const systems = ConfiguratorsDataService.data.windowLinesAll;
        const profileMaterials = new Set(profile.systems.map(systemId => {
            const system = systems.find(s => s.id == systemId);
            if (system) {
                return system.type;
            }
            return 'pvc';
        }));
        const config = {
            Colors: profile.selectedColor || {
                frame: {
                    core: {},
                    outer: {},
                    inner: {},
                    alushell: {}
                },
                sash: {
                    core: {},
                    outer: {},
                    inner: {},
                    alushell: {}
                }
            },
            HasAlushell: false,
            AlushellType: '',
            System: { type: Array.from(profileMaterials)[0] || 'pvc' },
            ColorType: 'White',
            Wood: profile.selectedWood || {},
            ColorsSashExt: false
        };

        const configDefault = Core.copy(config);
        ColorsFactory.loadData();
        ColorsFactory.loadColorsBySystem((color) =>
            profile.systems.some(systemId => color.systems && color.systems.map(Number).indexOf(Number(systemId)) > -1)
        );

        ColorsFactory.setDefaultColorTypeForSystem(config, configDefault);
        ColorsFactory.setDefaultWood(config, configDefault);
        ColorsFactory.loadColorsByWood(config);
        ColorsFactory.setDefaultColors(false, config, configDefault);
        ColorsFactory.setDefaultColorTypeForColors(config, configDefault);
        profile.selectedColor = Core.copy(config.Colors);
        profile.selectedWood = Core.copy(config.Wood);

        profile.priceUnit = ProfilesPriceService.getProfilePrice(profile.id, profile.type, {id: profile.systems[0]}, config.Colors, ConfiguratorsDataService.data.profilesPrices);
        calcProfilePrice(profile);
    }

    /**
     * Ustawia dostępne kolory dla kasonetki
     *
     * Funkcja analogiczna do funkcji z ComplementaryGoodsActiveConfiguration
     * @param {Object} cassonetto Kasonetka
     * @return {Array} Lista kolorów
     */
    function setCassonettoColors(cassonetto) {
        var colorGroupIds = cassonetto.pricelists && cassonetto.pricelists.map(a => a.window_color_group_id);
        return colorGroupIds && cassonettosData.windowColorsAll.filter(e =>
            colorGroupIds.find(search =>
                e.groups && (typeof (e.groups.find(windowColorGroup => windowColorGroup == search)) != 'undefined')
            )
        ) || [];
    }

    /**
     * Ustawia typ dobra
     * @param {Object} type Typ
     */
    function setGoodType(type) {
        currentGood.type = type;
        StepFactory.selectStep(type);

        if (!factory.loaded[type]) {
            switch (type) {
                case 'glass':
                    loadGlazings();
                    break;
                case 'windowsill':
                    loadSills();
                    break;
                case 'cassonetto':
                    loadCassonettos();
                    break;
                case 'profile':
                    loadProfiles();
                    break;
                case 'accessory':
                    loadAccessories();
                    break;
            }
            factory.loaded[type] = true;
        }

    }

    /**
     * Typ jest właczony
     * @param  {Object}  type Typ
     * @return {Boolean}      Czy typ jest właczony
     */
    function isEnabledType(type) {
        return StepFactory.hasStep(type) && ConfiguratorsAvailabilityService.availableConfigs.complementaryGoodsConfigs[type];
    }

    /**
     * Dodaj
     * @param {Object} place     Miejsce
     * @param {Object} accessory Akcesorium
     * @param {Object} color     Kolor
     * @param {Object} $event    Zdarzenie
     * @param {Object} type      Typ
     */
    function add(place, accessory, color, $event, type) {

        if ($($event.currentTarget).hasClass('unactive')) {
            return;
        }

        var inputCount = $($event.currentTarget).parent().parent().find('.count');
        var inputAmount = $($event.currentTarget).parent().parent().find('.amount');
        var inputAmountWidth = $($event.currentTarget).parent().parent().find('.amount_width');
        var inputAmountHeight = $($event.currentTarget).parent().parent().find('.amount_height');
        var colorOptions = $($event.currentTarget).parent().parent().find('.color_options').val();
        logger.log(inputCount, inputAmount);

        if (type == 'accessory') {
            accessory.sel_width = inputAmountWidth.val();
            accessory.sel_height = inputAmountHeight.val();
        }
        if (type == 'glass') {
            inputCount = $($event.currentTarget).parent().parent().find('.count');
            accessory.sel_width = inputAmountWidth.val();
            accessory.sel_height = inputAmountHeight.val();
            const warmEdge = glassData.warmEdges.find(w => Number(w.id) === Number(accessory.warmEdge));
            accessory.warmEdgeName = warmEdge && warmEdge.name;
        }
        if (type == 'cassonetto') {
            accessory.sel_width = accessory.currentWidth;
            accessory.sel_height = accessory.currentHeight;
        }

        if (type == 'windowsill') {
            inputCount = $($event.currentTarget).parent().parent().find('.count');
            accessory.sel_width = accessory.currentWidth;
        }

        if (type == 'profile') {
            inputCount = $($event.currentTarget).parent().parent().find('.count');
        }

        var count = 0;
        if (parseInt(inputCount.val())) {
            count = parseInt(inputCount.val());
        }

        var amount = 0;
        var weight = 0;

        if (accessory.price_type == 1) {
            if (parseFloat(inputAmountWidth.val()) && parseFloat(inputAmountHeight.val())) {
                amount = parseFloat(inputAmountWidth.val()) * parseFloat(inputAmountHeight.val()) / 1000000;
                weight = (parseInt(inputCount.val())
                    * parseFloat(inputAmountWidth.val())
                    * parseFloat(inputAmountHeight.val()) / 1000000
                    * parseFloat(accessory.weight)) || 0;
            }
        } else {
            if (parseFloat(inputAmountWidth.val()) > 0) {
                amount = parseFloat(inputAmountWidth.val());
                weight = (parseFloat(inputAmountWidth.val()) / 1000 * parseFloat(accessory.weight)) || 0;
            } else {
                weight = parseFloat(accessory.weight) || 0;
            }
        }

        var ComplementaryGoodsArr = ConfigurationsService.conf.Current.ComplementaryGoods[type];

        var found = false;
        for (var i = 0; i < ComplementaryGoodsArr.length; i++) {
            if (ComplementaryGoodsArr[i].accessory.id == accessory.id
                && ComplementaryGoodsArr[i].accessory.sel_width == accessory.sel_width
                && ComplementaryGoodsArr[i].accessory.sel_height == accessory.sel_height
                && ComplementaryGoodsArr[i].accessory.warmEdge == accessory.warmEdge
                && ComplementaryGoodsArr[i].accessory.currentColor == accessory.currentColor
                && ComplementaryGoodsArr[i].accessory.plugs == accessory.plugs
                && ComplementaryGoodsArr[i].accessory.currentLength == accessory.currentLength
                && ComplementaryGoodsArr[i].accessory.currentP1 == accessory.currentP1
                && ComplementaryGoodsArr[i].accessory.currentP2 == accessory.currentP2
                && ComplementaryGoodsArr[i].accessory.currentL1 == accessory.currentL1
                && (typeof accessory.selectedColor !== 'object'
                            || typeof ComplementaryGoodsArr[i].accessory.selectedColor !== 'object'
                            || accessory.selectedColor.frame
                            && accessory.selectedColor.frame.core
                            && accessory.selectedColor.frame.inner
                            && accessory.selectedColor.frame.outer
                            && ComplementaryGoodsArr[i].accessory.selectedColor.frame
                            && ComplementaryGoodsArr[i].accessory.selectedColor.frame.core
                            && ComplementaryGoodsArr[i].accessory.selectedColor.frame.inner
                            && ComplementaryGoodsArr[i].accessory.selectedColor.frame.outer
                            && ComplementaryGoodsArr[i].accessory.selectedColor.frame.core.id == accessory.selectedColor.frame.core.id
                            && ComplementaryGoodsArr[i].accessory.selectedColor.frame.inner.id == accessory.selectedColor.frame.inner.id
                            && ComplementaryGoodsArr[i].accessory.selectedColor.frame.outer.id == accessory.selectedColor.frame.outer.id)
                && ComplementaryGoodsArr[i].colorOptions == colorOptions
            ) {
                ComplementaryGoodsArr[i].count += count;
                found = true;
            }
        }

        if (!found) {
            let colorName = '';
            const colorOptionsName = {
                white: $filter('translate')('COLOR|Biały'),
                'color-white': $filter('translate')('COLOR|Kolor') + '/' + $filter('translate')('COLOR|Biały'),
                'color-color': $filter('translate')('COLOR|Kolor') + '/' + $filter('translate')('COLOR|Kolor'),
                ral: $filter('translate')('COLOR|Biały')
            };
            count = count || 1;

            if (Common.isDefined(accessoriesData.indexedColors[accessory.currentColor])) {
                colorName = accessoriesData.indexedColors[accessory.currentColor].name;
            } else if (Common.isDefined(accessoriesData.indexedColors[accessory.selectedColor])) {
                colorName = accessoriesData.indexedColors[accessory.selectedColor].name;
            } else if (Common.isDefined(colorOptionsName[colorOptions])) {
                colorName = colorOptionsName[colorOptions];
            } else if (accessory.colors){
                color = accessory.colors.find(cassonettoColor => cassonettoColor.id === accessory.selectedColor);
                colorName = color.name;
            }
            accessory = Core.copy(accessory);
            accessory.color = Core.copy(color);
            ComplementaryGoodsArr.push({
                count,
                amount,
                accessory: Core.copy(accessory),
                weight,
                colorOptions,
                color: Common.isObject(color) ? color.id : null,
                colorName
            });
        }

        inputCount.val('1');
        inputAmount.val('');

        PriceService.count();

        $rootScope.$broadcast('changedStep', {
            i: StepFactory.getStepByCode(ConfigurationsService.conf.stepCode),
            code: ConfigurationsService.conf.stepCode
        }, {
            i: StepFactory.getStepByCode(ConfigurationsService.conf.stepCode),
            code: ConfigurationsService.conf.stepCode
        });
        // ParametersService.count(ConfigurationsService.conf.Current);
    }

    /**
     * Usuń
     * @param  {String} index Index
     * @param  {String} key   Klucz
     */
    function remove(index, key) {
        ConfigurationsService.conf.Current.ComplementaryGoods[key].splice(index, 1);

        PriceService.count();
        ScrollbarService.update();
        // ParametersService.count(ConfigurationsService.conf.Current);
    }

    /**
     * Otwieranie obrazka modala dóbr komplementarych
     * @param  {Object} good Dobro
     */
    function openModalComplementaryGoodsImage(good) {
        var modalInstance = $uibModal.open({
            templateUrl: 'modalComplementaryGoodsImage.html',
            controller: 'ModalComplementaryGoodsImageCtrl as good',
            resolve: {
                good() {
                    return good;
                }
            }
        });

        modalInstance.result.then(() => { });
    }

    /**
     * Otwieranie obrazka modala dóbr komplementarych
     * @param  {Object} accessory Accessory
     */
    function openModalColors(accessory, type = 'accessory') {
        let material = accessory.material;
        if (type === 'profile') {
            const systems = ConfiguratorsDataService.data.windowLinesAll;
            const profileMaterials = new Set(accessory.systems.map(systemId => {
                const system = systems.find(s => s.id == systemId);
                if (system) {
                    return system.type;
                }
                return 'pvc';
            }));
            material = Array.from(profileMaterials)[0] || 'pvc';
        }

        $uibModal.open({
            templateUrl: colorsModalTemplate,
            controller : 'ModalColorsCtrl as $ctrl',
            resolve    : {
                accessory: () => accessory,
                type     : () => type,
                material : () => material
            }
        }).result.then((selection) => {
            accessory.selectedColor = Core.copy(selection.colors);
            accessory.selectedWood = Core.copy(selection.wood);
            if (type === 'profile') {
                accessory.priceUnit = ProfilesPriceService.getProfilePrice(accessory.id, accessory.type, {id: accessory.systems[0]}, accessory.selectedColor, ConfiguratorsDataService.data.profilesPrices);
                calcProfilePrice(accessory);
            }
        });
    }

    function calcProfilePrice(profile) {
        let price = NaN;
        if (profile.priceUnit) {
            price = profile.priceUnit.price_piece + profile.priceUnit.price_length * profile.currentLength / 1000;
        }
        profile.price = price;
        return price;
    }
}
