import { core } from 'helpers';
export default class SystemsComparisonDetailsModalController {
    resolve: {
        config;
        currentConf;
        currency;
        onOffer;
        drawOptions;
        allowEdit;
    };
    close: ({ $value }) => any;
    dismiss: () => any;

    constructor(
        private $rootScope,
        private ScrollbarService,
        private SystemsComparisonService,
        private InfoFactory,
        private $translate,
        public IccConfig,
    ) {}

    $onChanges() {
        this.ScrollbarService.update();
    }

    isPrice(price) {
        return !isNaN(price) && price !== null;
    }

    closeModal() {
        this.close({ $value: { config: false } });
    }

    nextPosition() {
        this.close({ $value: { nextPosition: true } });
    }

    changeSystem() {
        this.InfoFactory.confirmModal(
            this.$translate.instant('OFFER|Potwierdzenie zmiany systemu'),
            this.$translate.instant('OFFER|Czy na pewno chcesz zmienić system?'),
            [
                {
                    name: this.$translate.instant('INTERFACE|Tak'),
                    callback: () => {
                        this.close({ $value: { config: this.resolve.config } })
                    }
                },
                {
                    name:this.$translate.instant('INTERFACE|Nie'),
                    callback: () => {},
                    accent: true,
                },
            ]
        );
    }

    async changeColor() {
        await this.SystemsComparisonService.changeColor(this.resolve.config);
        this.resolve.config.defaultConf.Colors = core.copy(this.resolve.config.conf.Colors);
        this.SystemsComparisonService.getDetails(this.resolve.config);
        this.SystemsComparisonService.colorsFactory.loadColorsBySystem(
            color =>
                color
                && color.systems
                && color.systems.indexOf(this.SystemsComparisonService.originalConfig.conf.System.id) > -1,
            this.SystemsComparisonService.originalConfig.conf
        );
    }

    changeFilling(sashId = null, intSashId = null) {
        this.SystemsComparisonService.changeFilling(
            this.resolve.config,
            sashId,
            intSashId
        );
    }

    edit(config) {
        this.close({ $value: { config, edit: true } });
    }
}
