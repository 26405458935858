const OptionsModalTemplate = require<string>('ngtemplate-loader!./options-modal.component.html');
import OptionsModalController from './options-modal.controller';

export const OptionsModalComponent: ng.IComponentOptions = {
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  templateUrl: OptionsModalTemplate,
  controller: OptionsModalController
};
