import * as angular from 'angular';
import {Injectable, Inject} from '@angular/core';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import {ConfiguratorsDataService} from 'configurators/configurators-data.service';
import {IssuesService, IssueLevel} from 'issues.service';
import ProfilesService from 'profiles.service';
import {EventBusService} from 'event-bus.service';
import {APP_CONFIG, AppConfig} from 'config';
import Common from 'Common';
import ColorsDefaultsService from 'colors/colors-defaults.service';
import ColorMappingService from '../configurator/colors/ColorsMappingService';
import {core} from 'helpers';

@Injectable()
export default class DependenciesService {

    constructor(
        private eventBusService: EventBusService,
        @Inject(APP_CONFIG) private config: AppConfig,
        private configuratorsDataService: ConfiguratorsDataService,
        private issuesService: IssuesService,
        private profilesService: ProfilesService,
        private colorsDefaultsService: ColorsDefaultsService,
        private colorMappingService: ColorMappingService,
        @Inject('AccessoriesFactory') private accessoriesFactory,
    ) {
        if (this.config.IccConfig.Configurators.dependencies) {
            this.eventBusService.subscribe(['processDependencies'], data => {
                if (Common.isDefined((<WindowActiveConfiguration>data.activeConfiguration).Layout) && Common.isDefined((<WindowActiveConfiguration>data.activeConfiguration).Layout.id)) {
                    this.process(<WindowActiveConfiguration>data.activeConfiguration);
                }
            });
        }
    }

    /**
     * Funkcja sprawdzająca zależności
     * @param {object} conf Konfiguracja
     * @memberof DependenciesService
     */
    process(conf) {
        if (angular.isObject(conf)) {
            this.issuesService.unregister('dependency', conf);
            this.issuesService.unregister('dependencyBlockade', conf);
            conf.Dependencies = [];
            const dependencies = this.configuratorsDataService.data.dependencies || [];
            const sashesDependenciesAccessories = core.copy(conf.Sashes.map(sash => sash.hardware.filter(el => el.accessory.fromDependency && el.accessory.changedColor)));
            conf.Sashes.map(sash => sash.hardware = sash.hardware.filter(el => !el.accessory.fromDependency));

            dependencies.forEach(dependency => {
                if (dependency.conditions.length && dependency.actions.length) {
                    if (
                        dependency.conditions.some(
                            el => el.blockType === 'sash'
                        )
                        || dependency.actions.some(
                            el => el.blockType === 'sash' || el.valueType === 'sash'
                        )
                    ) {
                        conf.Sashes.forEach((sash, index) => {
                            if (
                                angular.isObject(sash) &&
                                eval(this.check(dependency.conditions, dependency.id, conf, sash))
                            ) {
                                eval(this.check(dependency.actions, dependency.id, conf, sash, sashesDependenciesAccessories[index]));
                            }
                        }, this);
                    } else {
                        if (eval(this.check(dependency.conditions, dependency.id, conf))) {
                            eval(this.check(dependency.actions, dependency.id, conf));
                        }
                    }
                }
            }, this);
        }
    }


    /**
     * Funkcja sprawdzająca warunki i tworząca wyrażenie
     * @param {array} conditions     warunki
     * @param {number} dependencyId  Id zależności
     * @param {object} [conf={}]     Konfiguracja
     * @param {object} [sash=null]   Skrzydło
     * @returns {string}
     * @memberof DependenciesService
     */
    check(conditions, dependencyId, conf: WindowActiveConfiguration, sash = null, sashDependenciesAccessories = []) {
        let check = '';

        conditions.forEach(condition => {
            switch (condition.type) {
                case 'productType':
                    check += ' ' + this.checkProductType(condition.value, conf); break;
                case 'system':
                    check += ' ' + this.checkSystem(condition.value, conf); break;
                case 'fitting':
                    check += ' ' + this.checkFitting(condition.value, conf); break;
                case 'sashType':
                    check += ' ' + this.checkSashType(condition.value, sash); break;
                case 'sashTypeInConstruction':
                    check += ' ' + this.checkSashTypeInConstruction(condition.value, conf); break;
                case 'withoutSashType':
                    check += ' ' + this.checkWithoutSashType(condition.value, conf); break;
                case 'sashWeight':
                    check += ' ' + this.checkSashWeight(condition.value, sash); break;
                case 'shape':
                    check += ' ' + this.checkShape(condition.value, conf); break;
                case 'sashHeight':
                    check += ' ' + this.checkSashHeight(condition.value, sash); break;
                case 'sashWidth':
                    check += ' ' + this.checkSashWidth(condition.value, sash); break;
                case 'height':
                    check += ' ' + this.checkHeight(condition.value, conf); break;
                case 'width':
                    check += ' ' + this.checkWidth(condition.value, conf); break;
                case 'colorType':
                    check += ' ' + this.checkColorType(condition.value, conf); break;
                case 'profile':
                    check += ' ' + this.checkProfile(condition.value, conf); break;
                case 'sizeRange':
                    check += ' ' + this.checkSizeRange(condition.data, conf.Width, conf.Height); break;
                case 'sizeRangeSash':
                    const sashDimensions = conf.drawData.sash.find(s => sash && s.sashId == sash.id);
                    check += ' ' + (sashDimensions ? this.checkSizeRange(condition.data, sashDimensions.inner.rect.width, sashDimensions.inner.rect.height) : false); break;
                case 'accessory':
                    check += ' ' + this.checkAccessory(condition.value, conf); break;
                case 'accessoriesCategory':
                    check += ' ' + this.checkAccessoriesCategory(condition.value, conf); break;
                case 'sashAccessory':
                    check += ' ' + this.checkSashAccessory(condition.value, sash); break;
                case 'sashAccessoriesCategory':
                    check += ' ' + this.checkSashAccessoriesCategory(condition.value, sash); break;
                case 'arcHeightInSash':
                    check += ' ' + this.checkArcHeightInSash(condition, conf, sash); break;
                case 'dividerOnSlant':
                    check += ' ' + this.checkDividerOnSlant(conf); break;
                case 'dividerOnSlantVertical':
                    check += ' ' + this.checkDividerOnSlantVertical(conf); break;
                case 'mosquitoType':
                    check += ' ' + this.checkMosquitoType(condition.value, sash); break;
                case 'lock':
                    check += ' ' + this.checkLock(condition.value, conf); break;
                case 'addAccessoryToSash':
                    check += ' ' + this.addAccessoryToSash(condition.value, sash, conf, sashDependenciesAccessories); break;
                case 'openingBracket':
                    check += ' ('; break;
                case 'closingBracket':
                    check += ' )'; break;
                case 'and':
                    check += ' &&'; break;
                case 'or':
                    check += ' ||'; break;
                case 'not':
                    check += ' !'; break;
                case 'info':
                    check += this.info(condition); break;
                case 'blockade':
                    check += this.blockade(condition.value, dependencyId); break;
                case 'surcharge':
                    check += this.surcharge(condition, dependencyId, sash); break;
            }
        });

        return check;
    }

    /**
     * Wyświetlanie warunku w zależności od typu
     * @param {object} info Informacja
     * @returns {string|bolean}
     * @memberof DependenciesService
     */
    info(info) {
        if (info.valueType === 'info') {
            return ' this.issuesService.simpleRegister("dependency", "Dependency INFO", "' + info.value + '", conf, {level: ' + IssueLevel.INFO + ', blockAddToOffer: false, showMessage: true, logLevel: ' + IssueLevel.NONE + '})';
        } else if (info.valueType === 'warning') {
            return ' this.issuesService.simpleRegister("dependency", "Dependency WARN", "' + info.value + '", conf, {level: ' + IssueLevel.WARN + ', blockAddToOffer: false, showMessage: true, logLevel: ' + IssueLevel.NONE + '})';
        } else if (info.valueType === 'error') {
            return ' this.issuesService.simpleRegister("dependency", "Dependency ERROR", "' + info.value + '", conf, {level: ' + IssueLevel.ERROR + ', blockAddToOffer: false, showMessage: true, logLevel: ' + IssueLevel.NONE + '})';
        }
        return true;
    }

    /**
     * Blokowanie dodawania do oferty lub wymuszenie przekazania do wyceny
     * @param {object} blockade     Blokada
     * @param {number} dependencyId Id zależności
     * @returns {string|bolean}
     * @memberof DependenciesService
     */
    blockade(blockade, dependencyId) {
        if (blockade == 'blockade') {
            return ' (this.issuesService.simpleRegister("dependencyBlockade", "Dependency Blockade", "", conf, {blockAddToOffer: true, showMessage: false, logLevel: ' + IssueLevel.NONE + '}))';
        } else if (blockade == 'valuation') {
            return ' conf.Dependencies.push({id: ' + dependencyId + ', type: "valuation"})';
        }
        return true;
    }

    /**
     * Dopłata do skrzydła lub konstrukcji
     * @param {object} surcharge    Dopłata
     * @param {number} dependencyId Id zależnośći
     * @param {object} sash         Skrzydło
     * @returns {string|bolean}
     * @memberof DependenciesService
     */
    surcharge(surcharge, dependencyId, sash) {
        if (surcharge.valueType == 'sash') {
            return ' conf.Dependencies.push({id: ' + dependencyId + ', type: "sash", sashId: ' + sash.id + ', value: ' + surcharge.value + '})';
        } else if (surcharge.valueType == 'construction') {
            return ' conf.Dependencies.push({id: ' + dependencyId + ', type: "construction", sashId: null , value: ' + surcharge.value + '})';
        }
        return true;
    }

    /**
     * Sprawdzanie typu produktu
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkProductType(value, conf) {
        if (angular.isObject(conf.System) && angular.isDefined(conf.System.type) && value == conf.System.type) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie systemu produktu
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkSystem(value, conf) {
        if (angular.isObject(conf.System) && angular.isDefined(conf.System.id) && value && value.map(Number).indexOf(Number(conf.System.id)) > -1) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie okucia
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkFitting(value, conf) {
        if (angular.isObject(conf.Fitting) && angular.isDefined(conf.Fitting.id) && value == conf.Fitting.id) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie typu skrzydła
     * @param {string} value Wartość do sprawdzenia
     * @param {object} sash  Konfiguracja skrzydła
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkSashType(value, sash) {
        if (sash != null && angular.isObject(sash.type) && angular.isDefined(sash.type.id) && value == sash.type.id) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie typu skrzydła w całej konstrukcji
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkSashTypeInConstruction(value, conf) {
        if (
            conf != null
            && angular.isArray(conf.Sashes)
            && conf.Sashes.some(
                sash =>
                    angular.isObject(sash.type)
                    && angular.isDefined(sash.type.id)
                    && value == sash.type.id)
        ) {
            return true;
        }
        return false;
    }
    /**
     * Sprawdzanie nieobecności typu skrzydła
     * @param {string} value Wartość do sprawdzenia
     * @param {object} sash  Konfiguracja skrzydła
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkWithoutSashType(value, conf) {
        if (
            conf != null
            && angular.isArray(conf.Sashes)
            && conf.Sashes.every(sash => angular.isObject(sash.type) && angular.isDefined(sash.type.id) && value != sash.type.id)
        ) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie wagi skrzydła
     * @param {string} value Wartość do sprawdzenia
     * @param {object} sash  Konfiguracja skrzydła
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkSashWeight(value, sash) {
        if (sash != null && angular.isDefined(sash.weight) && sash.weight != null && sash.weight !== false && value.from <= sash.weight && value.to >= sash.weight) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie kształtu
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkShape(value, conf) {
        if (angular.isObject(conf.Shape) && angular.isDefined(conf.Shape.shape) && value == conf.Shape.shape) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie wysokości skrzydła
     * @param {string} value Wartość do sprawdzenia
     * @param {object} sash  Konfiguracja skrzydła
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkSashHeight(value, sash) {
        if (sash != null && angular.isDefined(sash.rHeight) && sash.rHeight != null && sash.rHeight !== false && value.from <= sash.rHeight && value.to >= sash.rHeight) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie szerokości skrzydła
     * @param {string} value Wartość do sprawdzenia
     * @param {object} sash  Konfiguracja skrzydła
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkSashWidth(value, sash) {
        if (sash != null && angular.isDefined(sash.rWidth) && sash.rWidth != null && sash.rWidth !== false && value.from <= sash.rWidth && value.to >= sash.rWidth) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie wysokości konstrukcji
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkHeight(value, conf) {
        if (angular.isDefined(conf.Height) && conf.Height != null && value.from <= conf.Height && value.to >= conf.Height) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie szerokości konstrukcji
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkWidth(value, conf) {
        if (angular.isDefined(conf.Width) && conf.Width != null && value.from <= conf.Width && value.to >= conf.Width) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie typu koloru
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkColorType(value, conf) {
        if (angular.isDefined(conf.ColorType) && value === conf.ColorType) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie zakresu wymiarów
     * @param {string} value   Wartość do sprawdzenia
     * @param {number} width   Szerokość
     * @param {number} height  Wysokość
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkSizeRange(poly, width, height) {
        let inside = false;
        if (angular.isDefined(width) && angular.isDefined(height) && width != null && width !== false && height != null && height !== false) {
            const pointX = width;
            const pointY = height;
            for (let i = 0, j = poly.length - 1; i < poly.length; j = i++) {
                const xi = Number(poly[i].width);
                const yi = Number(poly[i].height);
                const xj = Number(poly[j].width);
                const yj = Number(poly[j].height);
                if (((yi > pointY) != (yj > pointY)) && (pointX < (xj - xi) * (pointY - yi) / (yj - yi) + xi)) {
                    inside = !inside;
                }
            }
        }
        return inside;
    }

    /**
     * Sprawdzanie akcesorium
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkAccessory(value, conf) {
        if ((angular.isObject(conf.Handle)
                && angular.isDefined(conf.Handle.id)
                && conf.Handle.id == value)
            || (angular.isArray(conf.Accessories)
                && conf.Accessories.some(el => angular.isObject(el.accessory)
                    && angular.isDefined(el.accessory.id)
                    && el.accessory.id == value))
            || (angular.isArray(conf.Sashes)
                && conf.Sashes.some(sash =>
                    (angular.isObject(sash.handle)
                        && angular.isDefined(sash.handle.id)
                        && sash.handle.id == value)
                    || (angular.isArray(sash.hardware)
                        && sash.hardware.some(el => angular.isObject(el.accessory)
                            && angular.isDefined(el.accessory.id)
                            && el.accessory.id == value))))
            || (angular.isObject(conf.SideAccessories)
                && Object.keys(conf.SideAccessories).some(side => angular.isArray(conf.SideAccessories[side])
                    && conf.SideAccessories[side].some(el => angular.isObject(el.accessory)
                        && angular.isDefined(el.accessory.id)
                        && el.accessory.id == value)))
        ) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie akcesorium w skrzydle
     * @param {string} value Wartość do sprawdzenia
     * @param {object} sash  Konfiguracja skrzydła
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkSashAccessory(value, sash) {
        if ((sash != null
                && ((angular.isObject(sash.handle) && angular.isDefined(sash.handle.id) && sash.handle.id == value)
                    || (angular.isArray(sash.hardware)
                        && sash.hardware.some(el => angular.isObject(el.accessory)
                            && angular.isDefined(el.accessory.id)
                            && el.accessory.id == value))))
        ) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie kategorii akcesoriów
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkAccessoriesCategory(value, conf) {
        if ((angular.isObject(conf.Handle)
                && angular.isDefined(conf.Handle.window_accessories_category_id)
                && conf.Handle.window_accessories_category_id == value)
            || (angular.isArray(conf.Accessories)
                && conf.Accessories.some(el => angular.isObject(el.accessory)
                    && angular.isDefined(el.accessory.window_accessories_category_id)
                    && el.accessory.window_accessories_category_id == value))
            || (angular.isArray(conf.Sashes)
                && conf.Sashes.some(sash =>
                    (angular.isObject(sash.handle)
                        && angular.isDefined(sash.handle.window_accessories_category_id)
                        && sash.handle.window_accessories_category_id == value)
                    || (angular.isArray(sash.hardware)
                        && sash.hardware.some(el => angular.isObject(el.accessory)
                            && angular.isDefined(el.accessory.window_accessories_category_id)
                            && el.accessory.window_accessories_category_id == value))))
            || (angular.isObject(conf.SideAccessories)
                && Object.keys(conf.SideAccessories).some(side => angular.isArray(conf.SideAccessories[side])
                    && conf.SideAccessories[side].some(el => angular.isObject(el.accessory)
                        && angular.isDefined(el.accessory.window_accessories_category_id)
                        && el.accessory.window_accessories_category_id == value)))
        ) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie kategorii akcesoriów w skrzydle
     * @param {string} value Wartość do sprawdzenia
     * @param {object} sash  Konfiguracja skrzydla
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkSashAccessoriesCategory(value, sash) {
        if ((sash != null
                && ((angular.isObject(sash.handle) && angular.isDefined(sash.handle.window_accessories_category_id) && sash.handle.window_accessories_category_id == value)
                    || (angular.isArray(sash.hardware)
                        && sash.hardware.some(el => angular.isObject(el.accessory)
                            && angular.isDefined(el.accessory.window_accessories_category_id)
                            && el.accessory.window_accessories_category_id == value))))
        ) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie wysokości łuku w skrzydle
     * @param {object} condition Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkArcHeightInSash(condition, conf, sash) {
        const openingSide = sash.type.symbol.indexOf('(R)') > -1 ? 'R' : (sash.type.symbol.indexOf('(L)') > -1 ? 'L' : false);
        if (openingSide) {
            const sashPoly = conf.drawData.sashFrame.find(o => o.sashId === sash.id);
            const sashHeight = sashPoly.outer.rect.height;

            if (conf.Shape.shape == 'arc'
                && ((conf.Shape.type == 'R' && openingSide == 'R') || (conf.Shape.type == 'L' && openingSide == 'L') || conf.Shape.type == 'F')
                && ((condition.valueType == '>' && (this.getArcHeight(sashPoly, openingSide) / sashHeight * 100) > (condition.value))
                    || (condition.valueType == '<' && (this.getArcHeight(sashPoly, openingSide) / sashHeight * 100) < (condition.value)))
            ) {
                return true;
            }
        }

        return false;
    }

    /**
     * Wyliczanie wysokości łuku w skrzydle
     * @param {object} sashPoly     Wymiary skrzydła
     * @param {string} openingSide  Strona otwierania
     * @returns {number}
     * @memberof DependenciesService
     */
    getArcHeight(sashPoly, openingSide) {
        let arcHeight = 0;
        const sashHeight = sashPoly.outer.rect.height;
        let sidePolyHeight = 0;
        let sidePoly;
        if (openingSide == 'R') {
            sidePoly = sashPoly.sides.find(el => el.outerEdge.side == 'right' && !el.outerEdge.circle);
        } else if (openingSide == 'L') {
            sidePoly = sashPoly.sides.find(el => el.outerEdge.side == 'left' && !el.outerEdge.circle);
        }
        if (sidePoly) {
            sidePolyHeight = sidePoly.rect.height;
        }
        arcHeight = sashHeight - sidePolyHeight;

        return arcHeight;
    }

    /**
     * Sprawdzanie czy słupek jest na połączeniu skosu z poziomą częścią ramy
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkDividerOnSlant(conf) {
        const verticalMullions = conf.Mullions.filter(el => el.direction === 'vertical');
         if (conf.Shape.shape === 'poligon' && verticalMullions.length) {
            return verticalMullions.some(mullion => {
                let profile = this.profilesService.getProfile(mullion.profileId);
                let alignment = conf.Alignments.find(el => el.perpendicularMullions && el.perpendicularMullions.bottom && el.perpendicularMullions.bottom.some(elem => elem == mullion.id));
                if (alignment) {
                    let alignmentRect = conf.drawData.alignment.find(o => o.alignmentId === alignment.id).rect;
                    if ((alignmentRect.x >= (mullion.rx - profile.width / 2) && alignmentRect.x <= (mullion.rx + profile.width / 2))
                        || ((alignmentRect.x + alignmentRect.width) >= (mullion.rx - profile.width / 2) && (alignmentRect.x + alignmentRect.width) <= (mullion.rx + profile.width / 2))
                    ) {
                        return true;
                    }
                } else {
                    if (mullion.ry == 0) {
                        const framePoly = conf.drawData.frame[0].inner.poly;
                        return framePoly.some(point => point.x >= (mullion.rx - profile.width / 2) && point.x <= (mullion.rx + profile.width / 2));
                    } else {
                        let topMullion = conf.Mullions.find(el => el.direction == 'horizontal' && el.multiAlignBottomDiv.length && el.multiAlignBottomDiv.some(div => div.id == mullion.id));
                        let topMullionRect = conf.drawData.mullion.find(o => o.mullionId === topMullion.id).rect;
                        if ((topMullionRect.x >= (mullion.rx - profile.width / 2) && topMullionRect.x <= (mullion.rx + profile.width / 2))
                            || ((topMullionRect.x + topMullionRect.width) >= (mullion.rx - profile.width / 2) && (topMullionRect.x + topMullionRect.width) <= (mullion.rx + profile.width / 2))
                        ) {
                            return true;
                        }
                    }
                    return false;
                }
            });
        }
        return false;
    }

    /**
      * Sprawdzanie czy słupek jest na połączeniu skosu z pionową częścią ramy
      * @param {object} conf  Konfiguracja pozycji
      * @returns {boolean}
      * @memberof DependenciesService
      */
      checkDividerOnSlantVertical(conf) {
        const horizontalMullions = conf.Mullions.filter(el => el.direction === 'horizontal');
        if ((conf.Shape.shape === 'poligon' || conf.Shape.shape === 'arc') && horizontalMullions.length) {
            return horizontalMullions.some(mullion => {
                let profile = this.profilesService.getProfile(mullion.profileId);

                // lewa strona
                let leftAlignment = conf.Alignments.find(el => el.perpendicularMullions && el.perpendicularMullions.right && el.perpendicularMullions.right.some(elem => elem == mullion.id));
                if (leftAlignment) {
                    let alignmentRect = conf.drawData.alignment.find(o => o.alignmentId === leftAlignment.id).rect;
                    if ((alignmentRect.y >= (mullion.ry - profile.width / 2) && alignmentRect.y <= (mullion.ry + profile.width / 2))) {
                        return true;
                    }
                } else {
                    let leftMullion = conf.Mullions.find(el => el.direction == 'vertical' && el.multiAlignRightDiv.length && el.multiAlignRightDiv.some(div => div.id == mullion.id));
                    if (leftMullion) {
                        let leftMullionRect = conf.drawData.mullion.find(o => o.mullionId === leftMullion.id).rect;
                        if ((leftMullionRect.y >= (mullion.ry - profile.width / 2) && leftMullionRect.y <= (mullion.ry + profile.width / 2))) {
                            return true;
                        }
                    }
                }

                // prawa strona
                let rightAlignment = conf.Alignments.find(el => el.perpendicularMullions && el.perpendicularMullions.left && el.perpendicularMullions.left.some(elem => elem == mullion.id));
                if (rightAlignment) {
                    let alignmentRect = conf.drawData.alignment.find(o => o.alignmentId === rightAlignment.id).rect;
                    if ((alignmentRect.y >= (mullion.ry - profile.width / 2) && alignmentRect.y <= (mullion.ry + profile.width / 2))) {
                        return true;
                    }
                } else {
                    let rightMullion = conf.Mullions.find(el => el.direction == 'vertical' && el.multiAlignLeftDiv.length && el.multiAlignLeftDiv.some(div => div.id == mullion.id));
                    if (rightMullion) {
                        let rightMullionRect = conf.drawData.mullion.find(o => o.mullionId === rightMullion.id).rect;
                        if ((rightMullionRect.y >= (mullion.ry - profile.width / 2) && rightMullionRect.y <= (mullion.ry + profile.width / 2))) {
                            return true;
                        }
                    }
                }

                const framePoly = conf.drawData.frame[0].inner.poly;
                const mullionDimensions = conf.drawData.mullion.find(el => el.mullionId == mullion.id);
                return framePoly.some(point =>
                    point.y >= (mullion.ry - profile.width / 2) && point.y <= (mullion.ry + profile.width / 2)
                    && (Math.round(point.x) === Math.round(mullionDimensions.rect.x)
                        || Math.round(point.x) === Math.round(mullionDimensions.rect.x + mullionDimensions.rect.width))
                );

            });
        }
        return false;
    }

    /**
     * Sprawdzanie typu siatki w skrzydle
     * @param {string} value Wartość do sprawdzenia
     * @param {object} sash  Konfiguracja skrzydła
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkMosquitoType(value, sash) {
        if (
            sash != null
            && angular.isObject(sash.mosquito)
            && angular.isDefined(sash.mosquito.Type)
            && angular.isDefined(sash.mosquito.Type.id)
            && sash.mosquito.Type.id == value
        ) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie wybranego zamka
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja konstrukcji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkLock(value, conf) {
        if (
            angular.isObject(conf.Lock)
            && angular.isDefined(conf.Lock.id)
            && conf.Lock.id == value
        ) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie typu siatki w skrzydle
     * @param {string} value Wartość do sprawdzenia
     * @param {object} sash  Konfiguracja skrzydła
     * @returns {boolean}
     * @memberof DependenciesService
     */
    addAccessoryToSash(value, sash, conf, sashDependenciesAccessories = []) {
        if (
            sash != null
            && angular.isArray(sash.hardware)
            && !sash.hardware.some(el => el.id == value && el.fromDependency)
        ) {
            let accessory = this.accessoriesFactory.getAccessory(value);
            if (accessory) {
                const dependencyAccessory = sashDependenciesAccessories.find(el => el.accessory.id == accessory.id);
                let color = null;
                if (dependencyAccessory) {
                    accessory = dependencyAccessory.accessory;
                    color = color = this.accessoriesFactory.colorsAll
                        .find(el => el.id == accessory.selectedColor);
                } else {
                    this.accessoriesFactory.setAmountAccessory(accessory, 'sash', conf, sash);
                    if (angular.isArray(accessory.colors_ids) && accessory.price_source === 'colors') {
                        this.colorMappingService.getWindowColorId(conf, 'inner');
                        const windowColorId = this.colorMappingService.getWindowColorId(conf, 'inner');
                        const windowColorRal = this.colorMappingService.getWindowColorId(conf, 'inner', 'RAL');
                        const matchedColors = this.colorMappingService.getColors(windowColorId, windowColorRal ? 'ral' : 'window', 'accessory');
                        const matchedColor = matchedColors.find(el => accessory.colors_ids.some(id => id == el));
                        if (matchedColor) {
                            color = this.accessoriesFactory.colorsAll
                                .find(el => matchedColor == el.id);
                        } else {
                            color = this.accessoriesFactory.colorsAll
                            .find(el => accessory.colors_ids.some(id => id == el.id));
                        }
                        accessory.selectedColor = color.id;
                    } else if (accessory.price_source === 'table') {
                        const colorInner = this.colorsDefaultsService.getColorValue(conf.Colors, 'inner').toLowerCase();
                        const colorOuter = this.colorsDefaultsService.getColorValue(conf.Colors, 'outer').toLowerCase();
                        let colorOptions = 'white';
                        if ((colorInner === 'white' || colorInner === 'none') && colorOuter === 'color') {
                            colorOptions = 'white-color';
                        } else if ((colorOuter === 'white' || colorOuter === 'none') && colorInner === 'color') {
                            colorOptions = 'color-white';
                        } else if (colorOuter === 'color' && colorInner === 'color') {
                            colorOptions = 'color-color';
                        }
                        accessory.colorOptions = colorOptions;
                    }
                    accessory.fromDependency = true;
                }
                this.accessoriesFactory.add(sash, accessory, color);
            }
        }
        return true;
    }

    /**
     * Sprawdzanie systemu produktu
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkProfile(value, conf) {
        if (
            angular.isArray(conf.UsedProfiles)
            && value
            && conf.UsedProfiles.some(profile => (
                profile.type === value.type
                && (value.options || []).every(o => profile.options && profile.options.indexOf(o) > -1)
                && (value.notOptions || []).every(o => profile.options && profile.options.indexOf(o) === -1)
            ))
        ) {
            return true;
        }
        return false;
    }

}
