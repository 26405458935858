import 'angular-ui-bootstrap';
import { core } from 'helpers';
import ConfigurationsService from 'configurations/configurations.service';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import { EventBusService } from 'event-bus.service';

export default class ModalSystemComparisonColorsCtrl {

    hasCream = false;

    constructor(
        private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        private config,
        private configDefault,
        private ColorsFactory,
        private configurationsService: ConfigurationsService,
        private eventBusService: EventBusService
    ) { }

    setColorType(type) {
        this.ColorsFactory.setColorType(
            type,
            true,
            this.config,
            this.configDefault
        );
    }

    openModalWood() {
        this.ColorsFactory.openModalWood(
            this.config,
            this.configDefault,
            this.setDefaults.bind(this)
        );
    }

    filter = group => false;
    filterColors = color => false;

    setDefaults() {
        this.ColorsFactory.loadData();
        this.ColorsFactory.loadColorsBySystem(this.filterColors.bind(this));

        this.ColorsFactory.setDefaultColorTypeForSystem(this.config, this.configDefault);
        this.ColorsFactory.setDefaultWood(this.config, this.configDefault);
        this.ColorsFactory.loadColorsByWood(this.config);
        this.ColorsFactory.setDefaultColors(
            false,
            this.config,
            this.configDefault,
            this.filter.bind(this)
        );
        this.ColorsFactory.setDefaultColorTypeForColors(this.config, this.configDefault);
    }

    close() {
        this.$uibModalInstance.close();
    }
}
ModalSystemComparisonColorsCtrl.$inject = [
    '$uibModalInstance',
    'config',
    'configDefault',
    'ColorsFactory',
    'ConfigurationsService',
    'EventBusService',
];
