import $ from 'jquery';
import angular from 'angular';

/**
 * Kontroler okna modal ze szkleniem
 * @param {object} $scope                  Angular scope
 * @param {object} $uibModalInstance          Okno modal
 * @param {object} $timeout                Timeout
 * @param {object} fillings              Typ szkła
 * @param {object} fillingsCategories    Kategorie typów szkla
 * @param {object} modalData               Dane szkła
 * @param {object} selGlassType            Ustawianie typu szkła
 * @param {object} glassCatFilter          Filtr kategorii szkła
 * @param {object} glassColors             Kolory szyb
 * @param {object} glassOrnamentFilter     Filtr ornamentów szyb
 */
export default function ModalGlazingCtrl($scope, $filter, $uibModalInstance, $timeout, fillings, fillingsCategories, IccConfig,
                                modalData, selGlassType, glassColors, bondedGlazing,
                                ConfigurationsService, FillingsService,
                                selectFor, InfoFactory, noDivInSash, hasGlasses, GlazingUnitsService, EventBusService, b2c,
                                glassCatFilter, decoPanelTypeFilter, glassThicknessFilter, glassOrnamentFilter, glassSecurityFilter, glassUgFilter,
                                glassRwFilter, glassColorFilter, glassCountFilter, glassOftenFilter, filterFilter, customPriceFilter, ScrollbarService
) {
    'ngInject';
    var vm = this;
    vm.b2c = b2c;
    vm.glazings = fillings.filter(filling => filling.type == 'glazing');
    vm.pvcPanels = fillings.filter(filling => filling.type == 'pvc_panels');
    if (vm.pvcPanels) {
        for (let i = 0; i <  vm.pvcPanels.length; i++) {
            vm.pvcPanels[i].displayedPrice = vm.pvcPanels[i].panel_prices ? minPrice(Array.from(JSON.parse(vm.pvcPanels[i].panel_prices))) : 0;
        }
    }
    vm.decoPanels = fillings.filter(filling => filling.type == 'deco_panels');
    if (vm.decoPanels) {
        for (let i = 0; i <  vm.decoPanels.length; i++) {
            vm.decoPanels[i].displayedPrice = vm.decoPanels[i].panel_prices ? minPrice(Array.from(JSON.parse(vm.decoPanels[i].panel_prices))) : 0;
        }
    }
    vm.selGlassType = selGlassType;
    vm.glassTab = modalData.glassTab;
    vm.glassColors = glassColors;
    vm.showDecoPanels = vm.selGlassType.type == 'deco_panels'
        || selectFor == 'doorActive' && noDivInSash
        || selectFor == 'doorPassive' && noDivInSash
        || angular.isObject(selectFor) && selectFor.type && ['DRA', 'DRP', 'DOA', 'DOP', 'FD', 'FDO'].indexOf(selectFor.type.type) > -1
            && selectFor.intSashes.length == 1
            && noDivInSash;
    vm.bondedGlazing = bondedGlazing;
    if (!vm.showDecoPanels && vm.glassTab == 'decoPanels') {
        vm.glassTab = 'glazing';
    }
    vm.showBondedGlazingOption = IccConfig.Configurators.bondedGlazingAvailable;
    vm.categories = {
        glazings: fillingsCategories.filter(cat =>
            cat.type == 'glazing' && cat.parent_id === null && glassCatFilter(vm.glazings, cat.id, null).length > 0),
        pvcPanels: fillingsCategories.filter(cat =>
            cat.type == 'pvc_panels' && cat.parent_id === null && glassCatFilter(vm.pvcPanels, cat.id, null).length > 0),
        decoPanels: fillingsCategories.filter(cat => {
            return cat.type == 'deco_panels' && cat.parent_id === null && glassCatFilter(vm.decoPanels, cat.id, null).length > 0;
        }),
        panelsGlazings: fillingsCategories.filter(cat =>
            cat.type == 'panels_glazing' && cat.parent_id === null && glassCatFilter(vm.panelsGlazings, cat.id, null).length > 0)
    };
    vm.filters = modalData;

    vm.areOrnaments = glassOrnamentFilter(vm.glazings, true).length > 0;

    vm.refresh = refresh;
    vm.selectGlass = selectGlass;
    vm.openInfoGlass = openInfoGlass;
    vm.close = close;
    vm.changedFilters = changedFilters;
    vm.addGlazingUnit = addGlazingUnit;

    vm.thicknessPvc = [24, 36, 48].filter(t => glassThicknessFilter(vm.pvcPanels, t).length > 0);
    vm.thicknessDeco = getUniqueOptions(vm.decoPanels, 'thickness_mm', false).filter(t => glassThicknessFilter(vm.decoPanels, t).length > 0);
    vm.decoPanelTypes = getUniqueOptions(vm.decoPanels, 'panel_type', true).filter(t => decoPanelTypeFilter(vm.decoPanels, t.type).length > 0);
    vm.selectedThicknessPvc = vm.thicknessPvc[0];
    vm.hasGlasses = hasGlasses;
    vm.tutorialGlazingModal = tutorialGlazingModal;
    $uibModalInstance.rendered.then(() => vm.tutorialGlazingModal());

    vm.addMoreItems = addMoreItems;
    vm.changeTab = changeTab;
    vm.fillings = vm.glazings || [];
    vm.displayedCount = 25;
    vm.customPrice = (id, price) => customPriceFilter('Filling', id.split('.')[0], 'price_sq_m', null, null, price);

    /**
     * Funkcja odświeżajaca
     */
    function refresh() {
        ScrollbarService.update();
    }

    /**
     * Funkcja wybierajaca szybe
     * @param  {object} glassType Typ szkła
     */
    function selectGlass(glassType) {
        vm.selGlassType = glassType;
    }

    /**
     * Funkcja otwierajaca okno informacji dla szyb
     * @param  {object} glass Szyba
     */
    function openInfoGlass(glass) {
        const imageSrc = `/files/filling/${glass.image}`;
        InfoFactory.openModal({template: 'imagePreview.html', title: $filter('translate')('INTERFACE|Podgląd'), data: {imageSrc}});
    }

    function addGlazingUnit(edit = false) {
        GlazingUnitsService.addGlazingUnit(edit ? vm.selGlassType : null).then(filling => {
            if (filling) {
                if (filling.custom) {
                    if (!vm.glazings.some(el => el.id === filling.id)) {
                        vm.glazings.unshift(filling);
                    }
                }
                selectGlass(filling);
            }
        });
    }

    /**
     * Funkcja zamykajaca okno modal
     */
    function close() {
        $uibModalInstance.close({
            glass           : vm.selGlassType,
            selectedSecurity: vm.selectedSecurity,
            selectedOrnament: vm.selectedOrnament,
            selectedUg      : vm.selectedUg,
            selectedRw      : vm.selectedRw,
            selectedCategory: vm.selectedCategory,
            glassTab        : vm.glassTab,
            bondedGlazing   : vm.bondedGlazing
        });
    }

    function changedFilters(filters) {
        vm.filters = filters;

        let fillingsFiltered = vm.glazings;
        fillingsFiltered = glassSecurityFilter(fillingsFiltered, vm.filters.selectedSecurity, IccConfig.Configurators.glazingFilters);
        fillingsFiltered = glassUgFilter(fillingsFiltered, vm.filters.selectedUg);
        fillingsFiltered = glassRwFilter(fillingsFiltered, vm.filters.selectedRw);
        fillingsFiltered = glassOrnamentFilter(fillingsFiltered, vm.filters.selectedOrnament);
        fillingsFiltered = glassColorFilter(fillingsFiltered, vm.filters.selectedColored);
        fillingsFiltered = glassCountFilter(fillingsFiltered, vm.filters.glazingCount);
        fillingsFiltered = glassOftenFilter(fillingsFiltered, vm.filters.selectedOften);
        fillingsFiltered = filterFilter(fillingsFiltered, vm.filters.q);
        vm.fillings = fillingsFiltered;
        refresh();
    }

    function minPrice(data) {
        return data.reduce((min, p) => p && p.price && p.price < min ? p.price : min, data && data[0] && data[0].price ? data[0].price : 0);
    }

    function tutorialGlazingModal() {
        if (IccConfig.Configurators.tutorialAvailable) {
            EventBusService.post({
                key: 'tutorialSteps',
                value: vm.glassTab
            });
        }
    }

    function changeTab() {
        vm.displayedCount = 25;
    }

    function addMoreItems() {
        if (vm.displayedCount + 5 < vm.fillings.length) {
            vm.displayedCount += 5;
        } else {
            vm.displayedCount = vm.fillings.length;
        }
    }


     /**

      * Wybranie unikalnych wartości z tablicy obiektów wg podanego klucza
      * @param ObjectsArray tablica obiektów
      * @param key klucz wg którego szukamy unikalnych wartości
      */
      function getUniqueOptions(ObjectsArray, key, isDecoPanel) {
        const options = [];
        const decoPanelsTranslationsMap = {
            'panel_type_inset' : $filter('translate')('DOOR|Wsadowy'),
            'panel_type_double' : $filter('translate')('DOOR|Nakładkowy'),
            'panel_type_outer' : $filter('translate')('DOOR|Nakładka zewnętrzna'),
            'panel_type_inner' : $filter('translate')('DOOR|Nakładka zewnętrzna'),
            'null' : $filter('translate')('DOOR|Brak ustawionego typu'),
        };

        // wybierz unikalne wartości
        const uniqueOptions = ObjectsArray.filter((object, index, self) => {
            return self.map(item => item[key]).indexOf(object[key]) === index;
        });

        uniqueOptions.forEach(item => {
            if (isDecoPanel) {
                options.push({
                    type: item[key],
                    name: decoPanelsTranslationsMap[item[key]],
                });

            } else {
                options.push(item[key]);
            }
        });
        return options;
    }
}
