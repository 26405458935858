import Common from '../Common';
import { Configuration } from './Configuration';
import WindowActiveConfiguration from './WindowActiveConfiguration';
import WindowSmallConfiguration from './WindowSmallConfiguration';
import { PriceSegment, DiscountGroups, PricePart } from '../price/Prices';
import { TimeLimitSegment } from '../../application/src/time_limit/time_limit_functions';
import { core } from '../helpers';
import { Issue } from 'issues/Issue';
import {
    Alignment,
    Mullion,
    Fitting,
    FrameProfile,
    HandlesType,
    Hinge,
    Layout,
    Lock,
    Monoblock,
    Muntin,
    MuntinsConfig,
    Profile,
    ProfileShape,
    ProfileSet,
    Sash,
    Shape,
    System,
    WindowParameters,
    SideProfile,
    SashesType,
    Measurement,
    Frame,
    Coupling,
} from './parts/window';

import MosquitoConfiguration from './MosquitoConfiguration';
import { RollerShutter } from './parts/roller_shutter';

import { Accessory, Color, Colors, ColorType, GlazingSpacer, Wood } from './parts/common';
import {
    changesInVersion4,
    changesInVersion5,
    changesInVersion6,
    changesInVersion7,
    changesInVersion8,
    changesInVersion9,
    changesInVersion10,
    changesInVersion11,
    changesInVersion12,
    muntinsConversion,
} from './converters/window';

export default class WindowConfiguration implements Configuration {
    public static is(configuration): configuration is WindowConfiguration {
        return (
            configuration instanceof WindowConfiguration
            || (configuration.$version
                && !configuration.active
                && !(configuration instanceof WindowSmallConfiguration)
                && ['window', 'door', 'hs', 'folding_door'].indexOf(configuration.type) > -1)
        );
    }
    //#region pola interfejsu
    $version = 12;
    type: 'window' | 'door' | 'hs' | 'folding_door' = 'window';
    name = '';
    price: number | null = null;
    priceNoMargin: number | null = null;
    priceAfterDiscounts: number | null = null;
    quantity: number = 1;
    priceSegments: PriceSegment[] = [];
    priceSegmentsNoMargin: PriceSegment[] = [];
    priceParts: PricePart[] = [];
    pricePartsNoMargin: PricePart[] = [];
    discountGroups: DiscountGroups = {};
    discountGroupsNoMargin: DiscountGroups = {};
    timeLimit: number | null = null;
    timeLimitsStack: TimeLimitSegment[] = [];
    title = '';
    description = '';
    attachments: any[] = [];
    valid: {
        system: null | boolean;
        shape: null | boolean;
        dimensions: null | boolean;
        sashes: null | boolean;
        sealColor: null | boolean;
        siliconeColor: null | boolean;
        colors: null | boolean;
        wood: null | boolean;
        profileSet: null | boolean;
        loadedMuntinsColors: null | boolean;
        loadedProfiles: null | boolean;
        mullionsProfiles: null | boolean;
        sashesProfiles: null | boolean;
        frameProfiles: null | boolean;
        loadedGlazingBeads: null | boolean;
        loadedFillings: null | boolean;
        glazingBeads: null | boolean;
        fillings: null | boolean;
    } = {
        system: null,
        shape: null,
        dimensions: null,
        sashes: null,
        sealColor: null,
        siliconeColor: null,
        colors: null,
        wood: null,
        profileSet: null,
        loadedMuntinsColors: null,
        loadedProfiles: null,
        mullionsProfiles: null,
        sashesProfiles: null,
        frameProfiles: null,
        loadedGlazingBeads: null,
        loadedFillings: null,
        glazingBeads: null,
        fillings: null,
    };

    isMistakeProduct = false;
    hasWarranty = true;
    measurements: Measurement[] = [];
    //#endregion

    //#region kształt, rozmiar, współczynniki
    height = 1500;
    width = 1500;
    shape: Shape = {
        shape: 'rect',
        width: 1500,
        height: 1500,
        circuit: 6000,
        s1: 1500,
        s2: 0,
        s3: 0,
        h1: 1500,
        h2: 0,
        h3: 1500,
    };
    parameters: WindowParameters = {
        weight: null,
    };
    //#endregion

    //#region kolory, drewno, wykończenie
    colors: Colors = {
        frame: {
            outer: null,
            inner: null,
            core: null,
            alushell: null,
        },
        sash: {
            outer: null,
            inner: null,
            core: null,
            alushell: null,
        },
    };
    colorType: ColorType = 'White';
    colorsSashExt: boolean = false;
    wood: Wood | null = null;
    alushellType: 'painted' | 'brushed' | null = null;
    glazingBeadColor: Color = null;
    sealColor: Color = null;
    siliconeColor: Color = null;
    //#endregion

    //#region wypełnienia
    glazingSpacer: GlazingSpacer = null;
    customFillings = [];
    hasGlazingWarranty: boolean = true;
    //#endregion

    //#region akcesoria
    accessories: Accessory[] = [];
    sideAccessories: {
        top: Accessory[];
        bottom: Accessory[];
        left: Accessory[];
        right: Accessory[];
    } = {
        top: [],
        bottom: [],
        left: [],
        right: [],
    };
    //#endregion

    //#region okucia
    hasHandle: boolean = false;
    handleType: HandlesType = 'InnerLever';
    hinge: Hinge = null;
    lock: Lock = null;
    fitting: Fitting = null;
    //#endregion

    //#region profile, wzmocnienia, system
    system: System | null = null;
    steel: 'Closed' | 'Opened' | null = null;
    hasAlushell = false;
    profileSet: ProfileSet = {
        id: null,
        name: '',
        frameTop: null,
        frameBottom: null,
        frameSide: null,
        sash: null,
        fixedMullion: null,
        falseMullion: null,
        threshold: null,
        sashOutward: null,
        centralHandleFalseMullion: null,
        doubleHandleFalseMullion: null,
        zMullion: null,
        glazingBeadShape: null,
        systems: [],
    };
    usedProfiles: Profile[] = [];
    usedProfileShapes: ProfileShape[] = [];
    usedProfilesSegments: {
        [profileId: number]: number[];
    } = {};

    weldFinishType: string = 'V';
    drainagePosition: string = 'front';
    oneBondedGlazing: boolean = false;

    lowThreshold:
        | false
        | {
              id: number;
              name: string;
          } = false;
    //#endregion

    //#region konstrukcja
    frames: Frame[] = [];
    sashes: Sash[] = [];
    mullions: Mullion[];
    alignments: Alignment[];
    couplings: Coupling[] = [];
    sideProfiles: SideProfile[] = [];

    rollerShutter: RollerShutter = null;
    mosquito: MosquitoConfiguration = null;

    sashesType: SashesType = 'Fix';
    ownedSashesTypes = {
        window: true,
        doorActive: false,
        doorPassive: false,
    };

    balcony: boolean = false;
    kicker: boolean = false;
    layout: Layout = null;
    muntins: MuntinsConfig = null;
    //#endregion

    //#region one
    oneGlazing: boolean = false;
    oneFilling: {
        window: boolean;
        doorActive: boolean;
        doorPassive: boolean;
    } = {
        window: false,
        doorActive: false,
        doorPassive: false,
    };
    oneGlazingBead: boolean = false;
    oneGlazingBeadSash: {
        fix: boolean | number;
        sashes: boolean | number;
    } = {
        fix: false,
        sashes: false
    };
    oneHandle: boolean = false;
    oneHandlesHeight: boolean = false;
    oneReinforcement: boolean = true;
    issues: Issue[] = [];
    //#endregion

    protected converters = {
        4: changesInVersion4,
        5: changesInVersion5,
        6: changesInVersion6,
        7: changesInVersion7,
        8: changesInVersion8,
        9: changesInVersion9,
        10: changesInVersion10,
        11: changesInVersion11,
        12: changesInVersion12,
    };

    constructor(
        configuration,
        dataRequiredToUpdate?: any,
        conversion = true,
        private configuratorsDataService?,
        private colorsDefaultsService?
    ) {
        if (WindowConfiguration.is(configuration) && conversion) {
            this.runConversion(configuration, dataRequiredToUpdate);
        } else {
            if (WindowActiveConfiguration.is(configuration)) {
                this.name = configuration.Name;
                this.price = configuration.Price;
                this.timeLimit = configuration.timeLimit;
                this.timeLimitsStack = configuration.timeLimitsStack;
                this.priceNoMargin = configuration.PriceNoMargin;
                this.priceAfterDiscounts = configuration.PriceAfterDiscounts || configuration.Price;
                this.quantity = configuration.Quantity;
                this.priceSegments = configuration.PriceSegments;
                this.priceSegmentsNoMargin = configuration.PriceSegmentsNoMargin;
                this.priceParts = configuration.PriceParts;
                this.pricePartsNoMargin = configuration.PricePartsNoMargin;
                this.discountGroups = configuration.DiscountGroups;
                this.discountGroupsNoMargin = configuration.DiscountGroupsNoMargin;
                this.title = configuration.Title;
                this.description = configuration.Description;
                this.attachments = configuration.Attachments;
                this.valid = configuration.valid;

                this.height = configuration.Height;
                this.width = configuration.Width;
                this.system = {
                    id: Number(configuration.System.id),
                    imageSide: configuration.System.image_side,
                    name: configuration.System.name,
                    titleImage: configuration.System.title_image,
                    type: configuration.System.type,
                    offerInfo: configuration.System.offer_info,
                    lcd: configuration.System.lcd,
                    frameType: configuration.System.type_frame,
                };

                ['frame', 'sash'].forEach(part => {
                    ['outer', 'inner', 'core', 'alushell'].forEach(side => {
                        if (configuration.Colors[part][side].id) {
                            this.colors[part][side] = {
                                id: Number(configuration.Colors[part][side].id),
                                name: configuration.Colors[part][side].name,
                                code: configuration.Colors[part][side].code,
                                color: configuration.Colors[part][side].color,
                                color_img: configuration.Colors[part][side].color_img,
                                RAL: Boolean(configuration.Colors[part][side].RAL),
                                isDefault: configuration.Colors[part][side].isDefault,
                                type: configuration.Colors[part][side].type,
                                groups:
                                    (configuration.Colors[part][side].groups
                                        && configuration.Colors[part][side].groups.map(Number))
                                    || [],
                            };
                        }
                    });
                });

                this.colorType = configuration.ColorType as ColorType;
                this.colorsSashExt = configuration.ColorsSashExt;
                this.steel = configuration.Steel;
                if (configuration.Wood && configuration.Wood.id) {
                    this.wood = {
                        id: Number(configuration.Wood.id),
                        name: configuration.Wood.name,
                    };
                }
                this.hasAlushell = configuration.HasAlushell;
                if (this.hasAlushell) {
                    this.alushellType = configuration.AlushellType;
                }
                this.shape = {
                    shape: configuration.Shape.shape,
                    circuit: configuration.Shape.circuit,
                };
                ['width', 'height', 's1', 's2', 's3', 'h1', 'h2', 'h3', 'd'].forEach(field => {
                    if (configuration.Shape[field] != null) {
                        this.shape[field] = Number(configuration.Shape[field]);
                    }
                });
                if (configuration.Shape.type != null) {
                    this.shape.type = configuration.Shape.type;
                }
                if (configuration.Shape.arcType != null) {
                    this.shape.arcType = configuration.Shape.arcType;
                }
                this.sashes = configuration.Sashes.map<Sash>(this.mapSash.bind(this));
                this.sashesType = configuration.SashesType;
                this.ownedSashesTypes = {
                    window: configuration.OwnedSashesTypes.window,
                    doorActive: configuration.OwnedSashesTypes.doorActive,
                    doorPassive: configuration.OwnedSashesTypes.doorPassive,
                };

                this.sideProfiles = configuration.SideProfiles;
                this.alignments = configuration.Alignments;
                this.mullions = configuration.Mullions.map<Mullion>(this.mapMullion.bind(this));
                this.usedProfiles = configuration.UsedProfiles;
                this.usedProfileShapes = configuration.UsedProfileShapes;
                this.usedProfilesSegments = configuration.UsedProfilesSegments;
                this.customFillings = configuration.CustomFillings;
                this.profileSet = configuration.ProfileSet;
                this.balcony = configuration.Balcony;

                this.layout = {
                    id: Number(configuration.Layout.id),
                    name: configuration.Layout.name,
                    changed:
                        configuration.Layout.divs && configuration.Layout.sashes
                            ? configuration.Layout.changed
                            : true,
                    sashes: configuration.Layout.sashes,
                    divs: configuration.Layout.divs,
                };
                this.oneGlazing = configuration.OneGlazing;
                this.oneFilling = {
                    window: configuration.OneFilling.window,
                    doorActive: configuration.OneFilling.doorActive,
                    doorPassive: configuration.OneFilling.doorPassive,
                };
                this.oneGlazingBead = configuration.OneGlazingBead;
                this.oneGlazingBeadSash = configuration.OneGlazingBeadSash;
                this.oneHandle = configuration.OneHandle;
                this.oneHandlesHeight = configuration.OneHandlesHeight;
                this.oneReinforcement = configuration.OneReinforcement;
                if (configuration.GlazingBeadColor && configuration.GlazingBeadColor.id) {
                    this.glazingBeadColor = {
                        id: Number(configuration.GlazingBeadColor.id),
                        name: configuration.GlazingBeadColor.name,
                        isDefault: configuration.GlazingBeadColor.isDefault,
                        RAL: Boolean(configuration.GlazingBeadColor.RAL),
                    };
                }
                if (configuration.SealColor && configuration.SealColor.id) {
                    this.sealColor = {
                        id: Number(configuration.SealColor.id),
                        name: configuration.SealColor.name,
                        isDefault: configuration.SealColor.isDefault,
                    };
                }
                if (configuration.SiliconeColor && configuration.SiliconeColor.id) {
                    this.siliconeColor = {
                        id: Number(configuration.SiliconeColor.id),
                        name: configuration.SiliconeColor.name,
                        isDefault: configuration.SiliconeColor.isDefault,
                    };
                }
                this.hasHandle = configuration.HasHandle;
                this.handleType = configuration.HandleType as HandlesType;
                if (configuration.Hinge && configuration.Hinge.id) {
                    this.hinge = {
                        id: Number(configuration.Hinge.id),
                        name: configuration.Hinge.name,
                        priceSource: configuration.Hinge.price_source,
                        color: {
                            id: Number(configuration.HingeColor.id),
                            name: configuration.HingeColor.name,
                            isDefault: configuration.HingeColor.isDefault,
                        },
                    };
                }
                if (configuration.Lock && configuration.Lock.id) {
                    this.lock = {
                        id: Number(configuration.Lock.id),
                        name: configuration.Lock.name,
                    };
                }
                if (configuration.HasWarmEdge) {
                    this.glazingSpacer = {
                        id: Number(configuration.WarmEdge.id),
                        name: configuration.WarmEdge.name,
                        u: Number(configuration.WarmEdge.u),
                    };
                }
                if (
                    this.sashes.some(
                        sash =>
                            (sash.muntins && !!sash.muntins.length)
                            || sash.intSashes.some(
                                intSash => intSash.muntins && !!intSash.muntins.length
                            )
                    )
                ) {
                    this.muntins = {
                        sizeId: Number(configuration.MuntinsData.sizeId),
                        size: Number(configuration.MuntinsData.rWidth),
                        colorDraw: configuration.MuntinsData.colorDraw,
                        colorInner: configuration.MuntinsData.color
                            ? {
                                  id: Number(configuration.MuntinsData.color.id),
                                  idWin: Number(configuration.MuntinsData.color.idWin),
                                  name: configuration.MuntinsData.color.name,
                                  code: configuration.MuntinsData.color.code,
                                  color: configuration.MuntinsData.color.color,
                                  color_img: configuration.MuntinsData.color.color_img,
                                  isDefault: false,
                                  RAL: Boolean(configuration.MuntinsData.color.RAL),
                                  type: configuration.MuntinsData.color.type,
                              }
                            : null,
                        colorOuter: configuration.MuntinsData.colorOut
                            ? {
                                  id: Number(configuration.MuntinsData.colorOut.id),
                                  idWin: Number(configuration.MuntinsData.colorOut.idWin),
                                  name: configuration.MuntinsData.colorOut.name,
                                  code: configuration.MuntinsData.colorOut.code,
                                  color: configuration.MuntinsData.colorOut.color,
                                  color_img: configuration.MuntinsData.colorOut.color_img,
                                  isDefault: false,
                                  RAL: Boolean(configuration.MuntinsData.colorOut.RAL),
                                  type: configuration.MuntinsData.colorOut.type,
                              }
                            : null,
                        type: {
                            id: Number(configuration.MuntinsData.typeCode),
                            type: configuration.MuntinsData.type,
                        },
                        duplex: configuration.MuntinsData.duplex,
                    };
                }

                this.fitting = {
                    id: Number(configuration.Fitting.id),
                    name: configuration.Fitting.name,
                };

                this.accessories = configuration.Accessories.map<Accessory>(
                    accessory => new Accessory(accessory)
                );
                this.sideAccessories = {
                    top: configuration.SideAccessories.top.map<Accessory>(
                        accessory => new Accessory(accessory)
                    ),
                    bottom: configuration.SideAccessories.bottom.map<Accessory>(
                        accessory => new Accessory(accessory)
                    ),
                    left: configuration.SideAccessories.left.map<Accessory>(
                        accessory => new Accessory(accessory)
                    ),
                    right: configuration.SideAccessories.right.map<Accessory>(
                        accessory => new Accessory(accessory)
                    ),
                };

                this.rollerShutter = configuration.hasRoller
                    ? new RollerShutter(configuration.RollerShutter)
                    : null;
                this.mosquito = configuration.mosquito
                    ? new MosquitoConfiguration(configuration.mosquito)
                    : null;
                this.isMistakeProduct = configuration.IsMistakeProduct;
                this.hasWarranty = configuration.Warranty;
                this.frames = configuration.Frames;
                this.couplings = configuration.couplings;
                this.parameters = {
                    weight: configuration.Weight,
                };
                this.weldFinishType = configuration.weldFinishType;
                this.drainagePosition = configuration.drainagePosition;
                this.hasGlazingWarranty = configuration.GlazingWarranty;
                this.measurements = configuration.Measurements;
                this.oneBondedGlazing = configuration.OneBondedGlazing;
                this.issues = configuration.Issues || [];
                delete this.converters;
            } else if (WindowSmallConfiguration.is(configuration)) {
                this.price = configuration.price;
                this.quantity = configuration.quantity;
                this.title = configuration.title;
                this.height = configuration.height;
                this.width = configuration.width;

                ['frame', 'sash'].forEach(part => {
                    ['outer', 'inner', 'core', 'alushell'].forEach(side => {
                        if (
                            (configuration.colors[part][side]
                                && configuration.colors[part][side].id)
                            || side === 'core'
                        ) {
                            let color;
                            if (side === 'core') {
                                color = configuratorsDataService.get('windowColorsAll', {
                                    key: 'type',
                                    val: 'white',
                                });
                            } else {
                                color = configuratorsDataService.get(
                                    'windowColorsAll',
                                    configuration.colors[part][side].id
                                );
                            }
                            this.colors[part][side] = {
                                id: Number(color.id),
                                name: color.name,
                                code: color.code,
                                color: color.color,
                                type: color.type,
                                color_img: color.color_img,
                                RAL: Boolean(configuration.colors[part][side].isRAL),
                                isDefault: Boolean(configuration.colors[part][side].isDefault),
                            };
                        }
                    });
                });

                this.colorType = this.getColorType(this.colors) as ColorType;
                this.colorsSashExt = false;
                if (configuration.wood && configuration.wood.id) {
                    const wood = configuratorsDataService.get('woodTypes', configuration.wood.id);
                    this.wood = {
                        id: Number(wood.id),
                        name: wood.name,
                    };
                }
                this.hasAlushell = configuration.hasAlushell;

                this.shape = {
                    shape: configuration.shape.shape,
                    circuit: (configuration.shape.h1 + configuration.shape.h3) * 2,
                };
                ['width', 'height', 's1', 's2', 's3', 'h1', 'h2', 'h3', 'd'].forEach(field => {
                    if (configuration.shape[field] != null) {
                        this.shape[field] = Number(configuration.shape[field]);
                    }
                });
                if (configuration.shape.type && configuration.shape.type != null) {
                    this.shape.type = configuration.shape.type;
                }
                const sashesData = this.getSashesData(configuration.sashes);
                this.sashesType = sashesData.sashesTypes;
                this.ownedSashesTypes = {
                    window: sashesData.ownedSashesTypes.window,
                    doorActive: sashesData.ownedSashesTypes.doorActive,
                    doorPassive: sashesData.ownedSashesTypes.doorPassive,
                };
                this.sideProfiles = [];
                this.alignments = [];
                this.mullions = configuration.mullions.map<Mullion>(
                    this.mapMullionSmallConf.bind(this)
                );

                this.oneGlazing = sashesData.oneGlazing;

                this.oneHandle = sashesData.oneHandle;

                const glazingBeadColor = this.colors.sash.inner.id
                    ? this.colors.sash.inner
                    : this.colors.sash.core;
                this.glazingBeadColor = {
                    id: glazingBeadColor.id,
                    name: glazingBeadColor.name,
                    isDefault: glazingBeadColor.isDefault,
                    RAL: glazingBeadColor.RAL,
                };
                if (configuration.sealColor && configuration.sealColor.id) {
                    const sealColor = configuratorsDataService.get(
                        'windowSealColors',
                        configuration.sealColor.id
                    );
                    this.sealColor = {
                        id: Number(sealColor.id),
                        name: sealColor.name,
                        isDefault: configuration.sealColor.isDefault,
                    };
                }
                if (configuration.siliconeColor && configuration.siliconeColor.id) {
                    const siliconeColor = configuratorsDataService.get(
                        'windowSiliconeColors',
                        configuration.siliconeColor.id
                    );
                    this.siliconeColor = {
                        id: Number(siliconeColor.id),
                        name: siliconeColor.name,
                        isDefault: configuration.siliconeColor.isDefault,
                    };
                }
                this.hasHandle = sashesData.hasHandle;
                this.handleType = sashesData.handleType as HandlesType;
                if (configuration.glazingSpacer && configuration.glazingSpacer.id) {
                    const glazingSpacer = configuratorsDataService.get(
                        'windowWarmEdges',
                        configuration.glazingSpacer.id
                    );
                    if (glazingSpacer && glazingSpacer.id) {
                        this.glazingSpacer = {
                            id: Number(glazingSpacer.id),
                            name: glazingSpacer.name,
                            u: Number(glazingSpacer.u),
                        };
                    }
                }
                if (
                    configuration.sashes.some(
                        sash =>
                            (sash.muntins && sash.muntins.length > 0)
                            || (sash.intSashes
                                && sash.intSashes.some(
                                    intSash => intSash.muntins && intSash.muntins.length > 0
                                ))
                    )
                ) {
                    const muntinType = configuratorsDataService.get(
                        'windowBarTypes',
                        configuration.muntins.type.id
                    );
                    let sizeId = muntinType.sizes.find(
                        el => el.size === configuration.muntins.size
                    );
                    sizeId = sizeId ? Number(sizeId.id) : null;
                    const colorInner = configuratorsDataService.get(
                        'windowColorsAll',
                        configuration.muntins.colorInner
                    );
                    const colorOuter =
                        configuration.muntins.colorInner.id === configuration.muntins.colorOuter.id
                            ? core.copy(colorInner)
                            : configuratorsDataService.get(
                                  'windowColorsAll',
                                  configuration.muntins.colorOuter.id
                              );
                    this.muntins = {
                        sizeId,
                        size: Number(configuration.muntins.size),
                        colorDraw: '#' + colorInner.color.toUpperCase(),
                        colorInner: {
                            id: Number(colorInner.id),
                            idWin: Number(colorInner.idWin),
                            name: colorInner.name,
                            code: colorInner.code,
                            color: colorInner.color,
                            color_img: colorInner.color_img,
                            isDefault: Boolean(configuration.muntins.colorInner.isDefault),
                            RAL: Boolean(configuration.muntins.colorInner.RAL),
                            type: colorInner.type,
                        },
                        colorOuter: {
                            id: Number(colorOuter.id),
                            idWin: Number(colorOuter.idWin),
                            name: colorOuter.name,
                            code: colorOuter.code,
                            color: colorOuter.color,
                            color_img: colorOuter.color_img,
                            isDefault: Boolean(configuration.muntins.colorOuter.isDefault),
                            RAL: Boolean(configuration.muntins.colorOuter.RAL),
                            type: colorOuter.type,
                        },
                        type: {
                            id: Number(muntinType.id),
                            type: muntinType.type,
                        },
                        duplex: configuration.muntins.duplex,
                    };
                }
                this.sashes = configuration.sashes.map<Sash>(this.mapSashSmallConf.bind(this));
                const systemsIds = configuration.system.id.split('|');
                if (systemsIds.length === 1) {
                    systemsIds[1] = systemsIds[0];
                }
                const filling =
                    core.copy(
                        this.configuratorsDataService.data.fillings.find(
                            e => e.id === configuration.sashes[0].filling.id
                        )
                    )
                    || core.copy(
                        this.configuratorsDataService.data.fillings.find(
                            e => e.id.split('.')[0] === configuration.sashes[0].filling.id
                        )
                    );
                const system = configuratorsDataService.get(
                    'windowLinesAll',
                    Number(systemsIds[Number(filling.glazing_count) - 2])
                );
                const type = configuratorsDataService.get('windowLineTypes', {
                    key: 'static',
                    val: system.type,
                });
                this.name =
                    (type && type.name ? type.name.toUpperCase() + ' > ' : '') + system.name;
                this.system = {
                    id: Number(system.id),
                    imageSide: system.image_side,
                    name: system.name,
                    titleImage: system.title_image,
                    type: system.type,
                    offerInfo: system.offer_info,
                    lcd: system.lcd,
                    frameType: system.type_frame,
                };
                let fitting = configuratorsDataService.get(
                    'windowFittings',
                    configuration.fitting.id
                );
                if (!fitting) {
                    fitting = configuratorsDataService.data.windowFittings.find(
                        el =>
                            el.window_lines_ids.includes(this.system.id + '')
                            && el.system_types.includes(this.system.type)
                    );
                }
                this.fitting = {
                    id: Number(fitting.id),
                    name: fitting.name,
                };

                this.accessories = configuration.accessories
                    .map<Accessory>(accessory => new Accessory(accessory, configuratorsDataService))
                    .filter(el => el && el.id);
                this.sideAccessories = {
                    top: configuration.sideAccessories.top
                        .map<Accessory>(
                            accessory => new Accessory(accessory, configuratorsDataService)
                        )
                        .filter(el => el && el.id),
                    bottom: configuration.sideAccessories.bottom
                        .map<Accessory>(
                            accessory => new Accessory(accessory, configuratorsDataService)
                        )
                        .filter(el => el && el.id),
                    left: configuration.sideAccessories.left
                        .map<Accessory>(
                            accessory => new Accessory(accessory, configuratorsDataService)
                        )
                        .filter(el => el && el.id),
                    right: configuration.sideAccessories.right
                        .map<Accessory>(
                            accessory => new Accessory(accessory, configuratorsDataService)
                        )
                        .filter(el => el && el.id),
                };
                const newFrame = new Frame({
                    id: 0,
                    index: 1,
                    x: 0,
                    y: 0,
                    height: configuration.height,
                    width: configuration.width,
                });
                newFrame.frame = [];
                newFrame.lowThreshold = false;
                newFrame.shape = configuration.shape.shape;
                newFrame.parameters = {
                    ap: null,
                    g: null,
                    lt: null,
                    rw: null,
                    ug: null,
                    uw: null,
                    sw: null,
                    weight: null,
                    wl: null,
                    wp: null,
                    lab: null,
                };
                this.frames = [newFrame];
                delete this.converters;
            }
        }
        delete this.configuratorsDataService;
        delete this.colorsDefaultsService;
    }

    protected runConversion(configuration, dataRequiredToUpdate: any) {
        let updatedConfiguration = core.copy(configuration);

        for (let version = configuration.$version + 1; version <= this.$version; version++) {
            updatedConfiguration.$version = version;
            if (this.converters[version] && typeof this.converters[version] === 'function') {
                updatedConfiguration = this.converters[version](
                    updatedConfiguration,
                    dataRequiredToUpdate
                );
            }
        }
        (Object as any)
            .entries(updatedConfiguration)
            .forEach(([key, value]) => (this[key] = value));
        delete this.converters;
    }

    private mapSash(sash): Sash {
        const newSash: Sash = {
            id: Number(sash.id),
            index: Number(sash.index),
            frameId: Number(sash.frameId),
            x: Number(sash.rx),
            y: Number(sash.ry),
            width: Number(sash.rWidth),
            height: Number(sash.rHeight),
            muntins: sash.muntins,
            nearMullions: {
                left: Number(sash.nearMullions.left),
                right: Number(sash.nearMullions.right),
                top: Number(sash.nearMullions.top),
                bottom: Number(sash.nearMullions.bottom),
            },
            nearAlignments: {
                left: Number(sash.nearAlignments.left),
                right: Number(sash.nearAlignments.right),
                top: Number(sash.nearAlignments.top),
                bottom: Number(sash.nearAlignments.bottom),
            },
            filling: {
                id: sash.glazing.custom ? sash.glazing.id : sash.glazing.id,
                custom: sash.glazing.custom || false,
                name: sash.glazing.name,
                producer: sash.glazing.producer,
                type: sash.glazing.type,
                weight: Number(sash.glazing.weight),
                availableSecondColor: sash.glazing.available_second_color,
                color: !sash.glazing.selectedColor
                    ? null
                    : {
                          inner: sash.glazing.selectedColor.frame.inner,
                          outer: sash.glazing.selectedColor.frame.outer,
                          core: sash.glazing.selectedColor.frame.core,
                      },
                overlay_color: !sash.glazing.selectedColorSecond
                    ? null
                    : {
                          inner: sash.glazing.selectedColorSecond.frame.inner,
                          outer: sash.glazing.selectedColorSecond.frame.outer,
                          core: sash.glazing.selectedColorSecond.frame.core,
                      },
                overlay_image: sash.glazing.overlay_image,
                overlay_position_y: sash.glazing.overlay_position_y,
                overlay_position_x: sash.glazing.overlay_position_x,
                overlay_paths: sash.glazing.overlay_paths,
                overlay_width: sash.glazing.overlay_width,
                overlay_height: sash.glazing.overlay_height,
                overlay_type: sash.glazing.panel_type,
                default_colors: sash.glazing.default_colors,
                thickness_mm: sash.glazing.thickness_mm,
            },
            glazingBead: sash.glazingBead,
            bondedGlazing: Boolean(sash.bondedGlazing),
            panelGlazing: null,
            fillingSizes: sash.glazingSizes
                ? {
                      area: Number(sash.glazingSizes.area),
                      width: Number(sash.glazingSizes.width),
                      height: Number(sash.glazingSizes.height),
                      x: Number(sash.glazingSizes.x),
                      y: Number(sash.glazingSizes.y),
                  }
                : null,
            shape: sash.shape.shape,
            hardware: sash.hardware.map(accessory => new Accessory(accessory)),
            mosquito: sash.mosquito ? new MosquitoConfiguration(sash.mosquito) : null,
            weight: sash.weight,
        };

        ['u', 'rw', 'c', 'ctr', 'lt', 'g', 'psi'].forEach(field => {
            if (sash.glazing[field] != null) {
                newSash.filling[field] = Number(sash.glazing[field]);
            }
        });

        if (sash.parentId != null) {
            newSash.parentId = sash.parentId;
        } else {
            newSash.type = {
                id: Number(sash.type.id),
                type: sash.type.type,
                name: sash.type.name,
                handlePosition: sash.type.handle_position,
                passive: sash.type.passive,
            };
            newSash.intSashes = sash.intSashes.map(this.mapSash.bind(this));
            newSash.intAlignments = sash.intAlignments;
            newSash.intMullions = sash.intMullions.map(this.mapMullion.bind(this));
            newSash.intAdjacentSashes = {
                top: sash.intEdgeSashes.top
                    .map(edgeSash => Common.isObject(edgeSash) ? Number(edgeSash.id) : edgeSash),
                bottom: sash.intEdgeSashes.bottom
                    .map(edgeSash => Common.isObject(edgeSash) ? Number(edgeSash.id) : edgeSash),
                left: sash.intEdgeSashes.left
                    .map(edgeSash => Common.isObject(edgeSash) ? Number(edgeSash.id) : edgeSash),
                right: sash.intEdgeSashes.right
                    .map(edgeSash => Common.isObject(edgeSash) ? Number(edgeSash.id) : edgeSash),
            };

            if (sash.glazing.type === 'deco_panels' && sash.panelGlazing && sash.panelGlazing.id) {
                newSash.panelGlazing = {
                    id: Number(sash.panelGlazing.id),
                    custom: sash.panelGlazing.custom || false,
                    name: sash.panelGlazing.name,
                    producer: sash.panelGlazing.producer,
                    type: sash.panelGlazing.type,
                    weight: Number(sash.panelGlazing.weight),
                    availableSecondColor: sash.panelGlazing.available_second_color,
                };
                ['u', 'rw', 'c', 'ctr', 'lt', 'g', 'psi'].forEach(field => {
                    if (sash.panelGlazing[field] != null) {
                        newSash.panelGlazing[field] = Number(sash.panelGlazing[field]);
                    }
                });
                newSash.panelType = sash.panelType;
            }

            if (sash.glazing.type === 'deco_panels') {
                newSash.panelType = sash.panelType;
            }
            newSash.oneGlazing = sash.oneGlazing;
            newSash.oneGlazingBead = sash.oneGlazingBead;

            newSash.frame = sash.frame;

            if (
                ['F', 'FF', 'OFF', 'DS', 'ODS', 'DSC', 'DRP', 'DOP'].indexOf(newSash.type.type)
                === -1
            ) {
                newSash.handleInner = sash.handle
                    ? {
                        id: Number(sash.handle.id),
                        handleType: sash.handle.handle_type,
                        name: sash.handle.name,
                        color: {
                            id: Number(sash.handleColor.id),
                            isDefault: sash.handleColor.isDefault,
                            name: sash.handleColor.name,
                        },
                    }
                    : null;
                newSash.handleOuter = sash.handleOuter
                    ? {
                        id: Number(sash.handleOuter.id),
                        handleType: sash.handleOuter.handle_type,
                        name: sash.handleOuter.name,
                        color: {
                            id: Number(sash.handleOuterColor.id),
                            isDefault: sash.handleOuterColor.isDefault,
                            name: sash.handleOuterColor.name,
                        },
                    }
                    : null;
                newSash.handleHeight = Number(sash.rHandleY);
                newSash.defaultHandleHeight = sash.defaultHandleHeight;
                newSash.handleHeightType = sash.handleHeightType;
            }
        }

        return newSash;
    }

    private mapSashSmallConf(sash): Sash {
        const filling =
            core.copy(
                this.configuratorsDataService.data.fillings.find(e => e.id === sash.filling.id)
            )
            || core.copy(
                this.configuratorsDataService.data.fillings.find(
                    e => e.id.split('.')[0] === sash.filling.id
                )
            );
        const newSash: Sash = {
            id: Number(sash.id),
            index: Number(sash.index),
            frameId: 0,
            x: Number(sash.x),
            y: Number(sash.y),
            width: Number(sash.width),
            height: Number(sash.height),
            muntins: sash.muntins,
            nearMullions: {
                left: Number(sash.nearMullions.left),
                right: Number(sash.nearMullions.right),
                top: Number(sash.nearMullions.top),
                bottom: Number(sash.nearMullions.bottom),
            },
            nearAlignments: {
                left: -1,
                right: -1,
                top: -1,
                bottom: -1,
            },
            filling: {
                id: filling.id,
                custom: false,
                name: filling.name,
                producer: filling.producer,
                type: filling.type,
                weight: Number(filling.weight),
                color: null,
                overlay_color: null,
                overlay_image: null,
                overlay_position_y: null,
                overlay_position_x: null,
                overlay_paths: null,
                overlay_width: null,
                overlay_height: null,
                overlay_type: null,
                default_colors: null,
                availableSecondColor: filling.available_second_color,
            },
            glazingBead: null,
            panelGlazing: null,
            fillingSizes: null,
            shape: this.shape.shape,
            hardware: sash.hardware.map(
                accessory => new Accessory(accessory, this.configuratorsDataService)
            ),
            bondedGlazing: null,
            weight: 0,
        };

        if (newSash.muntins && Common.isObject(sash.muntins) && !Common.isArray(newSash.muntins)) {
            muntinsConversion(newSash);
        }

        if (sash.parentId != null) {
            newSash.parentId = sash.parentId;
        } else {
            let sashType;
            if (sash.type.id) {
                sashType = this.configuratorsDataService.get('sashTypes', sash.type.id);
            } else {
                sashType = this.configuratorsDataService.data.sashTypes.find(
                    el =>
                        el.type === sash.type.type
                        && (!sash.type.handlePosition
                            || sash.type.handlePosition === el.handle_position)
                );
            }
            newSash.type = {
                id: Number(sashType.id),
                type: sashType.type,
                name: sashType.type,
                handlePosition: sash.type.handlePosition,
                passive: sashType.passive,
            };
            newSash.intSashes = sash.intSashes.map(this.mapSashSmallConf.bind(this));
            newSash.intAlignments = [];
            newSash.intMullions = sash.intMullions.map(this.mapMullion.bind(this));
            newSash.intAdjacentSashes = this.getAdjacentSashes(sash);

            newSash.oneGlazing = sash.oneGlazing;
            newSash.oneGlazingBead = sash.oneGlazingBead;

            newSash.mosquito = null;

            newSash.frame = sash.frame || null;

            if (['F', 'FF', 'OFF', 'DS', 'DSC', 'DRP'].indexOf(newSash.type.type) === -1) {
                const handleInner = this.configuratorsDataService.get(
                    'windowHandlers',
                    sash.handleInner.id
                );
                let handleInnerColor;
                if (sash.handleInner.color.id > 0) {
                    handleInnerColor = this.configuratorsDataService.get(
                        'windowHandlesColors',
                        sash.handleInner.color.id
                    );
                } else {
                    handleInnerColor = this.configuratorsDataService.data.windowHandlesColors.find(
                        el => el.color === sash.handleInner.color.color
                    );
                }
                const handleOuter = sash.handleOuter
                    ? this.configuratorsDataService.get('windowHandlers', sash.handleOuter.id)
                    : handleInner;
                let handleOuterColor;
                if (sash.handleOuter && sash.handleOuter.color.id > 0) {
                    handleOuterColor = sash.handleOuter
                        ? this.configuratorsDataService.get(
                              'windowHandlesColors',
                              sash.handleOuter.color.id
                          )
                        : handleInnerColor;
                } else if (sash.handleOuter && sash.handleOuter.color.id < 0) {
                    handleOuterColor = this.configuratorsDataService.data.windowHandlesColors.find(
                        el => el.color === sash.handleOuter.color.color
                    );
                } else {
                    handleOuterColor = handleInnerColor;
                }
                newSash.handleInner = {
                    id: Number(handleInner.id),
                    handleType: handleInner.handle_type,
                    name: handleInner.name,
                    color: {
                        id: Number(handleInnerColor.id),
                        isDefault: sash.handleInner.color.isDefault,
                        name: handleInnerColor.name,
                    },
                };
                newSash.handleOuter = {
                    id: Number(handleOuter.id),
                    handleType: handleOuter.handle_type,
                    name: handleOuter.name,
                    color: {
                        id: Number(handleOuterColor.id),
                        isDefault: sash.handleOuter
                            ? sash.handleOuter.color.isDefault
                            : sash.handleInner.color.isDefault,
                        name: handleOuterColor.name,
                    },
                };
            }
        }

        return newSash;
    }

    private getSashesData(sashes) {
        let allFix = true;
        let allFixSash = true;
        let allFunc = true;
        let sashesTypes: SashesType = 'Func';
        const ownedSashesTypes = {
            doorActive: false,
            doorPassive: false,
            window: false,
        };
        let oneGlazing = true;
        let oneHandle = true;
        let hasHandle = false;
        let handleType = null;
        let fillingId;
        let handleId;

        sashes.forEach(sash => {
            let sashType;
            if (sash.type.id) {
                sashType = this.configuratorsDataService.get('sashTypes', sash.type.id);
            } else {
                sashType = this.configuratorsDataService.data.sashTypes.find(
                    el =>
                        el.type === sash.type.type
                        && (!sash.type.handlePosition
                            || sash.type.handlePosition === el.handle_position)
                );
            }
            if (sashType.type === 'F' || sashType.type === 'FF' || sashType.type === 'OFF') {
                allFunc = false;
                if (sashType.type === 'F') {
                    allFixSash = false;
                } else {
                    allFix = false;
                }
            } else {
                allFix = false;
                allFixSash = false;
            }

            if (sashType.type === 'DRA') {
                ownedSashesTypes.doorActive = true;
            } else if (sashType.type === 'DRP') {
                ownedSashesTypes.doorPassive = true;
            } else if (sashType.type !== 'F' && sashType.type !== 'FF' && sashType.type !== 'OFF') {
                ownedSashesTypes.window = true;
            }

            if (sash.handleInner && sash.handleInner.id) {
                if (!handleId) {
                    handleId = Number(sash.handleInner.id);
                    const handle = this.configuratorsDataService.get('windowAccessories', handleId);
                    handleType = handle.handle_type;
                }
                hasHandle = true;
                oneHandle = oneHandle && handleId === Number(sash.handleInner.id);
            }

            if (sash.intSashes && sash.intSashes.length > 1) {
                sash.intSashes.forEach(intSash => {
                    if (!fillingId) {
                        fillingId = Number(intSash.filling.id);
                    }
                    oneGlazing = oneGlazing && fillingId === Number(intSash.filling.id);
                });
            } else {
                if (!fillingId) {
                    fillingId = Number(sash.filling.id);
                }
                oneGlazing = oneGlazing && fillingId === Number(sash.filling.id);
            }
        });

        if (allFix) {
            sashesTypes = 'Fix';
        } else if (allFixSash) {
            sashesTypes = 'FixSash';
        } else if (allFunc) {
            sashesTypes = 'Func';
        } else {
            sashesTypes = 'Mix';
        }

        return {
            sashesTypes,
            ownedSashesTypes,
            oneGlazing,
            oneHandle,
            hasHandle,
            handleType,
        };
    }

    private getAdjacentSashes(sash) {
        const adjacentSashes = {
            top: [],
            bottom: [],
            left: [],
            right: [],
        };

        sash.intSashes.forEach(intSash => {
            ['top', 'right', 'bottom', 'left'].forEach(side => {
                if (intSash.nearMullions[side] && intSash.nearMullions[side] === -1) {
                    adjacentSashes[side].push(intSash.id);
                }
            });
        });

        return adjacentSashes;
    }

    private getColorType(colors) {
        let colorType = 'White';
        const outer = this.colorsDefaultsService.getColorValue(colors, 'outer');
        const inner = this.colorsDefaultsService.getColorValue(colors, 'inner');
        // const core = this.colorsDefaultsService.getColorValue(colors, 'core');

        if (
            this.colorsDefaultsService.getDefaultColorType('White', 'outer') === outer
            && this.colorsDefaultsService.getDefaultColorType('White', 'inner') === inner
            // && this.colorsDefaultsService.getDefaultColorType('White', 'core') == core
        ) {
            colorType = 'White';
        } else if (
            this.colorsDefaultsService.getDefaultColorType('Cream', 'outer') === outer
            && this.colorsDefaultsService.getDefaultColorType('Cream', 'inner') === inner
            // && this.colorsDefaultsService.getDefaultColorType('Cream', 'core') == core
        ) {
            colorType = 'Cream';
        } else if (
            this.colorsDefaultsService.getDefaultColorType('Outer', 'outer') === outer
            && this.colorsDefaultsService.getDefaultColorType('Outer', 'inner') === inner
            // && this.colorsDefaultsService.getDefaultColorType('Outer', 'core') == core
        ) {
            colorType = 'Outer';
        } else if (
            this.colorsDefaultsService.getDefaultColorType('Inner', 'outer') === outer
            && this.colorsDefaultsService.getDefaultColorType('Inner', 'inner') === inner
            // && this.colorsDefaultsService.getDefaultColorType('Inner', 'core') == core
        ) {
            colorType = 'Inner';
        } else if (
            ((this.colorsDefaultsService.getDefaultColorType('Bilateral', 'outer') === outer
                && this.colorsDefaultsService.getDefaultColorType('Bilateral', 'inner') === inner)
                || outer === inner)
            && colors.frame.outer.id === colors.frame.inner.id
        ) {
            colorType = 'Bilateral';
        } else {
            colorType = 'Bicolor';
        }

        return colorType;
    }

    private mapMullion(mullion): Mullion {
        const newMullion: Mullion = {
            id: Number(mullion.id),
            isDefault: mullion.isDefault,
            frameId: mullion.frameId,
            profileId: Number(mullion.profileId),
            position: Number(mullion.direction === 'horizontal' ? mullion.ry : mullion.rx),
            length: Number(mullion.direction === 'horizontal' ? mullion.rWidth : mullion.rHeight),
            shift: Number(mullion.shift),
            direction: mullion.direction,
            type: mullion.type,
            adjacentSashes: {},
            parallelAlignments: {
                top: [],
                bottom: [],
                left: [],
                right: [],
            },
            perpendicularAlignments: {
                top: [],
                bottom: [],
                left: [],
                right: [],
            },
            parallelMullions: {},
            perpendicularMullions: {},
            reinforcement: mullion.reinforcement,
        };
        if (newMullion.direction === 'horizontal') {
            newMullion.adjacentSashes = {
                top: mullion.multiAlignTop
                    .filter(e => Common.isObject(e))
                    .map(sash => Number(sash.id)),
                bottom: mullion.multiAlignBottom
                    .filter(e => Common.isObject(e))
                    .map(sash => Number(sash.id)),
            };
            newMullion.perpendicularMullions = {
                top: mullion.multiAlignTopDiv
                    .filter(e => Common.isObject(e))
                    .map(div => Number(div.id)),
                bottom: mullion.multiAlignBottomDiv
                    .filter(e => Common.isObject(e))
                    .map(div => Number(div.id)),
            };
            newMullion.parallelAlignments = {
                top: mullion.parallelAlignments.top || [],
                bottom: mullion.parallelAlignments.bottom || [],
            };
            newMullion.perpendicularAlignments = {
                top: mullion.perpendicularAlignments.top || [],
                bottom: mullion.perpendicularAlignments.bottom || [],
            };
        } else {
            newMullion.adjacentSashes = {
                left: mullion.multiAlignLeft
                    .filter(e => Common.isObject(e))
                    .map(sash => Number(sash.id)),
                right: mullion.multiAlignRight
                    .filter(e => Common.isObject(e))
                    .map(sash => Number(sash.id)),
            };
            newMullion.perpendicularMullions = {
                left: mullion.multiAlignLeftDiv
                    .filter(e => Common.isObject(e))
                    .map(div => Number(div.id)),
                right: mullion.multiAlignRightDiv
                    .filter(e => Common.isObject(e))
                    .map(div => Number(div.id)),
            };
            newMullion.parallelAlignments = {
                left: mullion.parallelAlignments.left || [],
                right: mullion.parallelAlignments.right || [],
            };
            newMullion.perpendicularAlignments = {
                left: mullion.perpendicularAlignments.left || [],
                right: mullion.perpendicularAlignments.right || [],
            };
        }
        return newMullion;
    }

    private mapMullionSmallConf(mullion): Mullion {
        const newMullion: Mullion = {
            id: Number(mullion.id),
            frameId: 0,
            isDefault: true,
            profileId: Number(mullion.dividerId),
            position: Number(mullion.position),
            length: Number(mullion.length),
            shift: 0,
            direction: mullion.direction,
            adjacentSashes: mullion.adjacentSashes || {},
            parallelAlignments: {},
            perpendicularAlignments: {},
            parallelMullions: {},
            perpendicularMullions: mullion.perpendicularMullions || {},
            reinforcement: null,
            type: null,
        };
        return newMullion;
    }
}
