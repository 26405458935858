import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import SystemsComparisonService from './systems-comparison.service';
import { SystemsComparisonModalComponent } from './systems-comparison-modal/systems-comparison-modal.component';
import { SystemsComparisonDetailsModalComponent } from './systems-comparison-details-modal/systems-comparison-details-modal.component';
import { SystemsSelectModalComponent } from './systems-select-modal/systems-select-modal.component';
import { PositionsGroupsModalComponent } from './positions-groups-modal/positions-groups-modal.component';
import { OptionsModalComponent } from './options-modal/options-modal.component';

export default angular
    .module('icc.configurator.steps.window.systems-comparison', [])
    .component('systemsComparisonModal', SystemsComparisonModalComponent)
    .component('systemsComparisonDetailsModal', SystemsComparisonDetailsModalComponent)
    .component('systemsSelectModal', SystemsSelectModalComponent)
    .component('positionsGroupsModal', PositionsGroupsModalComponent)
    .component('optionsModal', OptionsModalComponent)
    .factory('SystemsComparisonService', downgradeInjectable(SystemsComparisonService))
    .name;