import { core } from '../helpers';
import Common from '../Common';
import Position from './Position';
import OfferGroupCodeService from './OfferGroupCodeService';
import OfferSequenceService from './OfferSequenceService';

export default class PositionService {
    static getCopied(positions, source, destination, skip, IccConfig, dealer, allDiscounts) {
        const relations = {};
        skip = Common.isArray(skip) ? skip : [];
        const newPositions = positions
            .filter(pos => pos && skip.indexOf(pos.confType) === -1)
            .map(pos => {
                const originalId = pos.tmp_id || pos._id;
                const params = Common.extend(pos, {
                    dealer_offer_id: destination,
                    id: null,
                    tmp_id: null,
                    created: null,
                });

                const groupCode = OfferGroupCodeService.generateGroupCode(params, IccConfig);
                params.configuration = core.parseJson(params.configuration);
                const newPosition = new Position(
                    Common.extend(params, {
                        groupCode,
                        offer: source,
                    }),
                    IccConfig,
                    dealer,
                    allDiscounts
                );
                newPosition.configuration = core.stringJson(newPosition.configuration);
                relations[originalId] = newPosition.id;
                return newPosition;
            });
        newPositions
            .filter(position => position.confType === 'coupled_window')
            .forEach(position => {
                position.details = core.parseJson(position.details);
                position.details.windows = position.details.windows.map(window => {
                    window.positionId = relations[window.positionId];
                    return window;
                });
                position.details.rollerShutters = position.details.rollerShutters.map(
                    rollerShutter => {
                        rollerShutter.positionId = relations[rollerShutter.positionId];
                        return rollerShutter;
                    }
                );
                position.details.couplings = position.details.couplings.map(coupling => {
                    coupling.framesId.forEach(rel => {
                        rel.positionId = relations[rel.positionId];
                    });
                    coupling.otherFramesId.forEach(rel => {
                        rel.positionId = relations[rel.positionId];
                    });
                    return coupling;
                });
                position.details.sideProfiles = position.details.sideProfiles.map(sideprofile => {
                    sideprofile.framesId.forEach(rel => {
                        rel.positionId = relations[rel.positionId];
                    });
                    return sideprofile;
                });
                position.details.windowSills = position.details.windowSills.map(sill => {
                    sill.framesId.forEach(rel => {
                        rel.positionId = relations[rel.positionId];
                    });
                    return sill;
                });
                position.details = core.stringJson(position.details);

                position.configuration = core.parseJson(position.configuration);
                position.configuration.windows = position.configuration.windows.map(window => {
                    window.positionId = relations[window.positionId];
                    return window;
                });
                position.configuration.rollerShutters = position.configuration.rollerShutters.map(
                    rollerShutter => {
                        rollerShutter.positionId = relations[rollerShutter.positionId];
                        return rollerShutter;
                    }
                );
                position.configuration.couplings = position.configuration.couplings.map(coupling => {
                    coupling.framesId.forEach(rel => {
                        rel.positionId = relations[rel.positionId];
                    });
                    coupling.otherFramesId.forEach(rel => {
                        rel.positionId = relations[rel.positionId];
                    });
                    return coupling;
                });
                position.configuration.sideProfiles = position.configuration.sideProfiles.map(sideprofile => {
                    sideprofile.framesId.forEach(rel => {
                        rel.positionId = relations[rel.positionId];
                    });
                    return sideprofile;
                });
                position.configuration.windowSills = position.configuration.windowSills.map(sill => {
                    sill.framesId.forEach(rel => {
                        rel.positionId = relations[rel.positionId];
                    });
                    return sill;
                });
                position.configuration = core.stringJson(position.configuration);
            });

        newPositions
            .filter(position => position.coupled_position_id)
            .forEach(position => {
                position.coupled_position_id = relations[position.coupled_position_id];
            });

        const newSequence = newPositions.reduce(
            (prevSequence, pos) =>
                OfferSequenceService.updateOfferSequence(
                    prevSequence,
                    [{
                        id: pos.tmp_id,
                        groupCode: pos.groupCode,
                    }],
                    'add',
                    IccConfig
                ),
            []
        );

        return {
            newPositions,
            newSequence,
            relations,
        };
    }

    static getDimensions(position, IccConfig) {
        const constructionData =
            position.configuration && position.configuration.drawData
            ? (position.configuration.type === 'coupled_window'
               || position.configuration.type === 'roller_shutter'
               && IccConfig.Configurators.roller_shutter.sumRollerAndBoxHeight)
                  && position.configuration.drawData.base.find(b => !b.positionId)
                    ? position.configuration.drawData.base.find(b => !b.positionId)
                    : position.configuration.drawData.reno
                      && position.configuration.drawData.reno[0]
                    ? position.configuration.drawData.reno[0]
                    : position.configuration.drawData.shape
                      && position.configuration.drawData.shape[0]
                    ? position.configuration.drawData.shape[0]
                    : null
                : null;

        const area =
            constructionData && constructionData.polyArea
                ? constructionData.polyArea
                : position.configuration.Width && position.configuration.Height
                ? (position.configuration.Width * position.configuration.Height) / 1e6
                : 0;

        const circuit =
            constructionData && constructionData.polyEdge
                ? constructionData.polyEdge * 1e3
                : position.configuration.Shape && position.configuration.Shape.circuit
                ? position.configuration.Shape.circuit
                : 0;

        const size =
            constructionData && constructionData.rect
                ? `${constructionData.rect.width}x${constructionData.rect.height}`
                : position.configuration.Width && position.configuration.Height
                ? `${position.configuration.Width}x${position.configuration.Height}`
                : ``;

        let glazingArea = 0;

        if (position.configuration.Sashes) {
            position.configuration.Sashes.forEach(sash => {
                glazingArea += parseFloat(sash.glazingSizes.area || 0);

                sash.intSashes.forEach(intSash => {
                    glazingArea += parseFloat(intSash.glazingSizes.area || 0);
                });
            });
        }

        return { area, glazingArea, circuit, size };
    }

    /**
     * Parsuje wartości pozycji przed zwroceniem
     * @param  {object} offer Obiekt pozycji
     * @return {object}       Obiekt sparsowany
     */
    static parsePositionValues(position) {
        return Common.extend(position, {
            configuration: core.parseJson(position.configuration),
            dealer_price: parseFloat(position.dealer_price),
            dealer_price_before_discount: parseFloat(position.dealer_price_before_discount),
            client_price: parseFloat(position.client_price),
            client_price_before_discount: parseFloat(position.client_price_before_discount),
            group_discounts: core.parseJson(position.group_discounts),
            circuit: parseFloat(position.circuit),
            area: parseFloat(position.area),
            glazing_area: parseFloat(position.glazing_area),
            weight: parseFloat(position.weight),
            quantity: parseFloat(position.quantity),
        });
    }
}
