import { Injectable, Inject } from '@angular/core';
import { ConfiguratorsDataService } from 'configurators/configurators-data.service';
import ConfigurationsService from 'configurations/configurations.service';
import { core } from 'helpers';
import PriceService from 'price/price.service';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import StateService from 'state.service';
import Common from 'Common';
import PositionsDetailsService from '../../../../../../common/PositionsDetailsService';
import GlazingSizesService from '../../../../../../common/configurators/glazing-sizes.service';
import { DrawService } from '../../../../../../common/configurators/draw.service';
import { FillingsService } from '../glazings/fillings.service';
import OfferGroupCodeService from 'offers/OfferGroupCodeService';
import { APP_CONFIG } from 'config';
import { EventBusService } from 'event-bus.service';
import WindowConfiguration from 'configurations/WindowConfiguration';
import { Location } from '@angular/common';
import OfferGroupService from '../../../../panel/offer-group.service';
import BrowserShapeService from 'configurator/layout/shape.service';
import { SashesLayoutService } from 'configurator/layout/sashes-layout.service';

@Injectable()
export default class SystemsComparisonService {
    systems;
    edit: { value: boolean } = { value: false };
    editId: number | null = null;
    drawOptions = {};
    onOffer = false;
    inSystemsComparison: { value: boolean } = { value: false};
    groups;
    originalGroups;
    option;

    private key = '';
    private objectId = 'id';
    private objectIds = {
        'mulions.astragalFrame': 'profileId',
        'mulions.movable': 'profileId',
        'mulions.movableCentral': 'profileId',
        'mulions.movableDouble': 'profileId',
        'mulions.static': 'profileId',
        'mulions.intMullions': 'profileId',
        'mulions.intMullions.astragalFrame': 'profileId',
        'mulions.intMullions.movable': 'profileId',
        'mulions.intMullions.movableCentral': 'profileId',
        'mulions.intMullions.movableDouble': 'profileId',
        'mulions.intMullions.static': 'profileId',
        'frames.frames': 'profileId',
        'couplings.couplings': 'couplingId',
        sashFrames: 'profileId',
        'sashFrames.sashes': 'profileId',
        'sashFrames.sashes.profiles': 'profileId',
    };
    private originalConfig: any = {};
    private configs = [];
    private changes: any = {};

    constructor(
        @Inject('$uibModal') private $uibModal: ng.ui.bootstrap.IModalService,
        @Inject('SystemsFactory') private systemsFactory,
        @Inject('ColorsFactory') private colorsFactory,
        @Inject('EditPositionFactory') private editPositionFactory,
        @Inject('OffersFactory') private offersFactory,
        @Inject('InfoFactory') private infoFactory,
        @Inject('$rootScope') private $rootScope,
        @Inject('$translate') private $translate: ng.translate.ITranslateService,
        @Inject(APP_CONFIG) protected config,
        private configuratorsDataService: ConfiguratorsDataService,
        private sashesLayoutService: SashesLayoutService,
        private configurationsService: ConfigurationsService,
        private priceService: PriceService,
        private stateService: StateService,
        private glazingSizesService: GlazingSizesService,
        private drawService: DrawService,
        private eventBusService: EventBusService,
        private fillingsService: FillingsService,
        private location: Location,
        private offerGroupService: OfferGroupService,
        private shapeService: BrowserShapeService,
    ) {}

    async systemsComparison() {
        this.onOffer = false;
        const systems = await this.openSystemsSelectModal(
            (this.configurationsService.conf.Current as WindowActiveConfiguration).System.id,
            (this.configurationsService.conf.Current as WindowActiveConfiguration).type,
            true
        );
        if (systems && systems.length) {
            this.$rootScope.loader = true;
            setTimeout(async () => {
                this.configs = this.calculateSystems(systems);
                this.$rootScope.loader = false;
                const data = await this.openSystemsComparisonModal();
                this.saveOrEditConfig(data);
            });
        }
    }

    saveOrEditConfig(data) {
        if (data.config) {
            this.configurationsService.conf.Current = Object.assign(
                this.configurationsService.conf.Current,
                core.copy(data.config.conf)
            );
            this.configurationsService.conf.Default = Object.assign(
                this.configurationsService.conf.Default,
                core.copy(data.config.defaultConf)
            );
            this.priceService.count();
            if (data.edit) {
                this.edit.value = true;
                this.editId = data.config.id;
            }
        }
    }

    openSystemsComparisonModal() {
        const modalOptions: ng.ui.bootstrap.IModalSettings = {
            component: 'systemsComparisonModal',
            resolve: {
                conf: () => this.configurationsService.conf.Current,
                currency: () => this.stateService.state.offers[0].doc.currency,
                configs: () => this.configs,
                onOffer: () => this.onOffer,
                drawOptions: () => this.drawOptions,
            },
        };
        const modalInstance = this.$uibModal.open(modalOptions);

        modalInstance.closed.then(() => {
            if (!this.edit.value) {
                this.configs = [];
            }
        });

        return modalInstance.result;
    }

    openSystemsComparisonDetailsModal(
        config,
        currentConfig = this.configurationsService.conf.Current,
        allowEdit = true
    ) {
        const modalOptions: ng.ui.bootstrap.IModalSettings = {
            component: 'systemsComparisonDetailsModal',
            resolve: {
                config: () => config,
                currentConfig: () => currentConfig,
                currency: () => this.stateService.state.offers[0].doc.currency,
                onOffer: () => this.onOffer,
                drawOptions: () => this.drawOptions,
                allowEdit: () => allowEdit,
            },
        };
        const modalInstance = this.$uibModal.open(modalOptions);

        return modalInstance.result;
    }

    calculateSystems(
        systems,
        configuration = this.configurationsService.conf.Current,
        defaultConfiguration = this.configurationsService.conf.Default,
        resetConfigs = true,
        positionId = '',
        positionIndex = 0,
    ): {
        id: number;
        conf: WindowActiveConfiguration;
        defaultConf: WindowActiveConfiguration;
        details: WindowConfiguration;
        detailsForView: any;
        positionId?: string;
        positionIndex?: number;
    }[] {
        if (resetConfigs) {
            this.configs = [];
        }
        let conf = core.copy(configuration) as WindowActiveConfiguration;
        let defaultConf = core.copy(defaultConfiguration) as WindowActiveConfiguration;

        this.originalConfig = { conf, defaultConf, details: {}, detailsForView: {} };
        this.getDetails(this.originalConfig, false);

        for (let i = 0; i < systems.length; i++) {
            const id = this.configs.length ? this.configs[this.configs.length - 1].id + 1 : 0;
            const system = this.systems.find(el => el.id == systems[i]);
            const systemType = this.configuratorsDataService.data.windowLineTypes.find(
                el => el.static === system.type
            );
            conf = core.copy(configuration) as WindowActiveConfiguration;
            defaultConf = core.copy(defaultConfiguration) as WindowActiveConfiguration;
            conf.systemComparison = true;
            this.sashesLayoutService.rebuildRefs(conf);

            this.systemsFactory.selectSystem(conf, defaultConf, system, systemType);
            const config: {
                id: number;
                conf: WindowActiveConfiguration;
                defaultConf: WindowActiveConfiguration;
                details: WindowConfiguration | {};
                detailsForView: any;
                positionId?: string;
                positionIndex?: number;
            } = { id, conf, defaultConf, details: {}, detailsForView: {} };
            if (positionId.length) {
                config.positionId = positionId;
            }
            if (positionIndex) {
                config.positionIndex = positionIndex;
            }
            this.getDetails(config);

            this.configs.push(config);
        }

        return this.configs;
    }

    getDetails(config, getChanges = true) {
        let details;
        let position;
        if (getChanges) {
            this.priceService.count(config.conf);
            details = this.configurationsService.createSimpleConfiguration(config.conf);
            config.conf.drawData = this.drawService.getData(details);
            if (config.conf.type !== 'coupled_window') {
                this.glazingSizesService.count(config.conf);
            }
            this.priceService.count(config.conf);
        }
        details = this.configurationsService.createSimpleConfiguration(config.conf);
        details.timestamp = Date.now();
        position = {
            configuration: config.conf,
            details,
            summary: {
                dealer: {
                    components: {},
                },
                client: {
                    components: {},
                },
            },
            group_discounts: {},
            listNumber: config.positionIndex,
            confType: details.type
        };
        const positions = new PositionsDetailsService(
            IccConfig,
            [position],
            this.stateService.getCurrentOffer(),
            this.$rootScope.user.access,
            true,
            this.$rootScope.user.access,
            this.$translate
        );
        const detailsForView = positions[0].detailsForView;

        if (getChanges) {
            this.changes = [];
            if (!this.onOffer) {
                detailsForView.allWidth = true;
            }
            this.extract(detailsForView, this.originalConfig.detailsForView);
        }

        config.details = Object.assign(config.details, details);
        config.detailsForView = Object.assign(config.detailsForView, detailsForView);
        config.changes = core.copy(this.changes);
    }

    extract(elems, currentConfElems, path = '', id = null) {
        for (const k in elems) {
            if (['prices', 'system'].indexOf(k) === -1) {
                if (Common.isArray(elems[k])) {
                    for (let i = 0; i < elems[k].length; i++) {
                        if (path === 'Sashes' || path === 'Frames') {
                            id = i;
                        }
                        if (Common.isObject(elems[k][i])) {
                            this.key = path ? path + '.' + k : k;
                            this.objectId = Common.isDefined(this.objectIds[this.key])
                                ? this.objectIds[this.key]
                                : 'id';
                            if (
                                elems[k][i][this.objectId] != null
                                && (!currentConfElems
                                    || !currentConfElems[k]
                                    || !currentConfElems[k][i]
                                    || !currentConfElems[k][i][this.objectId]
                                    || elems[k][i][this.objectId]
                                        !== currentConfElems[k][i][this.objectId])
                            ) {
                                const currentConfigId =
                                    currentConfElems
                                    && currentConfElems[k]
                                    && currentConfElems[k][i]
                                    && Common.isDefined(currentConfElems[k][i][this.objectId])
                                        ? currentConfElems[k][i][this.objectId]
                                        : null;
                                const currentConfigName =
                                    currentConfElems
                                    && currentConfElems[k]
                                    && currentConfElems[k][i]
                                    && Common.isDefined(currentConfElems[k][i][this.objectId])
                                        ? currentConfElems[k][i].name
                                        : null;
                                elems[k][i].changed = true;
                                elems[k][i].currentConfigId = currentConfigId;
                                elems[k][i].currentConfigName = currentConfigName;
                                this.changes.push({
                                    key: this.key,
                                    id: {
                                        before: currentConfigId,
                                        after: elems[k][i][this.objectId],
                                    },
                                    name: {
                                        before: currentConfigName,
                                        after: elems[k][i].name,
                                    },
                                });
                            }
                            this.extract(
                                elems[k][i],
                                currentConfElems
                                    && Common.isDefined(currentConfElems[k])
                                    && Common.isDefined(currentConfElems[k][i])
                                    ? currentConfElems[k][i]
                                    : null,
                                this.key,
                                id
                            );
                        }
                    }
                } else if (Common.isObject(elems[k])) {
                    this.key = path ? path + '.' + k : k;
                    this.objectId = Common.isDefined(this.objectIds[this.key])
                        ? this.objectIds[this.key]
                        : 'id';
                    if (
                        elems[k][this.objectId] != null
                        && (!currentConfElems
                            || !currentConfElems[k]
                            || !currentConfElems[k][this.objectId]
                            || elems[k][this.objectId] !== currentConfElems[k][this.objectId])
                    ) {
                        const currentConfigId =
                            currentConfElems
                            && currentConfElems[k]
                            && Common.isDefined(currentConfElems[k][this.objectId])
                                ? currentConfElems[k][this.objectId]
                                : null;
                        const currentConfigName =
                            currentConfElems
                            && currentConfElems[k]
                            && Common.isDefined(currentConfElems[k][this.objectId])
                                ? currentConfElems[k].name
                                : null;
                        elems[k].changed = true;
                        elems[k].currentConfigId = currentConfigId;
                        elems[k].currentConfigName = currentConfigName;
                        this.changes.push({
                            key: this.key,
                            id: {
                                before: currentConfigId,
                                after: elems[k][this.objectId],
                            },
                            name: {
                                before: currentConfigName,
                                after: elems[k].name,
                            },
                        });
                    }
                    this.extract(
                        elems[k],
                        currentConfElems && Common.isDefined(currentConfElems[k])
                            ? currentConfElems[k]
                            : null,
                        this.key,
                        id
                    );
                }
            }
        }
    }

    saveConfig(save = true) {
        if (this.onOffer) {
            this.location.go('/app/offers_view/' + this.stateService.state.offer_id);
        }
        if (save) {
            let config: any = {
                details: {},
                detailsForView: {}
            };
            if (!this.onOffer) {
                config = this.configs.find(el => el.id === this.editId);
            }
            config.conf = core.copy(this.configurationsService.conf.Current);
            config.defaultConf = core.copy(this.configurationsService.conf.Default);
            this.getDetails(config);
            if (this.onOffer) {
                for (const groupCode in this.groups) {
                    if (this.groups.hasOwnProperty(groupCode)) {
                        let price = 0;
                        for (let i = 0; i < this.groups[groupCode].rows.length; i++) {
                            if (this.groups[groupCode].rows[i].id === this.editId) {
                                Object.assign(
                                    this.groups[groupCode].rows[i].doc,
                                    {
                                        configuration: core.copy(config.conf),
                                        details: core.copy(config.details),
                                        detailsForView: core.copy(config.detailsForView),
                                    }
                                );
                            }
                            price += this.groups[groupCode].rows[i].doc.details.price;
                        }
                        this.groups[groupCode].price = price;
                    }
                }
                this.fixCoupledWindow(this.groups, config, this.editId);
            }
        }
        this.configurationsService.conf.Current = Object.assign(
            this.configurationsService.conf.Current,
            core.copy(this.originalConfig.conf)
        );
        this.configurationsService.conf.Default = Object.assign(
            this.configurationsService.conf.Default,
            core.copy(this.originalConfig.defaultConf)
        );
        delete (this.configurationsService.conf.Current as WindowActiveConfiguration)
            .systemComparison;
        this.edit.value = false;
        this.editId = null;
        if (!this.onOffer) {
            this.openSystemsComparisonModal();
        }
    }

    changeFilling(config, sashId = null, intSashId = null) {
        let sash;
        let intSash;
        config.conf.valid.frameProfiles = true;
        config.conf.valid.sashesProfiles = true;
        config.conf.valid.loadedFillings = true;
        config.conf.valid.loadedGlazingBeads = true;
        config.conf.valid.sashes = true;
        if (sashId !== null) {
            sash = config.conf.Sashes.find(el => el.id === sashId);
            if (intSashId !== null) {
                intSash = sash.intSashes.find(el => el.id === intSashId);
            }
        }
        this.fillingsService.loadMatchingFillings(config.conf);
        this.fillingsService.openModal(
            intSash ? intSash : sash ? sash : 'default',
            config.conf,
            config.defaultConf,
            sash ? sash : undefined,
            () => {
                this.getDetails(config);
                this.fillingsService.loadMatchingFillings(this.originalConfig.conf);
            }
        );
    }

    changeColor(config) {
        this.colorsFactory.loadColorsBySystem(
            color => color && color.systems && color.systems.indexOf(config.conf.System.id) > -1,
            config.conf
        );
        const modalInstance = this.$uibModal.open({
            templateUrl: require('ngtemplate-loader!configurator/steps/complementary_goods/colors/modal.html'),
            controller: 'ModalSystemComparisonColorsCtrl as $ctrl',
            resolve: {
                config: () => config.conf,
                configDefault: () => config.defaultConf,
            },
        });

        return modalInstance.result;
    }

    async systemsComparisonInOffer(groups, groupKey, drawOptions) {
        this.onOffer = true;
        this.drawOptions = drawOptions;
        this.configs = [];
        const positions = groups[groupKey].rows;
        let system;
        let confData = {
            nextPosition: false
        };
        if (positions) {
            this.option = await this.openOptionsModal();
            if (!this.option) {
                return false;
            }
            if (this.option === 'system') {
                system = await this.openSystemsSelectModal(
                    positions[0].doc.configuration.System.id,
                    positions[0].doc.confType,
                    false
                );
            }
            if (system || this.option !== 'system') {
                const firstPositionConf = this.configurationsService.createActiveConfiguration(positions[0].doc.details, this.configuratorsDataService);
                firstPositionConf.drawData = positions[0].doc.configuration.drawData;
                this.shapeService.setShapes(firstPositionConf);
                this.configurationsService.conf.Current = core.copy(firstPositionConf);
                this.configurationsService.conf.Default = core.copy(firstPositionConf);
                this.eventBusService.setCurrentConfiguration(this.configurationsService.conf);
                if (this.option === 'system') {
                    this.calculateSystems(
                        [system.id],
                        this.configurationsService.conf.Current,
                        this.configurationsService.conf.Default,
                        false,
                        positions[0].doc.tmp_id,
                        positions[0].doc.listNumber
                    );
                    confData = await this.openSystemsComparisonDetailsModal(
                        this.configs[0],
                        firstPositionConf,
                        false
                    );
                } else {
                    const config = {
                        id: 0,
                        conf: this.configurationsService.conf.Current,
                        defaultConf: this.configurationsService.conf.Default,
                        details: {},
                        detailsForView: {},
                        positionId: positions[0].doc.tmp_id,
                        positionIndex: positions[0].doc.listNumber,
                    }
                    this.originalConfig = { conf: core.copy(this.configurationsService.conf.Current), details: {}, detailsForView: {} };
                    this.getDetails(this.originalConfig, false);
                    this.getDetails(config);
                    this.configs.push(config);
                    await this.changeColor(this.configs[0]);
                    this.configs[0].defaultConf.Colors = core.copy(this.configs[0].conf.Colors);
                    this.getDetails(config);
                    this.colorsFactory.loadColorsBySystem(
                        color =>
                            color
                            && color.systems
                            && color.systems.indexOf(this.originalConfig.conf.System.id) > -1,
                        this.originalConfig.conf
                    );
                    confData.nextPosition = true;
                }


                if (confData.nextPosition) {
                    const changes = this.getChanges(
                        firstPositionConf,
                        this.configs[0].conf
                    );

                    if (positions.length > 1) {
                        for (let i = 1; i < positions.length; i++) {
                            let conf = this.configurationsService.createActiveConfiguration(positions[i].doc.details, this.configuratorsDataService);
                            conf.drawData = positions[i].doc.configuration.drawData;
                            this.shapeService.setShapes(conf);
                            this.configurationsService.conf.Current = core.copy(
                                conf
                            );
                            this.configurationsService.conf.Default = core.copy(
                                conf
                            );
                            this.eventBusService.setCurrentConfiguration(
                                this.configurationsService.conf
                            );
                            if (this.option === 'system') {
                                this.calculateSystems(
                                    [system.id],
                                    this.configurationsService.conf.Current,
                                    this.configurationsService.conf.Default,
                                    false,
                                    positions[i].doc.tmp_id,
                                    positions[i].doc.listNumber
                                );
                            } else {
                                const config = {
                                    id: this.configs[this.configs.length - 1].id + 1,
                                    conf: this.configurationsService.conf.Current,
                                    defaultConf: this.configurationsService.conf.Default,
                                    details: {},
                                    detailsForView: {},
                                    positionId: positions[i].doc.tmp_id,
                                    positionIndex: positions[i].doc.listNumber,
                                }
                                this.originalConfig = { conf: core.copy(this.configurationsService.conf.Current), details: {}, detailsForView: {} };
                                this.getDetails(this.originalConfig, false);
                                this.getDetails(config);
                                this.configs.push(config);
                            }
                            await this.changeColorsAndFillingsFromChanges(
                                this.configs[i],
                                positions[i].doc,
                                changes
                            );
                        }
                    }
                    this.updateGroups(groups, groupKey, this.configs);
                    this.groups = groups;
                    return groups;
                }
            }
        }
        return false;
    }

    async changeColorsAndFillingsFromChanges(config, position, changes) {
        let toEdit = false;
        this.colorsFactory.loadColorsBySystem(
            color => color && color.systems && color.systems.indexOf(config.conf.System.id) > -1,
            config.conf
        );
        for (const type in changes.colors) {
            if (changes.colors.hasOwnProperty(type) && type !== 'type') {
                for (const side in changes.colors[type]) {
                    if (changes.colors[type].hasOwnProperty(side)) {
                        let color = changes.colors[type][
                            side
                        ][
                            position.configuration
                                .Colors[type][
                                side
                            ].id
                        ];
                        if (!color && this.option === 'color') {
                            color = changes.colors[type][
                                side
                            ].undefined;
                        }
                        if (color) {
                            this.colorsFactory.setColor(
                                side,
                                type,
                                color,
                                color.type,
                                true,
                                config.conf,
                                config.defaultConf,
                            );
                            if (this.option === 'color') {
                                config.conf.ColorType = changes.colors.type
                            }
                        } else {
                            toEdit = true;
                        }
                    }
                }
            }
        }
        this.fillingsService.loadMatchingFillings(config.conf);
        config.conf.Sashes.forEach((sash, index) => {
            let filling = changes.fillings[position.configuration.Sashes[index].glazing.id];
            if (filling) {
                this.fillingsService.setFillingInSash(
                    sash,
                    sash,
                    filling,
                    config.conf
                );
            } else {
                toEdit = true;
            }
            if (sash.intSashes.length) {
                sash.intSashes.forEach((intSash, i) => {
                    filling =
                        position.configuration.Sashes[index].intSashes[i]
                        && changes.fillings[
                            position.configuration.Sashes[index].intSashes[i]
                                .glazing.id
                        ];
                    if (filling) {
                        this.fillingsService.setFillingInSash(
                            intSash,
                            sash,
                            filling,
                            config.conf
                        );
                    } else {
                        toEdit = true;
                    }
                });
            }
        });
        this.getDetails(config);
        if (toEdit && this.option === 'system') {
            const data = await this.openSystemsComparisonDetailsModal(
                config,
                position.configuration,
                false
            );
            if (data.nextPosition) {
                this.getChanges(position.configuration, config.conf);
            }
        }
    }

    getChanges(
        originalData,
        changedData,
        changes = {
            colors: {
                frame: {
                    core: {},
                    inner: {},
                    outer: {},
                },
                sash: {
                    core: {},
                    inner: {},
                    outer: {},
                },
                type: ''
            },
            fillings: {},
        }
    ) {

        for (const type in changes.colors) {
            if (changes.colors.hasOwnProperty(type) && type !== 'type') {
                for (const side in changes.colors[type]) {
                    if (originalData.Colors[type][side].id !== changedData.Colors[type][side].id) {
                        changes.colors[type][side][originalData.Colors[type][side].id] =
                            core.copy(changedData.Colors[type][side]);
                    }
                }
            }
        }
        changes.colors.type = changedData.ColorType;

        originalData.Sashes.forEach((sash, index) => {
            if (sash.glazing.id !== changedData.Sashes[index].glazing.id) {
                changes.fillings[sash.glazing.id] =
                    core.copy(changedData.Sashes[index].glazing);
            }
            if (sash.intSashes.length) {
                sash.intSashes.forEach((intSash, i) => {
                    if (changedData.Sashes[index].intSashes[i]) {
                        changes.fillings[intSash.glazing.id] =
                            core.copy(changedData.Sashes[index].intSashes[i].glazing);
                    }
                });
            }
        });

        return changes;
    }

    openOptionsModal() {
        const modalOptions: ng.ui.bootstrap.IModalSettings = {
            component: 'optionsModal',
            resolve: {},
        };
        const modalInstance = this.$uibModal.open(modalOptions);

        return modalInstance.result;
    }

    openSystemsSelectModal(systemId, confType, multiple = true) {
        this.systems = this.systemsFactory.getSystems(confType);
        const modalOptions: ng.ui.bootstrap.IModalSettings = {
            component: 'systemsSelectModal',
            resolve: {
                systems: () => this.systems.filter(el => Number(el.id) !== Number(systemId)),
                multiple: () => multiple,
            },
        };
        const modalInstance = this.$uibModal.open(modalOptions);

        return modalInstance.result;
    }

    openGroupSelectModal() {
        const groups = this.getPositionsGroups();

        const modalOptions: ng.ui.bootstrap.IModalSettings = {
            component: 'positionsGroupsModal',
            resolve: {
                groups: () => groups,
                drawOptions: () => this.drawOptions,
            },
        };
        const modalInstance = this.$uibModal.open(modalOptions);

        return modalInstance.result;
    }

    getPositionsGroups(forOffer = false) {
        let positions = core.copy(
            this.stateService
                .getKey('positions')
                .filter(
                    el =>
                        ['window', 'hs', 'door', 'folding_door', 'sliding_door', 'coupled_window'].indexOf(
                            el.doc.configuration.type
                        ) > -1
                )
        );
        const tmpGroups = {};
        const schema = {
            window: [
                'configuration.System.id',
                'configuration.Colors.frame.alushell.id',
                'configuration.Colors.frame.core.id',
                'configuration.Colors.frame.inner.id',
                'configuration.Colors.frame.outer.id',
                'configuration.Colors.sash.alushell.id',
                'configuration.Colors.sash.core.id',
                'configuration.Colors.sash.inner.id',
                'configuration.Colors.sash.outer.id',
                'configuration.HasAlushell',
                'configuration.Wood.id'
            ],
            hs: 'window',
            door: 'window',
            folding_door: 'window',
            sliding_door: 'window'
        };
        positions = new PositionsDetailsService(
            IccConfig,
            positions,
            this.stateService.getCurrentOffer(),
            this.$rootScope.user.access,
            true,
            this.$rootScope.user.access,
            this.$translate
        );
        positions.forEach(position => {
            position.doc.groupCode = OfferGroupCodeService.generateGroupCode(
                position.doc,
                this.config.IccConfig,
                schema
            );
            if (!tmpGroups[position.doc.groupCode]) {
                tmpGroups[position.doc.groupCode] = [];
            }
            tmpGroups[position.doc.groupCode].push(forOffer ? position : position.doc);
        });
        if (!forOffer) {
            return tmpGroups;
        }
        const groups = [];
        for (const groupCode in tmpGroups) {
            if (tmpGroups.hasOwnProperty(groupCode)) {
                groups.push({
                    groupCode,
                    confType: tmpGroups[groupCode][0].doc.confType,
                    rows: tmpGroups[groupCode],
                    groupData: this.offerGroupService.getPositionGroupData(tmpGroups[groupCode][0]),
                    groupEdition: 0,
                });
            }
        }

        this.groups = groups;

        return this.groups;
    }

    updateGroups(groups, groupKey, configs) {
        let price = 0;
        let originalPrice = 0;
        Object.assign(
            groups[groupKey].groupData,
            this.offerGroupService.getPositionGroupData({
                doc: {
                    configuration: configs[0].conf,
                    confType: groups[groupKey].groupData.confType
                }
            })
        );
        for (let i = 0; i< groups[groupKey].rows.length; i++) {
            const config = configs.find(el => el.positionId == groups[groupKey].rows[i].doc.tmp_id);
            if (config.detailsForView.prices.noPrice) {
                price = null;
            }
            if (price !== null) {
                price += config.details.price;
            }
            if (groups[groupKey].rows[i].doc.detailsForView.prices.noPrice) {
                originalPrice = null;
            }
            if (originalPrice !== null) {
                originalPrice += groups[groupKey].rows[i].doc.details.price;
            }
            Object.assign(
                groups[groupKey].rows[i].doc,
                {
                    configuration: core.copy(config.conf),
                    details: core.copy(config.details),
                    detailsForView: core.copy(config.detailsForView)
                }
            );
        }
        groups[groupKey].originalPrice = originalPrice;
        groups[groupKey].price = price;
        return groups;
    }

    createSubOffer(groups, draws) {
        const positions = {};
        let issues = false;
        groups.forEach(group => {
            group.rows.forEach(position => {
                if (position.doc.configuration.Issues.length) {
                    issues = true;
                }
                positions[position.doc.tmp_id] = {
                    configuration: position.doc.configuration,
                    details: position.doc.details,
                    image: core.svgToPngData(draws[position.doc.tmp_id])
                }
            });
        });
        if (issues) {
            this.infoFactory.openModal({
                title: this.$translate.instant('OFFER|Błędy w pozycjach'),
                message: this.$translate.instant('OFFER|W pozycjach występują błędy. Należy je wyeliminować aby utworzyć podofertę.'),
            });
        } else {
            this.inSystemsComparison.value = false;
            this.onOffer = false;
            this.offersFactory.addSubOffer(this.stateService.state.offer_id, false, false, false, positions);
        }
    }

    fixCoupledWindow(groups, positionConfig = null, positionId = null) {
        groups.forEach(group => {
            for (let i = 0; i< group.rows.length; i++) {
                if (group.rows[i].doc.confType === 'coupled_window' && group.rows[i].doc.configuration) {
                    if (group.rows[i].doc.configuration.windows) {
                        group.rows[i].doc.configuration.windows.forEach(window => {
                            const config =
                                positionId
                                && positionId === window.positionId
                                    ? positionConfig
                                    : this.configs.find(
                                          el =>
                                              el.positionId
                                              === window.positionId
                                      );
                            if (config) {
                                Object.assign(
                                    window,
                                    {
                                        details: core.copy(config.details),
                                    }
                                );
                            }
                        })
                    }
                    if (group.rows[i].doc.details.windows) {
                        group.rows[i].doc.details.windows.forEach(window => {
                            const config =
                                positionId
                                && positionId === window.positionId
                                    ? positionConfig
                                    : this.configs.find(
                                          el =>
                                              el.positionId
                                              === window.positionId
                                      );
                            if (config) {
                                Object.assign(
                                    window,
                                    {
                                        details: core.copy(config.details),
                                    }
                                );
                            }
                        })
                    }
                    const coupledConfig = {
                        details: {},
                        detailsForView: {},
                        conf: core.copy(group.rows[i].doc.configuration),
                        defaultConf: core.copy(group.rows[i].doc.configuration)
                    };
                    this.getDetails(coupledConfig);
                    Object.assign(
                        group.rows[i].doc,
                        {
                            configuration: core.copy(coupledConfig.conf),
                            details: core.copy(coupledConfig.details),
                            detailsForView: core.copy(coupledConfig.detailsForView),
                        }
                    );
                }
            }
        });
    }
}
