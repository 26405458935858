import angular from 'angular';

/**
 * Fabryka modeli
 * @param {Object} $rootScope               rootScope
 * @param {Object} ConfigurationsService    ConfigurationsService
 * @param {Object} GlazingFactory           GlazingFactory
 * @param {Object} HandlesFactory           HandlesFactory
 * @param {Object} CurConfService           CurConfService
 * @param {Object} ParametersService        ParametersService
 * @param {Object} ConfiguratorsDataService ConfiguratorsDataService
 * @param {Object} StepFactory              StepFactory
 * @param {Object} ColorsFactory            ColorsFactory
 * @param {Object} AccessoriesFactory       AccessoriesFactory
 * @param {Object} PriceService             PriceService
 */
export default function ModelsFactory(
    $rootScope,
    IccConfig,
    ConfigurationsService,
    GlazingFactory,
    HandlesFactory,
    CurConfService,
    ParametersService,
    ConfiguratorsDataService,
    StepFactory,
    ColorsFactory,
    AccessoriesFactory,
    PriceService,
    SystemsFactory,
    LayoutFactory,
    EventBusService
) {
    'ngInject';

    var factory = {
        models: [],
        loadedData: false,
        selectModel,
        currentModelGroup: null,
        selectModelGroup,
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });

    return factory;

    /**
     * Funkcja inicjujaca
     */
    function init() {
        if (CurConfService.conf != 'door') {
            return;
        }
        var visModel;

        factory.models = getModels();
        factory.modelsGroups = getModelsGroups();

        if (
            angular.isUndefined(ConfigurationsService.conf.Current.Model)
            || angular.isUndefined(ConfigurationsService.conf.Current.Model.id)
        ) {
            selectModel(factory.models[0]);

            visModel = localStorage.getItem('vis_svg_data_1');

            for (let i = 0; i < factory.models.length; i++) {
                if (factory.models[i].name == visModel) {
                    ConfigurationsService.conf.Current.Model = factory.models[i];
                    ConfigurationsService.conf.Default.Model = factory.models[i];
                    factory.currentModelGroup = factory.modelsGroups.filter((e) => {
                        return e.id == factory.models[i].door_models_group_id;
                    })[0];
                    break;
                }
            }
            if (!factory.currentModelGroup) {
                factory.currentModelGroup = factory.modelsGroups[0];
            }
        } else {
            if (ConfigurationsService.conf.Current.Model.standard_fills) {
                StepFactory.enable('glazing');
                StepFactory.disable('fills');
            } else {
                StepFactory.disable('glazing');
                StepFactory.enable('fills');
                for (let i = 0; i < ConfigurationsService.conf.Current.Sashes.length; i++) {
                    const sash = ConfigurationsService.conf.Current.Sashes[i];
                    if (sash.intMullions.length > 0) {
                        LayoutFactory.prepareWindowSashesData(
                            ConfigurationsService.conf.Current.Layout
                        );
                    }
                }
            }
            factory.currentModelGroup = factory.modelsGroups.filter((e) => {
                return e.id == ConfigurationsService.conf.Current.Model.door_models_group_id;
            })[0];
        }
        ConfigurationsService.conf.Current.ModelGroup = factory.currentModelGroup;
        ConfigurationsService.conf.Default.ModelGroup = factory.currentModelGroup;

        factory.loadedData = true;
    }

    /**
     * Zwracanie modelu
     * @return {Object} Model drzwi
     */
    function getModels() {
        return ConfiguratorsDataService.data.doorModels;
    }

    /**
     * Zwracanie grup modeli
     * @return {Object} Grupy modeli
     */
    function getModelsGroups() {
        return ConfiguratorsDataService.data.doorModelsGroups;
    }

    /**
     * Wybór grupy modelu
     * @param  {Object} group Grupa
     */
    function selectModelGroup(group) {
        factory.currentModelGroup = group;
        const conf = ConfigurationsService.conf.Current;
        const defaultConf = ConfigurationsService.conf.Default;
        conf.ModelGroup = group;
        defaultConf.ModelGroup = group;
        if (
            (factory.currentModelGroup.systems.length
                && factory.currentModelGroup.systems.indexOf(conf.System.id) == -1)
            || conf.System.type != factory.currentModelGroup.type
            || conf.System.static != 'door_' + conf.destination
        ) {
            var systems = SystemsFactory.systems.filter(
                system =>
                    system.static === 'door_' + conf.destination
                    && ((factory.currentModelGroup.systems.length
                        && factory.currentModelGroup.systems.indexOf(system.id) > -1)
                        || (!factory.currentModelGroup.systems.length
                            && system.type == factory.currentModelGroup.type))
            );
            if (systems.length > 0) {
                SystemsFactory.selectSystem(conf, defaultConf, systems[0]);
            }
        } else if (conf.type === 'door' && IccConfig.Configurators.door.version === 1) {
            conf.Name =
                (conf.ModelGroup && conf.ModelGroup.name ? conf.ModelGroup.name + ' > ' : '')
                + conf.System.name;
            defaultConf.Name =
                (conf.ModelGroup && conf.ModelGroup.name ? conf.ModelGroup.name + ' > ' : '')
                + conf.System.name;
        }
        if (conf.Model.door_models_group_id != factory.currentModelGroup.id) {
            var models = factory.models.filter((model) => {
                return model.door_models_group_id == factory.currentModelGroup.id;
            });
            if (models.length > 0) {
                selectModel(models[0], true, 10);
            }
        }
    }

    /**
     * Wybór modelu
     * @param  {Object} model    Model
     * @param  {Boolean} nextStep [description]
     * @param  {Number} price    Cena
     */
    function selectModel(model, nextStep, price) {
        ConfigurationsService.conf.Current.Model = model;
        ConfigurationsService.conf.Default.Model = model;

        if (model.standard_fills) {
            StepFactory.enable('glazing');
            StepFactory.disable('fills');
        } else {
            StepFactory.disable('glazing');
            StepFactory.enable('fills');
            for (var i = 0; i < ConfigurationsService.conf.Current.Sashes.length; i++) {
                var sash = ConfigurationsService.conf.Current.Sashes[i];
                if (sash.intMullions.length > 0) {
                    LayoutFactory.prepareWindowSashesData(
                        ConfigurationsService.conf.Current.Layout
                    );
                }
            }
        }

        if (nextStep) {
            // StepFactory.goToNextStep(true);
        }
        if (price) {
            PriceService.count();
        }
    }
}
