import { GlazingUnitElement } from 'configurations/parts/window';
import { Glass } from 'configurations/parts/window/Glass';
import { InterPaneSpace } from 'configurations/parts/window/InterPaneSpace';
import { core } from 'helpers';

export function fixCustomFillings(customFillings: any[], allGlasses, allInterPaneSpaces) {
    const fixedFillings = [];
    if (customFillings && customFillings.length > 0 && allGlasses.length > 0) {
        customFillings.forEach(filling => {
            if (filling.glazing_unit) {
                let extendedGlazingUnit = core.copy(filling.glazing_unit);
                const glasses = [];
                const interPaneSpaces = [];
                let hasAll = true;
                extendedGlazingUnit = extendedGlazingUnit.map(el => {
                    if (el.type === 'glass') {
                        el.data = core.fIdO(allGlasses, el.value) as Glass;
                        if (!el.data) {
                            hasAll = false;
                        }
                        glasses.push(core.copy(el.data));
                    } else if (el.type === 'interPaneSpace') {
                        el.data = core.fIdO(allInterPaneSpaces, el.value) as InterPaneSpace;
                        if (!el.data) {
                            hasAll = false;
                        }
                        interPaneSpaces.push(core.copy(el.data));
                    }
                    return el;
                });

                if (hasAll) {
                    filling.thickness_mm =
                        glasses.reduce((prev, curr) => prev + curr.thickness, 0)
                        + interPaneSpaces.reduce((prev, curr) => prev + curr.thickness, 0);
                    filling.thinkness_glass = Math.min(
                        ...glasses.map(el => el.thicknessForCalculations)
                    );
                    filling.price_sq_m = glazingUnitPrice(extendedGlazingUnit, allGlasses, allInterPaneSpaces);
                    fixedFillings.push(filling);
                }
            } else {
                fixedFillings.push(filling);
            }
        });
    }
    return fixedFillings;
}


export function glazingUnitPrice(glazingUnit: GlazingUnitElement[], glasses, interPaneSpaces, filling = null) {
    let price;

    if (filling == null) {
        price = glazingUnit.reduce((prev, curr) => prev + curr.data.price, 0);
    } else {
        price = filling.price_sq_m;
        for (let i = 0; i < glazingUnit.length; i++) {
            if (glazingUnit[i].value != filling.glazing_unit[i].value) {
                if (glazingUnit[i].type === 'glass') {
                    const glass = <Glass>core.fIdO(glasses, filling.glazing_unit[i].value);
                    price = price - glass.price + (<Glass>glazingUnit[i].data).price;
                } else if (glazingUnit[i].type === 'interPaneSpace') {
                    const interPaneSpace = <InterPaneSpace>core.fIdO(interPaneSpaces, filling.glazing_unit[i].value);
                    price = price - interPaneSpace.price + (<InterPaneSpace>glazingUnit[i].data).price;
                }
            }
        }
    }

    return price;
}