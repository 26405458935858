import Common from 'Common';

import {core} from 'helpers';
import {PriceSegment} from 'price/Prices';
import PriceBaseService from 'price/price-base.service';
import {Injectable, Inject} from '@angular/core';
import {APP_CONFIG, AppConfig} from 'config';
import {EventBusService} from 'event-bus.service';
import {DiscountsAndMultipliersService} from '../../../common/price/discounts-and-multipliers.service';
import {DatabaseManagerToken} from '../sync/Databases/DatabaseManager';

@Injectable()
export default class BrowserDiscountsAndMultipliersService extends DiscountsAndMultipliersService {

    /**
     * Creates an instance of CustomPricesService.
     *
     * @param {DatabaseManager} DatabaseManager
     *
     * @memberOf CustomPricesService
     */
    constructor(
        private EventBusService: EventBusService,
        @Inject(DatabaseManagerToken) private DatabaseManager,
        @Inject(APP_CONFIG) protected config
    ) {
        super();
        this.load();

        this.EventBusService.subscribeWithoutConfiguration('syncedDiscountsAndMultipliers', data => {
            this.reload();
        });
    }


    async reload() {
        this.offerDiscounts = [];
        this.multipliers = {};
        this.buyDiscounts = {};
        this.saleDiscounts = {};
        this.unavailableSystems = [];

        this.load();
    }

    async load() {
        const data: any = await this.DatabaseManager.get('DiscountsAndMultipliers').get();
        if (data && data.data) {
            const parsedData = core.parseJson(data.data);
            if (Common.isObject(parsedData)) {
                this.offerDiscounts = parsedData.offerDiscounts || [];
                this.multipliers = !core.isEmptyObject(parsedData.multipliers)
                    ? parsedData.multipliers
                    : {
                        Commodity: [1],
                        Service: [1],
                        Supplement: [1],
                    };
                this.buyDiscounts = parsedData.buyDiscounts || {};
                this.saleDiscounts = parsedData.saleDiscounts || {};
                this.unavailableSystems = parsedData.unavailableSystems || [];
                this.loaded = true;
            }
        }
    }
}
