import { Injectable, Inject } from '@angular/core';
import { Reinforcement, Profile, SideProfile } from 'configurations/parts/window';
import ReinforcementsService from 'configurators/reinforcements.service';
import ProfilesService from 'profiles.service';
import { TranslateService } from 'translate.service';
import StateService from 'state.service';
import { EventBusService } from 'event-bus.service';

@Injectable()
export class ReinforcementsModalService {
    constructor(
        private profilesService: ProfilesService,
        private reinforcementsService: ReinforcementsService,
        private translateService: TranslateService,
        private stateService: StateService,
        private eventBusService: EventBusService,
        @Inject('$uibModal') private $uibModal: ng.ui.bootstrap.IModalService
    ) {}

    async openReinforcementsModal(
        profileId: Profile['id'],
        selectedReinforcement: Reinforcement,
    ): Promise<Reinforcement | false> {
        const reinforcements = this.reinforcementsService.reinforcements.filter(el =>
            el.profiles.indexOf(Number(profileId)) > -1
        );
        reinforcements.unshift({
            id: null,
            name: this.translateService.instant('INTERFACE|Brak'),
            price: 0
        });
        const modalInstance = this.$uibModal.open({
            component: 'reinforcementsModal',
            resolve: {
                reinforcements: () => reinforcements,
                selectedReinforcement: () => selectedReinforcement,
                currency: () => this.stateService.state.offers[0].doc.currency,
            },
        });

        return modalInstance.result;
    }
}
