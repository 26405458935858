import Common from '../Common';

import { core } from 'helpers';
import { PriceSegment } from 'price/Prices';
import PriceBaseService from 'price/price-base.service';
import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from 'config';

@Injectable()
export abstract class DiscountsAndMultipliersService {
    public loaded = false;
    protected offerDiscounts: any;
    protected multipliers: any;
    protected buyDiscounts: any;
    protected saleDiscounts: any;
    protected unavailableSystems: any;
    protected config: AppConfig;

    abstract load();

    abstract reload();

    async getOfferDiscountAsync() {
        if (!this.loaded) {
            await this.load();
        }
        return this.offerDiscounts;
    }

    getMultipliers() {
        return this.multipliers;
    }

    getBuyDiscounts() {
        return this.buyDiscounts;
    }

    getSaleDiscounts() {
        return this.saleDiscounts;
    }

    getUnavailableSystems() {
        return this.unavailableSystems;
    }

    getDiscountGroups(priceStack: PriceSegment[]) {
        const systemSeg = priceStack.find(s => s && s.type === 'system');
        const discountGroups = priceStack
            .filter(segment => segment && segment.valueType === 'value')
            .reduce((prev, segment) => {
                if (segment.type === 'coupledWindow') {
                    const coupledPriceStack = segment.data.priceStack;
                    const coupledDiscountGroups = this.getDiscountGroups(coupledPriceStack);
                    Object.entries(coupledDiscountGroups).forEach(group => {
                        if (Common.isUndefined(prev[group[0]])) {
                            prev[group[0]] = 0;
                        }
                        if (prev[group[0]] !== null && !isNaN(parseFloat(group[1] as any))) {
                            prev[group[0]] += parseFloat(group[1] as any);
                        } else {
                            prev[group[0]] = null;
                        }
                    });
                } else {
                    this.segmentToDiscountGroup(segment, systemSeg, prev);
                }
                return prev;
            }, {});
        return discountGroups;
    }

    suppMultipliers(priceStack: PriceSegment[], type: string, extendId, multipliers = this.multipliers, dealerId?) {
        if (
            this.config.IccConfig.Dealer.systemMultipliers
            && Common.isObject(multipliers)
            && !Common.isArray(multipliers)
        ) {
            const multipliersSource = multipliers[dealerId] || multipliers;
            if (this.config.IccConfig.Dealer.surchargeMultipliers) {
                if (type === 'accessory' || type === 'complementary_goods') {
                    priceStack.push(
                        ...extendId([
                            {
                                type: 'commodityMultiplier',
                                baseValue:
                                    (multipliersSource.Commodity && multipliersSource.Commodity[0])
                                    || 1,
                                value:
                                    (multipliersSource.Commodity && multipliersSource.Commodity[0])
                                    || 1,
                                valueType: 'multiplier',
                                data: {},
                            },
                        ])
                    );
                } else {
                    const systemSeg = priceStack.filter(s => s && s.type === 'system');
                    if (systemSeg.length > 0 && systemSeg[0].data) {
                        priceStack.push(
                            ...extendId([
                                {
                                    type: 'systemMultiplier',
                                    baseValue:
                                        (multipliersSource.WindowLine
                                            && multipliersSource.WindowLine[systemSeg[0].data.id])
                                        || 1,
                                    value:
                                        (multipliersSource.WindowLine
                                            && multipliersSource.WindowLine[systemSeg[0].data.id])
                                        || 1,
                                    valueType: 'multiplier',
                                    data: {},
                                },
                            ])
                        );
                    }

                    priceStack.push(
                        ...extendId([
                            {
                                type: 'supplementMultiplier',
                                baseValue:
                                    (multipliersSource.Supplement
                                        && multipliersSource.Supplement[0])
                                    || 1,
                                value:
                                    (multipliersSource.Supplement
                                        && multipliersSource.Supplement[0])
                                    || 1,
                                valueType: 'multiplier',
                                data: {},
                            },
                        ])
                    );
                }
            }
            const rollerSeg = priceStack.filter(s => s && s.type === 'rollerBoxes');
            if (rollerSeg.length > 0 && rollerSeg[0].data) {
                priceStack.push(
                    ...extendId([
                        {
                            type: 'rollerMultiplier',
                            baseValue:
                                (multipliersSource.WindowLine
                                    && multipliersSource.WindowLine[rollerSeg[0].data.type])
                                || 1,
                            value:
                                (multipliersSource.WindowLine
                                    && multipliersSource.WindowLine[rollerSeg[0].data.type])
                                || 1,
                            valueType: 'multiplier',
                            data: {},
                        },
                    ])
                );
            }
        }
    }

    protected getDiscountGroupForSegmentType(segment: PriceSegment, systemSegment: PriceSegment) {
        let group = 'system';
        if (systemSegment && !~~IccConfig.Dealer.oneProductDiscount) {
            group = 'system' + systemSegment.data.id;
        }
        if (
            ['accessories', 'sashesHardware', 'sideAccessories'].indexOf(segment.type) > -1
            && this.config.IccConfig.Dealer.discountPerAllAccessoriesCategory
        ) {
            group = 'accessory' + (segment.data.categoryId || '');
        } else if (['complementaryGoods'].indexOf(segment.type) > -1) {
            group = 'accessory';
            if (
                IccConfig.Dealer.displayDiscountsForAddons
                && ['accessory', 'cassonetto', 'glass', 'windowsill'].includes(segment.data.good)
            ) {
                group = segment.data.good;
                if (segment.data.good === 'glass') {
                    group = 'glazing';
                }
            }
        } else if (
            ['sideProfile', 'alignment', 'couplings'].indexOf(segment.type) > -1
            && this.config.IccConfig.Dealer.discountPerAllAccessoriesCategory
        ) {
            group = 'priceLevel' + (segment.data.priceLevelId || '');
        } else if (
            [
                'rollerColors',
                'rollerColor',
                'rollerDrive',
                'rollerDriveManual',
                'rollerDriveElems',
                'rollerAssembly',
                'rollerBoxSize',
                'rollerShutterMosquito',
                'rollerConsole',
                'rollerConcealedStrip',
                'rollerBoxes',
                'rollerSlatDivision',
            ].indexOf(segment.type) > -1
        ) {
            group = 'roller' + (segment.data.typeId || '');
        }
        if (Common.isObject(this.config.IccConfig.Offer.discountGroups)) {
            for (const key in this.config.IccConfig.Offer.discountGroups) {
                if (
                    this.config.IccConfig.Offer.discountGroups.hasOwnProperty(key)
                    && Common.isArray(this.config.IccConfig.Offer.discountGroups[key])
                    && this.config.IccConfig.Offer.discountGroups[key].indexOf(segment.type) > -1
                ) {
                    group = key;
                }
            }
        }
        return group;
    }

    getMontageMultiplier() {
        const serviceMultiplier = this.getMultipliers().Service && this.getMultipliers().Service[0] || 1;
        const montageMultiplier = this.getBuyDiscounts().Montages && this.getBuyDiscounts().Montages[0] || 0;
        const multiplier =
            (this.loaded
                && (this.config.IccConfig.Dealer.surchargeMultipliers
                    ? serviceMultiplier
                    : (100 - montageMultiplier) / 100))
            || 1;
        return multiplier;
    }

    private segmentToDiscountGroup(segment: PriceSegment, systemSeg: PriceSegment, prev: {}) {
        const group = this.getDiscountGroupForSegmentType(segment, systemSeg);
        if (Common.isUndefined(prev[group])) {
            prev[group] = 0;
        }
        if (prev[group] !== null && !isNaN(parseFloat(segment.value as any))) {
            prev[group] += parseFloat(segment.value as any);
        } else {
            prev[group] = null;
        }
    }
}
