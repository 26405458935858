import { Injectable, Inject } from '@angular/core';
import { core } from 'helpers';
import Common from 'Common';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import ProfilesService from 'profiles.service';
import { EventBusService } from 'event-bus.service';
import { IssuesService, IssueLevel } from 'issues.service';
import { ValidationService } from 'configurators/validation.service';
import { APP_CONFIG, AppConfig } from 'config';
import { SideProfile } from 'configurations/parts/window';
import CoupledWindowActiveConfiguration from 'configurations/CoupledWindowActiveConfiguration';
import WindowConfiguration from 'configurations/WindowConfiguration';

@Injectable()
export class ExtensionsService {
    constructor(
        private eventBusService: EventBusService,
        private profilesService: ProfilesService,
        private issuesService: IssuesService,
        private validationService: ValidationService,
        @Inject(APP_CONFIG) private config: AppConfig,
        @Inject('$translate') private $translate
    ) {
        this.eventBusService.subscribe(
            ['setDefaultColors', 'setSystem', 'putExtensionOnSide', 'setExtensionProfile'],
            data => {
                this.validateExtensionsAndFixIsses(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.value
                );
            }
        );
    }

    putExtensionOnSide(conf: WindowActiveConfiguration, profile, side) {
        if (profile.type === 'sandwich') {
            profile.widthOut = profile.width;
        }
        const sides = this.profilesService.getFrameSidesOnEdge(conf);
        const windowSide = sides.find(s => s.side === side);
        const sideProfileOnSide = this.getLastSideProfileByFrameIndex(windowSide.frameEdges, conf);

        const newSideProfile = new SideProfile({
            id: this.getIdForNew(conf),
            profileId: profile.id,
            length: 0,
            width: profile.width,
            widthOut: profile.widthOut,
            count:
                this.config.IccConfig.Configurators.hs.doubleExtensions && conf.type === 'hs'
                    ? 2
                    : 1,
            color: profile.selectedColor ? core.copy(profile.selectedColor.frame) : {},
            wood: profile.selectedWood ? core.copy(profile.selectedWood) : {},
            adjacentSideProfileId: sideProfileOnSide ? [sideProfileOnSide.id] : [],
            reinforcement: profile.reinforcement,
            framesId: !sideProfileOnSide
                ? windowSide.frameEdges.map(fE => ({
                      id: fE.frameId,
                      edges: [fE.frameEdgeIndex],
                  }))
                : [],
            side,
        });
        conf.SideProfiles.push(newSideProfile);
        if (sideProfileOnSide) {
            sideProfileOnSide.adjacentOtherSideProfileId.push(newSideProfile.id);
        }

        this.eventBusService.post({
            key: 'putExtensionOnSide',
            value: {
                profile,
                side,
            },
        });
        if (profile.type === 'sandwich') {
            profile.width = null;
            profile.widthOut = null;
        }
    }

    removeExtensionFromSide(conf: WindowActiveConfiguration, side) {
        const sides = this.profilesService.getFrameSidesOnEdge(conf);
        const windowSide = sides.find(s => s.side === side);
        const sideProfileOnSide = this.getLastSideProfileByFrameIndex(windowSide.frameEdges, conf);
        conf.SideProfiles.splice(conf.SideProfiles.indexOf(sideProfileOnSide), 1);
        conf.SideProfiles.forEach(sp => {
            sp.adjacentOtherSideProfileId = sp.adjacentOtherSideProfileId.filter(
                spId => spId !== sideProfileOnSide.id
            );
            sp.adjacentSideProfileId = sp.adjacentSideProfileId.filter(
                spId => spId !== sideProfileOnSide.id
            );
        });

        this.eventBusService.post({
            key: 'removedExtensionFromSide',
            value: {
                side,
            },
        });
    }

    setDefaultColors(conf: WindowActiveConfiguration, colors) {
        const allColors = [].concat(colors.windowColors, colors.windowColorRals);

        Object.keys(conf.SideProfiles).map(side => {
            conf.SideProfiles.map(extension => {
                if (
                    extension.color
                    && extension.color.inner
                    && extension.color.outer
                    && !allColors.some(
                        el =>
                            el.id === extension.color.inner.id
                            && el.RAL === extension.color.inner.RAL
                    )
                    && !allColors.some(
                        el =>
                            el.id === extension.color.outer.id
                            && el.RAL === extension.color.outer.RAL
                    )
                ) {
                    extension.color = {
                        inner: null,
                        outer: null,
                    };
                }
            });
        });

        this.validationService.valid(conf, 'extensionsColors');
    }

    validateExtensionsAndFixIsses(conf: WindowActiveConfiguration, data) {
        this.validationService.indeterminate(conf, 'extensionsSystems');
        if (conf.SideProfiles) {
            if (!this.validSystems(conf)) {
                this.validationService.invalid(conf, 'extensionsSystems');
                this.issuesService.simpleRegister(
                    'invalid-extensions',
                    'Niepoprawne profile poszerzeń',
                    this.$translate.instant('WINDOW|Niepoprawne profile poszerzeń'),
                    conf,
                    {
                        level: IssueLevel.ERROR,
                        logLevel: IssueLevel.NONE,
                        blockStepsAfter: null,
                    }
                );
            } else {
                this.validationService.valid(conf, 'extensionsSystems');
                this.issuesService.unregister('invalid-extensions', conf);
            }
        }
        if (Common.isDefined(data.windowColors)) {
            this.validationService.indeterminate(conf, 'extensionsColors');
            if (conf.SideProfiles) {
                if (!this.validColors(conf, data)) {
                    this.validationService.invalid(conf, 'extensionsColors');
                    this.setDefaultColors(conf, data);
                } else {
                    this.validationService.valid(conf, 'extensionsColors');
                }
            }
        }
    }

    validSystems(conf: WindowActiveConfiguration) {
        return Object.keys(conf.SideProfiles).every(side =>
            conf.SideProfiles.every(extension => {
                const profile = conf.UsedProfiles.find(el => el.id === extension.profileId);
                return (
                    profile && profile.systems && profile.systems.includes(Number(conf.System.id))
                );
            })
        );
    }

    validColors(conf: WindowActiveConfiguration, colors) {
        const allColors = [].concat(colors.windowColors, colors.windowColorRals);

        Object.keys(conf.SideProfiles).map(side => {
            conf.SideProfiles.map(extension => {
                if (
                    extension.color
                    && extension.color.inner
                    && extension.color.outer
                    && !allColors.some(
                        el =>
                            el.id === extension.color.inner.id
                            && el.RAL === extension.color.inner.RAL
                    )
                    && !allColors.some(
                        el =>
                            el.id === extension.color.outer.id
                            && el.RAL === extension.color.outer.RAL
                    )
                ) {
                    return false;
                }
            });
        });

        return true;
    }

    getFirstSideProfileById(sideProfileId: number, conf: CoupledWindowActiveConfiguration) {
        const lastProfiles = conf.sideProfiles.filter(s => s.id === sideProfileId);
        const profiles = lastProfiles
            .map(s =>
                s.adjacentSideProfileId.length > 0 ? this.getBackProfiles(s, conf.sideProfiles) : s
            )
            .reduce<SideProfile[]>((acc, val) => acc.concat(val), []);
        return profiles[0];
    }

    getSideProfilesWidthById(sideProfileId: number, conf: CoupledWindowActiveConfiguration) {
        const lastProfiles = conf.sideProfiles.filter(s => s.id === sideProfileId);
        const profilesWidth =
            lastProfiles
                .map(s =>
                    s.adjacentSideProfileId.length > 0
                        ? this.getBackProfiles(s, conf.sideProfiles)
                        : []
                )
                .reduce<number>((acc, val) => acc + val.reduce((p, c) => p + c.width, 0), 0)
            + lastProfiles.reduce((p, c) => p + c.width, 0);
        return profilesWidth;
    }

    getIdForNew(conf: WindowActiveConfiguration | CoupledWindowActiveConfiguration) {
        const sideProfiles = CoupledWindowActiveConfiguration.is(conf)
            ? conf.sideProfiles
            : conf.SideProfiles;
        const intProfilesId = Object.keys(sideProfiles).reduce((prev, side) => {
            const maxId = sideProfiles.reduce(
                (intPrev, profile) => (profile.id > intPrev ? profile.id : intPrev),
                0
            );
            return maxId > prev ? maxId : prev;
        }, -1);
        return intProfilesId + 1;
    }

    getLastSideProfileByFrameIndex(
        frameEdges: {
            frameId: number;
            frameEdgeIndex: number;
            positionId?: string;
        }[],
        conf: WindowActiveConfiguration | CoupledWindowActiveConfiguration | WindowConfiguration
    ) {
        const firstProfiles = (conf.type === 'coupled_window' || WindowConfiguration.is(conf)
            ? conf.sideProfiles
            : conf.SideProfiles
        ).filter(s =>
            s.framesId.some(f =>
                frameEdges.some(
                    fE =>
                        f.id === fE.frameId
                        && f.edges.includes(fE.frameEdgeIndex)
                        && f.positionId === fE.positionId
                )
            )
        );
        const profiles = firstProfiles
            .map(s =>
                s.adjacentOtherSideProfileId.length > 0
                    ? this.getNextProfiles(
                          s,
                          conf.type === 'coupled_window' || WindowConfiguration.is(conf)
                              ? conf.sideProfiles
                              : conf.SideProfiles
                      )
                    : s
            )
            .reduce<SideProfile[]>((acc, val) => acc.concat(val), []);
        return profiles[0];
    }

    private getBackProfiles(baseProfile: SideProfile, sideProfiles: SideProfile[]): SideProfile[] {
        const backProfiles = sideProfiles.filter(s =>
            baseProfile.adjacentSideProfileId.includes(s.id)
        );
        const allBackProfiles = backProfiles
            .map(s =>
                s.adjacentSideProfileId.length > 0 ? this.getBackProfiles(s, sideProfiles) : s
            )
            .reduce<SideProfile[]>((acc, val) => acc.concat(val), []);
        return allBackProfiles;
    }

    private getNextProfiles(baseProfile: SideProfile, sideProfiles: SideProfile[]): SideProfile[] {
        const nextProfiles = sideProfiles.filter(s =>
            baseProfile.adjacentOtherSideProfileId.includes(s.id)
        );
        const allNextProfiles = nextProfiles
            .map(s =>
                s.adjacentOtherSideProfileId.length > 0 ? this.getNextProfiles(s, sideProfiles) : s
            )
            .reduce<SideProfile[]>((acc, val) => acc.concat(val), []);
        return allNextProfiles;
    }
}
