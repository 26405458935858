import { core } from 'helpers';
import { Frame, SideProfile, Shape } from '../../parts/window';
import WindowConfiguration from '../../WindowConfiguration';

export function changesInVersion11(oldConfiguration, dataRequiredToUpdate) {
    const configuration = core.copy(oldConfiguration);
    const newFrame = new Frame({
        id: 0,
        index: 1,
        x: 0,
        y: 0,
        height: oldConfiguration.height,
        width: oldConfiguration.width,
    });
    newFrame.frame = oldConfiguration.frame;
    newFrame.lowThreshold = oldConfiguration.lowThreshold;
    newFrame.shape = oldConfiguration.shape.shape;
    newFrame.parameters = oldConfiguration.parameters;
    configuration.frames = [newFrame];
    configuration.couplings = [];

    const sideProfiles: SideProfile[] = [];

    const frameSideIndexes = getFrameIndexesBySides(configuration);
    ['top', 'bottom', 'left', 'right'].forEach(side => {
        let lastSideProfile = null;
        sideProfiles.push(
            ...((configuration.sideProfiles[side]
                && configuration.sideProfiles[side].map(profile => {
                    profile.shiftPin = null;
                    profile.lengthPin = null;
                    profile.adjacentSideProfileId = [];
                    profile.adjacentOtherSideProfileId = [];
                    profile.side = side;
                    if (lastSideProfile) {
                        profile.adjacentSideProfileId.push(lastSideProfile.id);
                        lastSideProfile.adjacentOtherSideProfileId.push(profile.id);
                        profile.framesId = [
                            {
                                id: 0,
                                edges: [],
                            },
                        ];
                    } else {
                        profile.framesId = [
                            {
                                id: 0,
                                edges: frameSideIndexes[side],
                            },
                        ];
                    }
                    profile.color = profile.color.frame || profile.color.sash || profile.color;

                    lastSideProfile = profile;
                    return profile;
                }))
                || [])
        );
    });
    configuration.sideProfiles = sideProfiles;
    delete configuration.frame;
    delete configuration.lowThreshold;

    configuration.sashes.forEach(sash => {
        sash.frameId = 0;
        sash.hardware.forEach(accessory => {
            const accessoryData = dataRequiredToUpdate.windowAccessories.find(
                el => el.id == accessory.id
            );
            const accessoryCategoryData = dataRequiredToUpdate.windowAccessoriesCategories.find(
                el => el.id == accessoryData.window_accessories_category_id
            );
            accessory.category = {
                id: accessoryData.window_accessories_category_id,
                name: accessoryCategoryData ? accessoryCategoryData.name : accessory.category
            }
        });
    });
    configuration.mullions.forEach(sash => {
        sash.frameId = 0;
    });

    if (!configuration.alignments) {
        configuration.alignments = [];
    }
    configuration.alignments.forEach(sash => {
        sash.frameId = 0;
    });
    configuration.accessories.forEach(accessory => {
        const accessoryData = dataRequiredToUpdate.windowAccessories.find(
            el => el.id == accessory.id
        );
        const accessoryCategoryData = dataRequiredToUpdate.windowAccessoriesCategories.find(
            el => el.id == accessoryData.window_accessories_category_id
        );
        accessory.category = {
            id: accessoryData.window_accessories_category_id,
            name: accessoryCategoryData ? accessoryCategoryData.name : accessory.category
        }
    });
    ['top', 'bottom', 'left', 'right'].forEach(side => {
        configuration.sideAccessories[side].forEach(accessory => {
            const accessoryData = dataRequiredToUpdate.windowAccessories.find(
                el => el.id == accessory.id
            );
            const accessoryCategoryData = dataRequiredToUpdate.windowAccessoriesCategories.find(
                el => el.id == accessoryData.window_accessories_category_id
            );
            accessory.category = {
                id: accessoryData.window_accessories_category_id,
                name: accessoryCategoryData ? accessoryCategoryData.name : accessory.category
            }
        });
    });

    return configuration as WindowConfiguration;
}

function getFrameIndexesBySides(conf: WindowConfiguration) {
    let sides: Record<string, number[]> = {};
    const sidesMap = [
        { index: 0, side: 'bottom', test: hasBottomSide },
        { index: 1, side: 'right', test: hasRightSide },
        { index: 2, side: 'right', test: hasRightTopSide },
        { index: 3, side: 'top', test: hasTopSide },
        { index: 4, side: 'left', test: hasLeftTopSide },
        { index: 5, side: 'left', test: hasLeftSide },
    ];
    if (conf.shape) {
        sides = sidesMap
            .filter(side => side.test(conf.shape))
            .reduce((prev, side) => {
                if (!prev[side.side]) {
                    prev[side.side] = [];
                }
                prev[side.side].push(side.index);
                return prev;
            }, {});
    }

    return sides;
}

function hasBottomSide(shape: Shape) {
    return shape.shape !== 'circle';
}

function hasTopSide(shape: Shape) {
    return (
        ['rect', 'circle', 'arc'].indexOf(shape.shape) > -1
        || (shape.shape === 'poligon' && shape.s2 > 0)
    );
}

function hasRightSide(shape: Shape) {
    return (
        ['rect', 'triangle'].indexOf(shape.shape) > -1
        || (shape.shape === 'poligon'
            && (['SLT', 'SLS', 'SLC'].includes(shape.type) || shape.h2 > 0))
        || (shape.shape === 'arc' && (shape.h1 > 0 || shape.type === 'L'))
    );
}

function hasRightTopSide(shape: Shape) {
    return (
        shape.shape === 'poligon'
        && (['SRT', 'SRS', 'SRC'].includes(shape.type) || shape.h1 - shape.h2 > 0)
        && shape.s3 > 0
    );
}

function hasLeftTopSide(shape: Shape) {
    return shape.shape === 'poligon' && shape.h3 > 0;
}

function hasLeftSide(shape: Shape) {
    return (
        ['rect', 'triangle'].indexOf(shape.shape) > -1
        || (shape.shape === 'poligon' && shape.h1 - shape.h3 > 0 && shape.s1 > 0)
        || (shape.shape === 'arc' && (shape.h1 > 0 || shape.type === 'R'))
    );
}
