import angular from 'angular';

/**
 * Fabryka zamków
 * @param  {object} $rootScope               Angular Root Scope
 * @param  {object} $filter                  Filtry
 * @param  {object} ConfiguratorsDataService Fabyrka danych konfiguracji
 * @param  {object} CurConfService           Bieżaca konfiguracja
 * @param  {object} ParametersService        Fabryka parametrów
 * @param  {object} ConfigurationsService    Fabryka konfiguracji
 * @param  {object} Core                     Core
 * @param  {object} PriceService             Liczenie cen
 * @param  {object} IccConfig                Konfiguracja ICC
 * @return {object} factory                  Fabryka
 */
export default function LocksFactory($rootScope, $filter, ConfiguratorsDataService, CurConfService, // eslint-disable-line max-params
                                     ParametersService, ConfigurationsService, Core, PriceService, IccConfig, EventBusService) {
    'ngInject';

    var configurators       = ['door'];
    var allLocks         = [];

    var factory = {
        locks     : [],
        loadedData: false,

        selectLock,
        findLocksBySystem
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });

    return factory;

    /**
     * Funkcja inicjalizacyjna
     */
    function init() {
        allLocks      = ConfiguratorsDataService.data.locks;
        factory.locks = ConfiguratorsDataService.data.locks;

        if (configurators.indexOf(CurConfService.conf) !== -1) {
            findLocksBySystem();
        }

        factory.loadedData = true;
    }

    /**
     * Funkcja ustawiajaca domyślne zamki
     */
    function setDefaultLock(
        conf = ConfigurationsService.conf.Current,
        defaultConf = ConfigurationsService.conf.Default
    ) {
        if (angular.isUndefined(conf)) {
            return;
        }
        if (angular.isArray(factory.locks) && factory.locks.length > 0) {
            conf.Lock = factory.locks[0];
            defaultConf.Lock = factory.locks[0];
        } else {
            conf.Lock = {};
            defaultConf.Lock = {};
        }
        if (IccConfig.Configurators.dependencies) {
            EventBusService.post({ key: 'processDependencies', value: null });
        }
        PriceService.count();
        ParametersService.count(conf);
    }

    /**
     * Funkcja wybierajaca zamki
     * @param  {object} lock Zamki
     */
    function selectLock(lock) {
        ConfigurationsService.conf.Current.Lock = lock;
        ConfigurationsService.conf.Default.Lock = lock;
        if (IccConfig.Configurators.dependencies) {
            EventBusService.post({ key: 'processDependencies', value: null });
        }
        PriceService.count();
        ParametersService.count(ConfigurationsService.conf.Current);
    }

    /**
     * Funkcja znajdujaca zamki do systemu
     * @param  {object} system System
     */
    function findLocksBySystem(
        system = false,
        conf = ConfigurationsService.conf.Current,
        defaultConf = ConfigurationsService.conf.Default
    ) {
        if (!system) {
            system = conf.System;
        }

        if (!system) {
            return;
        }

        const sashTypesIds = conf.Sashes.map(sash => Number(sash.type.id));
        if (sashTypesIds.length === 0) {
            return;
        }

        factory.locks = [];
        angular.forEach(allLocks, lock => {
            var systemIds = lock.window_lines_ids;
            if (
                angular.isObject(system)
                && systemIds.indexOf(system.id) > -1
                && lock.sash_types_ids.some(
                    sashTypeId => sashTypesIds.map(Number).indexOf(Number(sashTypeId)) > -1
                )
            ) {
                if (
                    !IccConfig.Configurators.assignLockToHandles
                    || (IccConfig.Configurators.assignLockToHandles
                        && checkIfLockMatchHandles(lock, conf))
                ) {
                    factory.locks.push(lock);
                }
            }
        });
        if (factory.locks.map(lock => lock.id).indexOf(conf.Lock.id) === -1) {
            setDefaultLock(conf, defaultConf);
        }
    }

    /**
     * Funkcja sprawdzająca, czy zamek jest dostępny dla wybranej klamki
     * @param  {object} lock Zamek
     * @return {bool}      Informacja czy zamek pasuje do klamki
     */
    function checkIfLockMatchHandles(lock, conf = ConfigurationsService.conf.Current) {
        var handleType = conf.HandleType;
        var handle = conf.Handle;
        if (!handle || !handle.id) {
            const doorSash = conf.Sashes.find(sash => sash.type.type === 'DRA' || sash.type.type === 'DOA');
            if (doorSash) {
                handle = doorSash.handle;
            }
        }
        if (handleType == 'DoubleLever' && lock.lock_assign.indexOf(handle.handle_type) > -1) {
            return true;
        } else if (handleType == 'DoublePull' && handle.handle_type == 'pull' && lock.lock_assign.indexOf('doublePull') > -1) {
            return true;
        } else if (handleType == 'LeverPull' && handle.handle_type == 'doubleLever' && lock.lock_assign.indexOf('leverPull') > -1) {
            return true;
        } else if (handleType == 'LeverPull' && handle.handle_type == 'doubleKnob' && lock.lock_assign.indexOf('knobPull') > -1) {
            return true;
        }
        return false;
    }
}
