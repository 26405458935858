import { SideColors, Wood } from '../common';
import { Reinforcement } from '../window';

/** Profile montowane z boku ramy. */
export class SideProfile {
    /** Id */
    id: number;
    /** Ilość sztuk */
    count?: number;
    /** Id profilu */
    profileId: number;
    /** Id profilu */
    reinforcement: Reinforcement | null = null;
    /** Przesunięcie początku względem punktu. */
    shift: number = 0;
    /**
     * Punkt zaczepu początku.
     *
     * `{frame: 1}` - id ramy, od której górnej lub lewej krawędzi jest liczone przsunięcie
     * `{sideProfile: 1}` - id profilu bocznego, od którego początku jest liczone przesunięcie
     * `null` - początek znajdujący się na górnej lub lewej krawędzi konstrukcji
     */
    shiftPin: { frame: number } | { sideProfile: number } | null = null;
    /** Długość */
    length: number = 0;
    /**
     * Punkt zaczepu końca.
     *
     * `{frame: 1}` - id ramy, od której dolnej lub prawej krawędzi jest liczone przsunięcie
     * `{sideProfile: 1}` - id profilu bocznego, od którego końca jest liczone przesunięcie
     * `null` - początek znajdujący się na dolnej lub prawej krawędzi konstrukcji
     */
    lengthPin: { frame: number } | { sideProfile: number } | null = null;
    /** Szerokość wewnętrzna */
    width: number = 0;
    /** Szerokość zewnętrzna */
    widthOut: number = 0;
    /** Kolorystyka */
    color: Partial<SideColors> = {};
    /** Drewno */
    wood?: Partial<Wood> = {};
    /** Komentarz */
    comment: string = '';
    /** Identyfikatory sąsiadujących profili, do których jest przykręcony boczny profil */
    adjacentSideProfileId?: number[] = [];
    /** Identyfikatory sąsiadujących profili, które są przykręcone do tego profilu */
    adjacentOtherSideProfileId?: number[] = [];
    /** Identyfikatory sąsiadujących łączników, do których jest przykręcony boczny profil */
    adjacentCouplingId?: number[] = [];
    /** Identyfikatory sąsiadujących łączników, które są przykręcone do tego profilu */
    adjacentOtherCouplingId?: number[] = [];
    /**
     * Identyfikatory sąsiadujących ram oraz indeksy krawędzi ram,
     * do których jest przykręcony boczny profil
     */
    framesId: {
        id: number;
        edges: number[];
        positionId?: string;
    }[] = [];

    side: 'left' | 'right' | 'top' | 'bottom' | 'left-top' | 'right-top' = 'bottom';

    constructor(params?: {
        id: number;
        profileId: number;
        length: number;
        width: number;
        widthOut: number;
        color: SideColors;
        count?: number;
        wood: Wood;
        reinforcement: Reinforcement | null;
        adjacentSideProfileId: number[];
        adjacentCouplingId?: number[];
        framesId: {
            id: number;
            edges: number[];
            positionId?: string;
        }[];
        side: 'left' | 'right' | 'top' | 'bottom' | 'left-top' | 'right-top';
    }) {
        if (params) {
            this.id = params.id;
            this.profileId = params.profileId;
            this.length = params.length;
            this.width = params.width;
            this.widthOut = params.widthOut;
            this.color = params.color;
            this.count = params.count;
            this.wood = params.wood;
            this.reinforcement = params.reinforcement;
            this.adjacentSideProfileId = params.adjacentSideProfileId;
            this.adjacentCouplingId = params.adjacentCouplingId || [];
            this.framesId = params.framesId;
            this.side = params.side;
        }
    }
}
