import Common from '../../common/Common';

import { ActiveConfiguration } from './ActiveConfiguration';
import { Configuration } from './Configuration';
import { ConfiguratorsDataService } from '../configurators/configurators-data.service';

import AccessoriesActiveConfiguration from './AccessoriesActiveConfiguration';
import AccessoriesConfiguration from './AccessoriesConfiguration';
import AwningActiveConfiguration from './AwningActiveConfiguration';
import AwningConfiguration from './AwningConfiguration';
import ComplementaryGoodsActiveConfiguration from './ComplementaryGoodsActiveConfiguration';
import ComplementaryGoodsConfiguration from './ComplementaryGoodsConfiguration';
import CoupledWindowActiveConfiguration from './CoupledWindowActiveConfiguration';
import CoupledWindowConfiguration from './CoupledWindowConfiguration';
import DoorActiveConfiguration from './DoorActiveConfiguration';
import DoorConfiguration from './DoorConfiguration';
import FoldingDoorActiveConfiguration from './FoldingDoorActiveConfiguration';
import FoldingDoorConfiguration from './FoldingDoorConfiguration';
import GarageActiveConfiguration from './GarageActiveConfiguration';
import GarageConfiguration from './GarageConfiguration';
import HSActiveConfiguration from './HSActiveConfiguration';
import HSConfiguration from './HSConfiguration';
import MosquitoActiveConfiguration from './MosquitoActiveConfiguration';
import MosquitoConfiguration from './MosquitoConfiguration';
import RollerShutterActiveConfiguration from './RollerShutterActiveConfiguration';
import RollerShutterConfiguration from './RollerShutterConfiguration';
import WindowActiveConfiguration from './WindowActiveConfiguration';
import WindowConfiguration from './WindowConfiguration';
import WinterGardenActiveConfiguration from './WinterGardenActiveConfiguration';
import WinterGardenConfiguration from './WinterGardenConfiguration';
import ColorsDefaultsService from '../colors/colors-defaults.service';
import { TranslateService } from '../translate.service';

import { logger, core } from '../helpers';
import { APP_CONFIG, AppConfig } from '../config';
import { Injectable, Inject, Injector } from '@angular/core';
import WindowSmallConfiguration from './WindowSmallConfiguration';

interface Configurations {
    accessory: {
        active: typeof AccessoriesActiveConfiguration;
        simple: typeof AccessoriesConfiguration;
    };
    awning: {
        active: typeof AwningActiveConfiguration;
        simple: typeof AwningConfiguration;
    };
    complementary_goods: {
        active: typeof ComplementaryGoodsActiveConfiguration;
        simple: typeof ComplementaryGoodsConfiguration;
    };
    coupled_window: {
        active: typeof CoupledWindowActiveConfiguration;
        simple: typeof CoupledWindowConfiguration;
    };
    door: {
        active: typeof DoorActiveConfiguration;
        simple: typeof DoorConfiguration;
    };
    folding_door: {
        active: typeof FoldingDoorActiveConfiguration;
        simple: typeof FoldingDoorConfiguration;
    };
    garage: {
        active: typeof GarageActiveConfiguration;
        simple: typeof GarageConfiguration;
    };
    hs: {
        active: typeof HSActiveConfiguration;
        simple: typeof HSConfiguration;
    };
    mosquito: {
        active: typeof MosquitoActiveConfiguration;
        simple: typeof MosquitoConfiguration;
    };
    roller_shutter: {
        active: typeof RollerShutterActiveConfiguration;
        simple: typeof RollerShutterConfiguration;
    };
    window: {
        active: typeof WindowActiveConfiguration;
        simple: typeof WindowConfiguration;
    };
    winter_garden: {
        active: typeof WinterGardenActiveConfiguration;
        simple: typeof WinterGardenConfiguration;
    };
}

export type ConfigurationType = keyof Configurations;

export interface Configurator<T extends ConfigurationType = ConfigurationType> {
    Configuration: Configurations[T]['active'];
    Current: Configurations[T]['active']['prototype'];
    Edit: Configurations[T]['active']['prototype'] | undefined;
    Default: Configurations[T]['active']['prototype'];
    conf: T;
    NewPosition: boolean;
    measurementsAttachments: any[];
}

@Injectable()
export default abstract class ConfigurationsService<
    T extends ConfigurationType = ConfigurationType
> {
    conf: Partial<Configurator<T>> = {};
    price: any = { noPrice: null };
    version = 1;
    configurators: Partial<Record<ConfigurationType, Configurator>> = {};
    systemsComparisonEdit: boolean = false;

    configurations: Configurations = {
        accessory: {
            active: AccessoriesActiveConfiguration,
            simple: AccessoriesConfiguration,
        },
        awning: {
            active: AwningActiveConfiguration,
            simple: AwningConfiguration,
        },
        complementary_goods: {
            active: ComplementaryGoodsActiveConfiguration,
            simple: ComplementaryGoodsConfiguration,
        },
        coupled_window: {
            active: CoupledWindowActiveConfiguration,
            simple: CoupledWindowConfiguration,
        },
        door: {
            active: DoorActiveConfiguration,
            simple: DoorConfiguration,
        },
        folding_door: {
            active: FoldingDoorActiveConfiguration,
            simple: FoldingDoorConfiguration,
        },
        garage: {
            active: GarageActiveConfiguration,
            simple: GarageConfiguration,
        },
        hs: {
            active: HSActiveConfiguration,
            simple: HSConfiguration,
        },
        mosquito: {
            active: MosquitoActiveConfiguration,
            simple: MosquitoConfiguration,
        },
        roller_shutter: {
            active: RollerShutterActiveConfiguration,
            simple: RollerShutterConfiguration,
        },
        window: {
            active: WindowActiveConfiguration,
            simple: WindowConfiguration,
        },
        winter_garden: {
            active: WinterGardenActiveConfiguration,
            simple: WinterGardenConfiguration,
        },
    };
    constructor(
        protected translateService: TranslateService,
        @Inject(APP_CONFIG) protected config: AppConfig
    ) {}

    getSimpleConfiguration<K extends ConfigurationType>(type: K): Configurations[K]['simple'] {
        const configurations = this.configurations[type];
        if (configurations) {
            return configurations.simple;
        }
        return null;
    }

    getActiveConfiguration<K extends ConfigurationType>(type: K): Configurations[K]['active'] {
        const configurations = this.configurations[type];
        if (configurations) {
            return configurations.active;
        }
        return null;
    }

    createSimpleConfiguration(
        configuration: ActiveConfiguration | Configuration | WindowSmallConfiguration,
        dataRequiredForUpdate: any = {},
        configuratorsDataService?: ConfiguratorsDataService,
        colorsDefaultsService?: ColorsDefaultsService
    ) {
        const simpleConfiguration = this.getSimpleConfiguration(configuration.type);
        if (simpleConfiguration) {
            return new simpleConfiguration(
                configuration,
                dataRequiredForUpdate,
                true,
                configuratorsDataService,
                colorsDefaultsService
            );
        }
        return null;
    }

    createActiveConfiguration(
        configuration?: ActiveConfiguration | Configuration,
        configuratorsDataService?: ConfiguratorsDataService
    ) {
        if (configuration) {
            const activeConfiguration = this.getActiveConfiguration(configuration.type);
            if (configuration) {
                return new activeConfiguration(configuration, configuratorsDataService);
            }
        } else {
            const activeConfiguration = this.getActiveConfiguration(this.conf.conf);
            if (activeConfiguration) {
                return this.setDefaults(new activeConfiguration());
            }
        }
        return null;
    }

    setDefaults(configuration: Configurations[ConfigurationType]['active']['prototype']) {
        switch (configuration.type) {
            case 'window':
                configuration.Steel = this.config.IccConfig.Configurators.steelSelect
                    ? 'Opened'
                    : null;
                configuration.DrawId = 'draw_' + core.generateUUID();
                configuration.RollerShutter.hasAssembly = this.config.IccConfig.Configurators.roller_shutter.defaultAssemblyToWindow;
                break;
            case 'hs':
                configuration.Steel = this.config.IccConfig.Configurators.steelSelect
                    ? 'Opened'
                    : null;
                configuration.DrawId = 'draw_' + core.generateUUID();
                configuration.RollerShutter.hasAssembly = this.config.IccConfig.Configurators.roller_shutter.defaultAssemblyToWindow;
                break;
            case 'door':
                configuration.RollerShutter.hasAssembly = this.config.IccConfig.Configurators.roller_shutter.defaultAssemblyToWindow;
                break;
            case 'accessory':
                configuration.Name = this.translateService.instant('ACCESSORY|Akcesoria');
                break;
            case 'complementary_goods':
                configuration.Name = this.config.IccConfig.Configurators.complementary_goods
                    .namedAccessory
                    ? this.translateService.instant('ACCESSORY|Akcesoria')
                    : this.translateService.instant('GOODS|Dobra komplementarne');
                break;
            case 'winter_garden':
                configuration.Name = this.translateService.instant('WINTERGARDEN|Ogrody zimowe');
                break;
            case 'coupled_window':
                configuration.Name = this.translateService.instant('WINDOW|Konstrukcje złożone');
                break;
        }
        return configuration;
    }
}
