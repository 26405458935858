const SystemsSelectModalTemplate = require<string>('ngtemplate-loader!./systems-select-modal.component.html');
import SystemsSelectModalController from './systems-select-modal.controller';

export const SystemsSelectModalComponent: ng.IComponentOptions = {
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  templateUrl: SystemsSelectModalTemplate,
  controller: SystemsSelectModalController
};
