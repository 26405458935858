export default class PositionsGroupsModalController {
    resolve: {
        groups;
        drawOptions;
    };
    close: ({ $value }) => any;
    dismiss: () => any;

    constructor(
        private $rootScope,
        private ScrollbarService,
        private SystemsComparisonService,
        private InfoFactory,
        private $translate,
        public IccConfig,
    ) {}

    $onChanges() {
        this.ScrollbarService.update();
    }

    closeModal() {
        this.close({ $value: false });
    }

    selectGroup(group) {
        this.close({ $value: group });
    }

}
